import * as Sentry from "@sentry/browser"
import cn from "classnames"
import { styled } from "styled-components"

import { ThemeHeader } from "components"
import { NextButton, HorizontalRule } from "ui"
import { useQueryParams } from "ui/hooks"

const SCREENCAST_ID_TRANSLATION_MAPPINGS = {
  "psychological-safety": {
    es: {
      originalScreencastId: "665312098",
      translatedScreencastId: "871621248",
    },
  },
}

const VideoWalkthrough = ({ kitInstance, className }) => {
  const kitSlug = kitInstance?.kit?.slug
  const { lang } = useQueryParams()

  let screencastId = kitInstance.kit.screencast_id
  const screencastIdMapping = SCREENCAST_ID_TRANSLATION_MAPPINGS[kitSlug]?.[lang] ?? null
  if (screencastIdMapping) {
    const { originalScreencastId, translatedScreencastId } = screencastIdMapping
    if (screencastId && originalScreencastId && screencastId !== originalScreencastId) {
      Sentry.captureMessage(
        `It looks like the translated screencast ID for kit ${kitSlug}` +
          " is out of date, because the kit's original screencast ID has changed." +
          " Please check the translated screencast and update originalScreencastId" +
          " SCREENCAST_ID_TRANSLATION_MAPPINGS above if/when it is correct."
      )
    }
    screencastId = translatedScreencastId
  }

  return (
    <main className={cn("align-left", className)}>
      <div>
        <ThemeHeader kitInstance={kitInstance} className="pb-small" />
        <HorizontalRule />
        <div className="border-left-line my-large">
          <div className="text-gray-9 text-semi-bold pt-small">Give it a test run</div>
          <h2 className="text-gray-9 text-bold pb-small">Video: walk through this session</h2>
        </div>
        <HorizontalRule />
        <h3 className="text-gray-9 pt-medium pb-xs">Sneak preview</h3>
        <div className="text-gray-9">
          <div className="mb-large">
            Check out this short video of how this session will run when you do it with your team.
          </div>
        </div>
        {kitInstance.kit.screencast_id ? (
          <iframe
            title="Video"
            allowFullScreen
            frameBorder="0"
            src={`https://player.vimeo.com/video/${screencastId}`}
            width="100%"
            height="360"
            // passes the origin to the embedded iframe so vimeo can verify the domain
            referrerPolicy="strict-origin-when-cross-origin"
          />
        ) : (
          "Coming Soon"
        )}
      </div>
      <NextButton className="mt-xxxxl" navigateTo={kitInstance.home_url} />
    </main>
  )
}

export default styled(VideoWalkthrough)`
  .prep-container {
    display: flex;
    justify-content: space-between;
  }

  .border-left-line {
    position: relative;
    padding-left: 1.5rem;
  }
  .border-left-line:before {
    content: "";
    height: 100%;
    width: 8px;
    border-radius: 2px;
    display: block;
    position: absolute;
    left: 0;
    background: var(--rising-yellow);
  }
`
