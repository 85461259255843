import cn from "classnames"
import { Link, useLocation } from "react-router-dom"
import { styled } from "styled-components"

import SessionPreviewMessage from "domains/LeaderKit/SessionPreviewMessage"
import { KIT_GROUP_SIZE } from "domains/LeaderKit/utils"
import { ArrowRightIcon, PaperPlaneIcon, PenAltIcon, PlayCircleIcon } from "icons/FontAwesomeIcons"
import { getStandaloneExerciseUrl } from "resources/monthly_kit"
import { FlairContainer, Button, View, PageBreadcrumbs, TextContainer } from "ui"
import { useWindowSize } from "ui/hooks"

const MiniKitPrepHome = ({ kitInstance, user, team, className }) => {
  const { pathname } = useLocation()
  const { isDesktopOrLarger } = useWindowSize()
  const exerciseUrl = getStandaloneExerciseUrl({
    slug: kitInstance.slug,
    teamId: kitInstance.team_id,
    onFinishUrl: pathname,
  })
  const isDemoPreview = team.demo && user.id !== team.team_lead_id
  const previewMode = team.preview || isDemoPreview
  const isJumbo = kitInstance.kit.group_size === KIT_GROUP_SIZE.JUMBO

  return (
    <main className={cn("align-left full-width", className)}>
      <FlairContainer className="main-container">
        <TextContainer maxWidth={525} mobileMaxWidth={265} className="mb-large">
          <PageBreadcrumbs className="mb-medium">
            <Link to={`/kit?team_id=${team.id}`}>View all kits</Link>
            {kitInstance.kit.title}
          </PageBreadcrumbs>
          <h3 className="text-primary text-bold">{isJumbo ? "Jumbo " : ""}Mini Kit</h3>
          <h1 className="text-gray-9 text-bold py-small pr-medium">{kitInstance.kit.title}</h1>
          <div className="text-gray-9 text-thin">{kitInstance.kit.description}</div>
          {!!isJumbo && (
            <div className="text-gray-9 text-thin mt-medium">
              This version is optimized for groups up to 1000 people.
            </div>
          )}
        </TextContainer>
        <div>
          <div className="border-radius-small border-left-thick border-orange-3 box-shadow px-large py-medium mt-small mb-large">
            <View $alignItems="center" $justifyContent="space-between" className="pt-xs">
              <div className="step-title text-gray-9 text-bold">
                <h2 className="pr-small text-orange-3">Prep</h2>
              </div>
              <h4 className="step-time text-semi-bold text-orange-3 pr-xxs">{kitInstance.kit.prep_time} mins</h4>
            </View>
            <div className="text-gray-9 pt-medium pb-medium">{kitInstance.kit.prep_text}</div>
            {!!kitInstance.kit.screencast_id && (
              <View className="text-normal text-gray-9">
                <div className="icon-container">
                  <PlayCircleIcon color="var(--gray-9)" />
                </div>
                <div className="ml-medium mb-xs">
                  <Link to={`${kitInstance.prep_url}video-walkthrough`}>Watch a quick video</Link> on how to lead the
                  session with your team.
                </div>
              </View>
            )}
            <View>
              <div className="icon-container">
                <PaperPlaneIcon color="var(--gray-9)" />
              </div>
              <div className="ml-medium mb-xs">
                <Link to={kitInstance.session_preview_url}>Preview the session</Link> to walk through the details on
                each page.
              </div>
            </View>
            {!!kitInstance.kit.has_standalone_exercise && (
              <View>
                <div className="icon-container">
                  <PenAltIcon color="var(--gray-9)" />
                </div>
                <div className="ml-medium mb-xs">
                  <Link to={exerciseUrl}>Do the exercise in advance</Link> to complete the interactive activity on your
                  own.
                </div>
              </View>
            )}
          </div>
          <div className="border-radius-small border-left-thick border-rising-green box-shadow px-large py-medium mt-small">
            <View $alignItems="center" $justifyContent="space-between" className="pt-xs">
              <div className="step-title text-gray-9 text-bold">
                <h1 className="text-rising-green">GO</h1>
              </div>
              <h4 className="step-time text-semi-bold text-rising-green pr-xxs">45-60 mins</h4>
            </View>
            <div className="text-gray-9 pt-medium">
              All you (and your team) need to do is show up (in person or virtually) and bring a laptop or mobile device
              with internet access.
            </div>
            {!!team.preview && (
              <SessionPreviewMessage className="mt-small">
                To run a session, first <Link to="/admin?inviteLeads=true">add yourself as a team lead</Link>.
              </SessionPreviewMessage>
            )}
            <View
              $alignItems="center"
              $alignItemsMobile="flex-start"
              $flexDirectionMobile="column"
              className="mt-small"
            >
              {!previewMode && (
                <Button as={Link} to={kitInstance.session_start_url}>
                  Let's go
                  <ArrowRightIcon />
                </Button>
              )}
              <Link
                to={kitInstance.session_preview_url}
                className={cn("text-semi-bold", {
                  "ml-medium": isDesktopOrLarger && !previewMode,
                  "mt-xs": !isDesktopOrLarger && !previewMode,
                })}
              >
                Preview the session
              </Link>
            </View>
          </div>
          <div className="mt-xl">
            <h4>Still have questions?</h4>
            <div className="text-gray-9 pt-xs">
              <a href="https://app.risingteam.com/slack/invite" target="_blank" rel="noopener noreferrer">
                Ask our community of managers
              </a>{" "}
              and coaches in the Huddle on Slack.
            </div>
          </div>
        </div>
      </FlairContainer>
    </main>
  )
}

export default styled(MiniKitPrepHome)`
  .icon-container {
    font-size: 1.25rem;
    padding-top: 2px;
  }

  .border-left-thick {
    border-top: 1px solid #eee;
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;
  }

  @media (max-width: ${({ theme }) => theme.tabletMax}) {
    position: relative;

    .step-time {
      text-align: right;
    }

    .border-radius-small {
      border-radius: 2px;
    }

    .border-left-thick {
      border-top: none;
      border-right: none;
      border-bottom: none;
      margin-bottom: 40px;
      border-width: 5px;
    }
  }
`
