import cn from "classnames"

import { getUserAnswer } from "domains/Exercise/results_utils"
import { Loading, TextContainer, AnswerDisplayContent } from "ui"

const FeedbackDeliveringTeamLeadResults = ({ exerciseInstances, user, className }) => {
  const delivering_feedback_purpose = getUserAnswer(exerciseInstances, user, "delivering_feedback_purpose")
  const delivering_feedback_experience = getUserAnswer(exerciseInstances, user, "delivering_feedback_experience")
  const delivering_feedback_effect = getUserAnswer(exerciseInstances, user, "delivering_feedback_effect")
  const delivering_feedback_request = getUserAnswer(exerciseInstances, user, "delivering_feedback_request")

  if (!exerciseInstances || !user) {
    return <Loading />
  }

  return (
    <div className={cn("text-gray-9", className)}>
      <TextContainer>
        <h3 className="mb-medium">Fairy Godperson, deliver your message:</h3>
        <AnswerDisplayContent
          title="Cinderella, I want to give you some feedback because"
          answer={delivering_feedback_purpose}
          className="mb-medium"
        />
        <AnswerDisplayContent
          title="What I experienced was"
          answer={delivering_feedback_experience}
          className="mb-medium"
        />
        <AnswerDisplayContent
          title="The effect of this was"
          answer={delivering_feedback_effect}
          className="mb-medium"
        />
        <AnswerDisplayContent
          title="In the future, I hope that"
          answer={delivering_feedback_request}
          className="mb-medium"
        />
        <p>
          After you’ve delivered your message, pause and give Cinderella an opportunity to respond. Ask for their
          perspective and do your best active listening.
        </p>
      </TextContainer>
    </div>
  )
}

export default FeedbackDeliveringTeamLeadResults
