import cn from "classnames"
import { Link } from "react-router-dom"

import { EditIcon } from "icons/FontAwesomeIcons"
import { getStandaloneExerciseUrl } from "resources/monthly_kit"
import { useUser } from "resources/users"
import { Button } from "ui"

const UpdateResultsButton = ({ className, selectedUserId, kitSlug, selectedTeam }) => {
  const { data: user } = useUser({ userId: "me" })

  if (!user || selectedUserId !== user.id) {
    return null
  }

  return (
    <Button
      className={cn(className, "tertiary hide-in-print my-medium")}
      as={Link}
      to={getStandaloneExerciseUrl({ slug: kitSlug, teamId: selectedTeam.id })}
    >
      <EditIcon className="hide-on-mobile" />
      Update
    </Button>
  )
}

export default UpdateResultsButton
