import { useKitSession } from "../KitSessionContext"

import { useSessionExerciseInstances } from "domains/Exercise/resource"
import { getUserExerciseInstance } from "domains/Exercise/results_utils"
import { LearnerAgilityBehaviorsUserResults } from "domains/Exercise/ResultsComponents"
import {
  StepContent,
  SidebarContainer,
  SessionSelectedUsersShareView,
  FacilitatorTip,
} from "domains/KitSession/components"
import { useHasTeamFeature } from "domains/Results/utils"
import { TextContainer, Loading } from "ui"
import { SHARED_FLAGS } from "ui/hooks/useFeatures"
import { useSelectedUser } from "ui/SelectedUserContext"

const LearnerAgilityBehaviorsShareStep = ({ sessionStep, className }) => {
  const { kitInstance, team } = useKitSession()
  const { enabled: sessionRealtimeUpdates } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_ANSWER_UPDATE)

  const { data: exerciseInstances, isFetching } = useSessionExerciseInstances(kitInstance?.id, {
    sessionRealtimeUpdates,
    refetchInterval: 30000,
  })

  if (isFetching && !exerciseInstances) {
    return <Loading />
  }

  return (
    <StepContent className={className}>
      <h2 className="text-gray-9 mb-medium">Share out</h2>
      <SidebarContainer>
        <TextContainer className="text-gray-9 mr-xl">
          <p className="mb-large">
            Take turns sharing the strategies you picked, how you want to apply them, and how the team can support you.
          </p>
        </TextContainer>
        <FacilitatorTip className="sidebar-right" icon="share-screen" />
      </SidebarContainer>
      <SessionSelectedUsersShareView
        sessionStep={sessionStep}
        kitInstance={kitInstance}
        minMinutesPerUser={2}
        maxMinutesPerUser={5}
      >
        <UserResults exerciseInstances={exerciseInstances} />
      </SessionSelectedUsersShareView>
    </StepContent>
  )
}

const UserResults = ({ exerciseInstances }) => {
  const { selectedUser } = useSelectedUser()
  const selectedExercise = getUserExerciseInstance(exerciseInstances, selectedUser)

  if (!selectedExercise) {
    return null
  }

  return (
    <>
      <LearnerAgilityBehaviorsUserResults exercise={selectedExercise} selectedUser={selectedUser} />
    </>
  )
}

export default LearnerAgilityBehaviorsShareStep
