import { createContext, useContext } from "react"

import { useEffectAfterChange } from "ui/hooks"

const AccountTypeContext = createContext({
  accountType: null,
  setAccountType: () => {},
})

const useAccountType = () => useContext(AccountTypeContext) || {}

const useSetAccountType = ({ accountType, shouldSet, setAccountType }) => {
  useEffectAfterChange(() => {
    if (shouldSet) {
      setAccountType(accountType)
    }
  }, [shouldSet, accountType, setAccountType])
}

const useSetAccountTypeByTeam = (team) => {
  const { setAccountType } = useAccountType()
  useSetAccountType({ accountType: team?.account_type, shouldSet: !!team, setAccountType })
}

const useSetAccountTypeByBillingData = (billingData) => {
  const { setAccountType } = useAccountType()
  // if the billing data does not include subscriptionType and quantity, then we are not in the purchase flow and therefore shouldn't set the account type based on billing data
  const shouldSet = billingData && billingData.subscriptionType && billingData.quantity
  useSetAccountType({ accountType: billingData?.accountType, shouldSet, setAccountType })
}

const isSchoolAccountType = (accountType) => accountType === AccountType.MENZIES

const useIsSchoolAccountType = () => {
  const { accountType } = useAccountType()
  return isSchoolAccountType(accountType)
}

const useIsMenziesAccountType = () => {
  const { accountType } = useAccountType()
  return accountType === AccountType.MENZIES
}

const useRTBrandName = () => {
  const isSchool = useIsSchoolAccountType()
  return isSchool ? "Rising Team for Schools" : "Rising Team"
}

const AccountType = {
  STANDARD: "STANDARD",
  MENZIES: "MENZIES",
}

export {
  AccountTypeContext,
  isSchoolAccountType,
  useAccountType,
  useRTBrandName,
  useSetAccountType,
  useSetAccountTypeByTeam,
  useSetAccountTypeByBillingData,
  useIsSchoolAccountType,
  useIsMenziesAccountType,
  AccountType,
}
