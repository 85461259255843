import cn from "classnames"
import { useNavigate } from "react-router-dom"

import Button from "./Button"

import { ArrowLeftIcon } from "icons/FontAwesomeIcons"

const BackButton = ({ className, navigateTo, onClick, onNavigate, ...props }) => {
  const navigate = useNavigate()
  const handleOnClick = async (evt) => {
    if (onClick) {
      onClick(evt)
    } else {
      let cancelNavigation = false
      if (onNavigate) {
        cancelNavigation = await onNavigate()
      }
      if (!cancelNavigation) {
        if (navigateTo) {
          navigate(navigateTo)
        } else {
          navigate(-1)
        }
      }
    }
  }
  return (
    <Button className={cn(className, "nav-button")} onClick={handleOnClick} {...props}>
      <ArrowLeftIcon />
      Back
    </Button>
  )
}

export default BackButton
