import * as Yup from "yup"

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
/* for reference;
  https://stackoverflow.com/questions/52483260/validate-phone-number-with-yup
*/

const userSchemas = {
  first_name: Yup.string().required("This field is required.").label("First name"),
  last_name: Yup.string().required("This field is required.").label("Last name"),
  email: Yup.string().email().required("This field is required.").label("Email"),
  password: Yup.string().required("This field is required.").label("Password"),
  time_zone: Yup.string().required("This field is required.").label("Time zone"),
}

const profileSchemas = {
  pronouns: Yup.string().label("Pronouns"),
  org_name: Yup.string().required("This field is required.").label("Org name"),
  job_function: Yup.string().required("This field is required.").label("Job function"),
  years_of_management: Yup.string().required("This field is required."),
  years_in_industry: Yup.string().required("This field is required."),
  team_location_type: Yup.string().required("This field is required.").label("Company type"),
  org_type: Yup.string().required("This field is required."),
  hear_us_through: Yup.string().required("This field is required.").label("Hear us through"),
  product_usage_preference: Yup.string().required("This field is required.").label("Product usage preference"),
  phone_number: Yup.string().label("Your phone number").matches(phoneRegExp, "Phone number is not valid"),
}

const demoTeamSchemas = {
  org_name: Yup.string().required("This field is required.").label("Org name"),
  demo_type: Yup.string().label("Demo type"),
  search: Yup.string().label("Search"),
}

export default Yup

export { userSchemas, profileSchemas, demoTeamSchemas }
