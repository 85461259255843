import { find, set } from "lodash-es"

import AssessmentAccuracyForm from "./AssessmentAccuracyForm"

import { useMutateExerciseAnswer } from "domains/Exercise/resource"
import { Yup } from "forms"
import { TextareaField, LineRatingField } from "forms/fields"

const TalentsAccuracyForm = ({ exercise }) => {
  const { mutateAsync: mutateExerciseAnswer } = useMutateExerciseAnswer(exercise.id)

  const initialValues = {
    assessment_feel: find(exercise.answers, { identifier: "assessment_feel" })?.data,
    assessment_feel_reason: find(exercise.answers, { identifier: "assessment_feel_reason" })?.data,
  }
  const lineRatingLabels = ["Not at all", "Just a bit", "Somewhat", "Pretty much", "A lot"]

  const saveOnChange = (name, value) => {
    mutateExerciseAnswer(set({}, name, value))
  }

  return (
    <AssessmentAccuracyForm title="Reflect" initialValues={initialValues} validationSchema={reviewSchema}>
      <p className="text-normal text-gray-9 mb-large">
        The purpose of this exercise is to help uncover patterns and inspire conversation; not put you in a box.
      </p>
      <p className="text-field-label mb-xs">How much do these results feel like you?</p>
      <LineRatingField
        numOptions={5}
        name="assessment_feel"
        axisLabels={lineRatingLabels}
        ariaLabels={lineRatingLabels}
        className="mb-large"
        saveOnChange={saveOnChange}
      />
      <TextareaField
        name="assessment_feel_reason.value"
        label="Why or why not?"
        className="mb-large"
        saveOnChange={saveOnChange}
      />
    </AssessmentAccuracyForm>
  )
}

const reviewSchema = Yup.object().shape({
  assessment_feel: Yup.object().shape({
    value: Yup.string().required().label("How much does this assessment feel like you?"),
  }),
  assessment_feel_reason: Yup.object().shape({
    value: Yup.string().label("Why or why not?"),
  }),
})

export default TalentsAccuracyForm
