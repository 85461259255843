import cn from "classnames"
import { Formik } from "formik"
import { find, keyBy, sortBy } from "lodash-es"
import { useState } from "react"

import ShareResultsButton from "./ShareResultsButton"
import UpdateResultsButton from "./UpdateResultsButton"

import { useLatestTeamExerciseInstances } from "domains/Exercise/resource"
import { getUserExerciseInstance } from "domains/Exercise/results_utils"
import { LEARNER_AGILITY_DATA } from "domains/Exercise/results_utils/learner-agility"
import { LearnerAgilityBehaviorsUserResults } from "domains/Exercise/ResultsComponents"
import LearnerAgilityChart from "domains/Exercise/ResultsComponents/LearnerAgilityChart"
import LearnerAgilityLearningPreferencesResults from "domains/Exercise/ResultsComponents/LearnerAgilityLearningPreferencesResults"
import { SelectField } from "forms/fields"
import { BrainIcon } from "icons/FontAwesomeIcons"
import { sortUsersByShortName } from "resources/users"
import { View, Loading } from "ui"
import { useQueryParams } from "ui/hooks"

const LearnerAgilityResultsReview = ({ kit, selectedTeam }) => {
  const query = useQueryParams()
  const { data: exerciseInstances, isFetching } = useLatestTeamExerciseInstances({
    teamId: selectedTeam.id,
    exerciseSlug: kit.exercise.slug,
  })

  if (isFetching) {
    return <Loading />
  }

  const users = selectedTeam.members.filter((x) => getUserExerciseInstance(exerciseInstances, x))
  const userIdMap = keyBy(users, "id")
  const initialUserId = parseInt(query.participant)
  const initialUser = find(users, { id: initialUserId })
  const filteredExerciseInstances = exerciseInstances.filter((x) => userIdMap[x.user_id])

  return (
    <>
      <View $alignItems="flex-start" className="mb-large">
        <BrainIcon className="text-orange-2 title-icon mr-medium" />
        <h1 className="text-gray-9">Learning profile</h1>
      </View>
      <SelectedUserResults
        users={users}
        userIdMap={userIdMap}
        exerciseInstances={filteredExerciseInstances}
        initialUser={initialUser}
        kit={kit}
        selectedTeam={selectedTeam}
      />
    </>
  )
}

const TEAM_ALL = 0

const SelectedUserResults = ({ users, userIdMap, exerciseInstances, initialUser, kit, selectedTeam }) => {
  const [shareLink, setShareLink] = useState(null)
  const [selectedUserId, setSelectedUserId] = useState(initialUser?.id || TEAM_ALL)
  const sortedUsers = sortUsersByShortName({ users })
  const initialValues = { current_team_member: selectedUserId }
  const selectedExercise = getUserExerciseInstance(exerciseInstances, { id: selectedUserId })

  const handleUserChange = ({ target }) => {
    const user = find(users, {
      id: parseInt(target.value),
    })
    setSelectedUserId(user?.id || TEAM_ALL)
    setShareLink(null)
  }

  return (
    <>
      <View $alignItems="flex-start" $flexWrap="wrap" className="mb-xl mt-medium">
        <View $flexDirection="row" $flexDirectionTablet="column" $flexDirectionMobile="column" $alignItems="center">
          <View $flexDirection="column">
            <Formik initialValues={initialValues}>
              <>
                <SelectField
                  name="current_team_member"
                  size="medium"
                  value={selectedUserId}
                  onChange={handleUserChange}
                >
                  <option value={TEAM_ALL}>Team summary</option>
                  {sortedUsers.map(({ id, short_name }) => (
                    <option key={id} value={id}>
                      {short_name}
                    </option>
                  ))}
                </SelectField>
              </>
            </Formik>
          </View>
          <View $justifyContent="flex-end" $justifyContentTablet="flex-start" $justifyContentMobile="flex-start">
            <ShareResultsButton
              selectedUserId={selectedUserId}
              selectedExercise={selectedExercise}
              shareLink={shareLink}
              setShareLink={setShareLink}
              shareAssessmentTitle="Learning Profile"
            />
            <UpdateResultsButton selectedUserId={selectedUserId} kitSlug={kit.slug} selectedTeam={selectedTeam} />
          </View>
        </View>
      </View>
      {selectedUserId === TEAM_ALL ? (
        <LearnerAgilityTeamSummary exerciseInstances={exerciseInstances} userIdMap={userIdMap} />
      ) : (
        <LearnerAgilityUserSummary
          userIdMap={userIdMap}
          selectedExercise={selectedExercise}
          selectedUserId={selectedUserId}
        />
      )}
    </>
  )
}

const LearnerAgilityTeamSummary = ({ userIdMap, exerciseInstances, className }) => (
  <div className={className}>
    <h2 className="text-gray-9 mb-medium">Learning preferences</h2>
    <p>
      One benefit of the modern age is that there are many ways to learn just about any topic or skill. And people have
      different preferences around how they like to learn, process, and retain new information. Levering our preferences
      can make it easier to learn.
    </p>
    <LearnerAgilityChart userIdMap={userIdMap} exerciseInstances={exerciseInstances} />
    <LearningStyles className="mb-xl" />
  </div>
)

const LearningStyles = ({ className }) => (
  <div className={cn("space-y-xl", className)}>
    {sortBy(Object.values(LEARNER_AGILITY_DATA), "title").map((learningStyle) => (
      <div key={learningStyle.title}>
        <h4 className="text-rising-orange mb-xs">{learningStyle.title}</h4>
        <p className="text-gray-9 mb-small">{learningStyle.description}</p>
        <ul className="text-gray-9 ml-xl mb-small">
          {learningStyle.descriptionBulletPoints.map((item, idx) => (
            <li key={learningStyle + idx}>{item}</li>
          ))}
        </ul>
      </div>
    ))}
  </div>
)

const LearnerAgilityUserSummary = ({ userIdMap, selectedExercise, selectedUserId }) => (
  <>
    <h2 className="text-bold text-gray-9 mb-xs">Learning behaviors</h2>
    <p className="mb-medium text-gray-9">
      Our ability to learn new things isn’t fixed; it’s something we can strengthen by practicing specific behaviors.
    </p>
    <LearnerAgilityBehaviorsUserResults className="mb-xxxl" exercise={selectedExercise} selectedUser={selectedUserId} />
    <h2 className="text-bold text-gray-9 mb-xs">Learning preferences</h2>
    <p className="mb-medium text-gray-9">
      One benefit of the modern age is that there are many ways to learn just about any topic or skill. And people have
      different preferences around how they like to learn, process, and retain new information. Levering our preferences
      can make it easier to learn.
    </p>
    <LearnerAgilityChart userIdMap={userIdMap} exerciseInstances={[selectedExercise]} showLegend={false} />
    <h3 className="text-gray-9 mb-medium">Your top three</h3>
    <LearnerAgilityLearningPreferencesResults exercise={selectedExercise} isCompact={false} />
  </>
)

export default LearnerAgilityResultsReview

export { LearningStyles }
