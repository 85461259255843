import {
  CrisisResponsePreviousCrisisUserResults,
  CrisisResponseUserResponderTypeResults,
} from "domains/Exercise/ResultsComponents"
import { SirenOnIcon } from "icons/FontAwesomeIcons"
import { View } from "ui"

const CrisisResponseShareResults = ({ exerciseInstance }) => (
  <div>
    <div className="mb-medium">
      <View $alignItems="center">
        <SirenOnIcon className="text-orange-3 title-icon mr-medium" />
        <h1 className="text-gray-9">Crisis Response</h1>
      </View>
    </div>
    <p className="text-gray-9 mb-small">
      Your team’s Crisis Response Toolboxes were created during your Crisis Response session. You can view each person’s
      individual results and the team as a whole. Keeping these results top of mind can make working together more
      productive and fun.
    </p>
    <CrisisResponsePreviousCrisisUserResults exercise={exerciseInstance} />
    <h2 className="text-bold text-gray-9 mb-xs">Responder types</h2>
    <p className="text-normal text-gray-9 mb-medium">Top 3 matches:</p>
    <CrisisResponseUserResponderTypeResults exercise={exerciseInstance} className="mb-large" />
  </div>
)

export default CrisisResponseShareResults
