import { StepContent, SessionSelectedUsersShareView } from "../components"
import { useKitSession } from "../KitSessionContext"

import { useSessionExerciseInstances } from "domains/Exercise/resource"
import { getUserExerciseInstance } from "domains/Exercise/results_utils"
import { ExpectationsUserResults } from "domains/Exercise/ResultsComponents"
import { useHasTeamFeature } from "domains/Results/utils"
import { TextContainer, Callout, BulletedList, Loading } from "ui"
import { SHARED_FLAGS } from "ui/hooks/useFeatures"
import { useSelectedUser } from "ui/SelectedUserContext"

const ExpectationsRoadDiscussionStep = ({ sessionStep }) => {
  const { kitInstance, team } = useKitSession()
  const { enabled: sessionRealtimeUpdates } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_ANSWER_UPDATE)

  const { data: exerciseInstances, isFetching } = useSessionExerciseInstances(kitInstance?.id, {
    sessionRealtimeUpdates,
    refetchInterval: 30000,
  })

  if (isFetching && !exerciseInstances) {
    return <Loading />
  }

  return (
    <StepContent>
      <TextContainer className="text-gray-9">
        <h2 className="mb-medium">Wrap-up</h2>
        <div className="mb-large">Now take a few minutes to share your experience.</div>
        <Callout className="text-gray-9 mb-xxxxl">
          <h4 className="mb-small">Discuss</h4>
          <BulletedList>
            <li>What was your experience using the ROAD framework?</li>
            <li>What came easily to you?</li>
            <li>What was challenging?</li>
          </BulletedList>
        </Callout>
      </TextContainer>
      <SessionSelectedUsersShareView
        sessionStep={sessionStep}
        kitInstance={kitInstance}
        minMinutesPerUser={3}
        maxMinutesPerUser={5}
      >
        <UserResults exerciseInstances={exerciseInstances} />
      </SessionSelectedUsersShareView>
    </StepContent>
  )
}

const UserResults = ({ exerciseInstances }) => {
  const { selectedUser } = useSelectedUser()
  const selectedExercise = getUserExerciseInstance(exerciseInstances, selectedUser)

  if (!selectedExercise) {
    return null
  }

  return <ExpectationsUserResults exercise={selectedExercise} is_full_exercise={true} />
}

export default ExpectationsRoadDiscussionStep
