import { StarIcon, PeopleGroupIcon } from "icons/FontAwesomeIcons"
import { Tooltip, View } from "ui"
import { plural } from "utils/string"

const KitsTeamDetails = ({ selectedTeam, user, className }) => {
  const teamLeadId = selectedTeam?.team_lead_id
  const isCurrentTeamLead = selectedTeam.team_lead_id === user.id
  const teamLeadDetails = selectedTeam.members.find((member) => member.id === teamLeadId)

  const teamLeadToolTip = (
    <p className="ml-xs">Team Lead: {isCurrentTeamLead ? "You" : `${teamLeadDetails?.name ?? ""}`}</p>
  )
  const teamMembersToolTip = (
    <ul className="ml-xs">
      {selectedTeam?.members.map((member) => (
        <li key={member.id}>{member?.first_name}</li>
      ))}
    </ul>
  )

  return (
    <View $gap="16px" $alignItems="center" className={className}>
      <Tooltip bottom float title={teamLeadToolTip} className="fit-content">
        <View $alignItems="center" $justifyContent="flex-start">
          <StarIcon color="var(--rising-yellow)" className="fa-sm pr-xxs" />
          <p>{isCurrentTeamLead ? "You" : `${teamLeadDetails?.first_name}`}</p>
        </View>
      </Tooltip>
      <Tooltip bottom float title={teamMembersToolTip} className="fit-content">
        <View $alignItems="center" $alignContent="center" $justifyContent="flex-start">
          <PeopleGroupIcon color="var(--orange-2)" className="fa-sm pr-xxs" />
          <p>{plural(selectedTeam?.members?.length ?? 0, "member")}</p>
        </View>
      </Tooltip>
    </View>
  )
}

export default KitsTeamDetails
