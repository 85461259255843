import { Form, Formik } from "formik"
import { pick, mergeWith } from "lodash-es"
import { useNavigate } from "react-router"

import { handleErrors, Yup } from "forms"
import { TextField, PreferredPronounsField, JobFunctionsField } from "forms/fields"
import { profileSchemas } from "forms/yup"
import { useUser, useUpdateCurrentUser } from "resources/users"
import { SubmitButton } from "ui"

const profileSchema = Yup.object().shape({
  ...pick(profileSchemas, ["org_name", "job_function", "pronouns", "phone_number"]),
})

const SetupProfileStep = () => {
  const navigate = useNavigate()
  const { mutateAsync: updateCurrentUser } = useUpdateCurrentUser()
  const { data: user } = useUser({ userId: "me" })

  if (!user) {
    return null
  }

  const onSubmit = handleErrors(async (values) => {
    await updateCurrentUser({
      profile: values,
    })
    const numIncompleteSteps = user.setup_steps?.filter(({ completed }) => !completed).length ?? 0
    const nextStep = numIncompleteSteps ? "/setup/incomplete" : "/setup/complete"
    navigate(nextStep)
  })

  const initialValues = mergeWith(
    {
      org_name: "",
      job_function: "",
      pronouns: "",
      phone_number: "",
    },
    user.profile,
    (objValue, srcValue) => (srcValue != null ? srcValue : objValue)
  )

  return (
    <div>
      <h3 className="mb-medium">Set up your profile</h3>
      <p>Help us design a better experience by telling us a little about yourself</p>
      <>
        <Formik validationSchema={profileSchema} onSubmit={onSubmit} initialValues={initialValues}>
          <Form name="set-up-team-profile" className="mt-large">
            <TextField label="Your company/organization name" name="org_name" className="full-width p-xxs mb-small" />
            <JobFunctionsField label="What is your job function" name="job_function" className="p-xxs mb-small" />
            <PreferredPronounsField
              label="How would you like us to refer to you?"
              name="pronouns"
              className="p-xxs mb-small"
            />
            <TextField
              label="Would you like to get a text message when new Kits are available?"
              name="phone_number"
              placeholder="Enter your phone number"
              className="full-width p-xxs mb-small"
            />
            <SubmitButton label="Continue" className="primary mt-medium mb-small" />
          </Form>
        </Formik>
      </>
    </div>
  )
}

export default SetupProfileStep
