import { Formik, Form } from "formik"
import { useNavigate } from "react-router-dom"

import { FormMessage, handleErrors, Yup } from "../../../forms"
import { HiddenField, PasswordField } from "../../../forms/fields"
import { resetPassword } from "../resource"

import { SubmitButton } from "ui"
import { useQueryParams } from "ui/hooks"

const InputForm = () => (
  <>
    <h2 className="mb-xxxxl text-bold">Reset password</h2>
    <Form style={{ display: "flex", flexDirection: "column" }} className="space-y-large">
      <HiddenField name="uid" />
      <HiddenField name="token" />
      <PasswordField label="Enter new password" name="new_password1" autoComplete="new-password" autoFocus />
      <PasswordField label="Confirm new password" name="new_password2" autoComplete="new-password" />
      <FormMessage />
      <div>
        <SubmitButton>Done</SubmitButton>
      </div>
    </Form>
  </>
)

const InviteSchema = Yup.object().shape({
  new_password1: Yup.string().required("This field is required."),
  new_password2: Yup.string().oneOf([Yup.ref("new_password1"), null], "Passwords must match"),
})

const ResetPasswordForm = () => {
  const navigate = useNavigate()
  const { uid, token } = useQueryParams()

  const onSubmit = handleErrors(async (values) => {
    await resetPassword(values)
    navigate("/")
  })

  const initialValues = {
    uid: uid || "",
    token: token || "",
    new_password1: "",
    new_password2: "",
  }
  return (
    <Formik initialValues={initialValues} validationSchema={InviteSchema} onSubmit={onSubmit} component={InputForm} />
  )
}

export default ResetPasswordForm
