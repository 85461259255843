import cn from "classnames"
import { useField } from "formik"
import { shuffle, sortBy } from "lodash-es"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import { styled } from "styled-components"

import { ReorderIcon } from "icons"
import { AngleDownIcon, AngleUpIcon } from "icons/FontAwesomeIcons"
import { Button, Loading, View } from "ui"
import { useEffectAfterFirstRender } from "ui/hooks"

const ExerciseRankOrderField = styled(function ExerciseRankOrderField({ saveOnChange, name, options, className }) {
  const [field, _meta, { setValue }] = useField(name)
  const hasValue = !!field.value?.length

  useEffectAfterFirstRender(() => {
    if (!hasValue) {
      const shuffledOptions = shuffle(options)
      const currentValue = shuffledOptions.map((rankItem) => rankItem.value)
      setValue(currentValue)
      saveOnChange(name, currentValue)
    }
  })

  if (!hasValue) {
    return <Loading />
  }

  const sortedOptions = sortBy(options, [(o) => field.value.indexOf(o.value)])

  function handleOnDragEnd(result) {
    if (!result.destination) return
    reOrder(result.source.index, result.destination.index)
  }

  const handleOnUp = (index) => {
    if (index === 0) {
      return
    }
    reOrder(index, index - 1)
  }

  const handleOnDown = (index) => {
    if (index === sortedOptions.length - 1) {
      return
    }
    reOrder(index, index + 1)
  }

  function reOrder(currentIndex, newIndex) {
    const rankedItems = Array.from(sortedOptions)
    const [reorderedItem] = rankedItems.splice(currentIndex, 1)
    rankedItems.splice(newIndex, 0, reorderedItem)

    const newOrder = rankedItems.map((rankItem) => rankItem.value)
    setValue(newOrder)
    saveOnChange(name, newOrder)
  }

  return (
    <View className={cn("py-large", className)}>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef} className="rank-order-field-container">
              {sortedOptions.map(({ label, value }, index) => (
                <Draggable key={value} draggableId={value} index={index}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className="ranking-items border-top border-bottom--last py-medium"
                    >
                      <div className="number-ranking mr-medium">{index + 1}</div>
                      <View className="mr-small">{label}</View>
                      <View $width="auto" $alignItems="center">
                        <View $flexDirection="column" $width="auto">
                          <Button className="link" onClick={() => handleOnUp(index)}>
                            <AngleUpIcon className="text-gray-8" />
                          </Button>
                          <Button className="link" onClick={() => handleOnDown(index)}>
                            <AngleDownIcon className="text-gray-8" />
                          </Button>
                        </View>
                        <View $width="auto" className="ml-small">
                          <ReorderIcon />
                        </View>
                      </View>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </View>
  )
})`
  .ranking-items {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .link {
    margin: 0;
    padding: 0;
    height: auto;
  }

  .number-ranking {
    color: var(--rising-orange);
  }

  .rank-order-field-container {
    width: 100%;
    background-color: #fff;
  }
`

export default ExerciseRankOrderField
