import cn from "classnames"
import { styled } from "styled-components"

import { getExerciseAnswer } from "../results_utils"

import { PieChart, View } from "ui"

const SelectFieldMultiUserPieChart = styled(function SelectFieldMultiUserPieChart({
  identifier,
  exerciseInstances,
  exerciseComponent,
  colors,
  className,
  ...props
}) {
  const selectOptions = exerciseComponent.options
  const data = selectOptions.map((option) => {
    const selectedExerciseInstances = exerciseInstances.filter((instance) =>
      getExerciseAnswer(instance, identifier)?.includes(option.value)
    )
    const percentage = (selectedExerciseInstances.length / exerciseInstances.length) * 100
    return {
      id: option.value,
      label: option.short_label,
      tooltip: `${option.label}: <b>${selectedExerciseInstances.length}</b>`,
      value: percentage,
    }
  })

  if (!data.length) {
    return null
  }

  return (
    <View className={cn(className, "pie-chart")}>
      <PieChart
        data={data}
        innerRadius={0}
        enableArcLabels={false}
        enableArcLinkLabels={false}
        animate={false}
        colors={colors}
        showMargins={false}
        {...props}
      />
    </View>
  )
})`
  width: 40%;
  height: 250px;

  tspan {
    font-size: 0.875rem;
    line-height: 1.5rem;
  }

  // increase weight of text within chart sections
  text[text-anchor="middle"] {
    font-weight: 600;
  }

  @media (max-width: ${({ theme }) => theme.mobileMax}) {
    width: 100%;

    tspan {
      font-size: 0.8125rem;
      line-height: 1.25rem;
    }
  }
`

export default SelectFieldMultiUserPieChart
