import IconGroupRatingFieldAggregatedMultiUserBarChart from "./IconGroupRatingFieldAggregatedMultiUserBarChart"
import IconGroupRatingFieldAggregatedMultiUserBreakoutBarChart from "./IconGroupRatingFieldAggregatedMultiUserBreakoutBarChart"
import PercentageGroupFieldAggregatedMultiUserBarChart from "./PercentageGroupFieldAggregatedMultiUserBarChart"
import PieSliceGroupFieldAggregatedMultiUserBarChart from "./PieSliceGroupFieldAggregatedMultiUserBarChart"
import PieSliceGroupFieldAggregatedMultiUserScatterPlot from "./PieSliceGroupFieldAggregatedMultiUserScatterPlot"
import PieSliceGroupRatingFieldAggregatedMultiUserBarChart from "./PieSliceGroupRatingFieldAggregatedMultiUserBarChart"
import RadioGroupFieldAggregatedMultiUserBarChart from "./RadioGroupFieldAggregatedMultiUserBarChart"
import RadioGroupFieldAggregatedMultiUserStackedBar from "./RadioGroupFieldAggregatedMultiUserStackedBar"
import TextFieldAggregatedMultiUserScroll from "./TextFieldAggregatedMultiUserScroll"
import TextFieldAggregatedMultiUserSlideshowWithRevealAccordion from "./TextFieldAggregatedMultiUserSlideshowWithRevealAccordion"
import TextFieldAggregatedMultiUserWordCloud from "./TextFieldAggregatedMultiUserWordCloud"

import { renderBlocks, blockComponents } from "components/Blocks"
import { blockIcons } from "icons"
import { blockUIComponents } from "ui"

const EXERCISE_IDENTIFIER_COMPONENTS = {
  IconGroupRatingFieldAggregatedMultiUserBarChart,
  IconGroupRatingFieldAggregatedMultiUserBreakoutBarChart,
  PercentageGroupFieldAggregatedMultiUserBarChart,
  PieSliceGroupFieldAggregatedMultiUserBarChart,
  PieSliceGroupRatingFieldAggregatedMultiUserBarChart,
  PieSliceGroupFieldAggregatedMultiUserScatterPlot,
  RadioGroupFieldAggregatedMultiUserBarChart,
  RadioGroupFieldAggregatedMultiUserStackedBar,
  TextFieldAggregatedMultiUserScroll,
  TextFieldAggregatedMultiUserSlideshowWithRevealAccordion,
  TextFieldAggregatedMultiUserWordCloud,
}
const isExerciseIdentifierComponent = (Tag) => Object.values(EXERCISE_IDENTIFIER_COMPONENTS).includes(Tag)

const AggregatedResultsBlocks = ({ blocks, useAggregatedResultsData, sortedUsers }) => {
  if (!blocks) {
    return null
  }

  const components = { ...blockComponents, ...blockUIComponents, ...blockIcons, ...EXERCISE_IDENTIFIER_COMPONENTS }
  return renderBlocks(blocks, components, ({ Tag, props }) => {
    if (isExerciseIdentifierComponent(Tag)) {
      return {
        ...props,
        useAggregatedResultsData,
        sortedUsers,
      }
    }
    return props
  })
}

export default AggregatedResultsBlocks
