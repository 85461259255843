import type { UserData, TeamData } from "types"

import { NO_ONE_MEMBER } from "./constants"

const getPopularTopics = ({
  team,
  user,
  member,
  sampleOnly = false,
}: {
  team: TeamData
  user: UserData
  member: UserData
  sampleOnly: boolean
}) => {
  if (!member || member.id === NO_ONE_MEMBER.id) {
    return sampleOnly ? getSampleTopicsNoOne() : getPopularTopicsNoOne()
  } else if (user.id === member.id) {
    return sampleOnly ? getSampleTopicsMyself() : getPopularTopicsMyself()
  } else if (team.team_lead_id === user.id || team.demo) {
    return sampleOnly ? getSampleTopicsToDirectReport() : getPopularTopicsToDirectReport(member)
  } else if (team.team_lead_id === member.id) {
    return sampleOnly ? getSampleTopicsToManager() : getPopularTopicsToManager(member)
  } else {
    return sampleOnly ? getSampleTopicsToPeer() : getPopularTopicsToPeer(member)
  }
}

const getSampleTopicsNoOne = () => ["Setting goals", "Improving work/life balance", "General problem solving"]

const getPopularTopicsNoOne = () => [
  "How do I prioritize my workload?",
  "How can I increase my influence and impact at work?",
  "Help me stop procrastinating.",
  "How can I improve my work/life balance?",
  "How can I set better boundaries?",
  "How can I stop working on too many things at once?",
  "Help me create a career development plan.",
  "Help me write and set goals.",
  "Help me write my self-review.",
  "I just want to vent.",
  "I'm feeling stressed about…",
  "I'm struggling with…",
  "Help me resolve a conflict.",
  "Help me prepare for a difficult conversation.",
  "What do I do if I feel underappreciated?",
]

const getSampleTopicsMyself = () => ["Setting goals", "Improving work/life balance", "General problem solving"]

const getPopularTopicsMyself = () => [
  "How do I prioritize my workload?",
  "How can I increase my influence and impact at work?",
  "Help me stop procrastinating.",
  "How can I improve my work/life balance?",
  "How can I set better boundaries?",
  "How can I stop working on too many things at once?",
  "Help me create a career development plan.",
  "Help me write and set goals.",
  "Help me write my self-review.",
  "I just want to vent.",
  "I'm feeling stressed about…",
  "I'm struggling with…",
  "Help me resolve a conflict.",
  "Help me prepare for a difficult conversation.",
  "What do I do if I feel under appreciated?",
]

const getSampleTopicsToDirectReport = () => [
  "Giving feedback",
  "Showing appreciation",
  "Preparing for a difficult conversation",
]

const getPopularTopicsToDirectReport = (member: UserData) => {
  const first = member.first_name
  const possessiveAdjectivePronoun = member.pronouns[2]

  return [
    `I'd like to appreciate ${first} for ${possessiveAdjectivePronoun} work on…`,
    `I'd like to give constructive feedback to ${first} on…`,
    `How can I get more visibility into what ${first} is working on?`,
    `I'd like to support ${first} who is struggling with…`,
    "I need help with a difficult conversation about…",
    `What do I do if ${first} isn’t performing well?`,
    `How can I help ${first} grow ${possessiveAdjectivePronoun} career?`,
    `How can I encourage ${first} to give me feedback?`,
    `How can I encourage ${first} to be more flexible?`,
    `How can I help ${first} to be a better collaborator?`,
    `How can I encourage ${first} to accept help from others?`,
    `How can I encourage ${first} to ask for help when needed?`,
    `What can I do to manage ${first} who is not open to feedback?`,
  ]
}

const getSampleTopicsToManager = () => [
  "Discussing your career",
  "Giving feedback",
  "Preparing for a difficult conversation",
]

const getPopularTopicsToManager = (member: UserData) => {
  const first = member.first_name
  const possessiveAdjectivePronoun = member.pronouns[2]

  return [
    `I'd like to appreciate ${first} for ${possessiveAdjectivePronoun} help on…`,
    `I'd like help preparing for a conversation with ${first} about…`,
    `I'd like to discuss my career with ${first}.`,
    `I'd like to ask for feedback from ${first} on…`,
    `I'd like to ask ${first} for help with…`,
  ]
}

const getSampleTopicsToPeer = () => [
  "Giving feedback",
  "Showing appreciation",
  "Preparing for a difficult conversation",
]

const getPopularTopicsToPeer = (member: UserData) => {
  const first = member.first_name
  const subjectPronoun = member.pronouns[0]
  const objectPronoun = member.pronouns[1]
  const possessiveAdjectivePronoun = member.pronouns[2]

  return [
    `I'd like to appreciate ${first} for ${possessiveAdjectivePronoun} work on…`,
    `I'd like to give constructive feedback to ${first} on…`,
    "I need help with a difficult conversation about…",
    `I need help from ${first} with something. What’s the best way to ask ${objectPronoun}?`,
    `I am upset about something ${first} said. What should I do?`,
    `I needed ${first} to complete something by a certain date and ${subjectPronoun} didn't do it. What should I do?`,
    `I just want to vent about ${first}.`,
    `${first} is dealing with a personal issue, is there anything I can do or say?`,
    `${first} is dealing with a work challenge, how can I be helpful?`,
  ]
}

function formatPopularTopicAsSelectFieldOption(topic: string) {
  return { value: topic.replace("…", " "), label: topic }
}

export { getPopularTopics, formatPopularTopicAsSelectFieldOption }
