import { useNavigate } from "react-router-dom"

import { useLogout } from "./resource"

import { useEffectAfterFirstRender, useQueryParams } from "ui/hooks"

const Logout = () => {
  const navigate = useNavigate()
  const { mutateAsync: logout } = useLogout()
  const { next } = useQueryParams()

  async function loggingOut() {
    await logout()
    const nextStr = next ? `?next=${next}` : ""
    navigate(`/auth/login${nextStr}`)
  }

  useEffectAfterFirstRender(() => {
    loggingOut()
  })

  return null
}

export default Logout
