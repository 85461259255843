import { keyBy } from "lodash-es"

import { StepContent } from "../components"
import { useKitSession } from "../KitSessionContext"

import { useSessionExerciseInstances } from "domains/Exercise/resource"
import { getTeamLead } from "domains/Exercise/results_utils"
import {
  CrisisResponseChart,
  CrisisResponseSuperpower,
} from "domains/Exercise/ResultsComponents/CrisisResponseTeamSummary"
import { useHasTeamFeature } from "domains/Results/utils"
import { ExclamationCircleIcon } from "icons/FontAwesomeIcons"
import { useKitParticipants } from "resources/monthly_kit"
import { Loading } from "ui"
import { SHARED_FLAGS } from "ui/hooks/useFeatures"

const CrisisResponseScenarioAssignTeamMemberStep = ({ className }) => {
  const { kitInstance, team } = useKitSession()
  const { enabled: sessionRealtimeUpdatesV2 } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_REPLACE_POLLING)
  const { data: participants } = useKitParticipants({ kitInstance, sessionRealtimeUpdates: sessionRealtimeUpdatesV2 })
  const { enabled: sessionRealtimeUpdates } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_ANSWER_UPDATE)

  const { data: exerciseInstances, isFetching } = useSessionExerciseInstances(kitInstance?.id, {
    sessionRealtimeUpdates,
    refetchInterval: 30000,
  })

  if (!participants || (isFetching && !exerciseInstances)) {
    return <Loading />
  }

  const teamLead = getTeamLead(participants, team)
  const participantIdMap = keyBy(participants, "id")

  return (
    <StepContent className={className}>
      <h2 className="text-gray-9 mb-medium">Develop the response plan</h2>
      <p className="text-gray-8 mb-xxl">
        <ExclamationCircleIcon color="var(--orange-4)" className="mr-xs" />
        {teamLead.name} will capture responses for the group.
      </p>

      <h3 className="text-gray-9 mb-medium">
        Team Responder Types <span className="text-thin">(for reference)</span>
      </h3>
      <p className="text-gray-8">
        Use this chart to help identify the best team members for the responses created in the previous step.
      </p>
      <CrisisResponseChart
        userIdMap={participantIdMap}
        kitInstance={kitInstance}
        exerciseInstances={exerciseInstances}
      />
      <h3 className="text-gray-9 mt-xxl mb-medium">The Responder Types</h3>
      <CrisisResponseSuperpower className="mb-xl" />
    </StepContent>
  )
}

export default CrisisResponseScenarioAssignTeamMemberStep
