import { useKitSession } from "../KitSessionContext"

import { useTeamLevelExerciseInstance } from "domains/Exercise/resource"
import { getExerciseAnswer } from "domains/Exercise/results_utils"
import { useHasTeamFeature } from "domains/Results/utils"
import { useUser } from "resources/users"
import { SHARED_FLAGS } from "ui/hooks/useFeatures"

const TeamExerciseSelectedParticipantOnly = ({ selected_participant_identifier, children }) => {
  const { team, kitInstance } = useKitSession()
  const { enabled: sessionRealtimeUpdates } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_ANSWER_UPDATE)
  const { data: exerciseInstance } = useTeamLevelExerciseInstance({
    teamId: team.id,
    kitInstanceId: kitInstance?.id,
    slug: kitInstance.kit.exercise.slug,
    sessionRealtimeUpdates,
  })
  const { data: currentUser } = useUser({ userId: "me" })

  const selectedUserId = getExerciseAnswer(exerciseInstance, selected_participant_identifier)

  return currentUser && currentUser.id === selectedUserId ? children : null
}

export default TeamExerciseSelectedParticipantOnly
