import { useState } from "react"

import { Accordion } from "components"
import { useGetAccount } from "resources/billing"
import {
  useSessionReflections,
  useActionItemAISummaryRollup,
  useReflectionDataAISummaryRollup,
  getSessionReflections,
} from "resources/monthly_kit"
import { Button, Loading, AnswerDisplayContent } from "ui"
import { useEffectAfterChange } from "ui/hooks"
import useFeatures, { FLAGS } from "ui/hooks/useFeatures"

const SessionReflectionsReport = ({
  className,
  kitInstance,
  kitSlug,
  teamId,
  teamTags,
  accountId,
  accountTags,
  multiAccountIds,
  includeArchivedTeams,
}) => {
  const disableRollupQuery = !!kitInstance
  const features = useFeatures()
  const [fetchActionItemAISummary, setFetchActionItemAISummary] = useState(false)
  const [fetchReflectionTextAISummary, setfetchReflectionTextAISummary] = useState(false)
  const { data: account, isFetching: isFetchingAccount } = useGetAccount(accountId)
  const { data: initialSessionReflections, isFetching } = useSessionReflections({
    enabled: !disableRollupQuery,
    accountId,
    multiAccountIds,
    teamId,
    kitSlug,
    teamTags,
    accountTags,
    includeArchivedTeams,
  })

  const { data: actionItemsAIData, isFetching: isFetchingAIData } = useActionItemAISummaryRollup({
    enabled: !!fetchActionItemAISummary,
    accountId,
    multiAccountIds,
    teamId,
    kitSlug,
    teamTags,
    accountTags,
    includeArchivedTeams,
  })
  const { data: reflectionTextAIData, isFetching: isFetchingReflectionAIData } = useReflectionDataAISummaryRollup({
    enabled: !!fetchReflectionTextAISummary,
    accountId,
    multiAccountIds,
    teamId,
    kitSlug,
    teamTags,
    accountTags,
    includeArchivedTeams,
  })

  const [sessionReflections, setSessionReflections] = useState()

  useEffectAfterChange(() => {
    if (initialSessionReflections) {
      setSessionReflections(initialSessionReflections)
    }
  }, [initialSessionReflections])

  const onLoadMoreReflections = async () => {
    const moreReflections = await getSessionReflections({
      accountId,
      multiAccountIds,
      teamId,
      kitSlug,
      teamTags,
      accountTags,
      includeArchivedTeams,
      startIndex: sessionReflections.start_index + sessionReflections.page_size,
    })
    setSessionReflections({
      ...moreReflections,
      session_reflections: [...sessionReflections.session_reflections, ...moreReflections.session_reflections],
    })
  }

  const reflectionsData = kitInstance ? kitInstance.session_reflections : sessionReflections?.session_reflections

  if ((!disableRollupQuery && isFetching) || (!account && isFetchingAccount)) {
    return <Loading />
  }

  const reflections =
    reflectionsData?.map(({ reflection_text: text }) => text?.trim() ?? null).filter((text) => text?.length) ?? []

  const actionItems =
    reflectionsData?.map(({ action_items: text }) => text?.trim() ?? null).filter((text) => text?.length) ?? []

  if (!reflections.length && !actionItems.length) {
    return (
      <div className={className}>
        <h3 className="mb-medium text-gray-9">Reflections and Action Items</h3>
        No data
      </div>
    )
  }

  return (
    <div className={className}>
      <h3 className="mb-medium mr-medium text-gray-9">Reflections and Action Items</h3>
      <Accordion className="text-gray-9" title="Reflections">
        <div className="pl-large">
          {reflections.map((text, idx) => (
            <AnswerDisplayContent key={idx} answer={text} />
          ))}
          {!!sessionReflections &&
            sessionReflections.start_index + sessionReflections.page_size < sessionReflections.total_count && (
              <Button className="tertiary" onClick={onLoadMoreReflections}>
                Load more
              </Button>
            )}
        </div>
      </Accordion>
      <Accordion className="text-gray-9 mt-large" title="Action Items">
        <div className="pl-large">
          {actionItems.map((text, idx) => (
            <AnswerDisplayContent key={idx} answer={text} />
          ))}
          {!!sessionReflections &&
            sessionReflections.start_index + sessionReflections.page_size < sessionReflections.total_count && (
              <Button className="tertiary" onClick={onLoadMoreReflections}>
                Load more
              </Button>
            )}
        </div>
      </Accordion>
      {!!features[FLAGS.SESSION_FEEDBACK_AI_SUMMARY] && (
        <Button
          disabled={isFetchingAIData || !!fetchActionItemAISummary}
          onClick={() => setFetchActionItemAISummary(true)}
          className="mt-small tertiary"
        >
          Generate Action Items AI Summary
        </Button>
      )}
      <div className="my-small">
        {!!fetchActionItemAISummary &&
          (!isFetchingAIData && actionItemsAIData ? (
            <div className="py-small">
              <h3 className="mb-medium text-gray-9">Action Items AI Generated Summary</h3>
              {actionItemsAIData.output}
            </div>
          ) : (
            <>
              <h4 className="py-small">Generating your summary</h4>
              <Loading inline />
            </>
          ))}
      </div>
      {!!features[FLAGS.SESSION_FEEDBACK_AI_SUMMARY] && (
        <Button
          disabled={isFetchingReflectionAIData || !!fetchReflectionTextAISummary}
          onClick={() => setfetchReflectionTextAISummary(true)}
          className="mt-small tertiary"
        >
          Generate Reflection's AI Summary
        </Button>
      )}
      <div className="my-small">
        {!!fetchReflectionTextAISummary &&
          (!isFetchingReflectionAIData && reflectionTextAIData ? (
            <div className="py-small">
              <h3 className="mb-medium text-gray-9">Reflection's AI Generated Summary</h3>
              {reflectionTextAIData.output}
            </div>
          ) : (
            <>
              <h4 className="py-small">Generating your summary</h4>
              <Loading inline />
            </>
          ))}
      </div>
    </div>
  )
}

export default SessionReflectionsReport
