import isPropValid from "@emotion/is-prop-valid"
import { useState } from "react"
import { Helmet, HelmetProvider } from "react-helmet-async"
import { BrowserRouter } from "react-router-dom"
import { ThemeProvider, StyleSheetManager } from "styled-components"

import ScrollToTop from "components/ScrollToTop"
import Routes from "Routes"
import defaultTheme, { GlobalGradient, GlobalStyle, MobileOnlyStyle, MobileNeverStyle } from "ui/theme"
import { ThemeUpdateContext } from "ui/ThemeUpdateContext"
import { initDOMPurify } from "utils/dom"
import { isStagingEnv } from "utils/env"

// We cannot rely on an environment variable because staging and prod are
// sharing the same bundle
const FAVICON = isStagingEnv() ? "favicon-staging.ico" : "favicon.ico"
initDOMPurify()

function App() {
  const [theme, setTheme] = useState(defaultTheme)
  return (
    <HelmetProvider>
      <StyleSheetManager shouldForwardProp={shouldForwardProp} enableVendorPrefixes>
        <ThemeProvider theme={theme}>
          <ThemeUpdateContext.Provider value={{ defaultTheme, theme, setTheme }}>
            <Helmet>
              <link rel="icon" href={`${import.meta.env.VITE_PUBLIC_URL}/${FAVICON}`} />
            </Helmet>
            <GlobalGradient />
            <GlobalStyle />
            <MobileOnlyStyle />
            <MobileNeverStyle />
            <BrowserRouter>
              <ScrollToTop>
                <Routes />
              </ScrollToTop>
            </BrowserRouter>
          </ThemeUpdateContext.Provider>
        </ThemeProvider>
      </StyleSheetManager>
    </HelmetProvider>
  )
}

// This implements the default behavior from styled-components v5
function shouldForwardProp(propName, target) {
  if (typeof target === "string") {
    // For HTML elements, forward the prop if it is a valid HTML attribute
    return isPropValid(propName)
  }
  // For other elements, forward all props
  return true
}

export default App
