import { styled } from "styled-components"

import { StripesBandIcon, StripesBandDesktopIcon } from "icons"

const FlairContainer = styled(function FlairContainer({ children, className }) {
  return (
    <div className={className}>
      {children}
      <StripesBandIcon className="flair-icon flair-icon-mobile hide-on-desktop" />
      <StripesBandDesktopIcon className="flair-icon flair-icon-desktop show-on-desktop" />
    </div>
  )
})`
  position: relative;

  .flair-icon {
    position: absolute;
    right: 0;
  }

  .flair-icon-desktop {
    height: 165px;
    width: 200px;
    top: 40px;
  }

  .flair-icon-mobile {
    height: 80px;
    width: 80px;
    top: 24px;
  }
`

export default FlairContainer
