import { useGroupTimer } from "domains/KitSession/GroupTimersContext"
import { useKitSession } from "domains/KitSession/KitSessionContext"
import { useHasTeamFeature } from "domains/Results/utils"
import { Timer } from "ui"
import { positiveIntOrZero } from "ui/Duration"
import { useTimer } from "ui/hooks"
import { SHARED_FLAGS } from "ui/hooks/useFeatures"

const GroupTimer = ({ sessionStep, showTimerControls = true, msgPosition = "", isTeamLead, ...props }) => {
  const { team } = useKitSession()
  const { enabled: realtimeTimerEnabled } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_ACTIVITY_TIMER)
  const { groupTimer, updateGroupTimer, isInitialLoading } = useGroupTimer(sessionStep.timer_group, {
    realtimeGetEnabled: realtimeTimerEnabled,
    realtimeSetEnabled: realtimeTimerEnabled && isTeamLead,
  })
  const {
    timeLeft,
    timePassed,
    play: _play,
    pause: _pause,
    reset: _reset,
    isRunning,
  } = useTimer(
    groupTimer.minutes * 60 * 1000,
    {
      isRunning: groupTimer.isRunning,
      playClickedTime: groupTimer.playClickedTime,
      timePassed: groupTimer.timePassed,
    },
    {
      enableReinitialize: !!realtimeTimerEnabled,
    }
  )
  const durationSeconds = positiveIntOrZero(timeLeft / 1000)

  const play = () => {
    if (!realtimeTimerEnabled) {
      _play()
    }

    updateGroupTimer({
      isRunning: true,
      complete: false,
      ...(timeLeft < 1000
        ? {
            playClickedTime: Date.now(),
          }
        : {
            playClickedTime: Date.now() - timePassed,
          }),
    })
  }
  const pause = () => {
    if (!realtimeTimerEnabled) {
      _pause()
    }

    updateGroupTimer({
      isRunning: false,
      timePassed,
      complete: true,
    })
  }
  const reset = () => {
    if (!realtimeTimerEnabled) {
      _reset()
    }

    updateGroupTimer({
      isRunning: false,
      timePassed: 0,
      complete: false,
    })
  }

  if (isInitialLoading) {
    return null
  }

  return (
    <>
      {msgPosition === "left" && durationSeconds < 1 && (
        <h3 className="show-on-tablet-or-desktop text-gray-8 mr-small text-nowrap">Time’s up!</h3>
      )}
      <Timer
        durationSeconds={durationSeconds}
        isRunning={isRunning}
        showTimerControls={!!showTimerControls && (!!isTeamLead || !realtimeTimerEnabled)}
        onPlay={play}
        onPause={pause}
        onReset={reset}
        {...props}
      />
      {msgPosition === "right" && durationSeconds < 1 && (
        <h3 className="show-on-tablet-or-desktop text-gray-8 ml-small text-nowrap">Time’s up!</h3>
      )}
    </>
  )
}

export default GroupTimer
