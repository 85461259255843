import { maxBy, orderBy } from "lodash-es"
import { styled } from "styled-components"

import { getExerciseAnswer, getExerciseComponent } from "../results_utils"

import RTBar from "ui/RTBar"
import RTGridTable from "ui/RTGridTable"

const RadioGroupMultiUserBarChart = styled(function RadioGroupMultiUserBarChart({
  exerciseInstances,
  identifier,
  className,
}) {
  const latestExerciseInstance = maxBy(exerciseInstances, "created_at")
  const exerciseComponent = getExerciseComponent(latestExerciseInstance, identifier)
  const choiceOptions = exerciseComponent.options
  const answers = exerciseInstances.map((exercise) => getExerciseAnswer(exercise, identifier)).filter(Boolean)

  const data = choiceOptions.map((option) => {
    const value = option.value
    const count = answers.filter((answer) => answer.includes(value)).length
    return {
      shortLabel: option.short_label,
      value: option.value,
      count,
    }
  })

  const sortedData = orderBy(data, ["count"], "desc")
  const maxCount = Math.max(...sortedData.map(({ count }) => count))

  if (sortedData?.length <= 0) {
    return <p className="mt-large mb-xl text-gray-8">No data yet</p>
  }

  const rowItems = sortedData.map(({ shortLabel, count }) => {
    const shortLabelComponent = <div aria-hidden>{shortLabel}</div>
    const ariaLabel = `${shortLabel}: ${count}`
    const barChart = (
      <RTBar
        background="linear-gradient(258.26deg, #fb6211 28%, #f97830 62.68%, #f8d313 94.29%)"
        height="16px"
        width={`${!maxCount ? 0 : (100 * count) / maxCount}%`}
      />
    )

    return {
      ariaLabel,
      columns: [{ component: shortLabelComponent }, { component: count }, { component: barChart }],
    }
  })

  return (
    <RTGridTable
      rowItems={rowItems}
      _numColumns={3}
      _gridTemplateColumns="max-content max-content auto"
      _gridTemplateColumnsMobile="max-content auto"
      className={className}
    />
  )
})`
  max-width: 575px;
`

export default RadioGroupMultiUserBarChart
