import { StepContent, SidebarContainer, SessionSelectedUsersShareView, FacilitatorTip } from "../components"
import { useKitSession } from "../KitSessionContext"

import { useSessionExerciseInstances } from "domains/Exercise/resource"
import { getExerciseAnswer, getUserExerciseInstance } from "domains/Exercise/results_utils"
import {
  CareerHorizonsChartingPathRequirementsEdit,
  CareerHorizonsChartingPathRequirementsReadOnly,
  CareerHorizonsChartingPathNextStepsEdit,
  CareerHorizonsChartingPathNextStepsReadOnly,
} from "domains/Exercise/ResultsComponents"
import { useHasTeamFeature } from "domains/Results/utils"
import { useUser } from "resources/users"
import { TextContainer, Loading, Callout, NumberedList } from "ui"
import { SHARED_FLAGS } from "ui/hooks/useFeatures"
import { useSelectedUser } from "ui/SelectedUserContext"

const CareerHorizonsChartingPathShareStep = ({ sessionStep, className }) => {
  const { kitInstance, team } = useKitSession()
  const { enabled: sessionRealtimeUpdates } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_ANSWER_UPDATE)

  const { data: exerciseInstances, isFetching } = useSessionExerciseInstances(kitInstance?.id, {
    sessionRealtimeUpdates,
    refetchInterval: 30000,
  })

  if (isFetching && !exerciseInstances) {
    return <Loading />
  }

  return (
    <StepContent className={className}>
      <SidebarContainer>
        <TextContainer maxWidth={660}>
          <h2 className="text-gray-9 mb-medium">Share out</h2>
          <p className="text-gray-9 mb-medium">
            Now each person will get time to share their list with the team for input.
          </p>
          <Callout className="text-gray-9 mb-large mr-large">
            <h4>Instructions</h4>
            <NumberedList>
              <li>Share your requirements list</li>
              <li>Ask the team for any requirements you may have missed, and add those on your own page</li>
              <li>Repeat that process for sharing and adding to your next steps.</li>
            </NumberedList>
          </Callout>
        </TextContainer>
        <FacilitatorTip
          className="mb-large sidebar-right"
          tip={"Share your screen here. Each person can continue to edit their list from their own device."}
        />
      </SidebarContainer>
      <SessionSelectedUsersShareView
        sessionStep={sessionStep}
        kitInstance={kitInstance}
        minMinutesPerUser={3}
        maxMinutesPerUser={5}
      >
        <UserResults exerciseInstances={exerciseInstances} kitInstance={kitInstance} />
      </SessionSelectedUsersShareView>
    </StepContent>
  )
}

const UserResults = ({ exerciseInstances, kitInstance }) => {
  const { data: user } = useUser({ userId: "me" })
  const { selectedUser } = useSelectedUser()
  const selectedExercise = getUserExerciseInstance(exerciseInstances, selectedUser)

  if (!selectedExercise) {
    return null
  }

  if (!user) {
    return <Loading />
  }

  const path_field_id = getExerciseAnswer(selectedExercise, "path_to_share")?.split(".")[0]
  const path = getExerciseAnswer(selectedExercise, path_field_id)

  return (
    <TextContainer>
      <h4 className="text-gray-9 text-bold mb-xs">Potential Path: {path}</h4>
      {selectedUser.id === user.id ? (
        <>
          <CareerHorizonsChartingPathRequirementsEdit
            kitInstance={kitInstance}
            exerciseInstance={selectedExercise}
            selectedUserId={selectedUser.id}
          />
          <CareerHorizonsChartingPathNextStepsEdit
            kitInstance={kitInstance}
            exerciseInstance={selectedExercise}
            selectedUserId={selectedUser.id}
          />
        </>
      ) : (
        <>
          <CareerHorizonsChartingPathRequirementsReadOnly
            exercise={selectedExercise}
            selectedUserId={selectedUser.id}
          />
          <CareerHorizonsChartingPathNextStepsReadOnly exercise={selectedExercise} selectedUserId={selectedUser.id} />
        </>
      )}
    </TextContainer>
  )
}

export default CareerHorizonsChartingPathShareStep
