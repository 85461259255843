import { getExerciseComponent, getExerciseAnswer } from "../results_utils"

import { BulletedList } from "ui"

const FieldsList = ({ identifiers, exerciseInstance, className }) => {
  if (!exerciseInstance) {
    return null
  }

  const listItems = identifiers.flatMap((identifier) => getListItemsFromIdentifier({ identifier, exerciseInstance }))
  return <AnswerListDisplay answers={listItems} className={className} />
}

const getListItemsFromIdentifier = ({ identifier, exerciseInstance }) => {
  const exerciseComponent = getExerciseComponent(exerciseInstance, identifier)
  if (exerciseComponent.component === "ExerciseCheckboxGroupField") {
    const userSelections = getExerciseAnswer(exerciseInstance, identifier) ?? []
    const choiceOptions = exerciseComponent.options
    const formattedAnswers = choiceOptions
      .filter((option) => userSelections.includes(option.value))
      .map((option) => option.short_label)
    return formattedAnswers
  }
  if (exerciseComponent.component === "ExerciseTextField") {
    const listItem = getExerciseAnswer(exerciseInstance, identifier)
    return [listItem].filter(Boolean)
  }
}

const AnswerListDisplay = ({ title, answers, identifier, className }) => {
  if (answers.length < 1) {
    return null
  }

  return (
    <div>
      {!!title && <p className="text-field-label mb-small">{title}</p>}
      <BulletedList className={className}>
        {answers.map((answer) => (
          <li key={`${identifier}_${answer}`}>{answer}</li>
        ))}
      </BulletedList>
    </div>
  )
}

export default FieldsList
