import { getExerciseAnswer } from "../results_utils"

import { PurifyInnerHTMLDiv, View } from "ui"
import InputRadio from "ui/InputRadio"

const RadioGroupReadOnlyField = ({ identifier, exerciseComponent, exerciseInstance }) => {
  const choiceOptions = exerciseComponent.options
  const userSelections = getExerciseAnswer(exerciseInstance, identifier) ?? []

  return (
    <div className="space-y-small mb-xl">
      {choiceOptions.map(({ value, label }) => (
        <View $alignItems="center" as="label" key={value}>
          <InputRadio disabled type="radio" className="ml-none mr-small" checked={userSelections.includes(value)} />
          <PurifyInnerHTMLDiv className="text-small">{label}</PurifyInnerHTMLDiv>
        </View>
      ))}
    </div>
  )
}

export default RadioGroupReadOnlyField
