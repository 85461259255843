function getOrError(obj, key, message) {
  if (!Object.prototype.hasOwnProperty.call(obj, key)) {
    const quoted = typeof key === "string" ? `"${key}"` : key
    throw new Error(`${message} (${quoted})`)
  }
  return obj[key]
}

// disabled since we'll add more util exports in here; remove when there's more than one
// eslint-disable-next-line import/prefer-default-export
export { getOrError }
