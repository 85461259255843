import { styled } from "styled-components"

import { ChoicesField } from "forms/fields"
import { IconChoiceInput } from "forms/fields/ChoicesField"

const ExerciseGridCheckboxGroupField = styled(function ExerciseGridCheckboxGroupField({
  name,
  className,
  options,
  columns = 3, // eslint-disable-line @typescript-eslint/no-unused-vars -- used in css
  ...props
}) {
  return (
    <ChoicesField
      name={`${name}.value`}
      type="checkbox"
      options={options}
      component={IconChoiceInput}
      className={className}
      {...props}
    />
  )
})`
  display: grid;
  grid-auto-rows: 1fr;
  grid-template-columns: repeat(2, minmax(0, 176px));
  gap: 16px;

  @media (min-width: ${({ theme }) => theme.desktopMin}) {
    grid-template-columns: repeat(${({ columns }) => columns}, minmax(0, 176px));
  }
`

export default ExerciseGridCheckboxGroupField
