import cn from "classnames"
import { parseISO } from "date-fns"
import { Form, Formik } from "formik"
import { useNavigate } from "react-router"
import { styled } from "styled-components"

import { ThemeHeader, ProgressBar } from "components"
import { useSteps } from "components/Steps"
import { SessionQRCode } from "domains/KitSession/components"
import { isTranslatableKit } from "domains/KitSession/translation"
import { KIT_GROUP_SIZE } from "domains/LeaderKit/utils"
import { AdvancedSelectField } from "forms/fields"
import { StarIcon, ExclamationTriangleIcon, ClockIcon, CheckIcon } from "icons/FontAwesomeIcons"
import { getAppCodeURL } from "resources/monthly_kit"
import { useTeam } from "resources/teams"
import { View } from "ui"
import { useWindowSize, useQueryParams } from "ui/hooks"
import { TEAM_FLAGS } from "ui/hooks/useFeatures"
import { useCurrentTheme } from "ui/ThemeUpdateContext"
import { getNumSecondsPassed } from "utils/date"
import { plural } from "utils/string"

const SessionHeader = ({ kitInstance, allSessionSteps, className, isTeamLead }) => {
  const { data: team } = useTeam({ teamId: kitInstance.team_id })
  const { currentIndex, count } = useSteps()
  const theme = useCurrentTheme()
  const { isTabletOrLarger } = useWindowSize()
  const isJumbo = kitInstance?.kit?.group_size === KIT_GROUP_SIZE.JUMBO

  const sumStepsMinutes = (steps) => steps.reduce((time, step) => time + (step.minutes ?? 0), 0)

  const totalStepsMinutes = sumStepsMinutes(allSessionSteps)
  const passedStepsMinutes = sumStepsMinutes(allSessionSteps.slice(0, currentIndex))

  const roundedTotalMinutes = Math.ceil(totalStepsMinutes / 15) * 15
  const totalSessionHours = Math.floor(roundedTotalMinutes / 60)
  const totalRemainderMin = roundedTotalMinutes % 60

  const totalSessionTimer = () => {
    const hrs = !totalSessionHours ? "" : plural(totalSessionHours, "hr")
    const min = !totalRemainderMin ? "" : `${totalRemainderMin} min`
    return `${hrs} ${min}`.trim()
  }

  const renderSessionTimer = () => {
    const sessionStartedAt = kitInstance.session_started_at
    const timePassed = sessionStartedAt ? Math.floor(getNumSecondsPassed(parseISO(sessionStartedAt)) / 60) : 0

    if (timePassed === 0 || timePassed - passedStepsMinutes > totalStepsMinutes) {
      return (
        <>
          <ClockIcon color="var(--gray-7)" className="mr-xs" />
          {totalSessionTimer()} total time
        </>
      )
    }
    if (currentIndex === count - 1) {
      return (
        <>
          <CheckIcon color="var(--rising-green)" className="mr-xs" />
          Finished!
        </>
      )
    }
    if (timePassed <= passedStepsMinutes - 5) {
      return (
        <>
          <ClockIcon color="var(--gray-7)" className="mr-xs" />
          {Math.round(passedStepsMinutes - timePassed)} mins ahead
        </>
      )
    }
    if (
      (timePassed < passedStepsMinutes + 5 && passedStepsMinutes <= 30) ||
      (timePassed < passedStepsMinutes + 2 && passedStepsMinutes > 30)
    ) {
      return (
        <>
          <StarIcon color="var(--rising-green)" className="mr-xs" />
          On schedule
        </>
      )
    }
    return (
      <>
        <ExclamationTriangleIcon color="var(--orange-2)" className="mr-xs" />
        {Math.round(timePassed - passedStepsMinutes)} mins behind
      </>
    )
  }

  // Only show a code url (and QR code) in kit header if both:
  // - this is not the welcome step (welcome step has QR code + url in step body)
  // - the session has a code (is not yet completed)
  const isWelcomeStep = allSessionSteps[currentIndex]?.path === "welcome"
  const showSessionCode = !isWelcomeStep && !!kitInstance?.session_code

  // TODO(hackathon,kit-translation,evnp): Clean up code below after hackathon
  // TRANSLATION LAYER MANAGEMENT
  const { lang } = useQueryParams()
  const navigate = useNavigate()
  function changeTranslation(option) {
    if (team?.features?.includes(TEAM_FLAGS.RTDEV_KIT_TRANSLATION)) {
      navigate(option.value?.length ? `?lang=${option.value}` : "?")
    }
  }

  const languageSwitcher = (className) => (
    <>
      {!!team?.features?.includes(TEAM_FLAGS.RTDEV_KIT_TRANSLATION) && !!isTranslatableKit(kitInstance?.kit?.slug) && (
        <View
          $justifyContent="flex-end"
          className={cn(className, "language-switcher mt-xs mb-small", {
            "text-xs": isTeamLead && kitInstance?.kit?.group_size === "jumbo",
          })}
        >
          <Formik initialValues={{ lang: lang ?? "" }}>
            <Form className="fit-content">
              <AdvancedSelectField
                name="lang"
                onChange={changeTranslation}
                options={[
                  { value: "", label: "English" },
                  { value: "zh-CN", label: "简体中文 (Chinese — Simplified)" },
                  // TODO(kit-translation,evnp) Clean up special-casing below
                  // Special-case intro-kickoff step since we only have these translations done there:
                  //...(!pathname.endsWith('/intro-kickoff')
                  //  ? []
                  //  : [
                  //      { value: 'es', label: 'Español (Spanish)' },
                  //      { value: 'fr', label: 'Français (French)' },
                  //      { value: 'hi', label: 'हिंदी (Hindi)' },
                  //      { value: 'ru', label: 'Русский (Russian)' },
                  //      { value: 'vi', label: 'Tiếng Việt (Vietnamese)' },
                  //      { value: 'zh', label: '中文 (Chinese)' },
                  //      { value: 'pl', label: '🐷 (Pig-Latin)' },
                  //    ]),
                ]}
              />
            </Form>
          </Formik>
        </View>
      )}
    </>
  )
  // END TRANSLATION LAYER MANAGEMENT

  return (
    <View
      className={cn("mb-small", className, {
        "kit-translation-available":
          team?.features?.includes(TEAM_FLAGS.RTDEV_KIT_TRANSLATION) && isTranslatableKit(kitInstance?.kit?.slug),
      })}
      $flexDirection="column"
      data-testid="session-step-header"
    >
      <View $justifyContent="space-between" $alignItems="flex-end" className="mt-xs">
        <ThemeHeader kitInstance={kitInstance} />
        {!!isTeamLead && !!showSessionCode && !!isTabletOrLarger ? (
          <View
            className={cn("fit-content", { "text-medium": theme.isWidescreen })}
            $gap={theme.isWidescreen ? "3rem" : "2rem"}
          >
            <div>
              <p className="session-url mt-small text-gray-8">
                <span>{getAppCodeURL()}/</span>
                {kitInstance.session_code}
              </p>
              {languageSwitcher()}
            </div>
            <SessionQRCode className="session-qr-code" kitInstance={kitInstance} size={theme.isWidescreen ? 100 : 75} />
          </View>
        ) : (
          !!isTabletOrLarger && languageSwitcher("fit-content")
        )}
      </View>
      {!isTabletOrLarger && languageSwitcher("fit-content")}
      {!isTeamLead && !!isJumbo ? (
        <hr
          className={cn("border border-gray-4 full-width", {
            "mt-large": isTabletOrLarger,
            "mt-small": !isTabletOrLarger,
          })}
        />
      ) : (
        <ProgressBar
          className={cn("full-width", { "mt-large": isTabletOrLarger, "mt-xs": !isTabletOrLarger })}
          max={totalStepsMinutes}
          value={passedStepsMinutes}
        />
      )}
      {!theme.isWidescreen && !!isTabletOrLarger && !!isTeamLead && (
        <div className="text-semi-bold text-gray-8 full-width text-align-right mt-xxs">{renderSessionTimer()}</div>
      )}
    </View>
  )
}

export default styled(SessionHeader)`
  .duration-div {
    background-color: var(--rising-green);
    color: white;
    font-size: 1.5rem;
    line-height: 1.5rem;
    font-weight: 500;
    padding: 4px 8px;
    border-radius: 4px;
  }
  .duration-div .separator {
    color: var(--gray-4);
  }
  .session-qr-code {
    margin-top: -1.5rem;
    margin-bottom: -1rem;
  }
  /* TODO(hackathon,kit-translation,evnp): Clean up code below after hackathon */
  /* TRANSLATION LAYER MANAGEMENT */
  &.kit-translation-available {
    .session-qr-code {
      margin-top: 0;
    }
    .session-url {
      margin-top: -0.3rem;
    }
    .language-switcher {
      position: relative;
      z-index: var(--z-above-sticky);
    }
  }
  /* END TRANSLATION LAYER MANAGEMENT */
`
