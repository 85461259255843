import cn from "classnames"
import { Fragment } from "react"

import { getExerciseAnswer } from "domains/Exercise/results_utils"
import { StarIcon } from "icons/FontAwesomeIcons"
import { View, colors, HorizontalRule } from "ui"

const StickyNotesTeamFavoritesShare = ({ className, identifier, exerciseInstance, favoriteIdentifier }) => {
  const favoritedTeamNorms = getExerciseAnswer(exerciseInstance, identifier)?.filter(
    (stickyNote) => stickyNote[favoriteIdentifier]
  )

  if (!favoritedTeamNorms?.length) {
    return <div>No sticky note favorites selected!</div>
  }

  return (
    !!favoritedTeamNorms &&
    favoritedTeamNorms.map(({ value: favoredNote }, stickyNoteIndex) => (
      <Fragment key={stickyNoteIndex}>
        <View className={cn(className, "pr-large", "mb-medium")} $gap="16px" $alignItems="center">
          <StarIcon color={colors.risingOrange} className="fa-xl" />
          <p>{favoredNote}</p>
        </View>
        <HorizontalRule margin="mb-medium" height={1} />
      </Fragment>
    ))
  )
}

export default StickyNotesTeamFavoritesShare
