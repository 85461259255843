import * as Sentry from "@sentry/browser"
import cn from "classnames"
import { addMinutes, format as formatDate, parseISO } from "date-fns"
import { Formik } from "formik"
import { useState } from "react"
import AddToCalendarHOC, { SHARE_SITES } from "react-add-to-calendar-hoc"
import { styled } from "styled-components"
import { tzlib_get_ical_block, tzlib_get_timezones } from "timezones-ical-library"

import Dropdown from "./Dropdown"

import DateField from "forms/fields/DateField"
import { useUpdateOrCreateKitInstanceBySlug } from "resources/monthly_kit"
import { Button, View } from "ui"
import { useEffectAfterChange, useWindowSize } from "ui/hooks"
import { useModal } from "ui/ModalContext"
import { getTimeZone } from "utils/date"

const ScheduleForm = ({ className, kitInstance: initialKitInstance, kitInfo, onAddToCalendar, team }) => {
  const { mutateAsync: updateKitInstance } = useUpdateOrCreateKitInstanceBySlug({ teamId: team.id, slug: kitInfo.slug })
  const [kitInstance, setKitInstance] = useState(initialKitInstance)
  const scheduled_time = kitInstance?.scheduled_time
  const [calendarTime, setCalendarTime] = useState(scheduled_time)
  const { isMobileOrSmaller, isMobileLandscapeOrLarger } = useWindowSize()
  const { setModal } = useModal()

  const onChangeSessionTime = async (_, value) => {
    setCalendarTime(value)
    const data = await updateKitInstance({
      scheduled_time: value,
    })
    // Here we manage the current kitInstance with state, rather than invalidating
    // the original query to avoid a full re-render of parent components
    setKitInstance(data)
  }
  useEffectAfterChange(() => {
    setKitInstance(initialKitInstance)
  }, [initialKitInstance, kitInfo])

  const initialValues = { scheduled_time }

  function safeFormatFileNameDate(date) {
    try {
      return !date ? null : formatDate(parseISO(date), "MMM-dd-yyyy")
    } catch {
      return null
    }
  }
  const filenameSession = kitInfo.title.replace(/[ :]+/g, "-")
  const filenameDate = safeFormatFileNameDate(calendarTime)
  const filename = `Rising-Team-${filenameSession}-${filenameDate}`

  function setInfoModal(event) {
    const hrefAttrValue = event.target?.getAttribute("href") ?? ""
    const isWebCalendar = hrefAttrValue.startsWith("https://")
    const isICSDownload = hrefAttrValue.startsWith("BEGIN:VCALENDAR")
    const isICSDataHref = hrefAttrValue.startsWith("data:text/calendar;charset=utf8,BEGIN:VCALENDAR")
    if (!isWebCalendar && !isICSDownload && !isICSDataHref) {
      Sentry.captureMessage(
        `ScheduleForm.js: Unexpected calendar href value: "${hrefAttrValue}".` +
          " We should update info modal logic to handle this value type."
      )
    }
    if (isICSDownload) {
      const calendarAppDesc = event.target?.textContent ?? "your calendar app"
      setModal({
        title: `How to open in ${calendarAppDesc}`,
        content: (
          <>
            <p className="mb-large">
              Look for the ICS file that was downloaded by your browser and open it to add your session to{" "}
              {calendarAppDesc}.
            </p>
            <p className="mb-xl">
              If you don’t see a file called
              <br />
              <b>{filename}.ics</b>
              <br /> try looking for it in your Downloads folder.
            </p>
            <Button onClick={() => setModal(null)}>OK</Button>
          </>
        ),
      })
    }
  }

  return (
    <View className={cn(className, { "mt-medium": isMobileOrSmaller })}>
      <Formik initialValues={initialValues} enableReinitialize>
        <View $flexDirectionMobile="column">
          <DateField
            name="scheduled_time"
            className="date-field"
            showTimeSelect
            dateFormat="MM/dd/yyyy h:mm aa"
            saveOnChange={onChangeSessionTime}
            size="full-width"
            placeholder="Set date and time"
          />
          {!!scheduled_time && (
            <AddToCalendarButton
              kitInstance={kitInstance}
              kitInfo={kitInfo}
              calendarTime={calendarTime}
              filename={filename}
              onAddToCalendar={(event) => {
                onAddToCalendar?.(event)
                setInfoModal(event)
              }}
              className={cn({ "ml-medium": isMobileLandscapeOrLarger, "mt-small": isMobileOrSmaller })}
            />
          )}
        </View>
      </Formik>
    </View>
  )
}

const AddToCalendarButton = ({ kitInstance, kitInfo, calendarTime, filename, onAddToCalendar, className }) => {
  const buttonText = "Add to calendar"
  const buttonProps = { className: "add-to-calendar-button tertiary px-medium py-xs" }
  const linkProps = { onItemClick: onAddToCalendar }

  if (!calendarTime) {
    const onNoCalendarTimeClick = () => {
      alert("Please select a session time.")
    }
    return (
      <Button {...buttonProps} onClick={onNoCalendarTimeClick}>
        {buttonText}
      </Button>
    )
  }

  const AddToCalendarDropdown = AddToCalendarHOC(Button, Dropdown)
  const durationMinutes = kitInfo.session_time_minutes
  const startDatetime = parseISO(calendarTime)
  const endDatetime = addMinutes(startDatetime, durationMinutes)

  const timezone = getTimeZone()
  const vtimezoneOptions = tzlib_get_timezones()
  let [vtimezone = null] = vtimezoneOptions.includes(timezone) ? tzlib_get_ical_block(timezone) : []
  // tzlib uses \r\n as newline characters which we don't want, replace them with \n here:
  vtimezone = vtimezone?.replaceAll("\r\n", "\n") ?? null

  const event = {
    timezone,
    vtimezone,
    duration: durationMinutes / 60,
    endDatetime: formatDate(endDatetime, "yyyyMMdd'T'HHmmss"),
    startDatetime: formatDate(startDatetime, "yyyyMMdd'T'HHmmss"),
    title: `Rising Team session: ${kitInfo.title}`,
    description: getCalendarDescription({ kitInfo, kitInstance }),
  }
  return (
    <AddToCalendarDropdown
      buttonText={buttonText}
      buttonProps={buttonProps}
      dropdownProps={linkProps}
      filename={filename}
      items={[SHARE_SITES.GOOGLE, SHARE_SITES.ICAL, SHARE_SITES.OUTLOOK]}
      event={event}
      className={cn("calendar-dropdown", className)}
    />
  )
}

const getCalendarDescription = ({ kitInfo, kitInstance }) => {
  const originUrl = window.location.origin
  const teamLeadUrl = `${originUrl}${kitInstance.session_start_url}`
  const startSessionDesc = `${kitInfo.description}

How to start your session:
Team lead:
  - Go to ${teamLeadUrl} to start the session.
  - Share your screen with the group.
  - Share the session link and code with the team so they can join the session.

Team members:
  - Follow the shared link or QR code to join the session
  - Follow the prompts and enjoy!

What you'll need:
  - A computer or a mobile device
  - A video conference line or conference room (if in person)
  - An open mind; come ready to learn more about each other and how to work better together as a team!
`
  const previewSessionDesc = `
Want to preview the kit or prep for your team's session?
If you want extra time to think about the session in advance and have a Rising Team account:
  - Visit ${originUrl}, log in and navigate to the Kits tab.
  - Locate the ${kitInfo.title} kit and select "view or take exercise"
  - Your responses will be saved for when your team does the session together, and will still be editable

If you are not already a member of the team:
  - Contact your team lead to have them invite you to the team.
  - Once your Rising Team account is created, see the above steps to access the session in advance.
`
  const rtExplainDesc = `
What is Rising Team?
Rising Team helps leaders and their teams grow together through meaningful team development experiences around key leadership themes.`

  const additionalInviteText = kitInstance.additional_invite_text
    ? `

${kitInstance.additional_invite_text}`
    : ""

  return kitInfo.has_standalone_exercise
    ? `${startSessionDesc}${previewSessionDesc}${rtExplainDesc}${additionalInviteText}`
    : `${startSessionDesc}${rtExplainDesc}${additionalInviteText}`
}

export default styled(ScheduleForm)`
  .date-field {
    max-width: 246px;

    input::placeholder {
      color: var(--gray-8);
      opacity: 1;
    }
  }

  .calendar-dropdown {
    div {
      position: absolute;
      margin-top: 1px;
      width: 209px;
    }
  }

  .add-to-calendar-button {
    height: 44px;
    position: relative;
  }
`
