import { last, sortBy } from "lodash-es"
import { useMemo } from "react"
import ReactWordcloud from "react-wordcloud"
import { styled } from "styled-components"

import { getExerciseAnswer, getExerciseComponent } from "../results_utils"

import theme from "ui/theme"
import { plural } from "utils/string"

const RadioGroupMultiUserWordCloud = styled(function RadioGroupMultiUserWordCloud({
  exerciseInstances,
  identifier,
  minFontSize,
  maxFontSize,
  textFieldIdentifiers,
  className,
}) {
  const latestExerciseInstance = last(sortBy(exerciseInstances, "created_at"))
  const exerciseComponent = getExerciseComponent(latestExerciseInstance, identifier)
  const choiceOptions = exerciseComponent.options
  const answers = exerciseInstances.map((exercise) => getExerciseAnswer(exercise, identifier)).filter(Boolean)

  const words = choiceOptions
    .map((option) => {
      const value = answers.filter((answer) => answer.includes(option.value)).length
      if (value === 0) {
        return null
      }
      return {
        text: option.short_label,
        value,
      }
    })
    .filter(Boolean)

  const accumulatedAdditionalAnswers = (textFieldIdentifiers ?? [])
    .flatMap((identifier) => exerciseInstances.map((exercise) => getExerciseAnswer(exercise, identifier)))
    .filter(Boolean)

  const additionalwords = accumulatedAdditionalAnswers.map((item) => ({
    text: item,
    value: 1,
  }))

  const finalListofWords = words.concat(additionalwords)

  const options = useMemo(
    () => ({
      deterministic: true,
      enableTooltip: false,
      rotations: 1,
      rotationAngles: [0, 0],
      fontSizes: [minFontSize, maxFontSize],
      fontFamily: theme.normalFontFamily,
      fontWeight: "bold",
      colors: ["var(--rising-blue)", "var(--green-2)", "var(--orange-2)", "var(--yellow-2)"],
    }),
    [minFontSize, maxFontSize]
  )

  const sortedTopWords = finalListofWords.sort((a, b) => b.value - a.value).slice(0, 8)
  const topWordsText = sortedTopWords.map(({ text, value }) => `${text}, appeared ${plural(value, "time")}`).join(". ")
  const ariaLabel = `Top phrases: ${topWordsText}`

  return (
    <div className={className} aria-label={ariaLabel}>
      <ReactWordcloud aria-hidden options={options} words={finalListofWords} />
    </div>
  )
})`
  ${({ height }) => (height ? `height: ${height};` : "")};
`

export default RadioGroupMultiUserWordCloud
