import { useTimezoneSelect } from "react-timezone-select"

import { AdvancedSelectField } from "forms/fields"

const ExerciseTimeZoneSelectField = ({ name, ...props }) => {
  // TODO (loranne): adjust to do more processing of timezoneoptions before passing to advanced select field
  const fieldName = `${name}.value`
  const { options: timeZoneOptions } = useTimezoneSelect({})

  return <AdvancedSelectField name={fieldName} options={timeZoneOptions} {...props} />
}

export default ExerciseTimeZoneSelectField
