import { styled } from "styled-components"

import { Accordion } from "components"
import { DownloadIcon } from "icons/FontAwesomeIcons"
import { View, Button } from "ui"

const HorizontalTeammojis = styled(function HorizontalTeammojis({ className, kit }) {
  return (
    <div className={className}>
      <div className="text-gray-9 mb-medium">
        <h2>{kit.bonus_page.teammojis_subheader}</h2>
      </div>
      <div className="description-wrapper">
        <div>
          {kit.bonus_page.bonus_desc.map((description, index) => (
            <div className="mb-xs" key={index}>
              {description.paragraph_text}
            </div>
          ))}
        </div>
      </div>
      <View className="emoji-grid mt-small" $flexDirection="row">
        {kit.emoji_list.map(({ emoji, title }) => (
          <div key={title} className="emoji-column-container mb-medium">
            <img src={emoji} width="132px" alt={title} className="mb-xs" />
            <div className="text-gray-8 text-semi-bold">{title}</div>
          </div>
        ))}
      </View>
      <a
        href={`https://static.risingteam.com/kit_assets/${kit.bonus_page.emoji_download_slug}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button className="tertiary mb-xl mt-medium">
          <DownloadIcon />
          Download TeamMojis
        </Button>
      </a>
      <Accordion className="text-bold text-gray-8" title="How to install" isOpen={false}>
        <div className="text-normal text-gray-9">
          <div className="py-xs pb-large">
            TeamMojis can be added as custom emojis to{" "}
            <a target="_blank" rel="noopener noreferrer" href="https://support.google.com/chat/answer/12800149">
              Google Chat
            </a>{" "}
            and{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://slack.com/help/articles/206870177-Add-custom-emoji"
            >
              Slack
            </a>
            . If you use a different messaging platform, email us at{" "}
            <a target="_blank" rel="noopener noreferrer" href="mailto:hello@risingteam.com">
              hello@risingteam.com
            </a>{" "}
            to share what messaging tool you use so we can prioritize what we build next.
          </div>
        </div>
      </Accordion>
    </div>
  )
})`
  .emoji-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
    gap: var(--spacing-0);
  }

  .emoji-column-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

export default HorizontalTeammojis
