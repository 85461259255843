import { Formik } from "formik"
import { styled } from "styled-components"

import ExerciseForm from "domains/Exercise/ExerciseForm"
import { getExerciseAnswer } from "domains/Exercise/results_utils"
import useExerciseForm from "domains/Exercise/useExerciseForm"
import { FacilitatorTip, SidebarContainer, StepContent, StepTitle } from "domains/KitSession/components"
import { useKitSession } from "domains/KitSession/KitSessionContext"
import { useHasTeamFeature } from "domains/Results/utils"
import { SelectField } from "forms/fields"
import { useKitParticipants } from "resources/monthly_kit"
import { sortUsersByShortName, useUser } from "resources/users"
import { Loading, TextContainer, View } from "ui"
import { useEffectAfterChange } from "ui/hooks"
import { SHARED_FLAGS } from "ui/hooks/useFeatures"

const FeedbackFlexSelectTeamLead = styled(function FeedbackFlexSelectTeamLead({ kitInstance, sessionStep, className }) {
  const { team } = useKitSession()
  const { enabled: sessionRealtimeUpdatesV2 } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_REPLACE_POLLING)
  const exercise = kitInstance?.kit?.exercise
  const { minutes, auto_start_timer, show_timer_controls } = sessionStep
  const { data: participants } = useKitParticipants({ kitInstance, sessionRealtimeUpdates: sessionRealtimeUpdatesV2 })
  const { initialValues, exerciseInstance, onSubmit, saveOnChange, isFetching } = useExerciseForm({
    teamId: kitInstance?.team_id,
    slug: exercise?.slug,
    version: exercise?.version,
  })
  const { data: user } = useUser({ userId: "me" })

  useEffectAfterChange(() => {
    const sortedParticipants = sortUsersByShortName({ users: participants })
    if (!isFetching && sortedParticipants?.length > 1) {
      sortedParticipants.forEach((participant, idx) => {
        const key = `flex_feedback_assignment_${participant.id}`
        const assignmentUserId = getExerciseAnswer(exerciseInstance, key)
        const nextParticipant = sortedParticipants[idx + 1] ?? sortedParticipants[0]

        if (!assignmentUserId && nextParticipant) {
          saveOnChange(key, nextParticipant.id)
        }
      })
    }
  }, [isFetching, participants, exerciseInstance, saveOnChange])

  if (!user || !participants || isFetching) {
    return <Loading />
  }

  const sortedParticipants = sortUsersByShortName({ users: participants })
  const assignmentOptions = sortedParticipants.map((participant) => ({
    label: participant.short_name,
    value: participant.id,
  }))

  return (
    <StepContent className={className}>
      <StepTitle
        sessionStep={sessionStep}
        minutes={minutes}
        startSeconds={minutes * 60}
        autoStartTimer={auto_start_timer}
        showTimerLead={true}
        showTimerControls={show_timer_controls}
        className="mb-medium"
      >
        <h2 className="text-gray-9">Review the assignments</h2>
      </StepTitle>
      <div className="text-gray-9">
        <SidebarContainer>
          <TextContainer>
            <p className="mb-large">
              Review the assignments below. Everyone should have someone else to give feedback, too. If you want to
              change anything for any reason, change it below.
            </p>
          </TextContainer>
          <FacilitatorTip
            className="mb-large sidebar-right"
            tip="Turn off screen sharing before going to the next step."
          />
        </SidebarContainer>
        <Formik initialValues={initialValues} enableReinitialize onSubmit={onSubmit}>
          <ExerciseForm>
            {sortedParticipants.length > 1 ? (
              <TextContainer maxWidth={450} className="space-y-large">
                {sortedParticipants.map((participant) => (
                  <View $alignItems="center" $justifyContent="space-between" key={participant.id}>
                    <div className="text-field-label">{participant.short_name} will give feedback to:</div>
                    <SelectField
                      name={`flex_feedback_assignment_${participant.id}`}
                      options={assignmentOptions}
                      saveOnChange={(name, value) => saveOnChange(name, parseInt(value))}
                      className="assignment-select-field"
                    >
                      {sortedParticipants
                        .filter((selectParticipant) => selectParticipant.id !== participant.id)
                        .map((selectParticipant) => (
                          <option key={selectParticipant.id} value={selectParticipant.id}>
                            {selectParticipant.short_name}
                          </option>
                        ))}
                    </SelectField>
                  </View>
                ))}
              </TextContainer>
            ) : (
              <div className="text-italic">
                Once there are more people in your session, you'll assign people to give and receive feedback here.
              </div>
            )}
          </ExerciseForm>
        </Formik>
      </div>
    </StepContent>
  )
})`
  .assignment-select-field {
    width: fit-content;
  }
`

export default FeedbackFlexSelectTeamLead
