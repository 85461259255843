import { debounce } from "lodash-es"

async function scrollToTop({ behavior = "smooth" } = {}) {
  if (window.scrollY !== 0) {
    // Create a promise that will resolve after
    // - one scroll event fires, THEN
    // - 200ms passes with no scroll events firing
    // This will effectively tell us when the browser scroll operation has completed.
    // Note: 200ms chosen because generally browser scroll events fire every 100ms,
    //       though the exact duration between events is browser-dependent.
    const scrollPromise = new Promise((resolve) => {
      const debouncedResolve = debounce(() => {
        window.removeEventListener("scroll", debouncedResolve)
        resolve()
      }, 200)
      window.addEventListener("scroll", debouncedResolve)
    })
    window.scroll({ top: 0, behavior })
    await scrollPromise
  }
}

function onKeyEvent(
  key,
  handlerFn,
  { ctrl = false, shift = false, alt = false, meta = false, preventDefault = false, stopPropagation = false } = {}
) {
  return (keyEvent, ...otherArgs) => {
    if (typeof keyEvent?.key === "string" && keyEvent.key.toLowerCase() === key) {
      if (
        (!ctrl || keyEvent.ctrlKey) &&
        (!shift || keyEvent.shiftKey) &&
        (!alt || keyEvent.altKey) &&
        (!meta || keyEvent.metaKey)
      ) {
        if (preventDefault) {
          keyEvent.preventDefault()
        }
        if (stopPropagation) {
          keyEvent.stopPropagation()
        }
        return handlerFn(keyEvent, ...otherArgs)
      }
    }
  }
}

export { scrollToTop, onKeyEvent }
