import cn from "classnames"
import { getIn, useField, useFormikContext } from "formik"

function FieldMessage({ name, className, ...props }) {
  const { status } = useFormikContext()
  const [, meta] = useField(name)

  const errorMessage = meta.error
  const statusMessage = getIn(status, name)
  let message
  const messageToUse = (message = errorMessage || statusMessage)

  if (Array.isArray(messageToUse)) {
    message = messageToUse.map((m, idx) => (
      <div key={idx}>
        {m}
        <br />
      </div>
    ))
  } else if (typeof messageToUse === "object") {
    message = messageToUse?.message
  }

  const messageType = messageToUse?.messageType || "orange-4"

  return meta.touched && message ? (
    <div className={cn("mt-xs field-message text-semi-bold", `text-${messageType}`, className)} {...props}>
      {message}
    </div>
  ) : null
}

export default FieldMessage
