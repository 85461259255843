import { useState } from "react"
import { styled } from "styled-components"

import { getChartConfig } from "../results_utils/learner-agility"
import { createGetColorFunc, createFocusUserFunc } from "../results_utils/radarchart"

import { RadarChart } from "ui"
import { useEffectAfterChange } from "ui/hooks"

const LearnerAgilityChart = styled(function LearnerAgilityChart({
  userIdMap,
  exerciseInstances,
  focusedUser,
  showLegend = true,
  ...props
}) {
  const [focusedUsername, setFocusedUsername] = useState(focusedUser?.short_name)
  const chartConfig = getChartConfig(exerciseInstances, userIdMap)
  const getColorFunc = createGetColorFunc(focusedUsername)
  const focusUser = createFocusUserFunc({ focusedUsername, setFocusedUsername })

  useEffectAfterChange(() => {
    setFocusedUsername(focusedUser?.short_name)
  }, [focusedUser])

  return (
    <RadarChart
      {...props}
      {...chartConfig}
      showLegend={showLegend}
      onKeyClick={focusUser}
      getColorFunc={getColorFunc}
    />
  )
})`
  .chart-container {
    border: none;

    @media (min-width: ${({ theme }) => theme.tabletMin}) {
      flex: 2;
    }
  }
`

export default LearnerAgilityChart
