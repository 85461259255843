import { Formik } from "formik"
import { pick } from "lodash-es"
import { Link, Navigate, useNavigate } from "react-router-dom"
import { styled } from "styled-components"

import { useRegisterInvited, useVerifyToken } from "../resource"

import { TeamMemberRegisterForm, TeamLeadRegisterForm } from "./RegisterForm"
import TermsAndConditions from "./TermsAndConditions"

import {
  useAccountType,
  useIsSchoolAccountType,
  useRTBrandName,
  useSetAccountType,
} from "components/AccountTypeContext"
import { redirectWithSameAuthDomainIfNeeded } from "domains/Authentication/firebase"
import { useAuth } from "domains/Authentication/resource"
import { handleErrors, Yup } from "forms"
import { JOB_FUNCTIONS, SCHOOL_JOB_FUNCTIONS } from "forms/fields/JobFunctionsField"
import { userSchemas, profileSchemas } from "forms/yup"
import { Loading, PageTitle, View } from "ui"
import { useFeatures, useQueryParams } from "ui/hooks"
import MailToLink from "ui/MailToLink"
import { getTimeZone } from "utils/date"
import { buildUrl } from "utils/string"

const RegisterInvited = ({ teamMemberInvite }) => {
  const { data: auth, isFetching: authIsFetching } = useAuth()
  const { uid, token } = useQueryParams()
  const { data: tokenVerification, isFetching: verifyTokenIsFetching } = useVerifyToken({ uid, token })
  const { setAccountType } = useAccountType()
  // TODO: jey: remove after we switch all sso providers to same domain
  const features = useFeatures()

  useSetAccountType({ accountType: tokenVerification?.account_type, shouldSet: !!tokenVerification, setAccountType })

  if (authIsFetching || verifyTokenIsFetching || features.isInitialLoading) {
    return <Loading />
  }

  if (auth) {
    return <Navigate to="/" replace />
  }

  if (!tokenVerification) {
    return <ErrorValidating />
  }

  const { is_valid_token, user, sso_providers } = tokenVerification

  if (!is_valid_token) {
    return <TokenExpired />
  }

  if (!!sso_providers?.length) {
    const provider = sso_providers[0].provider

    const redirected = redirectWithSameAuthDomainIfNeeded({ features, provider })

    if (redirected) {
      return null
    }

    return <Navigate to={buildUrl(["auth", "sso", provider])} replace />
  } else {
    return <RegisterInvitedPasswordPage teamMemberInvite={teamMemberInvite} user={user} uid={uid} token={token} />
  }
}

const RegisterInvitedPasswordPage = styled(function RegisterInvitedPasswordPage({
  teamMemberInvite,
  user,
  uid,
  token,
  className,
}) {
  const { mutateAsync: registerInvited } = useRegisterInvited()
  const navigate = useNavigate()
  const isSchool = useIsSchoolAccountType()
  const rtBrandName = useRTBrandName()

  const RegisterForm = teamMemberInvite ? TeamMemberRegisterForm : TeamLeadRegisterForm

  const profileInitialValues = teamMemberInvite
    ? {
        profile: {
          pronouns: "",
          job_function: "",
          is_team_lead: false,
        },
      }
    : {}

  const initialValues = {
    email: user.email,
    first_name: user.first_name,
    last_name: user.last_name,
    password: "",
    ...profileInitialValues,
  }

  const profileSchema = teamMemberInvite
    ? {
        profile: Yup.object().shape({
          ...pick(profileSchemas, ["pronouns", "job_function"]),
          is_team_lead: Yup.boolean(),
        }),
      }
    : {}

  const validationSchema = Yup.object().shape({
    ...pick(userSchemas, ["first_name", "last_name", "email", "password"]),
    ...profileSchema,
  })

  const onSubmit = handleErrors(async (values) => {
    await registerInvited({
      ...values,
      uid,
      token,
      time_zone: getTimeZone(),
    })

    const nextUrl = teamMemberInvite ? "/" : "/get-started/create-account/onboarding/"
    navigate(nextUrl)
  })

  const jobFunctions = isSchool ? SCHOOL_JOB_FUNCTIONS : JOB_FUNCTIONS

  return (
    <>
      <PageTitle>Let's get started</PageTitle>
      <View $flex={1} $flexDirection="column" className={className}>
        <h1 className="text-large text-gray-9 mt-xs mb-large">Welcome to {rtBrandName}</h1>
        <p className="text-gray-8 mb-xl">Let's get your account set up</p>
        <View $alignItems="center" $flexDirection="column" className="form-container mt-medium">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnBlur={false}
            onSubmit={onSubmit}
          >
            <RegisterForm invited jobFunctions={jobFunctions} />
          </Formik>
        </View>
        <TermsAndConditions />
      </View>
    </>
  )
})`
  .form-container {
    width: 330px;
  }
`

const TokenExpired = () => (
  <>
    <PageTitle>Let's get started</PageTitle>
    <div>
      This invite link has expired or your account has already been created. If you already have an account please{" "}
      <Link to="/auth/login">login here</Link>. Otherwise, please ask your lead or admin to resend you an invite.
    </div>
  </>
)

const ErrorValidating = () => (
  <>
    <PageTitle>Unable to validate</PageTitle>
    <div>
      Unable to validate the invite link, please try again. If you are still getting an error, please contact
      <MailToLink email="support@risingteam.com" />.
    </div>
  </>
)

export default RegisterInvited
