// TODO: utils.js

import { HeartIcon, RocketLaunchIcon } from "icons/FontAwesomeIcons"

const KIT_TYPE = {
  KIT: "kit",
  BOOST: "boost",
  MINI: "mini",
}

// Keep in sync with backend/monthly_kit/definitions/constants.py
const CUSTOM_KIT_ORGANIZATION = {
  BOH: "boh",
}

const KIT_GROUP_SIZE = {
  STANDARD: "standard",
  JUMBO: "jumbo",
}

const KIT_LINK_KEYS = {
  SESSION_URL: "session_url",
  SESSION_PREVIEW_URL: "session_preview_url",
  SESSION_START_URL: "session_start_url",
  HOME_URL: "home_url",
  PREP_URL: "prep_url",
  REVIEW_URL: "review_url",
  RESULTS_URL: "results_url",
  BONUS_URL: "bonus_url",
  TEAM_HOME_URL: "team_home_url",
}

const PROMO_KIT_SLUG = {
  GRATITUDE: "gratitude",
}

const CUSTOM_KIT_LOGO_URL = "https://static.risingteam.com/kit_assets/custom-kit-organization-logo/"

const getKitTypeInfo = (kitType) => {
  switch (kitType) {
    case KIT_TYPE.BOOST:
      return {
        header: "Connection Kit",
        showLearnedSomethingValuableQuestion: true,
        showFeelMoreConnectedQuestion: true,
        showWorkMoreEffectivelyQuestion: false,
        showLikelihoodOfRecommendingQuestion: true,
        showThinkAboutSessionQuestion: false,
        showImproveExperienceFeedbackQuestion: true,
        showTeamPageTakeExerciseLink: false,
        hasReflections: false,
        hasReportExerciseResponses: false,
        pillIcon: HeartIcon,
        pillText: "Connection Kit",
        pillColorCss: "text-orange-3 border-orange-3",
      }
    case KIT_TYPE.MINI:
      return {
        header: "Mini",
        showLearnedSomethingValuableQuestion: false,
        showFeelMoreConnectedQuestion: false,
        showWorkMoreEffectivelyQuestion: false,
        showLikelihoodOfRecommendingQuestion: false,
        showThinkAboutSessionQuestion: true,
        showImproveExperienceFeedbackQuestion: true,
        showTeamPageTakeExerciseLink: false,
        hasReflections: false,
        hasReportExerciseResponses: false,
        pillIcon: HeartIcon,
        pillText: "Mini Kit",
        pillColorCss: "text-orange-3 border-orange-3",
      }
    default:
      return {
        header: "Theme",
        showLearnedSomethingValuableQuestion: true,
        showFeelMoreConnectedQuestion: true,
        showWorkMoreEffectivelyQuestion: true,
        showLikelihoodOfRecommendingQuestion: true,
        showThinkAboutSessionQuestion: false,
        showImproveExperienceFeedbackQuestion: true,
        showTeamPageTakeExerciseLink: true,
        hasReflections: true,
        hasReportExerciseResponses: true,
        pillIcon: RocketLaunchIcon,
        pillText: "Development Kit",
        pillColorCss: "text-rising-green border-rising-green",
      }
  }
}

const getCustomKitTypeInfo = (customKitOrganization) => {
  switch (customKitOrganization) {
    case CUSTOM_KIT_ORGANIZATION.BOH:
      return {
        customPillLogo: CUSTOM_KIT_LOGO_URL + "boh-logo.svg",
        pillText: "Bank of Hawaiʻi",
        pillColorCss: "text-blue-2 border-blue-2 bg-blue-tint",
      }
    default:
      return {
        customPillLogo: null,
        pillText: "Development Kit",
        pillColorCss: "text-rising-green border-rising-green",
      }
  }
}

const getRoundedKitSessionTime = (sessionTimeInMinutes, { plural = false } = {}) => {
  if (sessionTimeInMinutes < 60) {
    const minuteText = plural ? "mins" : "minute"
    return `${sessionTimeInMinutes} ${minuteText}`
  } else {
    const isMultipleHours = sessionTimeInMinutes / 60 > 1
    const hourText = plural && isMultipleHours ? "hours" : "hour"
    return `${sessionTimeInMinutes / 60} ${hourText}`
  }
}

export {
  KIT_TYPE,
  KIT_GROUP_SIZE,
  KIT_LINK_KEYS,
  PROMO_KIT_SLUG,
  getKitTypeInfo,
  getCustomKitTypeInfo,
  getRoundedKitSessionTime,
}
