import { useNavigate } from "react-router"

import { Button } from "ui"
import { useQueryParams } from "ui/hooks"

const ZapierAuth = () => {
  const navigate = useNavigate()
  //const { client_id, state, redirect_uri } = useQueryParams()
  const { redirect_uri, state } = useQueryParams()
  const authorization_code = "lRMIDcsKyD"

  const onDeny = () => {
    navigate("/")
  }

  const onGrant = () => {
    const url = new URL(redirect_uri)
    const search_params = url.searchParams
    search_params.append("code", authorization_code)
    search_params.append("state", state)
    search_params.append("client_id", "97821600")
    search_params.append("client_secret", "FlGhhG3OAf")
    url.search = search_params.toString()
    var new_redirect_uri = url.toString()
    window.location.href = new_redirect_uri
  }

  return (
    <div>
      <div>Do you want to provide access to Zapier?</div>
      <Button onClick={onGrant}>Grant</Button>
      <Button onClick={onDeny}>Deny</Button>
    </div>
  )
}

export default ZapierAuth
