import { Route, Routes } from "react-router-dom"

import KitsHomeTeamLeadOnboarding from "./KitsHomeTeamLeadOnboarding"
import KitSlugRedirect from "./KitSlugRedirect"
import KitUnavailable from "./KitUnavailable"
import LeaderKitDetail from "./LeaderKitDetail"
import LeaderKitHome from "./LeaderKitHome"

import { SimpleRouteLayout } from "domains/Authentication/Routes"
import { StandaloneExercise } from "domains/Exercise/Standalone"
import KitSessionReview from "domains/KitSession/KitSessionReview"
import KitTraining from "domains/KitTraining"

const LeaderKit = () => (
  <Routes>
    <Route element={<SimpleRouteLayout title="Kits" />}>
      <Route path="onboarding" element={<KitsHomeTeamLeadOnboarding />} />
      <Route path=":slug" element={<KitSlugRedirect />} />
      <Route path=":slug/:id" element={<LeaderKitDetail />} />
      <Route path=":slug/:id/review/*" element={<KitSessionReview />} />
      <Route path=":slug/:id/prep/*" element={<KitTraining />} />
      <Route path=":kitSlug/exercise/latest/*" element={<StandaloneExercise />} />
      <Route path=":slug/unavailable" element={<KitUnavailable />} />
      <Route path="*" element={<LeaderKitHome />} />
    </Route>
  </Routes>
)

export default LeaderKit
