import { Formik } from "formik"
import { find, shuffle } from "lodash-es"
import { useState } from "react"
import { styled } from "styled-components"

import { useKitSession } from "../KitSessionContext"

import { useSyncSessionEnabled } from "domains/KitSession/utils"
import { useHasTeamFeature } from "domains/Results/utils"
import { SelectField } from "forms/fields"
import { useUser } from "resources/users"
import { Button } from "ui"
import { SHARED_FLAGS } from "ui/hooks/useFeatures"

const SharingNow = ({ users, selectedUser, onSelectUser, className }) => {
  const { team } = useKitSession()
  const { data: user } = useUser({ userId: "me" })
  const { enabled: realtimeShareoutUserFeatureEnabled } = useHasTeamFeature(
    team,
    SHARED_FLAGS.RTDEV_REALTIME_SHAREOUT_USER
  )
  const syncSessionEnabled = useSyncSessionEnabled()
  const realtimeShareoutUserActive = realtimeShareoutUserFeatureEnabled && syncSessionEnabled
  const initialValues = { current_team_member: selectedUser.id }
  // Ensure team lead is always last to be picked by shuffle,
  // since we typically start with them
  const [shuffledUsers] = useState(
    shuffle(users).sort((user1, user2) =>
      team.team_lead_id === user1.id ? -1 : team.team_lead_id === user2.id ? 1 : 0
    )
  )
  const isTeamLead = user.id === team.team_lead_id
  const selectedUserIndex = shuffledUsers.findIndex(({ id }) => id === selectedUser?.id)
  const initialRandomUserIndex =
    (!!realtimeShareoutUserActive || selectedUser?.id) && selectedUserIndex !== -1
      ? selectedUserIndex
      : shuffledUsers.length - 1
  const [randomUserIndex, setRandomUserIndex] = useState(initialRandomUserIndex)

  const handleUserChange = ({ target }) => {
    const user = find(users, { id: parseInt(target.value) })
    onSelectUser(user)
  }

  const randomUsers = () => {
    const userIndex = randomUserIndex <= 0 ? shuffledUsers.length - 1 : randomUserIndex - 1
    setRandomUserIndex(userIndex)
    onSelectUser(shuffledUsers[userIndex])
  }

  return (
    <>
      {!realtimeShareoutUserActive || !!isTeamLead ? (
        <div className={className}>
          <div className="form mr-medium">
            <Formik initialValues={initialValues}>
              <SelectField name="current_team_member" size="medium" value={selectedUser.id} onChange={handleUserChange}>
                {selectedUser.id === "" && <option disabled value="" />}
                {users.map(({ id, short_name }) => (
                  <option key={id} value={id}>
                    {short_name}
                  </option>
                ))}
              </SelectField>
            </Formik>
          </div>
          <div className="btn-cont">
            <Button className="pick-for-me-btn tertiary" onClick={randomUsers}>
              Pick for me
            </Button>
          </div>
        </div>
      ) : (
        <div className="text-medium text-semi-bold mr-medium">{selectedUser.short_name}</div>
      )}
    </>
  )
}

export default styled(SharingNow)`
  .form,
  .btn-cont {
    display: inline-block;
  }

  .pick-for-me-btn {
    width: 160px;
    height: 42px;
  }

  @media (max-width: ${({ theme }) => theme.mobileMax}) {
    .form {
      margin-bottom: var(--spacing-2);
    }
  }
`
