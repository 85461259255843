import { Navigate, Route } from "react-router-dom"

import Onboarding from "./Onboarding"
import OnboardingFromDemo from "./OnboardingFromDemo"
import Welcome from "./Welcome"

import { useSetAccountTypeByTeam } from "components/AccountTypeContext"
import Routes from "components/Steps"
import { useTeams } from "resources/teams"
import { useUser } from "resources/users"
import { Loading, PageTitle, View } from "ui"

const CreateAccount = () => {
  const { data: user } = useUser({ userId: "me" })
  const { data: teams } = useTeams()
  const firstTeam = teams ? teams[0] : null
  useSetAccountTypeByTeam(firstTeam)

  if (!user) {
    return <Loading />
  }

  return (
    <>
      <PageTitle>Create your account</PageTitle>
      <View $flex={1} $flexDirection="column">
        <Routes>
          <Route path="onboarding" element={<Onboarding user={user} />} />
          <Route path="onboarding-from-demo" element={<OnboardingFromDemo user={user} />} />
          <Route path="welcome" element={<Welcome user={user} />} />
          <Route path="*" ignore element={<Navigate to="onboarding" replace />} />
        </Routes>
      </View>
    </>
  )
}

export default CreateAccount
