import cn from "classnames"
import { useField } from "formik"
import { styled } from "styled-components"

import renderBlocks from "components/Blocks/renderBlocks"
import { PieSliceNoneIcon, PieSliceOneIcon, PieSliceMultipleIcon, blockIcons } from "icons"
import { MinusIcon, PlusIcon } from "icons/FontAwesomeIcons"
import { View, Button } from "ui"

const ExercisePieSliceFieldBody = styled(function ExercisePieSliceFieldBody({
  id,
  sentence,
  color,
  availableSlices,
  customField,
  CustomFieldComponent,
  saveOnChange,
  customSaveOnChange,
  className,
}) {
  const [field, _meta, { setValue }] = useField(id)
  const Icon =
    !field.value || field.value <= 0 ? PieSliceNoneIcon : field.value === 1 ? PieSliceOneIcon : PieSliceMultipleIcon

  const updateValue = (v) => {
    const value = Math.max(0, v)

    setValue(value)
    saveOnChange?.(id, value)
  }

  const decreaseToVotes = (field.value ?? 0) - 1
  const increaseToVotes = (field.value ?? 0) + 1
  const canDecrease = !field.value || field.value <= 0
  const canIncrease = availableSlices <= 0
  const decreaseAriaLabel = canDecrease ? "Decrease votes" : `Decrease to ${decreaseToVotes} votes`
  const increaseAriaLabel = canIncrease ? "Increase votes" : `Increase to ${increaseToVotes} votes`

  return (
    <View
      className={cn(className, "my-xs px-xs py-xxs", { "slice-container-active": field.value && field.value > 0 })}
      $flex={1}
      $justifyContent="space-between"
      $alignItems="center"
    >
      <View $alignItems="center">
        <Icon color={color} className="slice-icon mr-small" aria-hidden="true" />
        <div className="text-small">{renderBlocks(sentence, blockIcons)}</div>
        {!!customField && (
          <CustomFieldComponent
            name={customField.identifier}
            saveOnChange={customSaveOnChange}
            className="custom-field ml-xs text-small"
          />
        )}
      </View>
      <View className="slice-input ml-medium" $width="auto" $alignItems="center" $justifyContent="center">
        <Button
          onClick={() => updateValue(decreaseToVotes)}
          aria-label={decreaseAriaLabel}
          disabled={canDecrease}
          className="slice-update-button"
        >
          <MinusIcon />
        </Button>
        <div className="slice-count text-center">{field.value ?? 0}</div>
        <Button
          onClick={() => updateValue(increaseToVotes)}
          aria-label={increaseAriaLabel}
          disabled={canIncrease}
          className="slice-update-button"
        >
          <PlusIcon />
        </Button>
      </View>
    </View>
  )
})`
  &.slice-container-active {
    border-radius: var(--border-radius-small);
    background-color: rgb(62 106 225 / 8%);
  }

  .custom-field input {
    padding: var(--spacing-0);
    font-size: inherit;
  }

  .slice-icon {
    flex: 0 0 40px;
  }

  .slice-input {
    flex: 0 0 100px;
    height: 32px;
  }

  .slice-update-button {
    height: 100%;
    padding: 0;
    margin: 0;
    flex: 1;
    background-color: var(--fg);
    color: var(--gray-9);
    border: 1px solid var(--gray-5);

    &:disabled {
      color: var(--gray-5);
      box-shadow: none;
    }

    &:first-child {
      border-radius: 8px 0 0 8px;
    }

    &:last-child {
      border-radius: 0 8px 8px 0;
    }
  }

  .slice-count {
    flex: 1;
    height: 100%;
    border-width: 1px 0;
    border-style: solid;
    border-color: var(--gray-5);
  }
`

export default ExercisePieSliceFieldBody
