import cn from "classnames"
import { uniqueId } from "lodash-es"
import { cloneElement, Fragment, Children } from "react"
import ReactDOM from "react-dom"
import { Tooltip as ReactTooltip } from "react-tooltip"
import "react-tooltip/dist/react-tooltip.css"
import { styled } from "styled-components"

import BulletedList from "./BulletedList"
import HorizontalRule from "./HorizontalRule"
import NumberedList from "./NumberedList"
import View from "./View"

import renderBlocks from "components/Blocks/renderBlocks"
import { blockIcons } from "icons"
import { ZIndex } from "ui/z-index"

const generateListTooltipTitle = (list, truncateCount, totalCount = null) => {
  totalCount = totalCount == null ? list.length : totalCount
  return (
    <>
      {list.slice(0, truncateCount).map((item, idx) => (
        <Fragment key={idx}>
          {!!idx && <br />}
          {item}
          {totalCount > truncateCount && idx === truncateCount - 1 ? "..." : ""}
        </Fragment>
      ))}
    </>
  )
}

const SUPPORTED_COMPONENTS = {
  BulletedList,
  NumberedList,
  HorizontalRule,
  ...blockIcons,
}

const DEFAULT_TOOLTIP_ORIENTATION = "right"
const DEFAULT_TOOLTIP_MAX_WIDTH = 300

let globalTooltipContainer = null

const Tooltip = ({
  className = null,
  title,
  type = null,
  children,

  // Optional "wrapper" component to add content around tooltip title,
  // while still allowing title block-rendering to happen inside Tooltip.js:
  titleWrapper = (title) => title,

  // Tooltip Orientation:
  place = DEFAULT_TOOLTIP_ORIENTATION,
  // convenience props for setting place="<direction>":
  top = false,
  right = false,
  bottom = false,
  left = false,

  // Optional Tooltip Configuration:
  width = "100%", // default: ensure tooltip content matches width of its container
  maxWidth = DEFAULT_TOOLTIP_MAX_WIDTH,
  wrap = false,
  disabled = false, // don't show tooltip if true (don't even render tooltip)
  float = false, // make the tooltip move around with the cursor

  // ID is optional, but can be passed if you need a consistent reference
  // to the tooltip, rather than an id from uniqueId('tooltip-'):
  id = null,

  wrapInView = true,

  ...props
}) => {
  if ([top, right, bottom, left].filter(Boolean).length > 1) {
    throw new Error("Tooltip.js: Don't set multiple of top, right, bottom, left props at once.")
  }

  // Don't render tooltip if disabled=true or if title is blank:
  const shouldRenderTooltip = !disabled && title != null && !(typeof title === "string" && !title.trim().length)

  id = id ?? uniqueId("tooltip-")
  place = top ? "top" : right ? "right" : bottom ? "bottom" : left ? "left" : place

  // The first time this runs, set up the global container element for tooltips:
  if (!globalTooltipContainer) {
    globalTooltipContainer = document.getElementById("react-tooltip-container")
    if (!globalTooltipContainer) {
      globalTooltipContainer = document.createElement("div")
      globalTooltipContainer.setAttribute("id", "react-tooltip-container")
      document.body.appendChild(globalTooltipContainer)
    }
  }

  if (!wrapInView && Children.count(children) > 1) {
    throw new Error(
      "Tooltip.js: Don't pass multiple children when setting wrapInView={false}, since this would result in duplicate HTML ids. Wrap your tooltip content in a single element."
    )
  }

  if (!wrapInView) {
    // If we're not wrapping in a <View> element, we must add the tooltip ID as an
    // attribute directly on the provided React child element (there can only be 1):
    children = Children.count(children) ? cloneElement(children, { id }) : null
  }

  // Needed for typescript typing since variant can't be null
  // eslint-disable-next-line no-undefined
  const variant = type ?? undefined

  return (
    <>
      {!wrapInView ? (
        children
      ) : (
        <View id={id} className={cn("tooltip", className)} $width={width}>
          {children}
        </View>
      )}
      {!!shouldRenderTooltip &&
        /* Use createPortal to render react-tooltip's in a central container,
         * outside the parent element here. This allows tooltips to be rendered
         * within elements that have `overflow: hidden` and avoid the tooltip
         * being cut off around the edges of the parent element.
         */
        ReactDOM.createPortal(
          <ReactTooltip
            anchorId={id}
            variant={variant}
            place={place}
            float={float}
            className={cn(className, "tooltip-content text-small", { "text-wrap": wrap })}
            style={{ zIndex: ZIndex.Tooltip, maxWidth }}
            key={
              title
              // this makes tooltip disappear -> reappear on hover if title changes,
              // which avoids positioning bugs with buttons that change title on click.
            }
            {...props}
          >
            {
              /* Normally we'd also pass components/index.js#blockComponents into
               * renderBlocks, but we avoid it here to avoid import cycles. Tooltips
               * are text-only elements (apart from icons), so none of the
               * blockComponents are useful here (though we do pass blockIcons).
               */
              titleWrapper(renderBlocks(title, SUPPORTED_COMPONENTS))
            }
          </ReactTooltip>,
          globalTooltipContainer
        )}
    </>
  )
}

export default styled(Tooltip)`
  ${({ type, place = DEFAULT_TOOLTIP_ORIENTATION }) =>
    type !== "light"
      ? ""
      : `&.tooltip-content {
          border: 2px solid var(--gray-5);
          .react-tooltip-arrow {
          }
        ${
          place === "right"
            ? `.react-tooltip-arrow {
              transform: rotate(45deg) translateX(-2px); // adjust arrow position
              border-bottom: 2px solid var(--gray-5);
              border-left: 2px solid var(--gray-5);
            }`
            : place === "left"
            ? `.react-tooltip-arrow {
              transform: rotate(45deg) translateX(2px); // adjust arrow position
              border-top: 2px solid var(--gray-5);
              border-right: 2px solid var(--gray-5);
            }`
            : place === "top"
            ? `.react-tooltip-arrow {
              transform: rotate(45deg) translateY(2px); // adjust arrow position
              border-bottom: 2px solid var(--gray-5);
              border-right: 2px solid var(--gray-5);
            }`
            : place === "bottom"
            ? `.react-tooltip-arrow {
              transform: rotate(45deg) translateX(-2px); // adjust arrow position
              border-top: 2px solid var(--gray-5);
              border-left: 2px solid var(--gray-5);
            }`
            : ""
        }
      }
    `}
  @media (max-width: ${({ theme }) => theme.mobileLandscapeMax}) {
    &.tooltip-content {
      max-width: 80vw;
    }
  }

  @media (min-width: ${({ theme }) => theme.tabletMin}) {
    &.tooltip-content {
      max-width: ${({ maxWidth }) => `${maxWidth ?? DEFAULT_TOOLTIP_MAX_WIDTH}px`};
    }
  }
`

export { generateListTooltipTitle }
