import { Formik } from "formik"

import { ErrorPage } from "components"
import ExerciseContent from "domains/Exercise/ExerciseContent"
import ExerciseForm from "domains/Exercise/ExerciseForm"
import useExerciseForm from "domains/Exercise/useExerciseForm"
import { Loading } from "ui"

const SessionExerciseForm = ({ kitInstance, components, className }) => {
  const exercise = kitInstance?.kit?.exercise
  const { initialValues, onSubmit, saveOnChange, isInvalid, isFetching } = useExerciseForm({
    teamId: kitInstance?.team_id,
    slug: exercise?.slug,
    version: exercise?.version,
  })

  if (!initialValues && isFetching) {
    return <Loading />
  }

  if (isInvalid) {
    return <ErrorPage />
  }

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      <ExerciseForm className={className}>
        <ExerciseContent
          kit={kitInstance.kit}
          components={components}
          saveOnChange={saveOnChange}
          is_full_exercise={true}
          is_session={true}
        />
      </ExerciseForm>
    </Formik>
  )
}

export default SessionExerciseForm
