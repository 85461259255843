import cn from "classnames"
import { Link, Navigate } from "react-router-dom"

import { useIsMenziesAccountType } from "components/AccountTypeContext"
import { ArrowRightIcon, BoltIcon } from "icons/FontAwesomeIcons"
import { useBasicKitInfos } from "resources/monthly_kit"
import { useTeams } from "resources/teams"
import { Button, Loading, PageTitle, View } from "ui"
import { useQueryParams } from "ui/hooks"

const Welcome = ({ user, className }) => {
  const isMenzies = useIsMenziesAccountType()
  const { data: teams, isFetching: teamsIsFetching } = useTeams()
  const tlTeams = teams?.filter((t) => !t.preview && t.team_lead_id === user.id)
  const tlTeam = tlTeams?.length === 1 ? tlTeams[0] : null
  const { data: tlKitInfos, isFetching: kitInfosFetching } = useBasicKitInfos({ teamId: tlTeam?.id })
  const { free_trial } = useQueryParams()
  const headerMessage = typeof free_trial !== "undefined" ? "Free trial activated!" : "Account activated!"

  if (!user.has_an_active_account) {
    return <Navigate to="/select-or-update-payment" replace />
  }

  if (teamsIsFetching || kitInfosFetching) {
    return <Loading />
  }

  const ignoreTLTeamsAndKits = isMenzies && user.can_manage_billing_for_an_account
  const hasTlTeams = !!tlTeams?.length && !ignoreTLTeamsAndKits
  const hasTLKitInfos = !!tlKitInfos?.length && !ignoreTLTeamsAndKits

  const [infoText, buttonUrl, buttonText] = hasTLKitInfos
    ? [
        "You now have everything you need to start your first Kit.",
        `/kit/${tlKitInfos[0].slug}?team_id=${tlTeam.id}`,
        "Check out my first Kit",
      ]
    : hasTlTeams
    ? ["You now have everything you need to get started.", "/", "Get Started"]
    : ["You now have everything you need to start inviting team leads.", "/admin?inviteLeads=true", "Get Started"]

  return (
    <div className={cn("full-width", className)}>
      <PageTitle>Welcome!</PageTitle>

      <View className="mb-large" $flexDirection="row" $alignItems="center">
        <BoltIcon color="var(--rising-yellow)" className="fa-3x mr-medium" />
        <h1 className="text-large text-gray-9">{headerMessage}</h1>
      </View>
      <p className="text-gray-8 mb-large">{infoText}</p>

      <View $flexDirection="column" $alignItems="center" className="mb-xxl">
        <img
          src="https://static.risingteam.com/kit_assets/onboarding/confettiball.gif"
          alt="confetti-ball"
          width="400px"
        />
      </View>

      <View $alignItems="center" className="mt-xxl">
        <Link to={buttonUrl} className="mr-xl">
          <Button>
            {buttonText}
            <ArrowRightIcon />
          </Button>
        </Link>
        {!!hasTLKitInfos && (
          <Link to={`/kit?team_id=${tlTeam.id}`} className="link text-button">
            See all kits
          </Link>
        )}
      </View>
    </div>
  )
}

export default Welcome
