import { styled } from "styled-components"

import LoginForm from "./forms/LoginForm"

import { Footer } from "components"
import { MARKETING_PRICING_URL } from "domains/GetStarted/resource"
import { LogoIcon } from "icons"
import { PageTitle, View, Background } from "ui"

const Login = ({ className }) => (
  <>
    <PageTitle>Login</PageTitle>
    <Background className={className} $url="https://static.risingteam.com/kit_assets/login-bg.png">
      <View className="text-center main-container" $flex={1} $flexDirection="column" $alignItems="center">
        <View className="text-center" $flex={1} $flexDirection="column" $alignItems="center" $width="auto">
          <LogoIcon className="logo mb-large" />
          <h2 className="mb-xl text-bold">Welcome to Rising Team</h2>
          <View className="text-uppercase text-bold mb-xxxl" $width="auto">
            <a className="text-gray-7 mr-medium" href={MARKETING_PRICING_URL}>
              Sign up
            </a>
            <p className="login-link pb-xs text-rising-orange mr-medium">Log in</p>
          </View>
          <LoginForm />
        </View>
        <Footer />
      </View>
    </Background>
  </>
)

export default styled(Login)`
  @media (max-width: ${({ theme }) => theme.mobileMax}) {
    background: var(--fg);
  }

  .main-container {
    margin: 80px auto 215px;
    padding: 50px 0 30px;
    max-width: 540px;
    background: white;
    opacity: 0.93;

    @media (min-width: ${({ theme }) => theme.mobileLandscapeMin}) {
      border: 1px solid var(--gray-5);
      box-sizing: border-box;
      box-shadow: 0 0 33px rgba(0 0 0 / 5%);
      border-radius: 15px;
    }

    .logo {
      width: 72px;
      height: 67px;
    }

    .login-link {
      border-bottom: 2px solid;
    }

    form {
      width: 330px;
    }
  }
`
