import { useQueryClient } from "@tanstack/react-query"
import cn from "classnames"
import { format as formatDate } from "date-fns"
import { Form, Formik } from "formik"
import { invert, orderBy, omitBy } from "lodash-es"
import { useRef, useState } from "react"
import Markdown from "react-markdown"
import { Route, Routes, Navigate, useNavigate, useParams, useLocation } from "react-router-dom"
import Sticky from "react-stickynode"
import remarkGfm from "remark-gfm"
import { styled } from "styled-components"

import { LLMType, LLMName, VANILLA_LLM_TYPES, ArtiSender, NO_ONE_MEMBER, ARTI_RATING_NA_VALUE } from "./constants"
import {
  artiTeamMemberChatStreaming,
  updateArtiExchangeRating,
  useArtiTeams,
  useArtiHistoryTopExchanges,
  useArtiHistorySessionExchanges,
  prependHistorySessionExchangeToQueryCache,
  updateExcludeFromManualReview,
  updateArtiExchangeInappropriateFlag,
  artiTeamMemberChatNonStreaming,
  preprocessArtiMemberData,
} from "./resource"
import { getPopularTopics, formatPopularTopicAsSelectFieldOption } from "./utils"

import { useScrollToTopContext } from "components/ScrollToTop"
import { DEFAULT_TOUR_HASH } from "components/Tour"
import ArtiTour, {
  ArtiTourStepNames,
  ArtiTourHintNames,
  ARTI_TOUR_OPEN_POPULAR_TOPICS_STEP_INDICES,
} from "domains/Arti/components/ArtiTour"
import { useHasTeamFeature } from "domains/Results/utils"
import { FormMessage, handleErrors } from "forms"
import { AdvancedSelectField, TextareaField, ChoicesField, TextField } from "forms/fields"
import { Choice } from "forms/fields/ChoicesField"
import LineRatingField, { removeLineRatingFieldNameSuffix } from "forms/fields/LineRatingField"
import {
  FlagIcon,
  PaperPlaneTopIcon,
  PlusIcon,
  QuestionIcon,
  MagnifyingGlassIcon,
  FaceFrownIcon,
  FaceMehIcon,
  FaceGrinHeartsIcon,
  FaceSmileIcon,
  FaceAngryIcon,
} from "icons/FontAwesomeIcons"
import { useScheduleNextKits } from "resources/monthly_kit"
import { useTeam } from "resources/teams"
import {
  CalloutName,
  useGetCalloutStatus,
  useUpdateCalloutStatus,
  useUser,
  sortUsersByShortName,
} from "resources/users"
import { Loading, PageTitle, SubmitButton, View, CopyButton, Button, Tooltip, HorizontalRule, ZIndex } from "ui"
import {
  useEffectAfterFirstRender,
  useEffectAfterChange,
  useQueryParams,
  useWindowSize,
  useScrollElementIntoViewRef,
} from "ui/hooks"
import { SHARED_FLAGS } from "ui/hooks/useFeatures"
import { useSelectedTeamIdWithTeamIdQueryParam } from "ui/SelectedTeamContext"
import { uniqueById } from "utils/array"
import { onKeyEvent } from "utils/browser"
import { formatTimestampAsDate } from "utils/date"
import { isProductionEnv } from "utils/env"
import { buildUrl } from "utils/string"

const MIN_RATEABLE_MESSAGE_LENGTH = 250

const Arti = () => {
  const { selectedTeamId, redirectTeamId, needsRedirect } = useSelectedTeamIdWithTeamIdQueryParam({
    allowJumboTeams: false,
    redirectIfNotSelectableTeam: true,
  })
  const { data: user } = useUser({ userId: "me" })
  const { data: team } = useTeam({ teamId: selectedTeamId })

  if (redirectTeamId) {
    return <Navigate to={`/arti?team_id=${redirectTeamId}${window.location.hash}`} replace />
  }

  if (!user || !team || needsRedirect) {
    return <Loading className="p-xxxl m-large" />
  }

  if (!user.has_arti_access) {
    return <Navigate to="/" replace />
  }

  return (
    <Routes>
      <Route path="/:id" element={<Chat user={user} team={team} />} />
      <Route path="*" element={<Chat user={user} team={team} />} />
    </Routes>
  )
}

const Chat = styled(function Chat({ className, user, team }) {
  const { enabled: showKitRecommendations } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_ARTI_KIT_RECS)
  const chatInputRef = useRef()
  const { state } = useLocation()
  const { isMobileLandscapeOrSmaller } = useWindowSize()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { setNoScrollToTopForPaths, resetNoScrollToTop } = useScrollToTopContext()
  useEffectAfterFirstRender(() => () => resetNoScrollToTop()) // reset when component unmounts

  const { enabled: hideRatingFeedback, isInitialLoading: hideRatingFeedbackLoading } = useHasTeamFeature(
    team,
    SHARED_FLAGS.ARTI_HIDE_RATING_FEEDBACK
  )
  const { enabled: doNotUseStreaming } = useHasTeamFeature(team, SHARED_FLAGS.ARTI_DO_NOT_USE_STREAMING)
  const { enabled: showLLMSelect } = useHasTeamFeature(team, SHARED_FLAGS.ARTI_LLM_SELECT)
  const { enabled: showArtiHelp } = useHasTeamFeature(team, SHARED_FLAGS.ARTI_HELP)

  const showRatingFeedback = !hideRatingFeedback && !hideRatingFeedbackLoading && !team.demo
  const showChatHistory = !team.demo
  const showExcludeFromManualReview = !!user.is_staff && !team.demo
  const showTextInputFeedback = !team.demo

  const artiTourIntroJsRef = useRef()
  const [artiTourPopularTopicsMenuOpen, setArtiTourForcePopularTopicsMenuOpen] = useState(false)
  const calloutName = CalloutName.ARTI_TOUR
  const { data: hasSeenArtiTour, isFetching: isFetchingHasSeenArtiTour } = useGetCalloutStatus({
    userId: user.id,
    calloutName,
  })
  const { mutateAsync: updateArtiTourStatus } = useUpdateCalloutStatus({ userId: user.id, calloutName })

  const { data: teams } = useArtiTeams()
  const allNonJumboTeams = (teams ?? []).filter(({ jumbo }) => !jumbo)

  const [messages, setMessages] = useState([])
  const [memberId, setMemberId] = useState(state?.memberId)
  const [loading, setLoading] = useState(false)
  const defaultLLM = isProductionEnv() ? LLMType.GPT_4o : LLMType.GPT_3_5
  const [llm, setLLM] = useState(defaultLLM)
  const [sessionStartedAt, setSessionStartedAt] = useState(null)
  const [startExchangeId, setStartExchangeId] = useState(null)
  const [prevExchangeId, setPrevExchangeId] = useState(null)
  const [latestExchangeId, setLatestExchangeId] = useState(null)
  const [excludeFromManualReview, setExcludeFromManualReview] = useState(false)
  const [hasLoadedHistory, setHasLoadedHistory] = useState(false)
  const { testId } = useQueryParams()
  const availableLLMs = availableLLMsForUser(user)
  const isDefaultLLM = llm === defaultLLM

  const { id: historyExchangeIdUrlParamValue } = useParams()
  const historyExchangeId = Number.isNaN(parseInt(historyExchangeIdUrlParamValue))
    ? null
    : parseInt(historyExchangeIdUrlParamValue)

  const { data: historySessionExchanges, isFetching: isFetchingHistorySession } = useArtiHistorySessionExchanges(
    historyExchangeId,
    { enabled: !hasLoadedHistory }
  )
  const { data: historyExchanges, isFetching: isFetchingHistoryExchanges } = useArtiHistoryTopExchanges({
    enabled: !!showChatHistory,
  })

  const memberIdTeamNameMap = new Map()
  const currentTeamLast = orderBy(allNonJumboTeams, ({ id }) => id === team.id)
  const teamMembers = uniqueById([
    user, // Place current user entry first in list of team members.
    NO_ONE_MEMBER, // Place "No one" option second in list of team members.
    ...currentTeamLast.flatMap(({ members, name: teamName }) =>
      sortUsersByShortName({ users: members }).map((member) => {
        // Associate team names with members for display in member select menu:
        if (!memberIdTeamNameMap.has(member.id)) {
          memberIdTeamNameMap.set(member.id, teamName)
        }
        return member
      })
    ),
  ])

  const selectedMember = teamMembers.find(({ id }) => id === memberId)
  const hasMessagesBeyondMemberSelected = messages.length > 1

  // Update member selected message if that's the only message:
  useEffectAfterChange(() => {
    if (!hasMessagesBeyondMemberSelected) {
      setMessages([getMemberSelectedMessage({ user, teamMember: selectedMember, llm, team })])
    }
  }, [user, team, llm, selectedMember, hasMessagesBeyondMemberSelected])

  // Update selectedMember if LLM is a vanilla type and there is no current conversation:
  useEffectAfterChange(() => {
    if (!hasMessagesBeyondMemberSelected && VANILLA_LLM_TYPES.includes(llm)) {
      setMemberId(NO_ONE_MEMBER.id)
    }
  }, [llm, hasMessagesBeyondMemberSelected])

  // Manage loading and rendering of history exchange (if selected):
  useEffectAfterChange(() => {
    const session = getHistorySession(historySessionExchanges)

    if (historyExchangeId && session.teamId && session.teamId !== team.id) {
      navigate(`/arti/${historyExchangeId}?team_id=${session.teamId}${window.location.hash}`, {
        replace: true,
      })
      return
    }

    if (hasLoadedHistory || !team || !user || !teams) {
      return
    }

    if (!historyExchangeId) {
      setHasLoadedHistory(true)
      return
    }

    if (session.messages.length && latestExchangeId !== historyExchangeId) {
      const sessionMemberId = session.memberId ?? NO_ONE_MEMBER.id
      const teamMember =
        teams?.flatMap(({ members }) => members)?.find(({ id }) => id === sessionMemberId) ?? NO_ONE_MEMBER

      // TODO(arti) Instead of building a new member selected message for this, we should store member selected messages with exchange records on backend
      setMessages([getMemberSelectedMessage({ user, teamMember, llm: session.llm, team }), ...session.messages])

      setMemberId(sessionMemberId)

      setStartExchangeId(session.firstExchangeId)
      setLatestExchangeId(session.lastExchangeId)
      setPrevExchangeId(session.lastExchangeId)
      setExcludeFromManualReview(session.excludeFromManualReview)

      if (session.llm && Object.values(availableLLMs).includes(session.llm)) {
        setLLM(session.llm)
        updatePersistedValue(llmPersistenceKey, session.llm, { defaultValue: defaultLLM })
      }
      preprocessArtiMemberData(team.id, sessionMemberId)
      setHasLoadedHistory(true)
    }
  }, [user, team, teams, latestExchangeId, historyExchangeId, historySessionExchanges, navigate, availableLLMs, defaultLLM, hasLoadedHistory])

  // Manage auto-scrolling to a selected message loaded from history:
  const scrollToLoadedMessageRef = useScrollElementIntoViewRef()

  const llmPersistenceKey = "RISINGTEAM_ARTI_LLM"

  // On initial render, load values from session storage.
  // If a value is invalid or matches default, clear it from session storage.
  function loadPersistedValue(key, { defaultValue = null, isValid, setValue }) {
    const value = window.sessionStorage.getItem(key)
    const isInvalid = isValid(value)
    const isDefault = value === defaultValue
    if (isInvalid || isDefault) {
      window.sessionStorage.removeItem(key)
    } else {
      setValue(value)
    }
  }
  function updatePersistedValue(key, value, { defaultValue } = {}) {
    if (value === defaultValue) {
      window.sessionStorage.removeItem(key)
    } else {
      window.sessionStorage.setItem(key, value)
    }
  }
  useEffectAfterFirstRender(() =>
    loadPersistedValue(llmPersistenceKey, {
      defaultValue: defaultLLM,
      isValid: (value) => !Object.values(availableLLMs).includes(value),
      setValue: (value) => setLLM(value),
    })
  )

  function onMemberChange({ value }) {
    const teamMemberId = parseInt(value)
    const teamMember = teamMembers.find(({ id }) => id === teamMemberId)
    setMessages([getMemberSelectedMessage({ user, teamMember, llm, team })])
    setMemberId(teamMemberId)
    setSessionStartedAt(null)
    setStartExchangeId(null)
    setPrevExchangeId(null)
    setLatestExchangeId(null)
    setExcludeFromManualReview(false)

    const isMemberOfCurrentTeam = !!team.members.find(({ id }) => id === teamMemberId)
    const memberTeam = isMemberOfCurrentTeam
      ? team
      : allNonJumboTeams.find(({ members }) => members.find(({ id }) => id === teamMemberId)) ?? team

    preprocessArtiMemberData(memberTeam.id, teamMemberId)
    // Update url to remove current ArtiExchange ID and set correct member team ID:
    navigate(`/arti?team_id=${memberTeam.id}${window.location.hash}`, {
      replace: true,
      state: { memberId: teamMemberId },
    })
    chatInputRef.current?.focus()
    chatInputRef.current?.scrollIntoView()

    if (artiTourIntroJsRef.current) {
      artiTourIntroJsRef.current.nextStep()
    }
  }

  function onLLMChange({ value }) {
    // Update current LLM value, and save to session storage if not the default.
    setLLM(value)
    updatePersistedValue(llmPersistenceKey, value, { defaultValue: defaultLLM })
  }

  const [isComponentMounted, setIsComponentMounted] = useState(true)
  useEffectAfterFirstRender(() => () => setIsComponentMounted(false))

  function startNewConversation() {
    setMessages([])
    setMemberId(null)
    setSessionStartedAt(null)
    setStartExchangeId(null)
    setPrevExchangeId(null)
    setLatestExchangeId(null)
    setExcludeFromManualReview(false)
    navigate(`/arti?team_id=${team.id}${window.location.hash}`)
  }

  function onHistoryExchangeSelect({ value, formik }) {
    requestAnimationFrame(() => isComponentMounted && formik.resetForm())
    // We reset form here because we don't want the select to actually
    // show the value; we just load the conversation and leave the
    // select text as-is. The reset won't work without waiting a frame.

    // Avoid router default "scroll to top" behavior for following navigation:
    setNoScrollToTopForPaths([latestExchangeId ? `/arti/${latestExchangeId}` : "/arti", `/arti/${value}`])
    setHasLoadedHistory(false)

    if (!historyExchangeId && latestExchangeId) {
      // If previous exchange wasn't represented by ID in URL, add a browser history
      // entry for it (w/ replace=true) so browser-back may nav back to it if desired.
      navigate(`/arti/${latestExchangeId}?team_id=${team.id}${window.location.hash}`, { replace: true })
    }
    // Navigate to URL for selected history exchange:
    navigate(`/arti/${value}?team_id=${team.id}${window.location.hash}`)
  }

  function isValidMessageText(values) {
    return !!values?.message.trim().length
  }

  function onPopularTopicSelect({ value, formik }) {
    if (
      !isValidMessageText(formik.values) ||
      window.confirm("Are you sure? This will clear your current prompt text.")
    ) {
      requestAnimationFrame(() => {
        if (isComponentMounted) {
          formik.setValues({ message: value, popularTopic: null })
          chatInputRef.current?.focus()
        }
      })
    }
  }

  function onExcludeFromManualReviewChange(event) {
    const newValue = event.target.checked
    setExcludeFromManualReview(newValue)
    updateExcludeFromManualReview(startExchangeId, newValue)
  }

  const onSubmit = handleErrors(async (values, { resetForm }) => {
    if (!selectedMember || loading || !isValidMessageText(values)) {
      // if a request is already in flight, or message is blank, ignore this submit
      return false
    } else {
      setLoading(true)
    }

    const newUserMessage = { sender: ArtiSender.USER, text: values.message }
    const newMessages = [...messages, newUserMessage]

    const updateNewBotMessage = ({ text, exchangeId }) => {
      const newBotMessage = { sender: ArtiSender.BOT, text, exchangeId }
      setMessages([...newMessages, newBotMessage])
    }

    updateNewBotMessage({ text: "" }) // show empty bot response with loading icon
    resetForm()

    const artiData = await sendAndProcessArtiChat({
      team,
      selectedMember,
      newUserMessage,
      updateNewBotMessage,
      useStreaming: !doNotUseStreaming,
      artiChatParams: {
        llm,
        sessionStartedAt,
        startExchangeId,
        prevExchangeId,
        testId,
      },
    })

    const artiExchange = artiData.arti_exchange

    setLatestExchangeId(artiExchange?.id ?? null)

    if (artiExchange) {
      setSessionStartedAt(artiExchange.session_started_at ?? null)
      setStartExchangeId(artiExchange.start_exchange_id ?? null)
      setPrevExchangeId(artiExchange.id ?? null)
      prependHistorySessionExchangeToQueryCache(
        queryClient,
        { ...artiExchange, query: values.message }
        // must add query to exchange here since we only get basic exchange from API
      )

      // Avoid router default "scroll to top" behavior for following navigation:
      setNoScrollToTopForPaths([
        latestExchangeId ? `/arti/${latestExchangeId}` : "/arti",
        `/arti/${artiExchange.id}`,
        `/arti/${historyExchangeId}`,
      ])

      // Update URL to match new exchange ID, so tab can be refreshed, duplicated, etc:
      navigate(`/arti/${artiExchange.id}?team_id=${team.id}${window.location.hash}`, { replace: true })
    }

    setLoading(false)
  })

  function getOnRatingFunction({ exchangeId }) {
    return async function onRating(name, value) {
      const fieldNameWithoutSuffix = removeLineRatingFieldNameSuffix(name)
      await updateArtiExchangeRating(exchangeId, {
        [fieldNameWithoutSuffix]: value === ARTI_RATING_NA_VALUE ? -1 : value,
      })
    }
  }

  return (
    <View className={className}>
      <ArtiTour
        introJsRef={artiTourIntroJsRef}
        autoStartTour={!isFetchingHasSeenArtiTour && !hasSeenArtiTour}
        onTourStart={() => updateArtiTourStatus({ hasViewedCallout: true })}
        onStepChange={(newStepIndex) => {
          if (newStepIndex === 0) {
            startNewConversation()
          }
          setArtiTourForcePopularTopicsMenuOpen(ARTI_TOUR_OPEN_POPULAR_TOPICS_STEP_INDICES.includes(newStepIndex))
        }}
        onTourExit={() => setArtiTourForcePopularTopicsMenuOpen(false)}
      />
      <div className="main-container full-width neg-mt-large">
        <PageTitle>aRTi</PageTitle>
        <Sticky enabled={true} top={0} innerZ={ZIndex.Sticky} className="arti-controls-sticky-wrapper">
          <View $flexDirection="column" $alignItems="flex-end" className="arti-controls pt-medium">
            <View>
              <h1 className="text-semi-bold mb-small-mobile-never mb-small-mobile-only flex-grow-1">aRTi</h1>
              <View $flexDirection="row-reverse" $flexDirectionMobile="column" $alignItemsMobile="flex-end">
                {!!showArtiHelp && (
                  <Button
                    className="ml-medium mt-xs mr-none arti-help-button"
                    onClick={() => {
                      navigate(`/arti?team_id=${team.id}#${DEFAULT_TOUR_HASH}`)
                      // NOTE: Don't include aRTi exchange ID here because the tour
                      // needs chat UI to be reset so it can start at member selection.
                      // We include team_id because it prevents a redirect / UI refresh.
                    }}
                  >
                    <Tooltip top title="Help">
                      <QuestionIcon className="ml-small" />
                    </Tooltip>
                  </Button>
                )}
                <Button
                  className={cn("mt-xs mr-none tertiary", { hidden: memberId == null })}
                  onClick={startNewConversation}
                  data-testid="arti-new-conversation"
                  data-arti-tour-step={ArtiTourStepNames.NewConversation}
                >
                  <PlusIcon className="ml-small" />
                  New conversation
                </Button>
                {!!showLLMSelect && (
                  <Formik initialValues={{ llm }} enableReinitialize>
                    <Form
                      className="fit-content mr-large-mobile-never mt-xxs-mobile-never my-medium-mobile-only"
                      data-testid="arti-llm-select"
                    >
                      <AdvancedSelectField
                        name="llm"
                        onChange={onLLMChange}
                        options={Object.keys(availableLLMs).map((key) => ({
                          value: LLMType[key],
                          label: LLMName[key],
                        }))}
                      />
                    </Form>
                  </Formik>
                )}
              </View>
            </View>
          </View>
        </Sticky>
        {!!showChatHistory && (
          <Formik initialValues={{ exchangeId: null }}>
            {(formik) => (
              <Form className="mt-xs mb-xl-mobile-never mb-xs-mobile-only">
                <div data-arti-tour-step={ArtiTourStepNames.PreviousConversations}>
                  <PreviousConversationsSelect
                    name="exchangeId"
                    width="100%"
                    alignRight
                    showTeamMember
                    user={user}
                    teamMembers={teamMembers}
                    formik={formik}
                    historyExchanges={historyExchanges}
                    isLoading={isFetchingHistoryExchanges}
                    placeholder={
                      isFetchingHistoryExchanges
                        ? "Loading previous conversations..."
                        : isMobileLandscapeOrSmaller
                        ? "Previous conversations"
                        : "Type to search previous conversations"
                    }
                    onSelect={onHistoryExchangeSelect}
                  />
                </div>
              </Form>
            )}
          </Formik>
        )}
        <ArtiIntro user={user} />
        <UserMemberSelectorMessage
          user={user}
          team={team}
          selectedMember={selectedMember}
          teamMembers={teamMembers}
          memberId={memberId}
          onMemberChange={onMemberChange}
          memberIdTeamNameMap={memberIdTeamNameMap}
          onStartNewConversation={startNewConversation}
        />
        <div className={cn("fadeable conversation-container", { hidden: !selectedMember || isFetchingHistorySession })}>
          <div className="mb-medium">
            {messages.map((message, index) => (
              <div key={index}>
                {message.sender === ArtiSender.BOT ? (
                  <div className="mb-xxs text-semi-bold">
                    <img
                      src="https://static.risingteam.com/rtai/arti-chat-icon.png"
                      width="16px"
                      alt="arti-chat-icon"
                    />
                    <span className="ml-xxs">aRTi</span>
                  </div>
                ) : (
                  <div className="text-right mb-xxs text-semi-bold">you</div>
                )}
                <div className={cn("mb-medium px-medium py-small message", message.sender)}>
                  {!!index && message.sender === ArtiSender.BOT && (
                    <View className="icon-buttons">
                      <FlagButton exchangeId={message.exchangeId} initialValue={message.inappropriateFlag} />
                      <ArtiCopyButton clipboardText={message.text} className="text-gray-7-important" />
                    </View>
                  )}
                  {message.sender === ArtiSender.BOT ? (
                    <BotMessage
                      message={message}
                      showLoading={!!loading && index === messages.length - 1}
                      showKitRecommendations={showKitRecommendations}
                      showRatingFields={
                        !!showRatingFeedback &&
                        index > 0 &&
                        VANILLA_LLM_TYPES.includes(llm) &&
                        message?.text?.length >= MIN_RATEABLE_MESSAGE_LENGTH
                      }
                      showTextInputFeedback={!!showTextInputFeedback}
                      onRating={getOnRatingFunction({ exchangeId: message.exchangeId })}
                      onKeyPressFocusElement={chatInputRef.current}
                      user={user}
                      team={team}
                      messageIndex={index}
                    />
                  ) : (
                    <div
                      className={cn("text-prewrap", {
                        "arti-message-highlighted": message.exchangeId === historyExchangeId,
                      })}
                      ref={message.exchangeId === historyExchangeId ? scrollToLoadedMessageRef : null}
                    >
                      {message.text?.trim()}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
          <Formik initialValues={{ message: "", popularTopic: null }} onSubmit={onSubmit}>
            {(formik) => (
              <Form className={cn("space-y-medium fadeable", { "non-interactive-and-faded": loading })}>
                <View $alignItems="center" $flexDirectionMobile="column">
                  <div
                    className="query-editor full-width mb-medium-mobile-only"
                    data-arti-tour-step={ArtiTourStepNames.QueryEditor}
                  >
                    <View $alignItems="stretch">
                      <TextareaField
                        inputRef={chatInputRef}
                        name="message"
                        className="query-editor-textarea p-medium pr-none cursor-text"
                        data-testid="arti-query-editor"
                        placeholder="What would you like to discuss?"
                        size="full-width"
                        onKeyPress={onKeyEvent(
                          "enter",
                          () => onSubmit(formik.values, formik),
                          { shift: true, preventDefault: true }
                          // preventDefault avoids extra newline in textarea
                        )}
                      />
                      <div
                        className="query-editor-submit-container m-medium ml-none cursor-text"
                        onClick={() => chatInputRef.current?.focus()}
                      >
                        <View $alignItems="center" className="full-height">
                          <Tooltip title="Enter a message" disabled={isValidMessageText(formik.values)}>
                            <SubmitButton
                              className="full-width-mobile-only link submit-button"
                              disabled={!isValidMessageText(formik.values)}
                              labelSubmitting={false}
                              data-testid="arti-query-submit"
                            >
                              <PaperPlaneTopIcon className="fa-2x" />
                            </SubmitButton>
                          </Tooltip>
                        </View>
                      </div>
                    </View>
                    <div
                      className={cn(
                        "query-help-text text-gray-6 text-italic non-interactive pr-large pb-medium hide-on-mobile",
                        { hidden: !isValidMessageText(formik.values) }
                      )}
                      style={messages.length > 1 ? { bottom: 0 } : {}}
                    >
                      shift + enter to send
                    </div>
                    {messages.length === 1 && (
                      <PopularTopicsSelect
                        name="popularTopic"
                        user={user}
                        team={team}
                        selectedMember={selectedMember}
                        width="100%"
                        alignCenter
                        formik={formik}
                        placeholder={isMobileLandscapeOrSmaller ? "Popular topics" : "Popular topics · type to search"}
                        onSelect={onPopularTopicSelect}
                        borderRadius="0 0 var(--border-radius) var(--border-radius)"
                        borderRadiusOpen="0"
                        {...(artiTourPopularTopicsMenuOpen
                          ? {
                              menuIsOpen: true,
                              disabled: true,
                              fadeWhenDisabled: false,
                              menuHeight: isMobileLandscapeOrSmaller ? 140 : 180,
                            }
                          : {})}
                        key={
                          artiTourPopularTopicsMenuOpen
                          // fixes an issue where popular-topics menu doesn't re-render
                          // when exiting arti tour, leaving it open unintentionally
                        }
                      />
                    )}
                  </div>
                </View>
                <FormMessage />
              </Form>
            )}
          </Formik>
        </div>
        {!isDefaultLLM && (
          <div className="text-center text-gray-7 text-small pt-xxxl">Powered by {LLMName[invert(LLMType)[llm]]}</div>
        )}
        {!!showExcludeFromManualReview && !!startExchangeId && (
          <div className="mt-small">
            <Choice
              type="checkbox"
              label="Exclude conversation from manual review (internal use only)"
              onChange={onExcludeFromManualReviewChange}
              checked={excludeFromManualReview}
            />
          </div>
        )}
      </div>
    </View>
  )
})`
  .conversation-container.hidden {
    height: 0;
  }

  .arti-controls-sticky-wrapper {
    .arti-controls {
      position: relative;
      background-color: var(--fg);
      width: calc(100% + 24px * 2);
      margin-left: -24px;
      margin-right: -24px;
      padding-left: 24px;
      padding-right: 24px;

      @media (min-width: ${({ theme }) => theme.mobileLandscapeMin}) {
        width: calc(100% + 48px * 2);
        margin-left: -48px;
        margin-right: -48px;
        padding-left: 48px;
        padding-right: 48px;
      }
    }

    &.active .arti-controls {
      box-shadow: 0 4px 4px -4px rgb(35 35 35 / 20%);
    }
  }

  .arti-help-button {
    color: var(--gray-9);
    background: var(--gray-3);
    border: none;
    box-shadow: none;
    border-radius: 50%;
    padding: 2px;
    width: 36px;
    height: 36px;
  }

  @media (max-width: ${({ theme }) => theme.mobileLandscapeMax}) {
    .arti-help-button {
      padding: 6px;
      width: 19px;
      height: 19px;
      font-size: 12px;
      position: absolute;
      top: -2px;
      right: 4px;

      svg {
        margin: 0;
      }
    }
  }

  .message {
    position: relative;
    color: var(--white);
    font-weight: 600;
    border-radius: 8px;

    /* nubbin */
    &::before {
      content: "";
      position: absolute;
      bottom: 100%;
      height: 0.5rem;
      width: 1rem;
      border-radius: 3px 3px 0 0;
      z-index: var(--z-above-zero);
    }
  }

  .message.user {
    background-color: var(--orange-4);
    text-shadow: var(--blur-4);
    font-weight: 500;

    /* nubbin */
    &::before {
      right: 1rem;
      background-color: var(--orange-4);
      transform: skew(0, -30deg);
      transform-origin: 100% 0;
      clip-path: polygon(0 0, 100% 0, 100% 100%);
    }
  }

  .message.bot {
    color: var(--default);
    font-weight: 400;

    background-color: var(--gray-1);
    border: 1px solid var(--gray-4);
    box-shadow: var(--blur-4);

    /* nubbin */
    &::before {
      left: 1rem;
      background-color: var(--gray-1);
      border: 1px solid var(--gray-4);
      border-bottom: none;
      padding-bottom: 1px;
      box-shadow: var(--blur-4);
      transform: skew(0, 30deg);
      transform-origin: 0 0;
      clip-path: polygon(-10px -10px, 35px -10px, -10px 16px);
    }

    .icon-buttons {
      display: flex;
      position: relative;
      flex-direction: column;
      justify-content: flex-end;
      float: right;
      color: var(--gray-7);
      width: fit-content;
      z-index: var(--z-below-sticky);
      // z-index needed to allow flag and copy buttons to be interactive
    }
  }

  li {
    margin-left: var(--spacing-4);
  }

  table {
    width: 100%;
    border-spacing: 0; // no white lines between table cells
    margin-bottom: 12px;
  }

  tr {
    height: var(--row-height);
  }

  th {
    padding: 8px 12px;

    &.shrink {
      width: 1px; // make column "shrink" to minimum width to contain header text
      padding-right: 15px; // adjust to ensure header text remains centered
    }
  }

  td {
    padding: 4px 12px;
  }

  th,
  td {
    text-align: left;
    position: relative;
  }

  .query-editor {
    position: relative;
    background: var(--orange-4);
    border-radius: 8px;
  }

  .query-editor-textarea {
    margin-right: calc(-1 * var(--spacing-6));
  }

  .query-editor-textarea > * {
    padding-bottom: var(--spacing-3);
    padding-right: calc(var(--spacing-6) + var(--spacing-2));
    // above puts --spacing-2 space between text and submit button
  }

  .query-editor-submit-container {
    z-index: 1; // without this, textarea will cover submit button, hiding it
  }

  .query-help-text {
    position: absolute;
    bottom: var(--spacing-7);
    right: var(--spacing-1);
  }

  .submit-button {
    color: var(--gray-7);
    &:hover {
      color: var(--rising-orange);
    }
    opacity: 1;
    &:disabled {
      opacity: 0.2;
    }
  }

  .query-help-text,
  .submit-button {
    transition: opacity 0.2s ease, color 0.1s ease;
  }
`

const MemberSelectField = styled(function MemberSelectField({
  className,
  user,
  team,
  selectedMember,
  teamMembers,
  memberId,
  onMemberChange,
  memberIdTeamNameMap,
  onStartNewConversation,
}) {
  const currentTeamMembersMinusUser = team?.members.filter(({ id }) => id !== user.id)
  const sortedCurrentTeamMembersMinusUser = sortUsersByShortName({ users: currentTeamMembersMinusUser })
  const selfAndNone = [user, NO_ONE_MEMBER]
  const availableTeamMembersOptions = team?.jumbo
    ? selfAndNone
    : uniqueById([...sortedCurrentTeamMembersMinusUser, ...selfAndNone])

  const currentTeamMembersOptions = selectedMember
    ? availableTeamMembersOptions.filter(({ id }) => id === memberId)
    : availableTeamMembersOptions

  return (
    <Formik initialValues={{ memberId }} enableReinitialize>
      <Form
        className={cn(className, "arti-member-select pl-small")}
        data-testid="arti-member-select"
        data-arti-tour-hint={ArtiTourHintNames.MemberSelector}
      >
        <View $flexDirection="row" $flexWrap="wrap" $gap="var(--spacing-2)">
          <ChoicesField
            noContainer
            name="memberId"
            type="radio"
            saveOnChange={(_name, value) => onMemberChange({ value })}
            options={currentTeamMembersOptions.map(({ id, short_name }) => ({
              value: id,
              label: id === user.id ? "Myself" : short_name,
            }))}
            component={ChoiceInput}
            disabled={!!selectedMember}
            childrenAfterChoices={
              <>
                <AdvancedSelectField
                  className="arti-member-search"
                  name="memberId"
                  width="100%"
                  hideCaret
                  disabled={!!selectedMember}
                  placeholder={<span className="text-gray-9">Search my teams</span>}
                  icon={<MagnifyingGlassIcon className="mr-xxs text-gray-9" />}
                  onChange={onMemberChange}
                  options={teamMembers.map(({ id, short_name }) => ({
                    value: id,
                    label: id === user.id ? "Myself" : short_name,
                  }))}
                  formatOptionLabel={({ value, label }) => (
                    <View $justifyContent="space-between">
                      <span className="text-semi-bold" title={label}>
                        {label}
                      </span>
                      <span className="text-gray-9 ml-large hide-on-mobile text-thin">
                        {memberIdTeamNameMap?.get(value) ?? ""}
                      </span>
                    </View>
                  )}
                />
                {!!selectedMember && (
                  <Button
                    className={cn("arti-member-select-new-conversation")}
                    onClick={onStartNewConversation}
                    data-testid="arti-member-select-new-conversation"
                  >
                    <PlusIcon className="ml-small" />
                    New conversation
                  </Button>
                )}
              </>
            }
          />
        </View>
      </Form>
    </Formik>
  )
})`
  .arti-member-search {
    display: inline-block;
    min-width: 184px;
    vertical-align: top;
    font-weight: 500;
    color: var(--gray-9); /* select options inherit their text color from this */
    &.advanced-select--is-disabled {
      display: none;
    }
    .advanced-select__control {
      padding-bottom: 45px; /* align select bottom with adjacent choice options */
      border: 1px solid var(--white);
      border-radius: 4px;
      background-color: #fdc774;
      box-shadow: 0px 0px 4px 0px rgba(255, 211, 89, 0.44);
      .advanced-select__input-container,
      .advanced-select__single-value {
        color: var(--gray-9);
      }
      &:hover:not(.disabled) {
        border: 1px solid var(--white);
        background-color: #fbe8ca;
      }
    }
  }
  .advanced-select__control--menu-is-open {
    font-size: 16px;
    font-weight: 600;
    background-color: var(--white) !important; // important is necessary here to override hover bg color
  }
  .arti-member-select-new-conversation {
    height: 100%;
    min-height: 46px;
    border: 1px solid var(--white);
    border-radius: 4px;
    background-color: #fdc774;
    box-shadow: 0px 0px 4px 0px rgba(255, 211, 89, 0.44);
    transition-property: background-color, border-color, box-shadow, color;
    transition-duration: 0.1s;
    transition-timing-function: ease-in-out;
    font-size: 0.9375rem; // ~15px
    line-height: 1.375rem;
    color: var(--gray-9);
    font-weight: 500;

    &:hover,
    &:focus-visible {
      border-radius: 4px;
      background-color: #fbe8ca;
      box-shadow: 0px 2px 4px 0px rgba(255, 211, 89, 0.75);
    }
  }

  @media (max-width: ${({ theme }) => theme.mobileMax}) {
    .arti-member-search {
      /* place search input on its own line on mobile so menu doesn't go offscreen: */
      display: block;
    }
  }
`

const ArtiCopyButton = ({ className, clipboardText }) => (
  <CopyButton
    icon
    iconCopied
    tooltip="Copy message text"
    tooltipCopied="Copied!"
    className={className}
    clipboardText={clipboardText}
    title=""
    aria-label="Copy message text"
    hoverColor="gray-8"
    onClickColor="gray-8"
  />
)

const FlagButton = styled(function FlagButton({ exchangeId, initialValue, className }) {
  const [flagged, setFlagged] = useState(initialValue)
  async function onFlagClick() {
    const newFlaggedValue = !flagged
    setFlagged(newFlaggedValue)
    await updateArtiExchangeInappropriateFlag(exchangeId, newFlaggedValue)
  }
  return (
    <Tooltip title={!flagged ? "Flag this message as inappropriate or offensive" : "Unflag this message"}>
      <Button
        className={cn(className, {
          flagged,
          // Need to flag these as important, since styled-components
          // is giving the default color a higher specificity in stage and prod.
          "text-error-red-important": !!flagged,
          "text-gray-7-important": !flagged,
        })}
        onClick={onFlagClick}
      >
        <FlagIcon />
      </Button>
    </Tooltip>
  )
})`
  background: none;
  box-shadow: none;
  border: none;
  padding: 6px;
  height: fit-content;
  width: fit-content;
  transition: color 150ms ease;
  &:hover {
    box-shadow: none;
  }
  &:hover:not(.flagged) {
    color: var(--gray-8) !important;
  }
  &:active {
    border: none;
    background: none;
  }
`

const BotMessage = styled(function BotMessage({
  className,
  message,
  showLoading,
  showRatingFields,
  showKitRecommendations,
  showTextInputFeedback,
  onRating,
  onKeyPressFocusElement,
  user,
  team,
  messageIndex,
}) {
  const { isMobileLandscapeOrSmaller } = useWindowSize()
  const userIsTeamLead = user.id === team.team_lead_id
  return (
    <div className={className}>
      <Markdown remarkPlugins={[remarkGfm]}>{message.text?.trim()}</Markdown>
      {!!showLoading && <Loading size="25px" className={cn("mb-small", { "arti-message-loading": !message.text })} />}
      {!showLoading && !!showRatingFields && (
        <Formik
          initialValues={{
            helpfulness_rating: { value: message.helpfulnessRating?.toString() ?? null },
            accuracy_rating: { value: message.accuracyRating?.toString() ?? null },
            feedback: message.feedback ?? "",
          }}
          enableReinitialize
        >
          <Form className="mt-medium">
            <HorizontalRule margin="my-medium" />
            <label className="text-semi-bold text-small">How helpful was this answer?</label>
            <LineRatingField
              className="mt-xxs mb-xs"
              name="helpfulness_rating"
              barMode
              iconRating={true}
              saveOnChange={onRating}
              numOptions={6}
              optionTransformer={getRatingFieldOptionTransformer({
                isMobile: isMobileLandscapeOrSmaller,
              })}
            />
            {!!showTextInputFeedback && (
              <TextField
                className="mt-xxs mb-xs full-width"
                name="feedback"
                placeholder="Optional: What was unhelpful or helpful about it?"
                saveOnChange={onRating}
                onKeyPress={onKeyEvent("enter", (ev) => {
                  ev.preventDefault()
                  onKeyPressFocusElement?.focus()
                })}
              />
            )}
          </Form>
        </Formik>
      )}
      {!showLoading && !!showKitRecommendations && !!userIsTeamLead && !!messageIndex && (
        <KitRecommendation user={user} team={team} />
      )}
    </div>
  )
})`
  position: relative;
  min-height: 3.5rem;

  ul {
    line-height: 1.6rem;
    padding-top: var(--spacing-0);
    padding-bottom: var(--spacing-1);
  }
  text-normal {
    line-height: 1.6rem;
  }

  .arti-message-loading {
    position: absolute;
    /* vertically-center loading spinner: */
    top: calc(50% - 25px); /* 25px: size of loading spinner */
    left: 0;
  }
`

function getRatingFieldOptionTransformer({ isMobile }) {
  return (value) => {
    const labels = [
      {
        label: "Very unhelpful",
        Component: FaceAngryIcon,
      },
      {
        label: "Somewhat unhelpful",
        Component: FaceFrownIcon,
      },
      {
        label: "Neutral",
        Component: FaceMehIcon,
      },
      {
        label: "Somewhat helpful",
        Component: FaceSmileIcon,
      },
      {
        label: "Very helpful",
        Component: FaceGrinHeartsIcon,
      },
    ].map(({ label, Component }, idx) => ({
      label: <Component key={label} className="icon" />,
      tooltip: label,
      value: idx + 1,
    }))

    return (
      [
        // We reverse ordering of rating fields on mobile. This required an inversion
        // of values, which is why we use 'value: labels.length - idx' below:
        ...(isMobile ? labels.reverse() : labels),
        // We designate 1st space on arti rating fields to the "N/A" value, which
        // is ARTI_RATING_NA_VALUE = -1 on backend. Perform that value mapping here:
        { value: ARTI_RATING_NA_VALUE, label: <div className="text-normal">N/A</div> },
      ][value - 1] ?? { value }
    )
  }
}

const PreviousConversationsSelect = styled(function PreviousConversationsSelect({
  className,
  name,
  placeholder,
  onSelect,
  formik,
  historyExchanges,
  showTeamMember = false,
  teamMembers = null,
  user = null,
  width = null,
  alignCenter = false,
  alignRight = false,
  isLoading = false,
  borderRadius = null,
  borderRadiusOpen = null,
}) {
  const teamMemberNameMap = new Map([
    ...(teamMembers?.map((member) => [member.id, member.short_name]) ?? []),
    ...(user ? [[user.id, "you"]] : []),
  ])
  return (
    <AdvancedSelectField
      className={className}
      overflowHidden
      name={name}
      width={width}
      alignCenter={alignCenter}
      alignRight={alignRight}
      isDisabled={isLoading}
      placeholder={<span className="text-italic">{placeholder}</span>}
      borderRadius={borderRadius}
      borderRadiusOpen={borderRadiusOpen}
      saveOnChange={(_name, value) => onSelect({ value, formik })}
      options={(historyExchanges ?? []).map(({ id, query, queried_at, member }) => ({
        value: id,
        label: query,
        date: queried_at,
        memberName: !showTeamMember ? null : teamMemberNameMap.get(member) ?? "no one",
      }))}
      formatOptionLabel={({ label, date, memberName }) => (
        <>
          <span className="text-gray-6 mr-xs" title={formatTimestampAsDate(date, "PPPP 'at' p")}>
            {formatTimestampAsDate(date, "M/d/yy", {
              omitYearIfCurrent: true,
            })}
          </span>
          <span title={label.trim()}>{label.trim()}</span>
          {!!memberName && <span className="text-gray-6 history-member-name">about {memberName}</span>}
        </>
      )}
    />
  )
})`
  .history-member-name {
    float: right;
  }
`

const PopularTopicsSelect = function PopularTopicsSelect({
  className,
  name,
  user,
  team,
  selectedMember,
  placeholder,
  onSelect,
  formik,
  menuIsOpen,
  width = null,
  menuWidth = null,
  menuHeight = null,
  alignCenter = false,
  alignRight = false,
  borderRadius = null,
  borderRadiusOpen = null,
  disabled = false,
  fadeWhenDisabled = false,
}) {
  const options = getPopularTopics({ team, user, member: selectedMember }).map(formatPopularTopicAsSelectFieldOption)
  return (
    <div className={className} data-arti-tour-step={ArtiTourStepNames.PopularTopics}>
      <AdvancedSelectField
        overflowHidden
        name={name}
        width={width}
        menuWidth={menuWidth}
        menuHeight={menuHeight}
        alignCenter={alignCenter}
        alignRight={alignRight}
        placeholder={<span className="text-italic">{placeholder}</span>}
        borderRadius={borderRadius}
        borderRadiusOpen={borderRadiusOpen}
        saveOnChange={(_name, value) => onSelect({ value, formik })}
        options={options}
        menuIsOpen={menuIsOpen}
        disabled={disabled}
        fadeWhenDisabled={fadeWhenDisabled}
      />
    </div>
  )
}

const ArtiIntro = ({ user }) => {
  const dayOfWeek = formatDate(new Date(), "EEEE")
  const userLabel = user.first_name?.trim() ? ` ${user.first_name.trim()}` : ""
  return (
    <View className="intro" $flexDirection="column">
      <div className="mb-xxs text-semi-bold">
        <img src="https://static.risingteam.com/rtai/arti-chat-icon.png" width="16px" alt="arti-chat-icon" />
        <span className="ml-xxs">aRTi</span>
      </div>
      <View $flexDirection="column" className="mb-medium px-medium py-small message bot">
        <div className="mb-medium">
          Happy {dayOfWeek}, {userLabel}! I'm aRTi, your digital leadership coach who can help you work better with your
          team by applying insights from Rising Team sessions.
        </div>
        <div>Which team member would you like to discuss?</div>
      </View>
    </View>
  )
}

const UserMemberSelectorMessage = styled(function UserMemberSelectorMessage({
  user,
  team,
  selectedMember,
  teamMembers,
  memberId,
  onMemberChange,
  memberIdTeamNameMap,
  onStartNewConversation,
}) {
  return (
    <View $flexDirection="column" className="flex-shrink-0">
      <div className="text-right mb-xxs text-semi-bold full-width">you</div>
      <div
        className="mb-medium pr-medium py-small message user full-width"
        data-arti-tour-step={ArtiTourStepNames.MemberSelector}
      >
        <MemberSelectField
          user={user}
          team={team}
          selectedMember={selectedMember}
          teamMembers={teamMembers}
          memberId={memberId}
          onMemberChange={onMemberChange}
          memberIdTeamNameMap={memberIdTeamNameMap}
          onStartNewConversation={onStartNewConversation}
        />
      </div>
    </View>
  )
})`
  width: 100%;
`

function getMemberSelectedMessage({ user, teamMember, llm, team }) {
  const sampleTopics = getPopularTopics({ team, user, member: teamMember, sampleOnly: true })
  const examplesText = `Sample topics I can help you with include:
${sampleTopics.map((topic) => `- ${topic}`).join("\n")}

Click "Popular topics" below for more examples.
`

  const memberLabel =
    teamMember?.id === user.id
      ? "yourself"
      : teamMember?.id === NO_ONE_MEMBER.id
      ? NO_ONE_MEMBER.full_name.toLowerCase()
      : teamMember?.first_name?.trim() || "your team member"
  const text = VANILLA_LLM_TYPES.includes(llm)
    ? "You're using the vanilla version of this LLM, which does not include information about aRTi or about team members."
    : teamMember
    ? `I see you're interested in chatting about ${memberLabel}.\n\n${examplesText}`
    : "Please select a team member to chat about."

  return { sender: ArtiSender.BOT, text }
}

function getHistorySession(sessionExchanges) {
  const [firstExchange] = sessionExchanges ?? []
  const [lastExchange] = (sessionExchanges ?? []).slice(-1)
  return {
    llm: lastExchange?.llm_type ?? null,
    teamId: lastExchange?.team ?? null,
    memberId: lastExchange?.member ?? null,
    firstExchangeId: firstExchange?.id ?? null,
    lastExchangeId: lastExchange?.id ?? null,
    excludeFromManualReview: firstExchange?.exclude_from_manual_review ?? false,
    messages: (sessionExchanges ?? []).flatMap((exchange) => [
      {
        sender: ArtiSender.USER,
        text: exchange.query,
        helpfulnessRating: exchange.helpfulness_rating,
        accuracyRating: exchange.accuracy_rating,
        feedback: exchange.feedback,
        inappropriateFlag: exchange.inappropriate_flag,
        exchangeId: exchange.id,
      },
      {
        sender: ArtiSender.BOT,
        text: exchange.response,
        helpfulnessRating: exchange.helpfulness_rating,
        accuracyRating: exchange.accuracy_rating,
        feedback: exchange.feedback,
        inappropriateFlag: exchange.inappropriate_flag,
        exchangeId: exchange.id,
      },
    ]),
  }
}

const sendAndProcessArtiChat = async ({
  team,
  selectedMember,
  newUserMessage,
  updateNewBotMessage,
  useStreaming,
  artiChatParams,
}) => {
  if (useStreaming) {
    const artiResponse = await artiTeamMemberChatStreaming(team.id, selectedMember.id, newUserMessage, artiChatParams)
    return await processArtiResponseStreaming({ artiResponse, updateNewBotMessage })
  }

  const artiResponse = await artiTeamMemberChatNonStreaming(team.id, selectedMember.id, newUserMessage, artiChatParams)
  return processArtiResponseNonStreaming({ artiResponse, updateNewBotMessage })
}

// Read in arti response stream and update the bot message text in real time
// The first line of the response is a JSON object with arti metatdata
// The rest of the response is the bot message text
// The rest of the response is the bot message text
const processArtiResponseStreaming = async ({ artiResponse, updateNewBotMessage }) => {
  const textBodyStream = artiResponse.body.pipeThrough(new TextDecoderStream())

  let artiMetadata = null
  let exchangeId = null
  let botMessageText = ""

  // Read in the response stream one chunk at a time and update the bot message text in real time
  for await (const chunk of textBodyStream) {
    const isFirstLineProcessed = !!artiMetadata

    if (!isFirstLineProcessed) {
      const newLineIndex = chunk.indexOf("\n")
      const foundEndOfFirstLine = newLineIndex >= 0

      if (foundEndOfFirstLine) {
        const firstLine = chunk.substring(0, newLineIndex)
        const remainingText = chunk.substring(newLineIndex + 1)
        artiMetadata = JSON.parse(firstLine)
        botMessageText = remainingText
        exchangeId = artiMetadata.arti_exchange?.id
        updateNewBotMessage({ text: botMessageText, exchangeId })
      }
    } else {
      botMessageText += chunk
      updateNewBotMessage({ text: botMessageText, exchangeId })
    }
  }

  return artiMetadata
}

const processArtiResponseNonStreaming = ({ artiResponse, updateNewBotMessage }) => {
  const newLineIndex = artiResponse.indexOf("\n")
  const firstLine = artiResponse.substring(0, newLineIndex)
  const remainingText = artiResponse.substring(newLineIndex + 1)
  const artiMetadata = JSON.parse(firstLine)
  const botMessageText = remainingText
  const exchangeId = artiMetadata.arti_exchange?.id

  updateNewBotMessage({ text: botMessageText, exchangeId })

  return artiMetadata
}

const ChoiceInput = styled(function ChoiceInput({
  name,
  value,
  label,
  type,
  className,
  onBlur,
  checked,
  disabled,
  onChange,
  initialValue: _, // omit from props to avoid "React does not recognize the `initialValue` prop on a DOM element" warning.
  onCustomChange: __, // omit from props to avoid "React does not recognize the `onCustomChange` prop on a DOM element" warning.
  ...props
}) {
  return (
    <View
      as="label"
      className={cn(className, "text-gray-9", {
        "cursor-default": disabled,
        disabled,
      })}
      data-testid="arti-member-choice"
    >
      <input
        type={type}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        checked={checked}
        disabled={disabled}
        {...props}
      />
      <View
        $alignItems="center"
        $justifyContent="center"
        className={cn("box fit-content p-xs", { disabled })}
        $flexDirection="row"
        role={type}
        aria-checked={checked}
        aria-disabled={disabled}
      >
        <div>
          <div className={cn("text-button", { disabled })}>
            <div className="text-nowrap">{label}</div>
          </div>
        </div>
      </View>
    </View>
  )
})`
  display: inline-block;
  text-align: center;
  user-select: none;
  width: fit-content;
  whitespace: nowrap;
  overflow: hidden;

  .box {
    height: 100%;
    min-height: 46px;
    border: 1px solid var(--white);
    border-radius: 4px;
    background-color: #fdc774;
    box-shadow: 0px 0px 4px 0px rgba(255, 211, 89, 0.44);
    transition-property: background-color, border-color, box-shadow, color;
    transition-duration: 0.1s;
    transition-timing-function: ease-in-out;
    &.disabled {
      opacity: 0.44;
      background-color: #feb139;
    }
  }
  &:hover&:not(.disabled) > div,
  &:focus-visible&:not(.disabled) > div {
    border-radius: 4px;
    background-color: #fbe8ca;
    box-shadow: 0px 2px 4px 0px rgba(255, 211, 89, 0.75);
  }

  .icon {
    width: 32px;
    height: 32px;
  }

  .text-button {
    font-size: 0.9375rem; // ~15px
    line-height: 1.375rem;
    color: var(--gray-9);
    font-weight: 500;
  }

  input {
    display: none;
    appearance: none;
  }
  input[type="radio"]:checked + div .icon,
  input[type="checkbox"]:checked + div .icon,
  input[type="radio"]:checked + div .text-button,
  input[type="checkbox"]:checked + div .text-button {
    color: var(--gray-9);
    font-size: 16px;
    font-weight: 600;
    opacity: 1;
  }
  &:hover input[type="radio"]:checked + div .icon,
  &:hover input[type="checkbox"]:checked + div .icon,
  &:hover input[type="radio"]:checked + div .text-button,
  &:hover input[type="checkbox"]:checked + div .text-button,
  &:focus-visible input[type="radio"]:checked + div .icon,
  &:focus-visible input[type="checkbox"]:checked + div .icon,
  &:focus-visible input[type="radio"]:checked + div .text-button,
  &:focus-visible input[type="checkbox"]:checked + div .text-button {
    color: var(--gray-9);
    font-size: 16px;
    font-weight: 600;
    background-color: var(--white);
    opacity: 1;
  }
  input[type="radio"]:checked + div,
  input[type="checkbox"]:checked + div {
    box-shadow: none;
    color: var(--gray-9);
    font-size: 16px;
    font-weight: 600;
    background-color: var(--white);
    opacity: 1;
  }
  &:hover input[type="radio"]:checked + div,
  &:hover input[type="checkbox"]:checked + div,
  &:focus-visible input[type="radio"]:checked + div,
  &:focus-visible input[type="checkbox"]:checked + div {
    background-color: var(--white);
  }
`

const KitRecommendation = styled(function KitRecommendation({ team, className }) {
  const { data: scheduleNextKits } = useScheduleNextKits({
    teamId: team.id,
    skipIfHasScheduled: true,
    limit: 1,
  })
  const nextKit = scheduleNextKits?.[0]

  if (!nextKit) return null

  const nextKitURL = buildUrl(["kit", nextKit.slug], { urlQueryParams: { team_id: team.id } })

  return (
    <div className={cn(className, "bg-orange-tint py-medium px-large mt-medium border-radius-small")}>
      For a more personalized answer, do the{" "}
      <a href={nextKitURL} target="_blank" rel="noopener noreferrer" className="text-semi-bold">
        {nextKit?.title}
      </a>{" "}
      kit with your team.
    </div>
  )
})`
  box-shadow: 0px 0px 2px 0px var(--orange-4);
`

const availableLLMsForUser = (user) =>
  user.is_staff ? LLMType : omitBy(LLMType, (llmType) => VANILLA_LLM_TYPES.includes(llmType))

export default Arti
