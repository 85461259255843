import cn from "classnames"
import { isValidElement } from "react"
import { styled } from "styled-components"

import { useKitSession } from "../KitSessionContext"

import { DesktopIcon, SlashIcon } from "icons/FontAwesomeIcons"
import { useUser } from "resources/users"
import { BulletedList, View } from "ui"

const FacilitatorTip = ({ className, tip, icon, positionAbsolute = false }) => {
  const { team } = useKitSession()
  const { data: user } = useUser({ userId: "me" })

  if ((!tip && !icon) || !user || user.id !== team.team_lead_id) {
    return null
  }

  const contents = getTipContents(tip)
  const screenIcon = getScreenIcon(tip, icon)

  return (
    (contents || screenIcon) && (
      <Tip className={className} label="Facilitator tips" positionAbsolute={positionAbsolute}>
        {screenIcon}
        <div className={cn({ "mt-xs": !!screenIcon })}>{contents}</div>
      </Tip>
    )
  )
}

const getTipContents = (tip) => {
  if (typeof tip === "string" || isValidElement(tip)) {
    return tip
  }

  if (Array.isArray(tip)) {
    return tip.map((item, idx) => (
      <div className="mt-xs" key={idx}>
        {getTipContents(item)}
      </div>
    ))
  }

  if (typeof tip === "object" && tip?.style === "paragraph") {
    return tip.content
  }

  if (typeof tip === "object" && tip?.style === "bullets") {
    return (
      <BulletedList>
        {tip.content.map((bullet_point, index) => (
          <li key={index}>{bullet_point}</li>
        ))}
      </BulletedList>
    )
  }

  return null
}

const getScreenIcon = (tip, icon) => {
  if ((tip && tip.icon === "share-screen") || (icon && icon === "share-screen")) {
    return (
      <>
        <DesktopIcon color="var(--rising-yellow)" className="fa-xl mr-xs" />
        Share your screen
      </>
    )
  }

  if ((tip && tip.icon === "turn-off-share-screen") || (icon && icon === "turn-off-share-screen")) {
    return (
      <>
        <span className="slash-desktop fa-stack fa-xl mr-xs">
          <SlashIcon className="fa-stack-1x" color="var(--gray-6)" />
          <DesktopIcon className="fa-stack-1x" color="var(--gray-6)" />
        </span>
        Turn screen sharing OFF
      </>
    )
  }

  return null
}

const Tip = styled(function Tip({ className, label, children, positionAbsolute = false }) {
  return (
    <View
      className={cn(className, "show-on-desktop bg-gray-1 p-small", {
        "position-absolute": positionAbsolute,
      })}
    >
      <div className="tip-content text-small text-thin text-gray-9">
        <span className="text-bold">{label}</span>
        <div className="mt-xs">{children}</div>
      </div>
    </View>
  )
})`
  border-radius: 8px;

  .tip-content {
    width: 229px;
  }

  .slash-desktop {
    width: 24px;
    height: 24px;
  }

  p {
    margin-top: 0.5rem;
  }

  &.position-absolute {
    position: absolute;
    top: 0;
    right: 0;
  }

  .theme-widescreen & {
    .slash-desktop {
      width: 45px;
      height: 45px;
    }
  }
`

export default FacilitatorTip
