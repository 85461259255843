import { Formik, Form, useFormikContext } from "formik"
import { styled } from "styled-components"

import { ToggleSwitchField } from "forms/fields"

const ScheduleModeToggle = styled(function ScheduleModeToggle({
  className,
  isScheduleModeActive,
  onSwitchScheduleMode,
}) {
  return (
    <div className={className}>
      <Formik initialValues={{ isScheduleModeActive }} onSubmit={onSwitchScheduleMode}>
        <Form title="Schedule Mode">
          <ScheduleSwitch isScheduleModeActive={isScheduleModeActive} />
        </Form>
      </Formik>
    </div>
  )
})`
  form {
    display: flex;
    margin-right: 0.5rem;
  }
`

const ScheduleSwitch = ({ isScheduleModeActive }) => {
  const { submitForm } = useFormikContext()
  return (
    <ToggleSwitchField
      name="schedule_mode"
      className="text-semi-bold"
      checked={isScheduleModeActive}
      saveOnChange={submitForm}
    >
      Schedule only mode
    </ToggleSwitchField>
  )
}

export default ScheduleModeToggle
