import { Formik } from "formik"
import { find } from "lodash-es"
import { useState } from "react"

import { getUserExerciseInstance } from "../Exercise/results_utils"

import ExerciseContent from "domains/Exercise/ExerciseContent"
import ExerciseForm from "domains/Exercise/ExerciseForm"
import { useLatestTeamExerciseInstances } from "domains/Exercise/resource"
import { ExpectationsUserResults } from "domains/Exercise/ResultsComponents"
import useExerciseForm from "domains/Exercise/useExerciseForm"
import { SelectField } from "forms/fields"
import { RoadIcon } from "icons/FontAwesomeIcons"
import { sortUsersByShortName, useUser } from "resources/users"
import { View, Loading } from "ui"
import { useQueryParams } from "ui/hooks"

const ExpectationsResultsReview = ({ kit, selectedTeam }) => {
  const { data: currentUser } = useUser({ userId: "me" })
  const query = useQueryParams()
  const { data: exerciseInstances, isFetching } = useLatestTeamExerciseInstances({
    teamId: selectedTeam.id,
    exerciseSlug: kit.exercise.slug,
  })
  const {
    initialValues,
    onSubmit,
    isFetching: exerciseIsFetching,
    saveOnChange,
  } = useExerciseForm({
    teamId: selectedTeam.id,
    slug: kit.exercise.slug,
    version: kit.exercise.version,
  })

  if (isFetching || exerciseIsFetching) {
    return <Loading />
  }

  const users = selectedTeam.members.filter((x) => getUserExerciseInstance(exerciseInstances, x))
  const initialUserId = parseInt(query.participant)
  const initialUser = find(users, { id: initialUserId })
  const { components } = kit.session.steps.find(({ path }) => path === "expectations-apply-road")

  return (
    <>
      <View $alignItems="flex-start" className="mb-large">
        <RoadIcon className="text-rising-yellow title-icon mr-medium" />
        <h1 className="text-gray-9">The ROAD Framework</h1>
      </View>
      <p className="text-gray-9 mb-medium">
        Whether setting a goal for someone on your team or clarifying expectations for your own goals, the ROAD
        framework outlines specific criteria needed to set everyone up for success. The ROAD model can be especially
        helpful to revisit expectations and commitments as things change.
      </p>
      <SelectedUserResults
        users={users}
        currentUser={currentUser}
        components={components}
        exerciseInstances={exerciseInstances}
        initialUser={initialUser}
        exerciseInitialValues={initialValues}
        exerciseOnSubmit={onSubmit}
        exerciseSaveOnChange={saveOnChange}
      />
    </>
  )
}

const SelectedUserResults = ({
  users,
  currentUser,
  components,
  exerciseInstances,
  initialUser,
  exerciseInitialValues,
  exerciseOnSubmit,
  exerciseSaveOnChange,
}) => {
  const [selectedUserId, setSelectedUserId] = useState(initialUser?.id)
  const sortedUsers = sortUsersByShortName({ users })
  const selectorInitialValues = { current_team_member: selectedUserId }
  const selectedExercise = getUserExerciseInstance(exerciseInstances, { id: selectedUserId })

  const handleUserChange = ({ target }) => {
    const newUser = find(users, {
      id: parseInt(target.value),
    })
    setSelectedUserId(newUser?.id)
  }

  return (
    <>
      <h4 className="text-gray-9 mb-small">View:</h4>
      <Formik initialValues={selectorInitialValues} className="mb-xl">
        <SelectField
          name="current_team_member"
          size="medium"
          value={selectedUserId}
          onChange={handleUserChange}
          className="mb-xl"
        >
          {sortedUsers.map(({ id, short_name }) => (
            <option key={id} value={id}>
              {short_name}
            </option>
          ))}
        </SelectField>
      </Formik>

      {currentUser.id === selectedUserId ? (
        <Formik initialValues={exerciseInitialValues} onSubmit={exerciseOnSubmit}>
          <ExerciseForm>
            <ExerciseContent components={components} saveOnChange={exerciseSaveOnChange} is_full_exercise={false} />
          </ExerciseForm>
        </Formik>
      ) : (
        <>
          <h2 className="text-gray-9 mb-medium">Applying the ROAD</h2>
          <ExpectationsUserResults exercise={selectedExercise} is_full_exercise={false} />
        </>
      )}
    </>
  )
}

export default ExpectationsResultsReview
