import { forwardRef, useState } from "react"
import { styled } from "styled-components"

import InputField, { StyledInput } from "./InputField"

import { EyeIcon, EyeSlashIcon } from "icons/FontAwesomeIcons"

const StyledPasswordInput = styled(
  forwardRef(({ className, passwordHint, ...props }, ref) => {
    const [hidden, setHidden] = useState(true)

    return (
      <div className={className}>
        {!!passwordHint && (
          <div className="text-thin text-small text-gray-8 pb-small show-on-desktop">
            Passwords must be at least eight characters long
          </div>
        )}
        {hidden ? (
          <>
            <StyledInput className={className} ref={ref} {...props} type="password" />
            <EyeIcon className="eye" onClick={() => setHidden(false)} />
          </>
        ) : (
          <>
            <StyledInput className={className} ref={ref} {...props} type="text" />
            <EyeSlashIcon className="eye" onClick={() => setHidden(true)} />
          </>
        )}
      </div>
    )
  })
)`
  position: relative;

  .eye {
    position: absolute;
    right: 1rem;
    bottom: 1rem;
  }
`

const PasswordField = ({ className, eye, passwordHint = false, inert = false, ...props }) => {
  if (inert) {
    props = { ...props, inert: "true" }
    // need to use "true" string for inert attribute until React supports it,
    // watch https://github.com/facebook/react/pull/24730
  }
  return !eye ? (
    <InputField className={className} type="password" {...props} passwordHint={passwordHint} />
  ) : (
    <InputField className={className} forwardedAs={StyledPasswordInput} {...props} passwordHint={passwordHint} />
  )
}

PasswordField.defaultProps = {
  eye: false,
}

export default PasswordField
