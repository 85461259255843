import { Formik, Form, useFormikContext } from "formik"
import { styled } from "styled-components"

import { useHasTeamFeature } from "domains/Results/utils"
import { handleErrors } from "forms"
import { ImageField } from "forms/fields"
import { CameraIcon, CloudArrowUpIcon } from "icons/FontAwesomeIcons"
import { useUpdateSessionPicture, useSessionPicture } from "resources/monthly_kit"
import { useTeam } from "resources/teams"
import { Button, DragAndDrop, Loading, View } from "ui"
import { SHARED_FLAGS } from "ui/hooks/useFeatures"
import { resizeImage, getImageType, SUPPORTED_RESIZING_IMAGE_TYPES } from "utils/image"

const MAX_IMAGE_SIZE_MB = 8

const getFieldId = (kitInstance) => `session_picture_${kitInstance.id}`

const SessionImage = ({ className, kitInstance, enableRefetch = true }) => (
  <Formik initialValues={{ [getFieldId(kitInstance)]: "" }} className="mt-medium">
    <Form className={className}>
      <SessionImageUploadField kitInstance={kitInstance} enableRefetch={!!enableRefetch} />
    </Form>
  </Formik>
)

const SessionImageUploadField = ({ kitInstance, enableRefetch }) => {
  const { data: team } = useTeam({ teamId: kitInstance.team_id })
  const { enabled: imageResizing } = useHasTeamFeature(team, SHARED_FLAGS.IMAGE_RESIZING)
  const { enabled: sessionRealtimeUpdatesV2 } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_REPLACE_POLLING)
  const { data: sessionPicture } = useSessionPicture({
    kitInstanceId: kitInstance.id,
    sessionRealtimeUpdates: sessionRealtimeUpdatesV2,
    ...(enableRefetch ? { refetchInterval: 3000 } : {}),
  })
  const formik = useFormikContext()
  const { mutateAsync: updateSessionPicture, status: uploadStatus } = useUpdateSessionPicture({
    kitInstanceId: kitInstance.id,
  })

  const uploadFile = async (file) => {
    // For some reason Formik doesn't automatically set this on image fields,
    // but it's needed for error messages to display
    formik.setFieldTouched(getFieldId(kitInstance), true, false)

    if (!file) {
      formik.setErrors({ [getFieldId(kitInstance)]: "Image upload failed, please try again" })
      return
    }
    const imageType = getImageType({ file })
    const image =
      imageResizing && SUPPORTED_RESIZING_IMAGE_TYPES.includes(imageType) ? await resizeImage({ file }) : file
    if (image.size > MAX_IMAGE_SIZE_MB * 1024 * 1024) {
      formik.setErrors({ [getFieldId(kitInstance)]: `Image size must be under ${MAX_IMAGE_SIZE_MB}mb` })
      return
    }
    await updateSessionPicture(image)
    formik.setFieldTouched(getFieldId(kitInstance), false)
  }

  const onChange = handleErrors(({ target: { files } }) => uploadFile(files[0]), null, formik)

  const onDropHandler = (files) => uploadFile(files[0])

  return uploadStatus === "loading" ? (
    <Loading />
  ) : (
    <DragAndDrop onDropHandler={onDropHandler}>
      <View $flexDirection="column" $justifyContent="center" $alignItemsMobile="center">
        {!sessionPicture ? (
          <View className="photo-dropzone border-radius-small" $justifyContent="center" $alignItems="center">
            <View className="text-blue-2" $flexDirection="column" $justifyContent="center" $alignItems="center">
              <CloudArrowUpIcon className="fa-3x" />
              <p className="text-bold mb-small">Drag & drop your photo here or</p>
              <Button
                as="label"
                className="tertiary cursor-pointer img-upload-button"
                htmlFor={getFieldId(kitInstance)}
              >
                Browse Files
              </Button>
            </View>
          </View>
        ) : (
          <View className="team-picture">
            <View $flexDirection="column">
              <img src={sessionPicture} alt="Team" />
            </View>
            <label
              htmlFor={getFieldId(kitInstance)}
              className="team-picture-button text-gray-9 border-radius-medium px-small"
            >
              <CameraIcon className="fa-lg cursor-pointer img-upload-button" />
            </label>
          </View>
        )}
        <ImageField id={getFieldId(kitInstance)} name={getFieldId(kitInstance)} hidden onChange={onChange} />
      </View>
    </DragAndDrop>
  )
}

export default styled(SessionImage)`
  .photo-dropzone {
    width: ${({ kitInstance }) => (!kitInstance.session_completed_at ? "377px" : "350px")};
    height: 212px;
    background: rgb(10 132 255 / 10%);
    border: 1px dashed var(--rising-blue);
  }

  label[for="${({ kitInstance }) => getFieldId(kitInstance)}"] .field-message {
    width: 100%;
    text-align: center;
  }

  .team-picture {
    position: relative;
    width: auto;

    img {
      max-width: 760px;
      max-height: 400px;
      object-fit: contain;
    }
  }

  .team-picture-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--fg);
    box-shadow: var(--lift-4);
  }

  .img-upload-button {
    z-index: var(--z-above-zero);
  }
`
