import { useKitSession } from "../KitSessionContext"

import { useTeamLevelExerciseInstance } from "domains/Exercise/resource"
import IfAnswerEquals from "domains/Exercise/ResultsBlocks/IfAnswerEquals"
import { useHasTeamFeature } from "domains/Results/utils"
import { Loading } from "ui"
import { SHARED_FLAGS } from "ui/hooks/useFeatures"

const IfTeamLevelExerciseAnswerEqualsContainer = ({ kitInstance, identifier, answerValue, children }) => {
  const { team } = useKitSession()
  const { enabled: sessionRealtimeUpdatesFlagActive } = useHasTeamFeature(
    team,
    SHARED_FLAGS.RTDEV_REALTIME_ANSWER_UPDATE
  )
  const sessionRealtimeUpdates = !!sessionRealtimeUpdatesFlagActive && !!kitInstance && !!team
  const { data: exerciseInstance, isFetching } = useTeamLevelExerciseInstance({
    teamId: kitInstance.team_id,
    kitInstanceId: kitInstance?.id,
    slug: kitInstance.kit.exercise.slug,
    sessionRealtimeUpdates,
  })

  if (!exerciseInstance && isFetching) {
    return <Loading />
  }

  if (!exerciseInstance) {
    return null
  }

  return (
    <IfAnswerEquals identifier={identifier} exerciseInstance={exerciseInstance} answerValue={answerValue}>
      {children}
    </IfAnswerEquals>
  )
}

export default IfTeamLevelExerciseAnswerEqualsContainer
