import { useFormikContext } from "formik"
import { useState } from "react"

import Button from "./Button"
import SavingDots from "./SavingDots"

const Submitting = ({ labelSubmitting }) => (
  <>
    {labelSubmitting}
    <SavingDots />
  </>
)

const FormikSubmitButton = ({ ...props }) => {
  const { isSubmitting, handleSubmit } = useFormikContext()
  return <CommonSubmitButton action={handleSubmit} isSubmitting={isSubmitting} {...props} />
}

const CustomSubmitButton = ({ onClick, onSuccess, ...props }) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const action = () => {
    setIsSubmitting(true)
    onClick()
      .then(() => {
        onSuccess && onSuccess()
      })
      // Note: setIsSubmitting here can cause a warning in React: Can't perform a React state update on an unmounted component.
      // This is a false positive and in fact this warning was removed in React 18
      // https://github.com/facebook/react/pull/22114
      .finally(() => setIsSubmitting(false))
  }
  return <CommonSubmitButton action={action} isSubmitting={isSubmitting} {...props} />
}

const CommonSubmitButton = ({ label, labelSubmitting, children, className, action, isSubmitting, ...props }) => {
  label = label || children || "Submit"
  if (labelSubmitting !== false) {
    labelSubmitting = labelSubmitting || children || "Submitting"
  }
  return (
    <Button type="submit" className={className} onClick={action} disabled={isSubmitting} {...props}>
      {isSubmitting && labelSubmitting ? <Submitting labelSubmitting={labelSubmitting} /> : label}
    </Button>
  )
}

const SubmitButton = ({ onClick, ...props }) => {
  const isFormik = !onClick

  return isFormik ? <FormikSubmitButton {...props} /> : <CustomSubmitButton onClick={onClick} {...props} />
}

export default SubmitButton
