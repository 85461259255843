import { Navigate, useParams } from "react-router"

import { isUsingSameAuthDomain, redirectWithSameAuthDomainIfNeeded } from "../firebase"
import { useSSOProvider } from "../resource"

import SSOLogin from "./SSOLogin"

import { Loading } from "ui"
import { useFeatures, useQueryParams } from "ui/hooks"
import { buildUrl } from "utils/string"

const ProviderSSOLogin = ({ ssoRedirectLogin = false, showLoadingUI = false }) => {
  // TODO: jey: remove after we switch all sso providers to same domain
  const features = useFeatures()
  const { next } = useQueryParams()
  const { provider } = useParams()
  const { data: ssoProvider, isFetching } = useSSOProvider(provider)

  if (!provider) {
    return <Navigate to="/" replace />
  }

  // TODO: jey: replace below after we switch all sso providers to same domain
  // if (!ssoProvider && isFetching) {
  if ((!ssoProvider && isFetching) || features.isInitialLoading) {
    return <Loading />
  }

  // TODO: jey: remove after we switch all sso providers to same domain
  const redirected = redirectWithSameAuthDomainIfNeeded({ features, provider, next })
  if (redirected) {
    return null
  }

  if (ssoRedirectLogin && !showLoadingUI) {
    const redirectLoadingUrl = buildUrl(["auth", "sso", provider, "redirect-loading"], {
      urlQueryParams: {
        next,
        useSameAuthDomain: isUsingSameAuthDomain(),
      },
    })
    return <Navigate to={redirectLoadingUrl} replace state={{ showLoginUI: true }} />
  }

  if (!ssoProvider) {
    return <Navigate to="/" replace />
  }

  const { providerName } = ssoProvider.sso_provider

  return <SSOLogin provider={provider} providerName={providerName} ssoRedirectLogin={ssoRedirectLogin} />
}

export default ProviderSSOLogin
