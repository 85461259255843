import { Formik } from "formik"
import { find, keyBy, last, sortBy } from "lodash-es"
import { useState } from "react"

import { getUserExerciseInstance } from "../Exercise/results_utils"

import ShareResultsButton from "./ShareResultsButton"
import UpdateResultsButton from "./UpdateResultsButton"

import { useLatestTeamExerciseInstances, useTeamLevelExerciseInstance } from "domains/Exercise/resource"
import AggregatedResultsBlocks from "domains/Exercise/ResultsBlocks/AggregatedResultsBlocks"
import ResultsBlocks from "domains/Exercise/ResultsBlocks/ResultsBlocks"
import TeamExerciseEditableResults from "domains/Exercise/TeamExerciseEditableResults"
import { SelectField } from "forms/fields"
import getIconOrError from "icons"
import { getUseGetAggregatedResultsDataForTeamFunction } from "resources/teams"
import { sortUsersByShortName, useUser } from "resources/users"
import { Loading, View } from "ui"
import { useQueryParams } from "ui/hooks"
import SelectedUserContext from "ui/SelectedUserContext"

const BlockResultsReview = ({ kit, resultsPage, selectedTeam }) => {
  const { data: user } = useUser({ userId: "me" })
  const query = useQueryParams()
  const { data: exerciseInstances, isFetching } = useLatestTeamExerciseInstances({
    teamId: selectedTeam.id,
    exerciseSlug: kit.exercise.slug,
  })

  if (!exerciseInstances && isFetching) {
    return <Loading />
  }

  if (!exerciseInstances) {
    return null
  }

  const users = selectedTeam.members.filter((x) => getUserExerciseInstance(exerciseInstances, x))
  const userIdMap = keyBy(users, "id")
  const sortedUsers = sortUsersByShortName({ users })
  const latestExerciseInstance = last(sortBy(exerciseInstances, "created_at"))
  const latestDefinition = latestExerciseInstance?.definition
  const initialUser = getInitialUser({ user, users, query, resultsPage, latestDefinition })
  const Icon = resultsPage.icon && getIconOrError(resultsPage.icon)
  const filteredExerciseInstances = exerciseInstances.filter((x) => userIdMap[x.user_id])

  return (
    <>
      <View $alignItems="flex-start" $flexWrap="wrap" className="mb-medium">
        <Icon className="title-icon mr-medium" color={`var(--${resultsPage.icon_color})`} />
        <h1 className="text-gray-9">{resultsPage.title}</h1>
      </View>
      <p className="text-gray-9 mb-large">{resultsPage.description}</p>
      {resultsPage.team_only ? (
        <TeamOverviewBlocks
          selectedTeam={selectedTeam}
          kit={kit}
          exerciseInstances={filteredExerciseInstances}
          latestDefinition={latestDefinition}
          sortedUsers={sortedUsers}
        />
      ) : (
        <SelectedUserResults
          sortedUsers={sortedUsers}
          userIdMap={userIdMap}
          exerciseInstances={filteredExerciseInstances}
          latestDefinition={latestDefinition}
          initialUser={initialUser}
          kit={kit}
          selectedTeam={selectedTeam}
        />
      )}
    </>
  )
}

const TEAM_ALL = 0

const SelectedUserResults = ({
  sortedUsers,
  userIdMap,
  exerciseInstances,
  latestDefinition,
  initialUser,
  kit,
  selectedTeam,
}) => {
  const [shareLink, setShareLink] = useState(null)

  const [selectedUserId, setSelectedUserId] = useState(initialUser?.id || TEAM_ALL)
  const initialValues = { current_team_member: selectedUserId }
  const selectedExercise = getUserExerciseInstance(exerciseInstances, { id: selectedUserId })
  const selectedUser = userIdMap[selectedUserId]

  const handleUserChange = ({ target }) => {
    const user = userIdMap[parseInt(target.value)]
    setSelectedUserId(user?.id || TEAM_ALL)
    setShareLink(null)
  }

  return (
    <>
      <View $alignItems="flex-start" $flexWrap="wrap" className="mb-large mt-medium">
        <View $flexDirection="row" $flexDirectionTablet="column" $flexDirectionMobile="column" $alignItems="center">
          <Formik initialValues={initialValues}>
            <SelectField name="current_team_member" size="medium" value={selectedUserId} onChange={handleUserChange}>
              {(!!latestDefinition?.team_overview_blocks || !!latestDefinition?.team_overview_aggregated_blocks) && (
                <option value={TEAM_ALL}>Team Overview</option>
              )}
              {sortedUsers.map(({ id, short_name }) => (
                <option key={id} value={id}>
                  {short_name}
                </option>
              ))}
            </SelectField>
          </Formik>
          <View $justifyContent="flex-end" $justifyContentTablet="flex-start" $justifyContentMobile="flex-start">
            <ShareResultsButton
              selectedUserId={selectedUserId}
              selectedExercise={selectedExercise}
              shareLink={shareLink}
              setShareLink={setShareLink}
              shareAssessmentTitle={selectedExercise?.definition?.share_results?.title}
            />
            <UpdateResultsButton selectedUserId={selectedUserId} kitSlug={kit.slug} selectedTeam={selectedTeam} />
          </View>
        </View>
      </View>
      {selectedUserId === TEAM_ALL ? (
        <TeamOverviewBlocks
          selectedTeam={selectedTeam}
          kit={kit}
          exerciseInstances={exerciseInstances}
          latestDefinition={latestDefinition}
          sortedUsers={sortedUsers}
        />
      ) : (
        <SelectedUserContext.Provider value={{ selectedUser }}>
          <ResultsBlocks
            blocks={selectedExercise.definition.user_results_blocks}
            exerciseInstances={exerciseInstances}
            sortedUsers={sortedUsers}
          />
        </SelectedUserContext.Provider>
      )}
    </>
  )
}

const getInitialUser = ({ user, users, query, resultsPage, latestDefinition }) => {
  const queryUserId = parseInt(query.participant)
  const queryUser = find(users, { id: queryUserId })
  if (queryUser) {
    return queryUser
  }

  // if team_member_only or no team overview blocks, try to select a default user to display
  if (
    !resultsPage.team_member_only &&
    (!!latestDefinition?.team_overview_blocks || !!latestDefinition?.team_overview_aggregated_blocks)
  ) {
    return null
  }

  const currentUser = find(users, { id: user?.id })
  if (currentUser) {
    return currentUser
  }

  return users[0] ?? null
}

const TeamOverviewBlocks = ({ selectedTeam, kit, exerciseInstances, latestDefinition, sortedUsers }) => {
  const { data: teamExerciseInstance, isFetching } = useTeamLevelExerciseInstance({
    teamId: selectedTeam.id,
    slug: kit.exercise.slug,
    enabled: !!latestDefinition ? !!latestDefinition?.use_team_level_exercise_for_team_overview : true,
  })

  if (!teamExerciseInstance && isFetching) {
    return <Loading />
  }

  const teamExerciseDefinition = latestDefinition ?? teamExerciseInstance?.definition
  const useAggregatedResultsData = getUseGetAggregatedResultsDataForTeamFunction({
    teamId: selectedTeam.id,
    kitSlug: kit.slug,
    apiResource: "teams",
  })

  if (!teamExerciseDefinition) {
    return null
  }

  return (
    <>
      {!!teamExerciseDefinition.team_overview_aggregated_blocks && (
        <AggregatedResultsBlocks
          blocks={teamExerciseDefinition?.team_overview_aggregated_blocks}
          useAggregatedResultsData={useAggregatedResultsData}
          sortedUsers={sortedUsers}
        />
      )}
      {!!teamExerciseDefinition.team_overview_blocks && (
        <ResultsBlocks
          blocks={teamExerciseDefinition?.team_overview_blocks}
          exerciseInstances={exerciseInstances}
          teamExerciseInstance={teamExerciseInstance}
          sortedUsers={sortedUsers}
        />
      )}
      {!!teamExerciseDefinition.team_overview_editable_results_components && (
        <TeamExerciseEditableResults
          kit={kit}
          selectedTeam={selectedTeam}
          teamExerciseInstance={teamExerciseInstance}
          components={teamExerciseDefinition?.team_overview_editable_results_components}
        />
      )}
    </>
  )
}

export default BlockResultsReview
