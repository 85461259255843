import * as Sentry from "@sentry/browser"
import { last, sortBy } from "lodash-es"

import { getExerciseComponent, getUserExerciseInstance } from "../results_utils"

import CheckboxGroupReadOnlyField from "./CheckboxGroupReadOnlyField"
import DateFieldReadOnlyField from "./DateFieldReadOnlyField"
import FieldsList from "./FieldsList"
import GuessingGameCorrectUserBubbles from "./GuessingGameCorrectUserBubbles"
import GuessingGameRanking from "./GuessingGameRanking"
import IconGroupRatingFieldSelectedUserBarChart from "./IconGroupRatingFieldSelectedUserBarChart"
import IconRatingFieldReadOnly from "./IconRatingFieldReadOnly"
import IfAnswerEquals from "./IfAnswerEquals"
import { IfAnswersExist, IfAnswersDoNotExist } from "./IfAnswersExist"
import IfMeIsSelectedUser from "./IfMeIsSelectedUser"
import Image from "./Image"
import MultiUserIfAnyAnswerEquals from "./MultiUserIfAnswersExist"
import PercentageGroupFieldDonutChart from "./PercentageGroupFieldDonutChart"
import PercentageGroupFieldMultiUserCircles from "./PercentageGroupFieldMultiUserCircles"
import PercentageGroupRatingFieldDonutChart from "./PercentageGroupRatingFieldDonutChart"
import PieSliceGroupFieldPieChart from "./PieSliceGroupFieldPieChart"
import PieSliceGroupRatingFieldPieChart from "./PieSliceGroupRatingFieldPieChart"
import RadioGroupCollectionMultiUserBarChart from "./RadioGroupCollectionMultiUserBarChart"
import RadioGroupIconButtons from "./RadioGroupIconButtons"
import RadioGroupIconList from "./RadioGroupIconList"
import RadioGroupIcons from "./RadioGroupIcons"
import RadioGroupList from "./RadioGroupList"
import RadioGroupMultiUserBarChart from "./RadioGroupMultiUserBarChart"
import RadioGroupMultiUserCircleCounter from "./RadioGroupMultiUserCircleCounter"
import RadioGroupMultiUserIconGrid from "./RadioGroupMultiUserIconGrid"
import RadioGroupMultiUserOptionList from "./RadioGroupMultiUserOptionList"
import RadioGroupMultiUserRangeGrid from "./RadioGroupMultiUserRangeGrid"
import RadioGroupMultiUserSimpleBarChart from "./RadioGroupMultiUserSimpleBarChart"
import RadioGroupMultiUserTextButtonGrid from "./RadioGroupMultiUserTextButtonGrid"
import RadioGroupMultiUserWordCloud from "./RadioGroupMultiUserWordCloud"
import RadioGroupRange from "./RadioGroupRange"
import RadioGroupReadOnlyField from "./RadioGroupReadOnlyField"
import RadioGroupSpanText from "./RadioGroupSpanText"
import RadioGroupTextButtonEmptyBlocks from "./RadioGroupTextButtonEmptyBlocks"
import RadioGroupTextButtons from "./RadioGroupTextButtons"
import RadioGroupWithCustomSpanText from "./RadioGroupWithCustomSpanText"
import RatingFieldReadOnly from "./RatingFieldReadOnly"
import RepeatingGroupTextFieldList from "./RepeatingGroupTextFieldList"
import RichTextFieldMultiUserAccordionList from "./RichTextFieldMultiUserAccordionList"
import RichTextFieldMultiUserList from "./RichTextFieldMultiUserList"
import RichTextFieldRichText from "./RichTextFieldRichText"
import SelectedUserRevealButton from "./SelectedUserRevealButton"
import SelectedUserShortName from "./SelectedUserShortName"
import SelectFieldMultiUserList from "./SelectFieldMultiUserList"
import SelectFieldMultiUserPieChart from "./SelectFieldMultiUserPieChart"
import SelectFieldReadOnlyField from "./SelectFieldReadOnlyField"
import SelectFieldSpanText from "./SelectFieldSpanText"
import StickyNotesTeamFavoritesShare from "./StickyNotesTeamFavoritesShare"
import StickyNotesUserFavoritesShare from "./StickyNotesUserFavoritesShare"
import TextFieldMultiUserList from "./TextFieldMultiUserList"
import TextFieldReadOnlyField from "./TextFieldReadOnlyField"
import TextFieldText from "./TextFieldText"
import TextFieldValueReferenceText from "./TextFieldValueReferenceText"
import TextReferenceCheckboxGroupReadOnlyField from "./TextReferenceCheckboxGroupReadOnlyField"

import { Accordion } from "components"
import { renderBlocks, blockComponents } from "components/Blocks"
import TeamExerciseParticipantSelectFieldShortName from "domains/KitSession/components/TeamExerciseParticipantSelectFieldShortName"
import TLParticipantSelectFieldShortName from "domains/KitSession/components/TLParticipantSelectFieldShortName"
import { blockIcons } from "icons"
import { blockUIComponents } from "ui"
import SelectedUserContext, { useSelectedUser } from "ui/SelectedUserContext"

const MultiUserSingleUserRow = ({
  exerciseInstances,
  sortedUsers,
  singleUserRowBlocks,
  showUserName,
  showAccordion,
  shortAccordion,
  accordionTag,
  className,
}) => {
  const WrapperComponent = showUserName && showAccordion ? Accordion : "div"
  return (
    <>
      {sortedUsers.map((user) => (
        <WrapperComponent
          key={user.id}
          title={user.short_name}
          {...(showUserName && showAccordion ? { shortAccordion, accordionTag } : {})}
          className={className}
        >
          {!!showUserName && !showAccordion && <h4 className="text-semi-bold mb-xs">{user.short_name}</h4>}
          <SelectedUserContext.Provider value={{ selectedUser: user }}>
            <ResultsBlocks
              blocks={singleUserRowBlocks}
              exerciseInstances={exerciseInstances}
              sortedUsers={sortedUsers}
            />
          </SelectedUserContext.Provider>
        </WrapperComponent>
      ))}
    </>
  )
}

const EXERCISE_IDENTIFIER_COMPONENTS = {
  CheckboxGroupReadOnlyField,
  DateFieldReadOnlyField,
  FieldsList,
  IconRatingFieldReadOnly,
  IconGroupRatingFieldSelectedUserBarChart,
  Image,
  PercentageGroupFieldDonutChart,
  PercentageGroupFieldMultiUserCircles,
  PercentageGroupRatingFieldDonutChart,
  PieSliceGroupFieldPieChart,
  PieSliceGroupRatingFieldPieChart,
  RadioGroupCollectionMultiUserBarChart,
  RadioGroupIconButtons,
  RadioGroupIconList,
  RadioGroupIcons,
  RadioGroupList,
  RadioGroupMultiUserBarChart,
  RadioGroupMultiUserCircleCounter,
  RadioGroupMultiUserIconGrid,
  RadioGroupMultiUserOptionList,
  RadioGroupMultiUserRangeGrid,
  RadioGroupMultiUserSimpleBarChart,
  RadioGroupMultiUserTextButtonGrid,
  RadioGroupMultiUserWordCloud,
  RadioGroupRange,
  RadioGroupReadOnlyField,
  RadioGroupSpanText,
  RadioGroupWithCustomSpanText,
  RadioGroupTextButtons,
  RadioGroupTextButtonEmptyBlocks,
  RatingFieldReadOnly,
  RepeatingGroupTextFieldList,
  RichTextFieldMultiUserAccordionList,
  RichTextFieldMultiUserList,
  RichTextFieldRichText,
  SelectFieldMultiUserList,
  SelectFieldMultiUserPieChart,
  SelectFieldReadOnlyField,
  SelectFieldSpanText,
  TextFieldMultiUserList,
  TextFieldReadOnlyField,
  TextFieldText,
  TextFieldValueReferenceText,
  TextReferenceCheckboxGroupReadOnlyField,
  GuessingGameRanking,
  GuessingGameCorrectUserBubbles,
  StickyNotesUserFavoritesShare,
  StickyNotesTeamFavoritesShare,
}
const MULTI_USER_COMPONENTS = {
  MultiUserIfAnyAnswerEquals,
  MultiUserSingleUserRow,
  PercentageGroupFieldMultiUserCircles,
  RadioGroupCollectionMultiUserBarChart,
  RadioGroupMultiUserBarChart,
  RadioGroupMultiUserCircleCounter,
  RadioGroupMultiUserIconGrid,
  RadioGroupMultiUserOptionList,
  RadioGroupMultiUserRangeGrid,
  RadioGroupMultiUserSimpleBarChart,
  RadioGroupMultiUserTextButtonGrid,
  RadioGroupMultiUserWordCloud,
  RichTextFieldMultiUserAccordionList,
  RichTextFieldMultiUserList,
  SelectFieldMultiUserList,
  SelectFieldMultiUserPieChart,
  TextFieldMultiUserList,
}
const OTHER_RESULTS_BLOCK_COMPONENTS = {
  IfAnswerEquals,
  IfAnswersExist,
  IfAnswersDoNotExist,
  IfMeIsSelectedUser,
  MultiUserIfAnyAnswerEquals,
  MultiUserSingleUserRow,
  SelectedUserRevealButton,
  // TODO: both of these below should be replaced with ParticipantSelectFieldShortName
  // ParticipantSelectFieldShortName should be implemented as an EXERCISE_IDENTIFIER_COMPONENT, not an OTHER_RESULTS_BLOCK_COMPONENT
  TeamExerciseParticipantSelectFieldShortName, // TODO: replace this with ParticipantSelectFieldShortName
  TLParticipantSelectFieldShortName, // TODO: replace this with ParticipantSelectFieldShortName
  SelectedUserShortName,
}

const isExerciseIdentifierComponent = (Tag) => Object.values(EXERCISE_IDENTIFIER_COMPONENTS).includes(Tag)
const isResultsBlockComponent = (Tag) =>
  Object.values({ ...EXERCISE_IDENTIFIER_COMPONENTS, ...OTHER_RESULTS_BLOCK_COMPONENTS }).includes(Tag)
const isMultiUserComponent = (Tag) => Object.values(MULTI_USER_COMPONENTS).includes(Tag)

const ResultsBlocks = ({ blocks, exerciseInstances, teamExerciseInstance, sortedUsers }) => {
  const { selectedUser } = useSelectedUser()

  if (!blocks || (!exerciseInstances?.length && !teamExerciseInstance)) {
    return null
  }

  const exerciseInstance = getUserExerciseInstance(exerciseInstances, selectedUser) ?? teamExerciseInstance

  const components = {
    ...blockComponents,
    ...blockUIComponents,
    ...blockIcons,
    ...EXERCISE_IDENTIFIER_COMPONENTS,
    ...OTHER_RESULTS_BLOCK_COMPONENTS,
  }
  return renderBlocks(blocks, components, ({ Tag, props }) => {
    if (isExerciseIdentifierComponent(Tag)) {
      const exerciseComponent = getBestGuessExerciseComponent({
        exerciseInstance,
        exerciseInstances,
        identifier: props.identifier,
      })
      if (!exerciseComponent) {
        return null // since there is no exerciseComponent, skip rendering for this block
      } else if (isMultiUserComponent(Tag)) {
        return {
          ...props,
          exerciseInstances,
          exerciseComponent,
          sortedUsers,
        }
      } else {
        return {
          ...props,
          exerciseInstance,
          exerciseComponent,
          sortedUsers,
        }
      }
    } else if (isResultsBlockComponent(Tag)) {
      if (isMultiUserComponent(Tag)) {
        return {
          ...props,
          exerciseInstances,
          sortedUsers,
        }
      } else {
        return {
          ...props,
          exerciseInstance,
          sortedUsers,
        }
      }
    }
    return props
  })
}

const getBestGuessExerciseComponent = ({ exerciseInstance, exerciseInstances, identifier }) => {
  const bestGuessExerciseInstance = exerciseInstance ?? last(sortBy(exerciseInstances, "created_at"))
  const exerciseComponent = getExerciseComponent(bestGuessExerciseInstance, identifier)
  if (!exerciseComponent) {
    Sentry.captureMessage(
      `ResultsBlocks.js: Missing exercise component for instance ${bestGuessExerciseInstance?.id}, identifier: ${identifier}`
    )
  }
  return exerciseComponent
}

export default ResultsBlocks
