import confetti from "canvas-confetti"

import { useEffectAfterFirstRender } from "ui/hooks"
import { ZIndex } from "ui/z-index"

function rand(min, max) {
  return Math.random() * (max - min) + min
}

const Fireworks = ({
  durationMs = 2 * 1000,
  colors = ["FFE400", "FFBD00", "E89400", "FFCA6C", "FDFFB8"],
  // default colors to various shades of orange
} = {}) => {
  useEffectAfterFirstRender(() => {
    const scalar = 2
    const defaults = {
      scalar,
      startVelocity: 30,
      spread: 360,
      ticks: 60,
      zIndex: ZIndex.Zero,
      colors,
      shapes: [
        "square",
        "star",
        confetti.shapeFromText({ text: "🎉", scalar }),
        confetti.shapeFromText({ text: "🎊", scalar }),
      ],
    }

    const endTime = Date.now() + durationMs
    const interval = setInterval(function () {
      const timeLeft = endTime - Date.now()
      if (timeLeft <= 0) {
        clearInterval(interval)
      } else {
        const config = { ...defaults, particleCount: 50 * (timeLeft / durationMs) }
        confetti({ ...config, origin: { x: rand(0.1, 0.3), y: rand(0, 1) - 0.2 } })
        confetti({ ...config, origin: { x: rand(0.7, 0.9), y: rand(0, 1) - 0.2 } })
      }
    }, 250)

    return () => clearInterval(interval)
  })

  return null
}

export default Fireworks
