import confetti from "canvas-confetti"

import { colors } from "ui"
import { useEffectAfterFirstRender } from "ui/hooks"

const Confetti = () => {
  useEffectAfterFirstRender(() => {
    confetti({
      particleCount: 300,
      spread: 90,
      origin: { x: 0.2, y: 0.6 },
      angle: 45,
      colors: [
        colors.risingBlue,
        colors.orange2,
        colors.risingYellow,
        colors.risingGreen,
        colors.risingYellow,
        colors.errorRed,
      ],
    })
  })

  return null
}

export default Confetti
