import cn from "classnames"
import { styled } from "styled-components"

import { KIT_GROUP_SIZE, getCustomKitTypeInfo, getKitTypeInfo } from "domains/LeaderKit/utils"
import { ElephantIcon } from "icons/FontAwesomeIcons"
import { View } from "ui"

const KitTypePill = styled(function KitTypePill({ className, selectedKitInfo }) {
  const kitType = selectedKitInfo?.type
  const isJumbo = selectedKitInfo?.group_size === KIT_GROUP_SIZE.JUMBO
  const customKitOrganization = selectedKitInfo?.custom_kit_organization
  if (!kitType) {
    return null
  }

  const kitTypeInfo = !!customKitOrganization ? getCustomKitTypeInfo(customKitOrganization) : getKitTypeInfo(kitType)
  const PillIcon = isJumbo ? ElephantIcon : kitTypeInfo.pillIcon
  const customPillLogo = !!customKitOrganization && kitTypeInfo?.customPillLogo
  const pillText = isJumbo ? "Jumbo" : kitTypeInfo.pillText
  const pillColorCss = isJumbo ? "text-rising-yellow border-rising-yellow" : kitTypeInfo.pillColorCss

  return (
    <View
      className={cn(className, "fit-content text-xs text-semi-bold border border-radius px-xs", pillColorCss)}
      $alignItems="center"
    >
      {!!customKitOrganization ? (
        <img src={customPillLogo} alt="custom-logo" className="xs-logo-icon mr-xs" />
      ) : (
        <PillIcon className="text-small mr-xs" />
      )}
      <div className="text-nowrap">{pillText}</div>
    </View>
  )
})`
  background: rgb(254 122 0 / 3%);
`

export default KitTypePill
