enum ZIndex {
  Zero = 0,
  Frame = 100, // The only "frame" currently is the preview-mode frame.
  Sticky = 200,
  Modal = 300,
  Menu = 400,
  Tooltip = 500,

  Above = 10,
  Below = -10,

  Max = Math.pow(2, 32) / 2 - 1,
}

type ZIndexAbove = Brand<number, "ZIndexAbove">
type ZIndexBelow = Brand<number, "ZIndexBelow">

function aboveZIndex(zIndex: ZIndex | ZIndexAbove): ZIndexAbove {
  return (zIndex + ZIndex.Above) as ZIndexAbove
}

function belowZIndex(zIndex: ZIndex | ZIndexBelow): ZIndexBelow {
  return (zIndex + ZIndex.Below) as ZIndexBelow
}

export { ZIndex, aboveZIndex, belowZIndex }
