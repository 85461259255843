import { useField } from "formik"

import { AnswerDisplayContent } from "ui"

const ExerciseAnswerDisplayContent = (props) => {
  const name = `${props.name}.value`
  const [field] = useField(name)
  return <AnswerDisplayContent answer={field.value} />
}

export default ExerciseAnswerDisplayContent
