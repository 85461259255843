import { styled } from "styled-components"

import PieChart from "ui/PieChart"
import { colors as themeColors, getRepeatingColorFunc } from "ui/theme"
import { capitalize, plural } from "utils/string"

const PieSliceChart = ({
  data,
  colors = null,
  availableSlices = 0,
  separateSlices = false, // if true, show white gaps between slices of same color
  width = null, // eslint-disable-line @typescript-eslint/no-unused-vars -- used in css
  height = null, // eslint-disable-line @typescript-eslint/no-unused-vars -- used in css
  getAriaAdditionalInfo = null,
  className,
  ...props
}) => {
  if (!data) {
    return null
  }

  // Optionally, accept an array of colors instead of a color function:
  if (Array.isArray(colors)) {
    colors = getRepeatingColorFunc(
      data.map(({ id }) => id),
      colors,
      { sortKeys: false }
    )
  }

  let parsedSlices
  if (!separateSlices) {
    // If slices are not "separated" just add a key to each entry in data:
    parsedSlices = data.map(({ id, ...other }) => ({ id, key: id, ...other }))
  } else {
    // "Separated" slice style shows a white gap between each pie slice in chart.
    // This is done by splitting each entry with value=N in data into N separate
    // entries each with value=1:
    parsedSlices = data.flatMap(({ id, value, label, ...other }) =>
      Array(value)
        .fill()
        .map((_, idx) => ({
          id: `${id}_${idx}`,
          key: id,
          label: Math.ceil(value / 2) === idx + 1 && (label ?? capitalize(id)),
          value: 1,
          ...other,
        }))
    )
  }

  const unknownSlices = Array(availableSlices)
    .fill()
    .map((_, idx) => ({
      id: `unknown_${idx}`,
      key: "unknown",
      label: false,
      value: 1,
    }))

  const displaySlices = [...parsedSlices, ...unknownSlices]
  const sliceValuesForAriaLabel = displaySlices
    .map((dataItem) => {
      if (!dataItem.label) {
        return null
      }
      const additionalInfoStr = getAriaAdditionalInfo ? getAriaAdditionalInfo(dataItem) : ""
      return `${dataItem.label}: ${plural(dataItem.value, "slice")} ${additionalInfoStr}`
    })
    .filter(Boolean)
  const ariaLabel = `Chart of pie slices with the following values: ${sliceValuesForAriaLabel.join(", ")}`

  return (
    <div aria-label={ariaLabel} className={className}>
      <PieChart
        data={displaySlices}
        innerRadius={0.15}
        arcLinkLabel={(d) => d.label && <tspan>{d.label}</tspan>}
        arcLinkLabelsColor={(d) => (d.label ? themeColors.gray9 : "transparent")}
        padAngle={2}
        animate={false}
        colors={({ data }) => colors({ id: data.key })}
        arcLinkLabelsStraightLength={0}
        {...props}
      />
    </div>
  )
}

export default styled(PieSliceChart)`
  width: ${({ width }) => width ?? "100%"};
  height: ${({ height }) => height ?? "400px"};

  ${({ theme }) =>
    theme.isWidescreen
      ? `
        tspan {
          font-size: 1.5rem;
          line-height: 2rem;
        }
        `
      : `
        tspan {
          font-size: 0.75rem;
          line-height: 1.25rem;
        }
        `}

  @media (max-width: ${({ theme }) => theme.mobileMax}) {
    height: 225px;

    tspan {
      font-size: 0.75rem;
      line-height: 1.25rem;
    }
  }
`
