import cn from "classnames"
import { Form, Formik, FormikContext } from "formik"
import { useRef, useState, useContext } from "react"
import { styled } from "styled-components"

import { handleErrors } from "forms"
import { TextField } from "forms/fields"
import { useUpdateTeam } from "resources/teams"
import { View, Button, SubmitButton, colors } from "ui"
import { isTextSelected, moveCursorToEnd } from "utils/text"

const TeamNameField = styled(function TeamNameField({ className, team, currentUser }) {
  const { mutateAsync: updateTeam } = useUpdateTeam({ team, currentUser })
  const [fieldValue, setFieldValue] = useState(team?.name)
  const [isEditing, setIsEditing] = useState(false)
  const formikContext = useContext(FormikContext)
  const input = useRef()

  const onSubmit = handleErrors(async (values) => {
    team = await updateTeam({ name: values.team_name })
    setFieldValue(values.team_name)
    setIsEditing(false)
    input.current.blur()
  })

  const renderField = ({ setValues }) => (
    <View $alignItems="center">
      <TextField
        className="full-width mr-large"
        name="team_name"
        autoComplete="off"
        // when the input is clicked, switch to editing mode:
        // NOTE: only do this if text isn't selected; this allows
        //       good UX for copying team name
        onClick={() => !isEditing && !isTextSelected() && setIsEditing(true)}
        // switch to editing mode if input is focused using "Tab" key:
        onFocus={() => !isEditing && setIsEditing(true)}
        // switch to editing mode on input event (just in case we haven't yet)
        onInput={() => !isEditing && setIsEditing(true)}
        inputRef={input}
      />
      {!isEditing ? (
        <Button
          color={colors.risingBlue}
          className="link-semi-bold p-xs"
          onClick={() => {
            setIsEditing(true)
            input.current?.focus()
            moveCursorToEnd(input.current)
          }}
        >
          Rename
        </Button>
      ) : (
        <>
          <SubmitButton color={colors.risingBlue} className="link-semi-bold p-xs">
            Save
          </SubmitButton>
          <Button
            color={colors.gray7}
            className="link-semi-bold p-xs"
            onClick={() => {
              setIsEditing(false)
              setValues({ team_name: fieldValue })
            }}
          >
            Cancel
          </Button>
        </>
      )}
    </View>
  )

  return (
    <div className={cn(className, { "is-editing": isEditing }, "py-xs")}>
      {formikContext ? (
        renderField(formikContext)
      ) : (
        <Formik initialValues={{ team_name: team?.name }} onSubmit={onSubmit}>
          {({ setValues }) => <Form>{renderField({ setValues })}</Form>}
        </Formik>
      )}
    </div>
  )
})`
  &:not(.is-editing) input {
    background: var(--gray-4);
    box-shadow: none;
  }
`

export default TeamNameField
