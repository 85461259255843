import cn from "classnames"
import { styled } from "styled-components"

const TermsAndConditions = ({ buttonText = "to register" }) => (
  <p className="text-gray-9 mt-large">
    By clicking {buttonText}, you are agreeing to the Rising Team{" "}
    <a href="https://risingteam.com/terms" target="_blank" rel="noopener noreferrer">
      Terms and Conditions
    </a>{" "}
    and{" "}
    <a href="https://risingteam.com/privacy" target="_blank" rel="noopener noreferrer">
      Privacy Policy
    </a>
    .
  </p>
)

const SHRMTermsAndConditions = styled(function SHRMTermsAndConditions({ className }) {
  return (
    <p className={cn(className, "text-gray-8 mt-large")}>
      By submitting this form, you are agreeing to receive communications from Rising Team and SHRM about this and other
      related products and services. You can unsubscribe at any time by clicking ‘Unsubscribe’ at the bottom of any
      email you receive. By submitting this form you are also acknowledging that you have read and agree to the Rising
      Team{" "}
      <a href="https://risingteam.com/terms" target="_blank" rel="noopener noreferrer">
        Terms and Conditions
      </a>{" "}
      and{" "}
      <a href="https://risingteam.com/privacy" target="_blank" rel="noopener noreferrer">
        Privacy Policy{" "}
      </a>
      and the{" "}
      <a
        href="https://www.shrm.org/about/privacy-policy"
        target="_blank"
        rel="noopener noreferrer"
        className="text-gray-9 text-underline"
      >
        SHRM HRM Privacy Policy{" "}
      </a>
      and{" "}
      <a
        href="https://www.shrm.org/about/terms-of-use"
        target="_blank"
        rel="noopener noreferrer"
        className="text-gray-9 text-underline"
      >
        Terms of Use
      </a>
    </p>
  )
})`
  font-size: 0.813rem;
  line-height: 1.25rem;
`

export default TermsAndConditions

export { SHRMTermsAndConditions }
