import { BasicTooltip } from "@nivo/tooltip"
import { last, sortBy, orderBy } from "lodash-es"
import { styled } from "styled-components"

import { getExerciseAnswer, getExerciseComponent } from "../results_utils"

import { BarChart } from "ui"
import { useWindowSize } from "ui/hooks"
import { splitStrIntoLines } from "utils/string"

const RadioGroupMultiUserSimpleBarChart = ({ exerciseComponent: _, ...props }) => <Chart {...props} />
const Chart = styled(function Chart({ exerciseInstances, identifiers, className }) {
  const latestExerciseInstance = last(sortBy(exerciseInstances, "created_at"))
  const { isMobileLandscapeOrSmaller } = useWindowSize()
  const choiceOptions = identifiers.flatMap((identifier) => {
    const exerciseComponent = getExerciseComponent(latestExerciseInstance, identifier)
    return exerciseComponent.options
  })
  const answersCollection = identifiers
    .flatMap((identifier) => exerciseInstances.map((exercise) => getExerciseAnswer(exercise, identifier)))
    .filter(Boolean)

  const data = choiceOptions
    .map((option) => {
      const value = option.value
      const count = answersCollection.filter((answer) => answer.includes(value)).length
      if (count === 0) {
        return null
      }
      return {
        question: option.label,
        shortLabel: option.short_label,
        value: option.value,
        count,
        tooltipText: option.tooltip_text,
      }
    })
    .filter(Boolean)

  const sortedData = orderBy(data, ["count"])

  const keys = ["count"]

  const colors = ["var(--rising-yellow)", "var(--orange-4)", "var(--orange-3)", "var(--orange-2)"]

  const questionAxisFormatter = (questionShortLabel) => {
    const maxWidth = isMobileLandscapeOrSmaller ? 10 : 16
    const lines = splitStrIntoLines(questionShortLabel, maxWidth)
    return (
      <>
        {lines.map((line, idx) => {
          const dy0 = (lines.length - 1) * 0.5
          const dy = lines.length === 1 ? null : idx > 0 ? "1em" : `-${dy0}em`
          return (
            <tspan key={idx} x="0" dy={dy}>
              {line}
            </tspan>
          )
        })}
      </>
    )
  }

  const Tooltip = ({ formattedValue, data }) => {
    const tooltipText = data.tooltipText ? data.tooltipText : data.question
    return <BasicTooltip id={tooltipText} value={formattedValue} />
  }

  return (
    <>
      {sortedData?.length > 0 ? (
        <BarChart
          className={className}
          data={sortedData}
          keys={keys}
          indexBy="shortLabel"
          padding={0.5}
          colors={colors}
          colorBy="indexValue"
          innerPadding={1}
          axisBottom={null}
          enableGridY={false}
          axisLeft={{
            format: questionAxisFormatter,
            tickSize: 0,
            tickPadding: isMobileLandscapeOrSmaller ? 10 : 14,
          }}
          tooltip={Tooltip}
          labelFormat={(d) => <tspan x={40}>{d}</tspan>}
        />
      ) : (
        <p className="mt-large mb-xl text-gray-8">No data yet</p>
      )}
    </>
  )
})`
  width: 100%;
  height: 500px;
`

export default RadioGroupMultiUserSimpleBarChart
