import cn from "classnames"

import { getExerciseAnswer } from "../results_utils"

import { AnswerDisplayContent } from "ui"

const TextFieldReadOnlyField = ({ identifier, exerciseInstance, className }) => {
  const answer = getExerciseAnswer(exerciseInstance, identifier)
  return <AnswerDisplayContent answer={answer} className={cn(className, "mb-xl")} />
}

export default TextFieldReadOnlyField
