import { uniqBy } from "lodash-es"
import { isValidElement, cloneElement } from "react"

function asArray(value) {
  return Array.isArray(value) ? value : [value]
}

function uniqueById(items) {
  return uniqBy(items, (item) => item.id)
}

function intersperse(arr, delimiter) {
  const isReactElement = isValidElement(delimiter)
  return arr
    .flatMap((item, idx) => [item, isReactElement ? cloneElement(delimiter, { key: `delimeter-${idx}` }) : delimiter])
    .slice(0, -1)
}

export { asArray, uniqueById, intersperse }
