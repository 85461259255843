import DOMPurify from "dompurify"
import { Formik } from "formik"

import { ErrorPage } from "components"
import { StepNavigation } from "components/Steps"
import ExerciseContext from "domains/Exercise/ExerciseContext"
import ExerciseStep from "domains/Exercise/ExerciseStep"
import useExerciseForm from "domains/Exercise/useExerciseForm"
import { SidebarContainer, StepContent, FacilitatorTip } from "domains/KitSession/components"
import { Loading, TextContainer } from "ui"

const TakeExerciseStep = ({
  kitInstance,
  sessionStep,
  navigateNextHandler,
  navigateBackHandler,
  nextButtonProps,
  backButtonProps,
  navRightContent,
}) => {
  const exercise = kitInstance?.kit?.exercise
  const { exerciseInstance, initialValues, onSubmit, saveOnChange, isInvalid, isFetching } = useExerciseForm({
    teamId: kitInstance?.team_id,
    slug: exercise?.slug,
    version: exercise?.version,
  })
  const { description, facilitator_tip, facilitator_tip_icon, facilitator_tip_full_width_step_body } = sessionStep

  if (!exerciseInstance && isFetching) {
    return <Loading />
  }

  if (isInvalid) {
    return <ErrorPage />
  }

  return (
    <ExerciseContext.Provider value={{ exerciseInstance }}>
      <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
        <StepContent>
          {facilitator_tip || facilitator_tip_icon ? (
            <SidebarContainer>
              <TextContainer
                className="pr-small full-width"
                maxWidth={!!facilitator_tip_full_width_step_body ? "100%" : null}
              >
                {!!description && (
                  <p
                    className="text-gray-9 mb-medium"
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description) }}
                  />
                )}
                <ExerciseStep
                  kit={kitInstance.kit}
                  step={sessionStep}
                  is_session={true}
                  saveOnChange={saveOnChange}
                  stepDescription={description}
                />
              </TextContainer>
              <FacilitatorTip
                className="sidebar-right"
                tip={facilitator_tip}
                icon={facilitator_tip_icon}
                positionAbsolute={facilitator_tip_full_width_step_body}
              />
            </SidebarContainer>
          ) : (
            <div className="full-width">
              {!!description && (
                <p
                  className="text-gray-9 mb-medium"
                  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description) }}
                />
              )}
              <ExerciseStep
                kit={kitInstance.kit}
                step={sessionStep}
                is_session={true}
                saveOnChange={saveOnChange}
                stepDescription={description}
              />
            </div>
          )}
          <StepNavigation
            onNext={navigateNextHandler}
            onBack={navigateBackHandler}
            nextButtonProps={nextButtonProps}
            backButtonProps={backButtonProps}
            rightContent={navRightContent}
          />
        </StepContent>
      </Formik>
    </ExerciseContext.Provider>
  )
}

export default TakeExerciseStep
