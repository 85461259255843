import * as Sentry from "@sentry/browser"
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query"
import { addHours, isAfter, isValid, parseISO } from "date-fns"

import { auth } from "../../api"

import { signOutFirebaseUser } from "domains/Authentication/firebase"
import { featuresCacheKey } from "ui/hooks/useFeatures"

const login = async (values) => {
  const { data } = await auth.post("/login/", values)
  return data
}

const logout = async (values) => {
  const { data } = await auth.post("/logout/", values)
  return data
}

const forgotPassword = async (values) => {
  const { data } = await auth.post("/password/reset/", values)
  return data
}

const resetPassword = async (values) => {
  const { data } = await auth.post("/password/reset/confirm/", values)
  return data
}

const me = async () => {
  try {
    const { data } = await auth.get("/me/")
    try {
      window.heap.identify(data.id)
    } catch (exception) {}
    try {
      Sentry.configureScope(function (scope) {
        scope.setUser({
          id: data.id,
        })
      })
    } catch {}
    return data
  } catch (exception) {
    if (exception.response.status === 401) {
      return false
    }
    throw exception
  }
}

const useLogin = () => {
  const queryClient = useQueryClient()
  return useMutation(login, {
    onSuccess: (data) => {
      queryClient.setQueryData(authCacheKey, data)
      queryClient.removeQueries({ queryKey: featuresCacheKey, exact: true })
      window.heap.identify(data.id)
    },
  })
}

const useLogout = () => {
  const queryClient = useQueryClient()
  return useMutation(logout, {
    onSuccess: async () => {
      try {
        await signOutFirebaseUser()
      } catch (e) {
        Sentry.captureException(e)
      }
      queryClient.removeQueries({ queryKey: authCacheKey, exact: true })
      queryClient.removeQueries({ queryKey: featuresCacheKey, exact: true })
      queryClient.clear()
      window.heap.resetIdentity()
    },
  })
}

const useAuth = () =>
  useQuery(authCacheKey, me, {
    retry: 0,
    cacheTime: 5 * 60 * 1000,
    staleTime: 5 * 60 * 1000,
  })

const authCacheKey = ["auth"]

const getSSOProvider = (provider) => auth.get("/sso_provider/", { params: { provider } }).then(({ data }) => data)
const useSSOProvider = (provider) =>
  useQuery(["sso_provider", provider], () => getSSOProvider(provider), { enabled: !!provider })

// useAPIJSDate and checkForStaleJS are used in Routes.js
//
// useAPIJSDate
// 1. This represents the date that the JS was deployed.
// 2. On our first page load, we store the date in the react query cache for 30 minutes.
// 3. Every 30 minutes we mark the cached value as stale.
// 4. If the window/tab is in focus, we refetch the value, if not, we do the refetch the next time it is in focus.
//
// checkForStaleJS
// 1. checkForStaleJS is called whenever the value of useAPIJSDate changes (this happens whenever we do a release)
// 2. If the date is null, this function does nothing.
// 3. The first time there is a date, CLIENT_JS_DATE is set to that date.
// 4. If the date ever changes from CLIENT_JS_DATE we check if we should reload the page.
// 5. If forceRefresh is true, reload immediately
// 6. Otherwise, wait until it's been 12 hours since we loaded the app to reload.
//
// The reason we wait 12 hours is because we don't want to force a refresh right after you load the app
// since the initial time period is the most likely time for you to be in the middle of something.
//
// [WARNING] If making changes to this code, please review the scheme
// described above and ensure changes cannot result in a refresh loop.
const INITIALIZE_DATE = new Date()
let CLIENT_JS_DATE
const checkForStaleJS = (apiJSDateInfo) => {
  const parsedDate = apiJSDateInfo?.apiJSDate ? parseISO(apiJSDateInfo.apiJSDate) : null
  const apiJSDate = isValid(parsedDate) ? parsedDate : null

  if (!apiJSDate) {
    return
  }

  if (!CLIENT_JS_DATE) {
    CLIENT_JS_DATE = apiJSDate
    return
  }

  const isOldClientJS = isAfter(apiJSDate, CLIENT_JS_DATE)
  const initializedOver12HoursAgo = isAfter(new Date(), addHours(INITIALIZE_DATE, 12))

  if (isOldClientJS && (apiJSDateInfo.forceRefresh || initializedOver12HoursAgo)) {
    window.location.reload()
  }
}

const getAPIJSDateInfo = async () => {
  const { data } = await auth.get("/api_js_date/")
  return data
}

// [WARNING] If making changes to this code, please review the scheme
// described above and ensure changes cannot result in a refresh loop.
const useAPIJSDateInfo = () =>
  useQuery(["api_js_date"], getAPIJSDateInfo, {
    retry: 0,
    staleTime: 29 * 60 * 1000, // needs to be slightly before refetchInterval
    cacheTime: 29 * 60 * 1000, // needs to be slightly before refetchInterval
    refetchInterval: 30 * 60 * 1000, // check every 30 minutes
    refetchOnWindowFocus: true,
  })

export {
  checkForStaleJS,
  forgotPassword,
  resetPassword,
  useAPIJSDateInfo,
  useLogin,
  useLogout,
  useAuth,
  useSSOProvider,
  authCacheKey,
}
