import cn from "classnames"
import DOMPurify from "dompurify"

import { getExerciseAnswer } from "../results_utils"

import { View } from "ui"

const SelectFieldSpanText = ({
  className,
  identifier,
  exerciseComponent,
  exerciseInstance,
  tag = "h3",
  use_short_label = false,
}) => {
  const userAnswer = getExerciseAnswer(exerciseInstance, identifier)
  const choiceOptions = exerciseComponent.options
  const selectedChoice = choiceOptions.find(({ value }) => value === userAnswer)
  const answer = use_short_label ? selectedChoice?.short_label : selectedChoice?.label
  const Tag = tag

  if (!answer) {
    return null
  }

  return (
    <View className={cn(className, "mb-medium")} $alignItems="center" $width="fit-content">
      <Tag dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(answer) }} />
    </View>
  )
}

export default SelectFieldSpanText
