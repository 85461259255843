import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { keyBy, mapValues, orderBy } from "lodash-es"
import type { UserData, TeamData, SSOProviderData } from "types"

import api, { auth } from "api"
import { checkNamedArguments } from "utils/function"
import { buildUrl } from "utils/string"

function updateUser({ userId }: { userId: UserID | "me" }) {
  checkNamedArguments("updateUser", arguments, { required: { userId } })
  return async (values: Partial<UserData>): Promise<UserData> => {
    const { data } = await api.patch(`/users/${userId}/`, values)
    return data
  }
}

function useUpdateCurrentUser() {
  const queryClient = useQueryClient()
  return useMutation(updateUser({ userId: "me" }), {
    onSuccess: (data: UserData) => {
      queryClient.setQueryData(meUserCacheKey, data)
    },
  })
}

type CalloutArguments = { userId: UserID; calloutName: string; enabled?: boolean }
type CalloutStatusData = { view_callout_status: boolean }

// NOTE: Keep in sync with CalloutName enum in backend/risingteam/views/callout.py
enum CalloutName {
  MINIS_INFO_MODAL = "minis_info_modal",
  ARTI_INFO_MODAL = "arti_info_modal",
  ARTI_TOUR = "arti_tour",
}

function updateCalloutStatus({ userId, calloutName }: CalloutArguments) {
  return async ({ hasViewedCallout }: { hasViewedCallout: boolean }): Promise<CalloutStatusData> => {
    const { data } = await api.post("/callout/update_callout_status/", {
      userId,
      callout_name: calloutName,
      has_viewed_callout: hasViewedCallout,
    })
    return data
  }
}

function useUpdateCalloutStatus({ userId, calloutName }: CalloutArguments) {
  checkNamedArguments("useUpdateCalloutStatus", arguments, { required: { userId, calloutName } })
  const queryClient = useQueryClient()
  return useMutation(updateCalloutStatus({ userId, calloutName }), {
    onSuccess: (data: CalloutStatusData) => {
      queryClient.setQueryData([userId, calloutName], data)
    },
  })
}

function getCalloutStatus({ calloutName }: { calloutName: string }) {
  checkNamedArguments("getCalloutStatus", arguments, { required: { calloutName } })
  return async (): Promise<CalloutStatusData> => {
    const { data } = await api.get("/callout/get_callout_status/", {
      params: { callout_name: calloutName },
    })
    return data
  }
}

function useGetCalloutStatus({ userId, calloutName, enabled = true }: CalloutArguments) {
  checkNamedArguments("useGetCalloutStatus", arguments, { required: { userId, calloutName }, optional: { enabled } })
  return useQuery([userId, calloutName], getCalloutStatus({ calloutName }), { enabled: !!userId && enabled })
}

function getUserCacheKey({ userId }: { userId: UserID | "me" }): Array<string | number> {
  checkNamedArguments("getUserCacheKey", arguments, { required: { userId } })
  return ["users", userId]
}
const meUserCacheKey: Array<string | number> = getUserCacheKey({ userId: "me" })

function getUser({ userId }: { userId: UserID | "me" }) {
  checkNamedArguments("getUser", arguments, { required: { userId } })
  return async (): Promise<UserData> => {
    const { data } = await api.get(`/users/${userId}/`)
    return data
  }
}

function useUser({
  userId,
  cacheTime = 5 * 60 * 1000,
  staleTime = 5 * 60 * 1000,
}: {
  userId: UserID | "me"
  cacheTime?: number
  staleTime?: number
}) {
  checkNamedArguments("useUser", arguments, { required: { userId }, optional: { cacheTime, staleTime } })
  return useQuery(getUserCacheKey({ userId }), getUser({ userId }), { cacheTime, staleTime, enabled: !!userId })
}

async function getLoginOptions({ email }: { email: string }): Promise<SSOProviderData[]> {
  checkNamedArguments("getLoginOptions", arguments, { required: { email } })
  const { data } = await auth.get(buildUrl(["login"], { urlQueryParams: { email } }))
  return data
}

function addShortNameFieldToUsers({
  users,
  forceFullUserNames = false,
}: {
  users: UserData[]
  forceFullUserNames?: boolean
}): UserData[] {
  checkNamedArguments("addShortNameFieldToUsers", arguments, { required: { users }, optional: { forceFullUserNames } })
  const id2UserMap = keyBy(users, (u) => u.id)
  const id2PossibleNamesMap = mapValues(id2UserMap, (user) => {
    if (!user.first_name?.trim() || !user.last_name?.trim()) {
      return [user.email]
    }

    if (forceFullUserNames) {
      return [`${user.first_name} ${user.last_name}`, `${user.first_name} ${user.last_name} (${user.email})`]
    }

    return [
      user.first_name,
      `${user.first_name} ${user.last_name[0]}`,
      `${user.first_name} ${user.last_name}`,
      `${user.first_name} ${user.last_name[0]} (${user.email.split("@")[0]}@)`,
      `${user.first_name} ${user.last_name[0]} (${user.email})`,
    ]
  })

  const nameCountMap = Object.values(id2PossibleNamesMap)
    .flat()
    .reduce<Record<string, number>>((acc, name) => {
      const currentCount = acc[name] ?? 0
      return {
        ...acc,
        [name]: currentCount + 1,
      }
    }, {})

  const id2NameMap = mapValues(id2PossibleNamesMap, (possibleNames) =>
    possibleNames.find((name) => nameCountMap[name] === 1)
  )

  return users.map((user) => ({
    ...user,
    short_name: id2NameMap[user.id],
  }))
}

function addShortNameFieldToTeamMembers({ team }: { team: TeamData }): TeamData {
  checkNamedArguments("addShortNameFieldToTeamMembers", arguments, { required: { team } })
  return {
    ...team,
    members: addShortNameFieldToUsers({
      users: team.members ?? [],
      forceFullUserNames: !!team.jumbo,
    }),
  }
}

function sortUsersByShortName({ users }: { users: UserData[] }): UserData[] {
  checkNamedArguments("sortUsersByShortName", arguments, { required: { users } })
  return orderBy(users, (u) => u.short_name?.toLowerCase())
}

async function sendExerciseInvite({
  teamId,
  inviteeId,
  kitSlug,
}: {
  teamId: TeamID
  inviteeId: UserID
  kitSlug: string
}): Promise<void> {
  checkNamedArguments("sendExerciseInvite", arguments, { required: { teamId, inviteeId, kitSlug } })
  await api.post(`/manage_team/${teamId}/send_exercise_invite/`, { invitee_id: inviteeId, kit_slug: kitSlug })
}

export {
  CalloutName,
  useUpdateCurrentUser,
  useUpdateCalloutStatus,
  useGetCalloutStatus,
  useUser,
  getLoginOptions,
  addShortNameFieldToUsers,
  addShortNameFieldToTeamMembers,
  sortUsersByShortName,
  sendExerciseInvite,
  meUserCacheKey,
}
