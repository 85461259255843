import { useQueryParams } from "ui/hooks"

const useSyncSessionEnabled = () => {
  const queryParams = useQueryParams()
  const syncSession = queryParams["page-sync"] ?? "true"

  return syncSession === "true"
}

// disabled since we'll add more util exports in here; remove when there's more than one
// eslint-disable-next-line import/prefer-default-export
export { useSyncSessionEnabled }
