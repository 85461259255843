import { keyBy } from "lodash-es"

import { StepContent } from "../components"
import { useKitSession } from "../KitSessionContext"

import { useSessionExerciseInstances } from "domains/Exercise/resource"
import { mashupExercises, getTalentsData } from "domains/Exercise/results_utils/talents"
import { TalentsChart, TalentsGrid } from "domains/Exercise/ResultsComponents"
import { useHasTeamFeature } from "domains/Results/utils"
import { useKitParticipants } from "resources/monthly_kit"
import { TextContainer, Callout, Loading, BulletedList } from "ui"
import { SHARED_FLAGS } from "ui/hooks/useFeatures"

const TalentsOverviewStep = ({ className }) => {
  const { kitInstance, team } = useKitSession()
  const { enabled: sessionRealtimeUpdatesV2 } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_REPLACE_POLLING)
  const { data: participants } = useKitParticipants({ kitInstance, sessionRealtimeUpdates: sessionRealtimeUpdatesV2 })
  const { enabled: sessionRealtimeUpdates } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_ANSWER_UPDATE)

  const { data: exerciseInstances, isFetching } = useSessionExerciseInstances(kitInstance?.id, {
    sessionRealtimeUpdates,
    refetchInterval: 30000,
  })

  if (!participants || (isFetching && !exerciseInstances)) {
    return <Loading />
  }

  const participantIdMap = keyBy(participants, "id")

  return (
    <StepContent className={className}>
      <TextContainer maxWidth={860}>
        <p className="text-gray-9 mb-small">
          Take 10 minutes to review the team as a whole, using the discussion questions below as a guide. To focus on an
          individual, select their name from the key on the right.
        </p>
        <Callout title="Discuss" className="mb-xxxxl">
          <BulletedList>
            <li>What do you notice? Does anything stand out to you?</li>
            <li>How are we doing on the talents that are most important for our team to succeed?</li>
            <li>How might we leverage team members who have talents that others don’t?</li>
            <li>
              Do you see any significant gaps? If we think those gaps are important to cover, how might we do that?
            </li>
          </BulletedList>
        </Callout>
      </TextContainer>
      <TalentsChart
        userIdMap={participantIdMap}
        exerciseInstances={exerciseInstances}
        showLegend={true}
        className="mb-xl"
      />
      <TalentsCoverage participantIdMap={participantIdMap} exerciseInstances={exerciseInstances} className="mb-xl" />
      <TalentsGrid exerciseInstances={exerciseInstances} userIdMap={participantIdMap} className="mb-xl" />
    </StepContent>
  )
}

const TalentsCoverage = ({ participantIdMap, exerciseInstances, className }) => {
  if (!exerciseInstances?.length) {
    return null
  }

  const talentsData = getTalentsData(exerciseInstances[0])
  const talents = Object.keys(talentsData)
  const numTalents = talents.length
  const { numHighTalents } = mashupExercises(exerciseInstances, participantIdMap)

  return (
    <div className={className}>
      <h2 className="text-bold">Coverage</h2>
      <p>
        Together as a team, you have very high or high talent coverage across {numHighTalents} of the {numTalents}{" "}
        types.
      </p>
    </div>
  )
}

export default TalentsOverviewStep
