import SSOLogin from "./SSOLogin"

import { useQueryParams } from "ui/hooks"

const TestSSOLogin = ({ ssoRedirectLogin = false }) => {
  const { provider, providerName } = useQueryParams()
  return <SSOLogin provider={provider} providerName={providerName} ssoRedirectLogin={ssoRedirectLogin} />
}

export default TestSSOLogin
