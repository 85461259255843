import cn from "classnames"
import { useField } from "formik"
import { useCallback } from "react"
import Select, { components } from "react-select"
import { styled } from "styled-components"

import getIconOrError from "icons"
import { formatLength } from "ui/utils"

const AdvancedSelectField = ({
  className,
  options,
  saveOnChange,
  width, // eslint-disable-line @typescript-eslint/no-unused-vars
  menuWidth, // eslint-disable-line @typescript-eslint/no-unused-vars
  menuHeight, // eslint-disable-line @typescript-eslint/no-unused-vars
  icon = null, // eslint-disable-line @typescript-eslint/no-unused-vars
  formatOptionLabel = null,
  forwardRef = null,
  autoSelectSingleExactMatch = true,
  isMulti = false,
  overflowHidden = false,
  alignCenter = false,
  alignRight = false,
  hideCaret = false,
  disabled = false,
  fadeWhenDisabled = true, // eslint-disable-line @typescript-eslint/no-unused-vars
  ...props
}) => {
  const name = props.name
  const [{ value }, _, { setValue }] = useField(name)

  const handleChange = useCallback(
    (option) => {
      setValue(option.value)
      saveOnChange?.(name, option.value)
    },
    [name, saveOnChange, setValue]
  )

  const handleInput = useCallback(
    (inputFilterValue) => {
      if (autoSelectSingleExactMatch && !isMulti && inputFilterValue?.length) {
        // autoSelectSingleExactMatch setting doesn't work with multi-selects
        const [singleExactMatch, ...otherMatches] = options.filter(
          ({ label }) => label.toLowerCase().trim() === inputFilterValue.toLowerCase().trim()
        )
        if (singleExactMatch && !otherMatches?.length) {
          handleChange(singleExactMatch)
        }
      }
    },
    [autoSelectSingleExactMatch, isMulti, options, handleChange]
  )

  const selected = options.find((option) => option.value === value)

  if (options.some((option) => typeof option.label !== "string")) {
    throw new Error(
      "AdvancedSelectField.js: All option labels must be strings, otherwise search functionality will not work."
    )
  }

  // Set a default option label formatter function; callers may pass a custom
  // formatOptionLabel function in as a prop which will override this default.
  formatOptionLabel =
    formatOptionLabel ??
    (({ label, icon, iconClassName }) => {
      const Icon = icon ? getIconOrError(icon) : () => null
      return (
        <>
          {label}
          <Icon className={cn("ml-xxs", iconClassName)} />
        </>
      )
    })

  // We override React Select's IconControl component to use our own icon styling:
  const IconControl = ({ children, icon = null, ...props }) => (
    <components.Control {...props}>
      {!!icon && <span style={{ transform: "translateX(10px)" }}>{icon}</span>}
      {children}
    </components.Control>
  )

  // We override React Select's Option component to add e2e test ID/value attrs:
  // TODO(@evnp) Convert this file to TypeScript and use this typing for props:
  // const Option = (props: OptionProps<SearchDropdownOption, false>) => {
  const Option = (props) => (
    <components.Option {...props}>
      <span data-testid="advanced-select-option" data-testvalue={props.value} key={props.innerProps.key}>
        {formatOptionLabel(props.data)}
      </span>
    </components.Option>
  )

  // Because we override React Select's Option component, we also need to override
  // the SingleValue component in order to apply custom formatting/styling to
  // the "control" label (which is displayed in button above single-select menu):
  // TODO(@evnp) Convert this file to TypeScript and use this typing for props:
  // const SingleValue = (props: SingleValueProps<SearchDropdownSingleValue, false>) => {
  const SingleValue = (props) => (
    <components.SingleValue {...props}>
      {props.data.formattedSelectedLabel ?? formatOptionLabel(props.data)}
    </components.SingleValue>
  )

  // Because we override React Select's Option component, we also need to override
  // the MultiValue component in order to apply custom formatting/styling to
  // the "control" label (which is displayed in button above multi-select menu):
  // TODO(@evnp) Convert this file to TypeScript and use this typing for props:
  // const MultiValue = (props: MultiValueProps<SearchDropdownMultiValue, false>) => {
  const MultiValue = (props) => (
    <components.MultiValue {...props}>
      {props.data.formattedSelectedLabel ?? formatOptionLabel(props.data)}
    </components.MultiValue>
  )

  return (
    <Select
      className={cn(className, {
        "advanced-select--overflow-hidden": overflowHidden,
        "advanced-select--align-center": alignCenter,
        "advanced-select--align-right": alignRight,
        "advanced-select--hide-caret": hideCaret,
      })}
      classNamePrefix="advanced-select"
      name={name}
      key={selected?.value}
      // key necessary to ensure select always updates when form values change
      // and for autoSelectSingleExactMatch behavior to work properly
      ref={forwardRef}
      defaultValue={selected}
      options={options}
      onChange={handleChange}
      onInputChange={handleInput}
      isMulti={isMulti}
      isDisabled={!!disabled}
      openMenuOnFocus // this leads to better keyboard-nav and makes testing easier
      {...props}
      components={{
        Option,
        SingleValue,
        MultiValue,
        IndicatorSeparator: () => null,
        ...(icon ? { Control: (props) => <IconControl icon={icon} {...props} /> } : {}),
        ...(props.components ?? {}),
      }}
    />
  )
}

export default styled(AdvancedSelectField)`
  .advanced-select__control {
    border: 0;
    border-radius: ${({ borderRadius }) => borderRadius ?? "var(--border-radius)"};
    background-color: var(--fg);
    box-shadow: var(--blur-4);
    cursor: pointer;
    height: 44px;

    &:focus-visible,
    &:hover {
      box-shadow: var(--lift-4);
    }
  }

  .advanced-select__placeholder {
    line-height: 0;
  }
  .advanced-select__single-value,
  .advanced-select__input-container {
    color: var(--subtitle);
    line-height: 1rem;
    padding: 0;
    overflow: visible;
  }

  .advanced-select__value-container {
    padding: 12px 0 12px 12px;
  }

  .advanced-select__control {
    width: max-content;
    ${({ width = null }) => formatLength("width", width)}
  }

  .advanced-select__menu {
    margin: 0;
    z-index: var(--z-menu);
    width: max-content;
    ${({ width = null }) => formatLength("min-width", width)}
    ${({ menuWidth = null }) => formatLength("width", menuWidth)}
    ${({ menuHeight = null }) => formatLength("height", menuHeight)}

    // Add padding to bottom of select menu so that if it would hit the bottom of page
    // or the bottom of a modal, a gap is shown to avoid the menu looking cut-off:
    padding-bottom: var(--spacing-4);
    background-color: transparent; // move these styles to the inner menu-list element below
    box-shadow: none; // so they don't wrap around the padding added above
  }
  .advanced-select__menu-list {
    ${({ menuHeight = null }) => formatLength("height", menuHeight)}

    // These styles are taken from react-select's default styling for the menu element:
    background-color: hsl(0, 0%, 100%);
    box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
  }

  .advanced-select__option {
    cursor: pointer;
    max-width: 100%;
  }

  .advanced-select__control--menu-is-open {
    border-radius: ${({ borderRadiusOpen }) => borderRadiusOpen ?? "var(--border-radius) var(--border-radius) 0 0"};
    & + .advanced-select__menu .advanced-select__menu-list {
      border-radius: 0 0 var(--border-radius) var(--border-radius);
    }
  }

  &.advanced-select--is-disabled {
    ${({ fadeWhenDisabled = true }) => (fadeWhenDisabled ? "opacity: 0.5;" : "")};
  }

  &.advanced-select--overflow-hidden {
    .advanced-select__menu {
      max-width: 100%;
    }
    .advanced-select__option {
      max-width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  &.advanced-select--align-center {
    .advanced-select__control {
      text-align: center;
    }
  }

  &.advanced-select--align-right {
    .advanced-select__control {
      text-align: right;
    }
  }

  &.advanced-select--hide-caret {
    .advanced-select__control {
      padding-right: 1rem;
    }
    .advanced-select__indicators {
      display: none;
    }
  }
`
