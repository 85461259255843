import cn from "classnames"
import { styled } from "styled-components"

import theme from "ui/theme"
import View from "ui/View"

const BadgeCallout = ({ badgeContent = null, children, className }) => (
  <View $alignItems="stretch" $justifyContent="center" className={cn("border-radius text-align-center", className)}>
    {!!badgeContent && (
      <View
        $alignItems="center"
        $justifyContent="center"
        className="badge-callout-badge bg-orange-2 px-xs py-xxs flex-basis-0"
      >
        <h3 className="text-fg text-center">{badgeContent}</h3>
      </View>
    )}
    <View $alignItems="center" className="fg px-xs py-xxs">
      {children}
    </View>
  </View>
)

export default styled(BadgeCallout)`
  border: 2px solid var(--orange-2);
  min-height: 44px;

  /* Force non-widescreen size */
  font-size: ${theme.normalFontSize};
  line-height: ${theme.normalLineHeight};

  .badge-callout-badge {
    min-width: 56px;

    h3 {
      /* Force non-widescreen size */
      font-size: 1.25rem; // ~ 20px
      line-height: 2rem;
    }
  }
`
