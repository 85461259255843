import { StepContent, SidebarContainer, SessionSelectedUsersShareView, FacilitatorTip } from "../components"
import { useKitSession } from "../KitSessionContext"

import { useSessionExerciseInstances } from "domains/Exercise/resource"
import { getUserExerciseInstance } from "domains/Exercise/results_utils"
import { getChartConfig } from "domains/Exercise/results_utils/talents"
import { TalentsUserResults } from "domains/Exercise/ResultsComponents"
import { useHasTeamFeature } from "domains/Results/utils"
import { TextContainer, Loading, Callout, RadarChart, BulletedList } from "ui"
import { SHARED_FLAGS } from "ui/hooks/useFeatures"
import { useSelectedUser } from "ui/SelectedUserContext"

const TalentsShareStep = ({ sessionStep, className }) => {
  const { kitInstance, team } = useKitSession()
  const { enabled: sessionRealtimeUpdates } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_ANSWER_UPDATE)

  const { data: exerciseInstances, isFetching } = useSessionExerciseInstances(kitInstance?.id, {
    sessionRealtimeUpdates,
    refetchInterval: 30000,
  })

  if (isFetching && !exerciseInstances) {
    return <Loading />
  }

  return (
    <StepContent className={className}>
      <SidebarContainer>
        <TextContainer maxWidth={860} className="mr-xl">
          <p className="text-gray-9 mb-large">
            Now let's take a few minutes to each share our results with each other.
          </p>
          <Callout title="Discuss" className="text-gray-9 mb-xl">
            <p className="mb-small">Here are some questions to think about as you share your results:</p>
            <BulletedList>
              <li>Do these results feel generally accurate to you? Why or why not?</li>
              <li>Share an example when you were using one of your talents at work.</li>
              <li>Any talents you’d like to use more than you currently do? What might that look like?</li>
            </BulletedList>
          </Callout>
        </TextContainer>
        <FacilitatorTip
          className="mb-xl sidebar-right"
          tip="Use the dropdown to choose someone to present and then start the timer. The timer will restart automatically when you choose the next person."
        />
      </SidebarContainer>
      <SessionSelectedUsersShareView
        sessionStep={sessionStep}
        kitInstance={kitInstance}
        minMinutesPerUser={3}
        maxMinutesPerUser={5}
      >
        <UserResults exerciseInstances={exerciseInstances} />
      </SessionSelectedUsersShareView>
    </StepContent>
  )
}

const UserResults = ({ exerciseInstances }) => {
  const { selectedUser } = useSelectedUser()
  const participantIdMap = { [selectedUser.id]: selectedUser }
  const selectedExercise = getUserExerciseInstance(exerciseInstances, selectedUser)

  if (!selectedExercise) {
    return null
  }

  const chartConfig = getChartConfig([selectedExercise], participantIdMap)

  return (
    <>
      <RadarChart {...chartConfig} />
      <h2 className="text-bold text-gray-9 mt-xl mb-medium">Top talents</h2>
      <p className="text-gray-9 mb-large">
        These areas are more likely to come easily to you and bring you joy. Try to spend as much of your time here as
        you can.
      </p>
      <TalentsUserResults exercise={selectedExercise} accordion className="mb-xl" />
    </>
  )
}

export default TalentsShareStep
