import { styled } from "styled-components"

import { CopyIcon } from "icons/FontAwesomeIcons"
import { getAppCodeURL } from "resources/monthly_kit"
import { CopyButton } from "ui"
import { buildUrl } from "utils/string"

const SessionCodeCopyLink = ({ className, kitInstance, fullUrl }) => {
  const codeUrl = buildUrl([getAppCodeURL(), kitInstance.session_code], {
    useTrailingSlash: false,
  })
  return (
    <div className={className}>
      {!!kitInstance && !!fullUrl ? (
        <SessionCodeFullUrlCopyLink codeUrl={codeUrl} />
      ) : (
        <SessionCodeCopyButton codeUrl={codeUrl} />
      )}
    </div>
  )
}

const SessionCodeFullUrlCopyLink = ({ codeUrl }) => (
  <CopyButton buttonClassName="link-semi-bold text-button copy-button-link" clipboardText={codeUrl}>
    <div className="text-large-mobile-never text-small-mobile-only">{codeUrl}</div>
    <div>
      <CopyIcon color="var(--rising-blue)" className="mr-xs fa-lg mt-xs" /> Copy
    </div>
  </CopyButton>
)

const SessionCodeCopyButton = ({ codeUrl }) => (
  <CopyButton buttonClassName="tertiary" clipboardText={codeUrl}>
    <div className="copy-button">
      <CopyIcon className="mr-xs fa-lg fa-regular" /> Copy
    </div>
  </CopyButton>
)

export default styled(SessionCodeCopyLink)`
  .copy-button-link {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .copy-button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
