import cn from "classnames"
import { Formik } from "formik"

import { ExerciseComponent } from "../ExerciseComponents"

import ExerciseForm from "domains/Exercise/ExerciseForm"
import { getExerciseAnswer, getExerciseComponent } from "domains/Exercise/results_utils"
import useExerciseForm from "domains/Exercise/useExerciseForm"
import { TextContainer, Loading } from "ui"
import { AnswerDisplayContentWithIcon } from "ui/AnswerDisplayContent"

const CareerHorizonsChartingPathRequirementsEdit = ({ kitInstance, exerciseInstance, className }) => {
  const exercise = kitInstance.kit.exercise
  const path_field_id = getExerciseAnswer(exerciseInstance, "path_to_share")?.split(".")[0]
  const { initialValues, isFetching, onSubmit, saveOnChange } = useExerciseForm({
    teamId: kitInstance.team_id,
    slug: exercise.slug,
    version: exercise.version,
  })
  const path_requirements_component = getExerciseComponent(exerciseInstance, `${path_field_id}_requirements`)

  if (isFetching) {
    return <Loading />
  }

  if (!path_requirements_component) {
    return null
  }

  return (
    <div className={cn("text-gray-9", className)}>
      <TextContainer>
        {!!path_requirements_component && (
          <Formik initialValues={initialValues} onSubmit={onSubmit}>
            <ExerciseForm>
              <ExerciseComponent
                {...path_requirements_component}
                key={path_requirements_component.identifier}
                kit={kitInstance.kit}
                saveOnChange={saveOnChange}
                className="mb-large"
              />
            </ExerciseForm>
          </Formik>
        )}
      </TextContainer>
    </div>
  )
}

const CareerHorizonsChartingPathRequirementsReadOnly = ({ exercise, className }) => {
  const path_field_id = getExerciseAnswer(exercise, "path_to_share")?.split(".")[0]
  const path_requirements = getExerciseAnswer(exercise, `${path_field_id}_requirements`)

  if (!path_requirements) {
    return null
  }

  const path_requirements_component = getExerciseComponent(exercise, `${path_field_id}_requirements`)
  const icons = path_requirements_component.components.find(({ identifier }) => identifier === "status").options
  const answers = path_requirements.filter((answer) => answer.requirement.value !== "")

  return (
    <div className={cn("text-gray-9", className)}>
      <TextContainer>
        <p className="text-field-label">Requirements</p>
        {answers?.length > 0 ? (
          <>
            <div className="answer-list">
              {answers.map(({ requirement, status }, idx) => {
                const iconConfig = icons.find(({ value }) => value === status?.value)
                return <AnswerDisplayContentWithIcon key={idx} answer={requirement.value} iconConfig={iconConfig} />
              })}
            </div>
          </>
        ) : (
          <div className="text-gray-9 mb-medium">
            <p>No results to display</p>
          </div>
        )}
      </TextContainer>
    </div>
  )
}

export { CareerHorizonsChartingPathRequirementsEdit, CareerHorizonsChartingPathRequirementsReadOnly }
