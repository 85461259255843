import cn from "classnames"
import { Link, useNavigate } from "react-router-dom"
import { styled } from "styled-components"

import { useSteps } from "components/Steps/StepsContext"
import { ArrowLeftIcon, ArrowRightIcon } from "icons/FontAwesomeIcons"
import { View, Button } from "ui"
import { useKeyDown } from "ui/hooks"

const SetupNavigation = styled(function SetupNavigation({ className, steps = null }) {
  const navigate = useNavigate()
  const { previous, next } = useSteps()

  const numIncompleteSteps = steps?.filter(({ completed }) => !completed).length ?? 0
  const complete = numIncompleteSteps ? "/setup/incomplete" : "/setup/complete"

  // Navigate to next step when CMD + Right Arrow are pressed:
  useKeyDown(
    {
      key: "ArrowRight",
      meta: true,
      skipIfTextFieldFocused: true,
      // if a text element is focused, navigation away might cause unsaved content loss
    },
    () => navigate(next ?? complete)
  )

  return (
    <View className={cn(className, "mt-xxxl-mobile-never mb-small")}>
      {!!previous && (
        <Link to={previous} className="back-button no-underline mr-small">
          <Button className="secondary">
            <ArrowLeftIcon />
            <span> Back</span>
          </Button>
        </Link>
      )}
      <Link to={next ?? complete} className="no-underline">
        <Button>
          Next
          <ArrowRightIcon />
        </Button>
      </Link>
    </View>
  )
})`
  @media not all and (max-width: ${({ theme }) => theme.mobileLandscapeMax}) {
    .back-button {
      position: absolute;
      transform: translateX(calc(-100% - var(--spacing-2)));
    }
  }
`

export default SetupNavigation
