import { find } from "lodash-es"
import { styled } from "styled-components"

import { SidebarContainer, StepContent, FacilitatorTip } from "../components"
import { useKitSession } from "../KitSessionContext"

import { useSessionExerciseInstances } from "domains/Exercise/resource"
import { getUserExerciseInstance, getExerciseAnswer, getTeamLead } from "domains/Exercise/results_utils"
import { RESPONDER_TYPES_DATA } from "domains/Exercise/results_utils/crisis_response"
import { useHasTeamFeature } from "domains/Results/utils"
import { InfoCircleIcon } from "icons/FontAwesomeIcons"
import { useKitParticipants } from "resources/monthly_kit"
import { View, Loading, Callout, BulletedList, Tooltip, TextContainer, AnswerDisplayContent } from "ui"
import { SHARED_FLAGS } from "ui/hooks/useFeatures"

const RoleTooltip = styled(function RoleTooltip({ role, className, children }) {
  const responderType = RESPONDER_TYPES_DATA[role]
  return (
    <div className={className}>
      <Tooltip
        float
        className={className}
        type="light"
        title={
          <div className="responder-type-box">
            <h3 className="text-gray-9 mb-xs">{responderType.title}</h3>
            <p className="responder-role text-italic text-gray-8 text-semi-bold mb-xs">{responderType.role}</p>
            <p className="text-normal text-gray-8 mb-xs text-wrap">{responderType.description}</p>
          </div>
        }
      >
        {children}
      </Tooltip>
    </div>
  )
})`
  .responder-type-box {
    .responder-role {
      font-size: 1.0625rem;
    }

    .point-bar {
      width: 100px;
      height: 16px;
    }
  }

  .tooltip {
    + .tooltip-content {
      opacity: 1;
      text-align: left;
      white-space: normal;
      box-shadow: 0 1px 2px rgb(0 0 0 / 20%);
      padding: var(--spacing-4) var(--spacing-3);
      border: 1px solid var(--gray-4);
      border-radius: var(--border-radius);
      background-color: var(--fg);

      @media (min-width: ${({ theme }) => theme.tabletMin}) {
        max-width: 448px;
      }
    }
  }
`

const CrisisResponsePracticeResponse = ({ participants, exerciseInstances, team }) => {
  const teamLead = getTeamLead(participants, team)
  const exercise = getUserExerciseInstance(exerciseInstances, teamLead)
  const team_responder_assign = getExerciseAnswer(exercise, "team_responder_assign") ?? {}
  const responder_assign_users = {}

  for (const responder of Object.keys(RESPONDER_TYPES_DATA)) {
    const responder_users = []
    for (const [user_id, responders] of Object.entries(team_responder_assign)) {
      if (responders.includes(responder)) {
        responder_users.push(find(participants, { id: parseInt(user_id) }))
      }
    }
    responder_assign_users[responder] = responder_users.filter(Boolean)
  }

  return (
    <>
      {Object.entries(RESPONDER_TYPES_DATA).map(([responder]) => {
        const responder_users = responder_assign_users[responder]
        if (!responder_users || responder_users.length === 0) {
          return null
        }
        return (
          <div key={responder} className="mb-large">
            <View className="mb-xs" $alignItems="center">
              <h3 className="text-bold text-gray-9 text-nowrap mr-xs">{responder}</h3>
              <RoleTooltip role={responder}>
                <InfoCircleIcon className="text-rising-blue" />
              </RoleTooltip>
            </View>
            {responder_users.map((user) => {
              const exerciseInstance = getUserExerciseInstance(exerciseInstances, user)
              const exerciseAnswer =
                exerciseInstance &&
                getExerciseAnswer(exerciseInstance, `own_${responder.toLowerCase().trim().split(/\s+/).join("_")}`)
              return (
                <div key={user.id}>
                  <p className="text-gray-8 mb-small">{RESPONDER_TYPES_DATA[responder].role}</p>
                  <p className="text-field-label text-gray-9 mb-xs">{user.name}:</p>
                  <AnswerDisplayContent className="mb-medium" answer={exerciseAnswer} />
                </div>
              )
            })}
          </div>
        )
      })}
    </>
  )
}

const CrisisResponseScenarioResultStep = ({ className }) => {
  const { kitInstance, team } = useKitSession()
  const { enabled: sessionRealtimeUpdatesV2 } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_REPLACE_POLLING)
  const { data: participants } = useKitParticipants({ kitInstance, sessionRealtimeUpdates: sessionRealtimeUpdatesV2 })
  const { enabled: sessionRealtimeUpdates } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_ANSWER_UPDATE)

  const { data: exerciseInstances, isFetching } = useSessionExerciseInstances(kitInstance?.id, {
    sessionRealtimeUpdates,
    refetchInterval: 30000,
  })

  if (!participants || (isFetching && !exerciseInstances)) {
    return <Loading />
  }

  const teamLead = getTeamLead(participants, team)
  const exercise = getUserExerciseInstance(exerciseInstances, teamLead)
  const team_scenario = getExerciseAnswer(exercise, "team_scenario")

  return (
    <StepContent className={className}>
      <SidebarContainer>
        <TextContainer>
          <h2 className="text-bold text-gray-9 mb-medium">Group discussion</h2>
          <p className="mb-large">Below is a summary of everyone’s responses. How did it go?</p>
          <Callout title="Discuss" className="text-gray-9 mb-large">
            <BulletedList>
              <li>
                Was the work distributed evenly enough so that everything could be completed without too much burden on
                any one person? Did your team have enough coverage?
              </li>
              <li>How would you know if you were successful?</li>
              <li>Did this exercise reveal any surprises?</li>
            </BulletedList>
          </Callout>

          <h3 className="text-bold text-gray-9 mb-xs">Your team scenario</h3>
          <AnswerDisplayContent className="mb-large" answer={team_scenario} />
          <h2 className="text-bold text-gray-9 mb-medium">The responses</h2>
          <CrisisResponsePracticeResponse
            participants={participants}
            exerciseInstances={exerciseInstances}
            team={team}
          />
        </TextContainer>
        <FacilitatorTip className="sidebar-right" icon="share-screen" />
      </SidebarContainer>
    </StepContent>
  )
}

export default CrisisResponseScenarioResultStep

export { RoleTooltip, CrisisResponsePracticeResponse }
