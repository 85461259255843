import { styled } from "styled-components"

import { getRatingColorFunc } from "../ExerciseComponents/ExercisePercentageGroupRatingField"
import { getExerciseAnswer, getExerciseComponent } from "../results_utils"

import renderBlocks from "components/Blocks/renderBlocks"
import { blockIcons } from "icons"
import { PercentagesChart } from "ui"

const PercentageGroupRatingFieldDonutChart = styled(function PercentageGroupRatingFieldDonutChart({
  identifier,
  percentageGroupId,
  exerciseInstance,
}) {
  if (!exerciseInstance) {
    return null
  }

  const percentageGroupComponent = getExerciseComponent(exerciseInstance, percentageGroupId)
  const percentages = getExerciseAnswer(exerciseInstance, percentageGroupId)
  const percentageIdentifiers = percentages ? Object.keys(percentages) : []
  for (const percentageIdentifier of percentageIdentifiers) {
    const exercise_item = percentageGroupComponent.items.find((item) => item.identifier === percentageIdentifier)
    if (exercise_item) {
      percentages[percentageIdentifier].tooltip = (
        <>
          {renderBlocks(exercise_item.sentence, blockIcons)} <b>({percentages[percentageIdentifier].percentage}%)</b>
        </>
      )
    }
  }

  const ratingsValues = getExerciseAnswer(exerciseInstance, identifier)

  return (
    <PercentagesChart
      className="percentages-chart mb-xl"
      data={percentages}
      colors={getRatingColorFunc(percentageIdentifiers, ratingsValues, percentageGroupComponent.percentage_colors)}
      arcLabelsTextColor="white"
    />
  )
})`
  @media (max-width: ${({ theme }) => theme.mobileMax}) {
    .percentages-chart {
      svg > g > g:nth-child(3) {
        display: none;
      }
    }
  }
`

export default PercentageGroupRatingFieldDonutChart
