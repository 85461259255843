import { useExerciseDefinition, useExerciseInstance } from "domains/Exercise/resource"
import AggregatedResultsBlocks from "domains/Exercise/ResultsBlocks/AggregatedResultsBlocks"
import ResultsBlocks from "domains/Exercise/ResultsBlocks/ResultsBlocks"
import getIconOrError from "icons"
import { getUseGetAggregatedResultsDataForTeamFunction } from "resources/teams"
import { sortUsersByShortName, useUser } from "resources/users"
import { Loading, View } from "ui"
import { useQueryParams } from "ui/hooks"
import SelectedUserContext from "ui/SelectedUserContext"

const JumboBlockResultsReview = ({ kit, resultsPage, selectedTeam }) => {
  const { data: user, isFetching: userIsFetching } = useUser({ userId: "me" })
  const query = useQueryParams()

  if (userIsFetching) {
    return <Loading />
  }

  const users = selectedTeam.members
  const sortedUsers = sortUsersByShortName({ users })
  const queryUserId = parseInt(query.participant)
  const showIndividualResults = user.id === queryUserId

  const Icon = resultsPage.icon && getIconOrError(resultsPage.icon)

  return (
    <>
      <View $alignItems="flex-start" $flexWrap="wrap" className="mb-medium">
        <Icon className="title-icon mr-medium" color={`var(--${resultsPage.icon_color})`} />
        <h1 className="text-gray-9">{resultsPage.title}</h1>
      </View>
      <p className="text-gray-9 mb-large">{resultsPage.description}</p>
      {showIndividualResults ? (
        <UserResultsView
          selectedTeam={selectedTeam}
          exerciseSlug={kit.exercise.slug}
          user={user}
          sortedUsers={sortedUsers}
        />
      ) : (
        <ResultsReview selectedTeam={selectedTeam} kit={kit} sortedUsers={sortedUsers} />
      )}
    </>
  )
}

const ResultsReview = ({ selectedTeam, kit, sortedUsers }) => {
  const useAggregatedResultsData = getUseGetAggregatedResultsDataForTeamFunction({
    teamId: selectedTeam.id,
    kitSlug: kit.slug,
    apiResource: "teams",
  })
  const { data: exerciseDefinition, isFetching } = useExerciseDefinition({
    teamId: selectedTeam.id,
    slug: kit.exercise.slug,
    version: kit.exercise.version,
  })

  if (isFetching) {
    return <Loading />
  }

  if (!exerciseDefinition.team_overview_aggregated_blocks) {
    return null
  }

  return (
    <AggregatedResultsBlocks
      blocks={exerciseDefinition?.team_overview_aggregated_blocks}
      useAggregatedResultsData={useAggregatedResultsData}
      sortedUsers={sortedUsers}
    />
  )
}

const UserResultsView = ({ selectedTeam, exerciseSlug, user, sortedUsers }) => {
  const { data: exerciseInstance } = useExerciseInstance({ teamId: selectedTeam.id, slug: exerciseSlug })

  if (!exerciseInstance) {
    return null
  }

  return (
    <SelectedUserContext.Provider value={{ selectedUser: user }}>
      <ResultsBlocks
        blocks={exerciseInstance.definition?.user_results_blocks}
        exerciseInstances={[exerciseInstance]}
        sortedUsers={sortedUsers}
      />
    </SelectedUserContext.Provider>
  )
}

export default JumboBlockResultsReview
