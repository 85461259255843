import cn from "classnames"
import { last } from "lodash-es"
import { useState } from "react"
import { styled } from "styled-components"

import { ProgressBar } from "components"
import {
  MAX_POINTS_PER_BUCKET,
  processRespondersData,
  RESPONDER_TYPES_DATA,
} from "domains/Exercise/results_utils/crisis_response"
import { View, Button } from "ui"

const TOP_COUNT = 3

const CrisisResponseUserResponderTypeResults = ({ exercise, className }) => {
  const [viewMore, setViewMore] = useState(false)
  const maxPoints = last(MAX_POINTS_PER_BUCKET)

  const { responderPoints, sortedResponderTypes } = processRespondersData(exercise)
  const visibleResponderTypes = viewMore ? sortedResponderTypes : sortedResponderTypes.slice(0, TOP_COUNT)

  return (
    <div className={cn("text-gray-9", className)}>
      {visibleResponderTypes.map((responderType, index) => {
        const points = responderPoints[responderType]
        const responderTypeData = RESPONDER_TYPES_DATA[responderType]
        return (
          <View key={responderType} className="mb-large">
            <h2 className="text-rising-orange mr-large">{index + 1}.</h2>
            <div>
              <View $alignItems="center">
                <h4 className="text-gray-9 text-bold mr-medium">{responderType}</h4>
                <ProgressBar className="point-bar" max={maxPoints} value={points} />
              </View>
              <p className="text-bold text-normal text-gray-8">{responderTypeData.role}</p>
              <p className="text-normal text-gray-9">{responderTypeData.description}</p>
            </div>
          </View>
        )
      })}
      <Button className="ml-xl link text-button" onClick={() => setViewMore(!viewMore)}>
        {!viewMore ? "View more >" : "Show less"}
      </Button>
    </div>
  )
}

export default styled(CrisisResponseUserResponderTypeResults)`
  .point-bar {
    width: 100px;
    height: 16px;
  }
`
