import { styled } from "styled-components"

import { View, Button } from "ui"

const ASSET_URL_BASE = "https://static.risingteam.com/kit_assets"

const InstructionsLink = ({ url, path, children }) => (
  <a href={url ?? ASSET_URL_BASE + path} target="_blank" rel="noopener noreferrer">
    {children}
  </a>
)

const OrigamiExercises = styled(function OrigamiExercises({ className, kit }) {
  return (
    <div className={className}>
      <div className="description-wrapper">
        <div>
          {kit.bonus_page.bonus_desc.map((description, index) => (
            <div className="mb-xs" key={index}>
              {description.paragraph_text}
            </div>
          ))}
        </div>
      </div>
      <h2 className="text-gray-9 my-medium">Origami exercises</h2>
      <p className="mb-medium">
        Each of the exercises below models a different learning approach. Give them a try and reflect on which was the
        easiest for you to complete. Why do you think that was? Did the instructions better match your learning
        preferences? Or, was the format of the instructions a better match for the task?
      </p>
      <p className="mb-medium">
        During the session we learned about individual learning preferences. Also true is that certain tasks lend
        themselves to different modalities. For example, it might be hard to learn how to paint through a podcast. Or
        how to sing by reading a book.
      </p>
      <View className="origami-templates-grid">
        {kit.bonus_page.origami_templates.map(
          ({ title, type, image_asset_path, instructions_asset_path, video_link }) => (
            <View key={title} $flexDirection="column">
              <h4 className="text-gray-9 mb-xs">{title}</h4>
              {type === "template-image" && (
                <InstructionsLink path={instructions_asset_path}>
                  <img
                    alt={title}
                    src={ASSET_URL_BASE + image_asset_path}
                    className="border-radius mr-large mb-large template-image"
                  />
                </InstructionsLink>
              )}
              {type === "template-image-video" && (
                <InstructionsLink url={video_link}>
                  <img
                    alt={title}
                    src={ASSET_URL_BASE + image_asset_path}
                    className="border-radius mr-large mb-large template-image-video"
                  />
                </InstructionsLink>
              )}
            </View>
          )
        )}
      </View>
      <InstructionsLink path={kit.bonus_page.origami_templates_download_slug}>
        <Button className="tertiary">Download them all</Button>
      </InstructionsLink>
    </div>
  )
})`
  .template-image {
    width: 160px;
    height: 160px;
  }

  .template-image-video {
    width: 237px;
    height: 160px;
  }

  .origami-templates-grid {
    display: grid;
    grid-template-columns: auto auto;

    @media (max-width: ${({ theme }) => theme.mobileMax}) {
      grid-template-columns: repeat(auto-fill, minmax(237px, 1fr));
    }
  }
`

export default OrigamiExercises
