import { Formik, Form } from "formik"

import { TextContainer } from "ui"

const AssessmentAccuracyForm = ({ title, description, initialValues, validationSchema, children, className }) => (
  <TextContainer className={className}>
    <h3 className="text-bold text-gray-9 mb-xs">{title}</h3>
    {!!description && (
      <p className="text-gray-8">
        If your results feel off in any way, please note that here! The purpose of this exercise is to help you identify
        the activities that feel most like your natural talents. It's not to put you in a box or label you.
      </p>
    )}
    <Formik initialValues={initialValues} validationSchema={validationSchema}>
      <Form>{children}</Form>
    </Formik>
  </TextContainer>
)

export default AssessmentAccuracyForm
