import "react-datepicker/dist/react-datepicker.css"

import cn from "classnames"
import { formatISO, parseISO } from "date-fns"
import { useField } from "formik"
import { isEmpty, uniqueId } from "lodash-es"
import { forwardRef } from "react"
import DatePicker from "react-datepicker"
import { styled } from "styled-components"

import { View } from "../../ui"

import InputField from "./InputField"

import { CalendarSolidIcon } from "icons/FontAwesomeIcons"
import { useEffectAfterFirstRender } from "ui/hooks"

const InputWithIcon = styled(
  forwardRef(({ value, onClick, placeholder, onChange, className, ...props }, ref) => (
    <View $alignItems="center" className={cn("text-default", className)} $width="auto">
      <CalendarSolidIcon width="24px" height="24px" className="mr-xs fa-lg" color="var(--rising-blue)" />
      <input
        ref={ref}
        className="pl-small full-width"
        value={value}
        onClick={onClick}
        onChange={onChange}
        placeholder={placeholder}
        {...props}
      />
    </View>
  ))
)`
  border-radius: var(--border-radius);
  padding: var(--spacing-1) var(--spacing-2);
  box-shadow: var(--blur-4);

  input {
    outline: none;
    border: none;
    padding-top: 4px;
    padding-bottom: 4px;
  }

  &:focus-visible,
  &:hover {
    box-shadow: var(--lift-6);
  }

  &:focus-within {
    border-color: var(--border-color-dark);
  }
`

const DatePickerField = ({
  name,
  value,
  onBlur,
  disabled,
  className,
  showTimeSelect = false,
  dateFormat = "MM/dd/yyyy",
  placeholder = "Set date",
  ...props
}) => {
  const onChange = (date) => {
    const value = date && formatISO(date)
    props.onChange({
      target: {
        name,
        value,
      },
    })
  }

  const filterTime = (time) => {
    const currentDate = new Date()
    const selectedDate = new Date(time)

    return currentDate.getTime() < selectedDate.getTime()
  }

  const id = uniqueId("date-picker-")
  const minDate = new Date()
  const selected = !isEmpty(value) ? parseISO(value) : null
  return (
    <DatePicker
      id={id}
      selected={selected}
      disabled={disabled}
      minDate={minDate}
      onChange={onChange}
      onClickOutside={onBlur} // ios doesn't respect onBlur for DatePicker
      showPopperArrow={false}
      placeholderText={placeholder}
      customInput={<InputWithIcon role="combobox" aria-controls={id} />}
      showTimeSelect={showTimeSelect}
      dateFormat={dateFormat}
      wrapperClassName={className}
      filterTime={filterTime}
    />
  )
}

const DateField = ({ saveOnChange, className, defaultValue, ...props }) => {
  const [field, _meta, { setValue }] = useField(props.name)
  const onBlur = () => {
    saveOnChange && saveOnChange(field.name, field.value)
  }

  useEffectAfterFirstRender(() => {
    if (defaultValue && !field.value) {
      setValue(defaultValue)
    }
  })

  return <InputField forwardedAs={DatePickerField} onBlur={onBlur} className={className} {...props} />
}

export default DateField
