import cn from "classnames"

import { getExerciseAnswer } from "domains/Exercise/results_utils"
import { BulletedList, TextContainer, AnswerDisplayContent } from "ui"

const LearnerAgilityBehaviorsUserResults = ({ exercise, className }) => {
  const comfortable_situation = getExerciseAnswer(exercise, "comfortable_situation")
  const comfortable_outcome = getExerciseAnswer(exercise, "comfortable_outcome")
  const help_feel_comfortable = getExerciseAnswer(exercise, "help_feel_comfortable")
  const help_feel_comfortable_other = getExerciseAnswer(exercise, "help_feel_comfortable_other")

  const behaviors = getExerciseAnswer(exercise, "behaviors_list")
  const behaviors_other = getExerciseAnswer(exercise, "behaviors_other")
  const behaviors_specific_ways = getExerciseAnswer(exercise, "behaviors_specific_ways")
  const behaviors_team_support = getExerciseAnswer(exercise, "behaviors_team_support")

  const exerciseSteps = exercise.definition.steps
  const exerciseComponents = exerciseSteps.flatMap((step) => step.components)
  const helpFeelComfortableComponent = exerciseComponents.find(
    (exerciseComponent) => exerciseComponent.identifier === "help_feel_comfortable"
  )
  const behaviorsComponent = exerciseComponents.find(
    (exerciseComponent) => exerciseComponent.identifier === "behaviors_list"
  )

  return (
    <div className={cn("text-gray-9", className)}>
      <TextContainer className="text-gray-9">
        <h3 className="mb-medium">A time I asked for help</h3>
        <AnswerDisplayContent title="The situation" answer={comfortable_situation} />
        <AnswerDisplayContent title="The outcome" answer={comfortable_outcome} />

        <p className="text-bold mb-medium">What helped me feel comfortable:</p>
        <AnswersList
          answers={help_feel_comfortable}
          otherAnswer={help_feel_comfortable_other}
          exerciseComponentDef={helpFeelComfortableComponent}
        />
        <h3 className="mb-medium mt-xxxl">Strategies for the future</h3>
        <p className="text-bold mb-medium">I want to do more of this in the future</p>
        <AnswersList answers={behaviors} otherAnswer={behaviors_other} exerciseComponentDef={behaviorsComponent} />

        <AnswerDisplayContent
          title="Specific ways to apply these to my current role"
          answer={behaviors_specific_ways}
        />
        <AnswerDisplayContent title="How the team can support me" answer={behaviors_team_support} />
      </TextContainer>
    </div>
  )
}

const AnswersList = ({ answers, otherAnswer, exerciseComponentDef }) => (
  <BulletedList className="mb-large">
    {!!answers &&
      answers.map((answer) => {
        const option = exerciseComponentDef.options.find((option) => option.value === answer)
        return (
          option && (
            <li key={answer} className="text-field-response text-gray-9">
              {option.label}
            </li>
          )
        )
      })}
    {!!otherAnswer && <li className="text-field-response text-gray-9">{otherAnswer}</li>}
  </BulletedList>
)

export default LearnerAgilityBehaviorsUserResults
