/* stylelint-disable selector-class-pattern */
// this file uses react-select CSS classes with underscores which trip up stylelint

import * as Sentry from "@sentry/browser"
import cn from "classnames"
import { useState, useRef, useCallback } from "react"
import { styled } from "styled-components"

import AdvancedSelectField from "forms/fields/AdvancedSelectField"
import { PlusIcon, XmarkIcon, CheckIcon, EyeIcon } from "icons/FontAwesomeIcons"
import { View, Button, Tooltip, colors } from "ui"
import { useEffectAfterChange, useEffectAfterFirstRender } from "ui/hooks"
import useFeatures, { FLAGS } from "ui/hooks/useFeatures"
import { useModal } from "ui/ModalContext"

function tagSuffixArray(tag) {
  return Array.from(tag.matchAll(/\[\[([A-Z0-9_]+)\]\]/g)).map(([_, suffix]) => suffix)
}

function tagWithoutSuffixes(tag) {
  return tag.replaceAll(/\[\[[A-Z0-9_]+\]\]/g, "")
}

function removeTagSuffix(tag, suffix) {
  return tag.replaceAll(`[[${suffix}]]`, "")
}

const SUFFIX_DISPLAY_NAME_MAP = {
  STAFF: "INTERNAL",
}

const TagOptionLabel = styled(function TagOptionLabel({ className, tag }) {
  return (
    <span className={className}>
      {tagWithoutSuffixes(tag)}
      {tagSuffixArray(tag).map((suffix) => (
        <Tooltip key={suffix} wrapInView={false} title="tag only visible to Rising Team staff">
          <span className="tag tag-suffix">
            <EyeIcon className="mr-xxs" />
            {SUFFIX_DISPLAY_NAME_MAP[suffix] ?? suffix}
          </span>
        </Tooltip>
      ))}
    </span>
  )
})`
  ${getTagStyles()}
`

const TagSuffixModalContent = styled(function TagSuffixModalContent({ className, suffix, onConfirm, setModal }) {
  if (suffix !== "STAFF") {
    throw new Error(
      "TagSuffixModalContent is only relevant to 'STAFF' tag suffixes. " +
        "Please update this component code if repurposing for use with other tag suffixes."
    )
  }
  return (
    <>
      <div className={cn(className, "pb-large")}>
        Removing
        <span className="tag tag-suffix non-interactive mr-xs">
          <EyeIcon className="mr-xxs" />
          {SUFFIX_DISPLAY_NAME_MAP[suffix] ?? suffix}
        </span>
        will make this tag visible to anyone who can view reports for this account.
      </div>
      <View>
        <Button
          className="tertiary"
          onClick={() => {
            setModal(null)
            onConfirm()
          }}
        >
          Confirm
        </Button>
        <Button color={colors.risingBlue} className="link-semi-bold text-field-label" onClick={() => setModal(null)}>
          Cancel
        </Button>
      </View>
    </>
  )
})`
  ${getTagStyles()}
`

const TaggedSelectField = styled(function TaggedSelectField({
  className,
  name,
  options,
  onChange = null,
  onTagAdd = null,
  onTagRemove = null,
  onTagRemoveSuffix = null,
  onTagClick = null,
  ...props
}) {
  const { [FLAGS.TAG_MANAGEMENT]: hasTagManagementFeature } = useFeatures()
  const [filterValue, setFilterValue] = useState("")
  const [isEditingTagForValue, setIsEditingTagForValue] = useState(null)
  const [isEditingTagKeepMenuOpenWithSameFilter, setIsEditingTagKeepMenuOpenWithSameFilter] = useState(false)
  const [addTagValue, setAddTagValue] = useState("")
  const [updatedTagListsByValue, setUpdatedTagListsByValue] = useState({})
  const [addTagErrorForValue, setAddTagErrorForValue] = useState(null)
  const [delTagErrorForValue, setDelTagErrorForValue] = useState(null)
  const [delTagSuffixErrorForValue, setDelTagSuffixErrorForValue] = useState(null)
  const addTagInput = useRef()
  const cancelAsyncActions = useRef(false)
  const { setModal } = useModal()

  useEffectAfterFirstRender(
    () =>
      // Cancel all async actions after component unmount to avoid memory leaks:
      () =>
        (cancelAsyncActions.current = true)
  )

  const focusTagInput = useCallback(() => {
    addTagInput.current?.focus()
  }, [addTagInput])

  // Focus add-tag input whenever it is added to DOM:
  useEffectAfterChange(focusTagInput, [focusTagInput, isEditingTagForValue])

  // Set up outside-click listener which will close the menu on any click
  // that does _not_ come from inside the tag management UI. We want clicks
  // on tag controls to leave the menu open.
  useEffectAfterFirstRender(() => {
    function onClickOutside(ev) {
      const target = ev.target
      const parent = target && target.parentNode
      const grandparent = parent && parent.parentNode
      if (
        !cancelAsyncActions.current &&
        ![target, parent, grandparent].some(
          (el) => el?.getAttribute?.("class")?.includes?.("tag") || el?.getAttribute?.("class")?.includes?.("modal")
        )
      ) {
        setIsEditingTagForValue(null)
        setAddTagValue("")
        setIsEditingTagKeepMenuOpenWithSameFilter(false)
      }
    }
    document.addEventListener("click", onClickOutside)
    return () => document.removeEventListener("click", onClickOutside)
  })

  // Helper used to stop propagation of events from tag management UI to outer
  // react-select, which helps prevent it from closing when it needs to remain open:
  const stop = useCallback((ev) => {
    ev?.stopPropagation()
  }, [])

  const startAddingTagForValue = useCallback(
    (ev, value) => {
      stop(ev)
      setIsEditingTagForValue(value)
      setIsEditingTagKeepMenuOpenWithSameFilter(true)
    },
    [stop, setIsEditingTagForValue, setIsEditingTagKeepMenuOpenWithSameFilter]
  )

  const delTag = useCallback(
    async (ev, value, tag, tags) => {
      stop(ev)
      setAddTagErrorForValue(null)
      setDelTagErrorForValue(null)
      setDelTagSuffixErrorForValue(null)
      if (onTagRemove) {
        tags = updatedTagListsByValue[value] ?? tags ?? []
        // pre-emptively update tag list in UI for better preceived responsiveness:
        const newTags = tags.filter((t) => t !== tag)
        setUpdatedTagListsByValue({ ...updatedTagListsByValue, [value]: newTags })
        const originalUpdatedTagListsByValue = { ...updatedTagListsByValue }
        try {
          const data = await onTagRemove(value, tag)
          if (Array.isArray(data.tags)) {
            setUpdatedTagListsByValue({ [value]: data.tags })
          } else {
            throw new Error("Response data.tags was unexpectedly not an array!")
          }
        } catch (error) {
          if (!cancelAsyncActions.current) {
            setUpdatedTagListsByValue(originalUpdatedTagListsByValue)
            setDelTagErrorForValue(value)
            Sentry.captureException(error)
          }
        }
      }
    },
    [
      stop,
      setAddTagErrorForValue,
      setDelTagErrorForValue,
      setDelTagSuffixErrorForValue,
      onTagRemove,
      updatedTagListsByValue,
    ]
  )

  const delTagSuffix = useCallback(
    async (value, tag, tags, suffix) => {
      setAddTagErrorForValue(null)
      setDelTagErrorForValue(null)
      setDelTagSuffixErrorForValue(null)
      if (onTagRemoveSuffix) {
        tags = updatedTagListsByValue[value] ?? tags ?? []
        // pre-emptively update tag list in UI for better preceived responsiveness:
        const newTags = tags.map((t) => (t !== tag ? tag : removeTagSuffix(tag, suffix)))
        setUpdatedTagListsByValue({ ...updatedTagListsByValue, [value]: newTags })
        const originalUpdatedTagListsByValue = { ...updatedTagListsByValue }
        try {
          const data = await onTagRemoveSuffix(value, tag, suffix)
          if (Array.isArray(data.tags)) {
            setUpdatedTagListsByValue({ [value]: data.tags })
          } else {
            throw new Error("Response data.tags was unexpectedly not an array!")
          }
        } catch (error) {
          if (!cancelAsyncActions.current) {
            setUpdatedTagListsByValue(originalUpdatedTagListsByValue)
            setDelTagSuffixErrorForValue(value)
            Sentry.captureException(error)
          }
        }
      }
    },
    [
      setAddTagErrorForValue,
      setDelTagErrorForValue,
      setDelTagSuffixErrorForValue,
      onTagRemoveSuffix,
      updatedTagListsByValue,
    ]
  )

  const confirmDelTagSuffix = useCallback(
    async (ev, value, tag, tags, suffix) => {
      stop(ev)
      setModal({
        title: "Are you sure?",
        content: (
          <TagSuffixModalContent
            suffix={suffix}
            setModal={setModal}
            onConfirm={() => delTagSuffix(value, tag, tags, suffix)}
          />
        ),
        size: "small",
      })
    },
    [setModal, delTagSuffix, stop]
  )

  const confirmAddTag = useCallback(
    async (ev, value, tag, tags) => {
      stop(ev)
      setAddTagErrorForValue(null)
      setDelTagErrorForValue(null)
      setDelTagSuffixErrorForValue(null)
      if (!tag.trim().length) {
        ev.preventDefault()
      } else if (onTagAdd) {
        tags = updatedTagListsByValue[value] ?? tags ?? []
        setIsEditingTagForValue(null)
        setAddTagValue("")
        // pre-emptively update tag list in UI for better preceived responsiveness:
        const newTags = tags.includes(tag) ? tags : [...tags, tag]
        setUpdatedTagListsByValue({ [value]: newTags })
        const originalUpdatedTagListsByValue = { ...updatedTagListsByValue }
        try {
          const data = await onTagAdd(value, tag)
          if (Array.isArray(data.tags)) {
            setUpdatedTagListsByValue({ [value]: data.tags })
          } else {
            throw new Error("Response data.tags was unexpectedly not an array!")
          }
        } catch (error) {
          if (!cancelAsyncActions.current) {
            setUpdatedTagListsByValue(originalUpdatedTagListsByValue)
            setAddTagErrorForValue(value)
            setIsEditingTagForValue(value)
            setAddTagValue(tag)
            Sentry.captureException(error)
          }
        }
      }
    },
    [stop, setAddTagErrorForValue, setDelTagErrorForValue, onTagAdd, updatedTagListsByValue]
  )

  const cancelAddTag = useCallback(
    (ev) => {
      stop(ev)
      setAddTagErrorForValue(null)
      setDelTagErrorForValue(null)
      setIsEditingTagForValue(null)
      setAddTagValue("")
    },
    [stop, setAddTagErrorForValue, setDelTagErrorForValue, setIsEditingTagForValue, setAddTagValue]
  )

  const clearTagErrors = useCallback(
    (ev) => {
      stop(ev)
      setAddTagErrorForValue(null)
      setDelTagErrorForValue(null)
    },
    [stop, setAddTagErrorForValue, setDelTagErrorForValue]
  )

  const onAddTagInputKeypress = useCallback(
    async (ev, value, tag, tags) => {
      if (ev.key === "Enter" || ev.key === "Tab") {
        ev.preventDefault()
        if (tag.trim().length) {
          await confirmAddTag(ev, value, tag, tags)
          if (!cancelAsyncActions.current) {
            startAddingTagForValue(null, value)
          }
        }
      }
    },
    [confirmAddTag, startAddingTagForValue]
  )

  const onAddTagInputBlur = useCallback(async () => {
    // Timeout needed here so that click events are processed before
    // input blur which allows tag to be added when clicking checkmark.
    setTimeout(() => {
      if (!cancelAsyncActions.current) {
        setIsEditingTagForValue(null)
      }
    }, 0)
  }, [setIsEditingTagForValue])

  return (
    <AdvancedSelectField
      className={className}
      name={name}
      options={options}
      onChange={onChange}
      inputValue={filterValue}
      onInputChange={(filterValue) => {
        // isEditingTagKeepMenuOpenWithSameFilter here fixes an issue where
        // react-select clears the search filter when the add-tag input is focused:
        if (!isEditingTagKeepMenuOpenWithSameFilter) {
          setFilterValue(filterValue)
        }
      }}
      {...props}
      // isEditingTagKeepMenuOpenWithSameFilter here fixes an issue where
      // react-select automatically closes whenever the add-tag input is focused:
      {...(isEditingTagKeepMenuOpenWithSameFilter ? { menuIsOpen: true } : {})}
      formatOptionLabel={({ label, value, tags }) => {
        const isTaggable = Array.isArray(tags)
        tags = updatedTagListsByValue[value] ?? tags ?? []
        const labelElements = label
        if (!tags?.length && !hasTagManagementFeature) {
          return labelElements
        }
        return (
          <View className="tag-menu-item">
            {labelElements}
            <View className="tags" onKeyPress={stop} onKeyDown={stop} onKeyUp={stop}>
              {!isTaggable ? null : (
                <>
                  {tags.map((tag) => (
                    <div
                      className="tag"
                      key={tag}
                      onClick={(ev) => {
                        stop(ev)
                        onTagClick?.(tag)
                      }}
                    >
                      {tagWithoutSuffixes(tag)}
                      {!!hasTagManagementFeature && (
                        <XmarkIcon className="del-tag" onClick={(ev) => delTag(ev, value, tag, tags)} />
                      )}
                      {tagSuffixArray(tag).map((suffix) => (
                        <Tooltip key={suffix} wrapInView={false} title="tag only visible to Rising Team staff">
                          <span className="tag tag-suffix">
                            <EyeIcon className="mr-xxs" />
                            {SUFFIX_DISPLAY_NAME_MAP[suffix] ?? suffix}
                            {!!hasTagManagementFeature && (
                              <XmarkIcon
                                className="del-tag"
                                onClick={(ev) => confirmDelTagSuffix(ev, value, tag, tags, suffix)}
                              />
                            )}
                          </span>
                        </Tooltip>
                      ))}
                    </div>
                  ))}
                  {!!hasTagManagementFeature && value !== isEditingTagForValue && (
                    <div className="tag add-tag" onClick={(ev) => startAddingTagForValue(ev, value)}>
                      <span className="text-gray-7 mr-xs">add tag</span>
                      <PlusIcon className="add-tag-icon mr-xs" />
                    </div>
                  )}
                  {!!hasTagManagementFeature && value === isEditingTagForValue && (
                    <div
                      className="tag add-tag-input"
                      onClick={focusTagInput}
                      onKeyDown={(ev) => onAddTagInputKeypress(ev, value, addTagValue, tags)}
                      onBlur={onAddTagInputBlur}
                    >
                      <div className="auto-resize-input-container">
                        <input
                          ref={addTagInput}
                          onInput={(ev) => setAddTagValue(ev.target.value)}
                          onClick={stop}
                          defaultValue={addTagValue}
                        />
                        <span className="auto-resize-input-ghost">{addTagValue}</span>
                      </div>
                      <CheckIcon
                        className="confirm-tag"
                        onClick={(ev) => confirmAddTag(ev, value, addTagValue, tags)}
                      />
                      <XmarkIcon className="del-tag" onClick={cancelAddTag} />
                    </div>
                  )}
                  {value === addTagErrorForValue && (
                    <div className="tag tag-error">
                      error adding tag
                      <XmarkIcon className="del-tag" onClick={clearTagErrors} />
                    </div>
                  )}
                  {value === delTagErrorForValue && (
                    <div className="tag tag-error">
                      error removing tag
                      <XmarkIcon className="del-tag" onClick={clearTagErrors} />
                    </div>
                  )}
                  {value === delTagSuffixErrorForValue && (
                    <div className="tag tag-error">
                      error removing tag suffix
                      <XmarkIcon className="del-tag" onClick={clearTagErrors} />
                    </div>
                  )}
                </>
              )}
            </View>
          </View>
        )
      }}
    />
  )
})`
  ${getTagStyles()}

  .tag-menu-item {
    white-space: nowrap;
  }

  .tags {
    padding-left: 4px;
    max-width: 500px;
    flex-wrap: wrap;
    margin-bottom: -8px;
  }

  .tag-error {
    background: var(--danger);
    color: white;
  }

  .add-tag {
    cursor: pointer;
    min-width: 28px;
    padding: 0 4px 0 12px;
    opacity: 0;
    transition: opacity 0.1s ease;

    .add-tag-icon {
      margin-bottom: -0.5px;
      margin-left: -4.5px;
      transition: color 0.1s ease-in-out;
    }

    &:hover {
      .add-tag-icon {
        color: var(--gray-8);
      }
    }
  }

  .advanced-select__option:hover {
    .add-tag {
      opacity: 1;
    }
  }

  .add-tag-input {
    display: flex;
    align-items: center;

    input {
      outline: none;
      border: none;
      background: none;
      position: absolute;
      top: 2px;
      left: -2px;
      width: 100%;
      text-indent: 4px;
    }
  }

  .auto-resize-input-container {
    position: relative;
  }

  .auto-resize-input-ghost {
    visibility: hidden;
    margin-right: 0.5rem;
    white-space: pre;
  }

  // Don't show tags in the main select control (the menu button):
  .advanced-select__single-value > div > * {
    display: none;
  }
`

function getTagStyles() {
  return `
    .tag {
      margin-left: 8px;
      margin-bottom: 8px;
      padding: 0 12px;
      color: var(--gray-9);
      height: 28px;
      border-radius: 14px;
      font-weight: 500;
      position: relative;
      background: var(--gray-4);
      transition: background 0.1s ease-in-out;

      &:not(.tag-error):hover {
        background: var(--gray-5);
      }
    }

    .tag-suffix {
      display: inline-block;
      padding: 0 6px;
      border-radius: 10px;
      font-size: 0.6rem;
      line-height: 1rem;
      height: 16px;
      background: var(--gray-6);
      text-transform: uppercase;
      transform: translateY(-1px);

      &:not(.tag-error):hover {
        background: var(--gray-4);
      }

      .del-tag {
        height: 12px;
        width: 12px;
      }
    }

    .del-tag,
    .confirm-tag {
      cursor: pointer;
      width: 16px;
      height: 16px;
      padding: 0;
      margin-bottom: -1px;
      margin-left: 4px;
      transition: color 0.2s ease-in-out;
    }

    .del-tag {
      &:hover {
        color: var(--danger-hover);
      }
    }

    .confirm-tag {
      &:hover {
        color: var(--rising-green);
      }
    }
  `
}

export default TaggedSelectField

export { TagOptionLabel }
