import { useField } from "formik"

import { formatExercisePieChartData } from "domains/Exercise/results_utils"
import { SmileRatingField } from "forms/fields"
import { View, TextContainer, PieSliceChart } from "ui"
import { getAriaAdditionalInfoFunc, getRatingColorFunc } from "ui/PieSliceRatingUtils"

const ExercisePieSliceGroupRatingField = ({ name, pieGroupId, items, colors, showLabels, saveOnChange, className }) => {
  const [{ value: ratingValues = {} }, _meta, { setValue }] = useField(name)
  const [{ value: sliceValues = {} }] = useField(pieGroupId)
  const [{ value: otherValue = {} }] = useField(`${pieGroupId}_other`)

  if (!Object.values(sliceValues).some((value) => value > 0)) {
    return (
      <TextContainer>
        <p>
          It looks like you haven't selected any slices to rate. If this was done in error, please go back and add some
          values.
        </p>
      </TextContainer>
    )
  }

  const selectedItems = items.filter(({ identifier }) => sliceValues[identifier] > 0)
  const data = formatExercisePieChartData(selectedItems, sliceValues, otherValue)
  const keys = selectedItems.map(({ identifier }) => identifier)
  const getColor = getRatingColorFunc(keys, ratingValues, colors)
  const getAriaAdditionalInfo = getAriaAdditionalInfoFunc(ratingValues)

  const onChangeItem = (fieldName, value) => {
    const itemName = fieldName.split(".")[1]
    const newRatingValues = {
      ...ratingValues,
      [itemName]: value,
    }

    setValue(newRatingValues)
    saveOnChange(name, newRatingValues)
  }

  return (
    <View className={className} $flexDirectionTablet="column" $alignItems="center">
      <View $flexDirection="column" $flex={1} className="space-y-small">
        {selectedItems.map(({ identifier, label, custom_field, sentence }) => {
          const ratingName = `${name}.${identifier}`
          return (
            <SmileRatingField
              key={ratingName}
              name={ratingName}
              label={label}
              tooltip={custom_field ? [...sentence, otherValue?.value ?? ""] : sentence}
              showLabels={showLabels}
              saveOnChange={onChangeItem}
              className="full-width"
            />
          )
        })}
      </View>
      <div className="hide-on-desktop text-gray-8 mt-medium">
        Having trouble deciding? Tap on each item for a definition.
      </div>
      <View $flexDirection="column" $flex={1}>
        <PieSliceChart data={data} colors={getColor} getAriaAdditionalInfo={getAriaAdditionalInfo} />
      </View>
    </View>
  )
}

export default ExercisePieSliceGroupRatingField
