import { useKitSession } from "../KitSessionContext"

import { useSessionExerciseInstances } from "domains/Exercise/resource"
import { getExerciseAnswer, getTeamLead } from "domains/Exercise/results_utils"
import { useHasTeamFeature } from "domains/Results/utils"
import { useKitParticipants } from "resources/monthly_kit"
import { Loading } from "ui"
import { SHARED_FLAGS } from "ui/hooks/useFeatures"

const TLParticipantSelectFieldShortName = ({ identifier, noOneSelectedText }) => {
  const { kitInstance, team } = useKitSession()
  const { enabled: sessionRealtimeUpdatesV2 } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_REPLACE_POLLING)
  const { data: participants } = useKitParticipants({ kitInstance, sessionRealtimeUpdates: sessionRealtimeUpdatesV2 })
  const { enabled: sessionRealtimeUpdates } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_ANSWER_UPDATE)

  const { data: exerciseInstances } = useSessionExerciseInstances(kitInstance?.id, {
    sessionRealtimeUpdates,
    refetchInterval: 10000,
  })

  if (!participants || !exerciseInstances) {
    return <Loading inline />
  }

  const teamLead = getTeamLead(participants, team)
  const teamLeadExercise = exerciseInstances.find(({ user_id }) => user_id === teamLead.id)
  const selectedUserId = getExerciseAnswer(teamLeadExercise, identifier)
  const selectedUser = participants.find(({ id }) => id === selectedUserId)
  const selectedUserDisplayValue = selectedUser ? selectedUser.short_name : noOneSelectedText

  return selectedUserDisplayValue
}

export default TLParticipantSelectFieldShortName
