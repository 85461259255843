import { getExerciseAnswer } from "../results_utils"

import { AnswerDisplayContent } from "ui"

const RepeatingGroupTextFieldList = ({ identifier, textIdentifier, exerciseInstance, className }) => {
  const answerList = getExerciseAnswer(exerciseInstance, identifier) ?? []
  return (
    <AnswerDisplayContent
      answer={
        <ul>
          {answerList
            .filter((answer) => !!answer[textIdentifier]?.value)
            .map((answer, idx) => (
              <li key={`${identifier}_${textIdentifier}_${idx}`}>{answer[textIdentifier].value}</li>
            ))}
        </ul>
      }
      className={className}
    />
  )
}

export default RepeatingGroupTextFieldList
