import cn from "classnames"
import { Link } from "react-router-dom"
import { styled } from "styled-components"

import {
  CalendarStarIcon,
  ChartUserIcon,
  StarIcon,
  UsersCogIcon,
  ChartMixedIcon,
  RocketLaunchIcon,
  WandMagicSparklesIcon,
  FishFinsIcon,
} from "icons/FontAwesomeIcons"
import { useUser } from "resources/users"
import { View } from "ui"

const SidebarItem = styled(function SidebarItem({ className, icon, text, path, active = false }) {
  const Icon = icon
  return (
    <View
      as={Link}
      to={path}
      className={cn("border-radius-small mb-medium", className, {
        "text-orange-2": active,
        "text-gray-7": !active,
      })}
      $flexDirection="column"
      $justifyContent="center"
      $alignItems="center"
    >
      {icon === "ArtiChatIcon" ? (
        !!active ? (
          <img src="https://static.risingteam.com/rtai/arti-chat-icon.png" width="24px" alt="arti-chat-icon" />
        ) : (
          <img src="https://static.risingteam.com/rtai/arti-chat-icon-gray-7.png" width="24px" alt="arti-chat-icon" />
        )
      ) : (
        <Icon className="fa-xl mb-xxs" />
      )}
      <span className="text-small">{text}</span>
    </View>
  )
})`
  width: 76px;
  height: 76px;
  background-color: var(--fg);
  box-shadow: var(--blur-4);

  &:hover {
    text-decoration: none;
    box-shadow: var(--lift-4);
  }
`

const Sidebar = styled(function Sidebar({ selected, className }) {
  const { sideBarMenuItems } = useSideBarMenuItems()

  return (
    <div className={cn("show-on-desktop ml-medium", className)}>
      {sideBarMenuItems.map((item, index) => (
        <SidebarItem key={index} active={selected === item.text} {...item} />
      ))}
    </div>
  )
})`
  position: sticky;
  z-index: var(--z-sticky);
  top: 16px;
`

const useSideBarMenuItems = () => {
  const { data: user, isFetching } = useUser({ userId: "me" })

  if (!user) {
    return { sideBarMenuItems: [], isFetching }
  }

  const potentialSideBarMenuItems = [
    {
      text: "Set up",
      path: "/setup",
      icon: WandMagicSparklesIcon,
      visible: !!user.setup_steps?.filter(({ completed }) => !completed).length,
    },
    {
      text: "Kits",
      path: "/kit",
      icon: CalendarStarIcon,
      visible: user.can_see_kits_tab,
    },
    {
      text: "Minis",
      path: "/minis",
      icon: FishFinsIcon,
      visible: user.can_see_minis_tab,
    },
    {
      text: "Team",
      path: "/team",
      icon: ChartUserIcon,
      visible: user.can_see_team_tab,
    },
    {
      text: "Reports",
      path: "/reports",
      icon: ChartMixedIcon,
      visible: user.can_view_reports,
    },
    {
      text: "Subscribe",
      path: "/subscribe",
      icon: RocketLaunchIcon,
      visible: user.can_view_subscribe_tab,
    },
    {
      text: "Admin",
      path: "/admin",
      icon: UsersCogIcon,
      visible: user.can_view_admin_tab,
    },
    {
      text: "aRTi",
      path: "/arti",
      icon: "ArtiChatIcon",
      visible: user.has_arti_access,
    },
    {
      text: "Demo",
      path: "/demo",
      icon: StarIcon,
      // Ensure only RT staff with demo mode active may access demo tab:
      visible: user?.is_staff && user?.is_demo_mode_active,
    },
  ]

  const sideBarMenuItems = potentialSideBarMenuItems.filter((item) => item.visible)

  return { sideBarMenuItems, isFetching }
}

export default styled(Sidebar)`
  width: 76px;
`

export { useSideBarMenuItems }
