import qs from "qs"

import { isSchoolAccountType } from "components/AccountTypeContext"
import { Tooltip } from "ui"
import { plural } from "utils/string"

function getReportTypes(selectedAccount, ReportTypes) {
  if (isSchoolAccountType(selectedAccount?.account_type)) {
    return [ReportTypes.SESSIONS, ReportTypes.KIT_USAGE]
  }
  return [ReportTypes.SESSIONS, ReportTypes.ENGAGEMENT_SURVEY, ReportTypes.KIT_USAGE]
}

function getReportUrl({
  accountId,
  accountTags,
  multiAccountIds,
  multiAccountActive,
  reportType,
  teamId,
  teamTags,
  kitSlug,
  includeAllAccounts,
  includeArchivedTeams,
}) {
  const queryParams = qs.stringify(
    {
      accountId,
      accountTags,
      multiAccountIds,
      multiAccountActive,
      reportType,
      teamId,
      teamTags,
      kitSlug,
      includeAllAccounts,
      includeArchivedTeams,
    },
    { arrayFormat: "comma" }
    // Use arrayFormat:comma so we can fit more items within the URL character limit
    // (it's much more compact). See https://www.npmjs.com/package/qs for details.
  )
  return `/reports/?${queryParams}`
}

function getTagSelectedLabel(tags, matches) {
  return !(tags?.length && matches?.length) ? null : (
    <>
      {matches[0].name}
      <span className="text-gray-6">
        {matches.length <= 1
          ? ` (matches ${plural(tags.length, "tag", { showCount: false })})`
          : ` and ${matches.length - 1} more`}
      </span>
    </>
  )
}

function getTaggedItemsDescription(type, tags, matches) {
  return `${plural(matches.length, type)} with ${plural(tags.length, "tag", {
    showCount: false,
  })}`
}

function TaggedItemsTooltip({ children, type, tags, matches }) {
  return (
    <Tooltip
      top
      title={
        <>
          {plural(matches.length, type)} with {plural(tags.length, "tag", { showCount: false })}
          :<br />
          {matches.map(({ name }) => name).join(", ")}
        </>
      }
      disabled={!matches.length}
      className="width-auto"
    >
      {children}
    </Tooltip>
  )
}

export { getReportUrl, getReportTypes, getTagSelectedLabel, getTaggedItemsDescription, TaggedItemsTooltip }
