import cn from "classnames"
import { styled } from "styled-components"

import AppreciationsTraining1 from "./AppreciationsTraining1"
import AppreciationsTraining2 from "./AppreciationsTraining2"
import BOHEmployeeExperienceTraining1 from "./BOHEmployeeExperienceTraining1"
import BOHEmployeeExperienceTraining2 from "./BOHEmployeeExperienceTraining2"
import BOHEmployeeExperienceTraining3 from "./BOHEmployeeExperienceTraining3"
import BOHLeadershipPrinciplesTraining1 from "./BOHLeadershipPrinciplesTraining1"
import BOHLeadershipPrinciplesTraining2 from "./BOHLeadershipPrinciplesTraining2"
import BOHLeadershipPrinciplesTraining3 from "./BOHLeadershipPrinciplesTraining3"
import BOHOperationalExcellenceTraining1 from "./BOHOperationalExcellenceTraining1"
import CareerHorizonsTraining1 from "./CareerHorizonsTraining1"
import CareerHorizonsTraining2 from "./CareerHorizonsTraining2"
import CareerHorizonsTraining3 from "./CareerHorizonsTraining3"
import CivilConversationsTraining1 from "./CivilConversationsTraining1"
import CrisisResponseTraining1 from "./CrisisResponseTraining1"
import CrisisResponseTraining2 from "./CrisisResponseTraining2"
import CrisisResponseTraining3 from "./CrisisResponseTraining3"
import DEIInclusiveCultureTraining1 from "./DEIInclusiveCultureTraining1"
import DEIInclusiveCultureTraining2 from "./DEIInclusiveCultureTraining2"
import DEISafeSpacesTraining1 from "./DEISafeSpacesTraining1"
import DEISafeSpacesTraining2 from "./DEISafeSpacesTraining2"
import DEISafeSpacesTraining3 from "./DEISafeSpacesTraining3"
import ExpectationsTraining1 from "./ExpectationsTraining1"
import ExpectationsTraining2 from "./ExpectationsTraining2"
import ExpectationsTraining3 from "./ExpectationsTraining3"
import FeedbackTraining1 from "./FeedbackTraining1"
import FeedbackTraining2 from "./FeedbackTraining2"
import FeedbackTraining3 from "./FeedbackTraining3"
import LearnerAgilityTraining1 from "./LearnerAgilityTraining1"
import LearnerAgilityTraining2 from "./LearnerAgilityTraining2"
import LearnerAgilityTraining3 from "./LearnerAgilityTraining3"
import ListeningTraining1 from "./ListeningTraining1"
import ListeningTraining2 from "./ListeningTraining2"
import MenziesCELeadershipTraining1 from "./MenziesCELeadershipTraining1"
import MenziesCELeadershipTraining2 from "./MenziesCELeadershipTraining2"
import MenziesCETeamsTraining1 from "./MenziesCETeamsTraining1"
import MenziesCETeamsTraining2 from "./MenziesCETeamsTraining2"
import MenziesCollectiveEfficacyTraining1 from "./MenziesCollectiveEfficacyTraining1"
import MenziesCollectiveEfficacyTraining2 from "./MenziesCollectiveEfficacyTraining2"
import MenziesExpectationsTraining1 from "./MenziesExpectationsTraining1"
import MenziesExpectationsTraining2 from "./MenziesExpectationsTraining2"
import MotivatorsTraining1 from "./MotivatorsTraining1"
import MotivatorsTraining3 from "./MotivatorsTraining3"
import PsychologicalSafetyTraining1 from "./PsychologicalSafetyTraining1"
import PsychologicalSafetyTraining2 from "./PsychologicalSafetyTraining2"
import PsychologicalSafetyTraining3 from "./PsychologicalSafetyTraining3"
import ResilienceTraining1 from "./ResilienceTraining1"
import ResilienceTraining2 from "./ResilienceTraining2"
import RT4SPurposeGoalsTraining1 from "./RT4SPurposeGoalsTraining1"
import RT4SPurposeGoalsTraining2 from "./RT4SPurposeGoalsTraining2"
import SchoolAppreciationsTraining1 from "./SchoolAppreciationsTraining1"
import SchoolAppreciationsTraining2 from "./SchoolAppreciationsTraining2"
import SchoolListeningTraining1 from "./SchoolListeningTraining1"
import SchoolListeningTraining2 from "./SchoolListeningTraining2"
import TalentsTraining1 from "./TalentsTraining1"
import TalentsTraining2 from "./TalentsTraining2"
import TalentsTraining3 from "./TalentsTraining3"
import TeamNormsTraining1 from "./TeamNormsTraining1"
import TeamNormsTraining2 from "./TeamNormsTraining2"

import { ThemeHeader } from "components"
import { useSteps, StepNavigation } from "components/Steps"
import { LinkIcon, ShareIcon } from "icons/FontAwesomeIcons"
import { AudioPlayer, Button, CopyButton, HorizontalRule, View } from "ui"
import { useModal } from "ui/ModalContext"

const SLUG_TO_COMPONENT_MAP = {
  "1-team-dynamics-psychological-safety": PsychologicalSafetyTraining1,
  "2-team-dynamics-trust": PsychologicalSafetyTraining2,
  "3-team-dynamics-psychological-safety-tips": PsychologicalSafetyTraining3,
  "1-psychological-safety": PsychologicalSafetyTraining1,
  "2-psychological-safety-trust": PsychologicalSafetyTraining2,
  "3-psychological-safety-tips": PsychologicalSafetyTraining3,
  "1-talents-approach-helps-teams-succeed": TalentsTraining1,
  "2-talents-approach-how-to-with-your-team": TalentsTraining2,
  "3-talents-apply-natural-talents": TalentsTraining3,
  "1-appreciations-why-appreciation-matters": AppreciationsTraining1,
  "2-appreciations-cultivate-appreciation-all-the-time": AppreciationsTraining2,
  "1-resilience-resilient-teams-are-stronger": ResilienceTraining1,
  "2-resilience-grow-team-resilience": ResilienceTraining2,
  "1-expectations-clarifying-expectations": ExpectationsTraining1,
  "2-expectations-build-discipline": ExpectationsTraining2,
  "3-expectations-clarifying-ongoing": ExpectationsTraining3,
  "1-motivators-why-motivation": MotivatorsTraining1,
  "3-motivators-keep-team-engaged": MotivatorsTraining3,
  "1-career-horizons-connection-team-performance": CareerHorizonsTraining1,
  "2-career-horizons-explore-career-paths": CareerHorizonsTraining2,
  "3-career-horizons-support-career-paths": CareerHorizonsTraining3,
  "1-feedback-helps-teams-thrive": FeedbackTraining1,
  "2-feedback-build-feedback-culture": FeedbackTraining2,
  "3-feedback-into-practice": FeedbackTraining3,
  "1-learner-agility-drives-growth": LearnerAgilityTraining1,
  "2-learner-agility-learn-as-team": LearnerAgilityTraining2,
  "3-learner-agility-culture-of-learning": LearnerAgilityTraining3,
  "1-crisis-response-why-teams-add-value-in-a-crisis": CrisisResponseTraining1,
  "2-crisis-response-practice-team-crisis-response": CrisisResponseTraining2,
  "3-crisis-response-tips-to-navigate-future-crises": CrisisResponseTraining3,
  "1-collective-efficacy-drives-school-improvement": MenziesCollectiveEfficacyTraining1,
  "2-collective-efficacy-at-your-school": MenziesCollectiveEfficacyTraining2,
  "1-dei-safe-spaces-creating-a-safe-and-brave-space": DEISafeSpacesTraining1,
  "2-dei-safe-spaces-make-the-most-of-session": DEISafeSpacesTraining2,
  "3-dei-safe-spaces-maintaining-safe-and-brave-space": DEISafeSpacesTraining3,
  "1-boh-employee-experience": BOHEmployeeExperienceTraining1,
  "2-boh-employee-experience-how-to-run-session": BOHEmployeeExperienceTraining2,
  "3-boh-employee-experience-guide-our-way": BOHEmployeeExperienceTraining3,
  "1-listening-builds-team-cohesion": ListeningTraining1,
  "2-maintaining-listening-culture": ListeningTraining2,
  "1-school-listening-builds-team-cohesion": SchoolListeningTraining1,
  "2-school-maintaining-listening-culture": SchoolListeningTraining2,
  "1-boh-leadership-principles": BOHLeadershipPrinciplesTraining1,
  "2-boh-leadership-principles-walking-through-leadership-experience": BOHLeadershipPrinciplesTraining2,
  "3-boh-leadership-principles-after-the-session": BOHLeadershipPrinciplesTraining3,
  "1-boh-operational-excellence": BOHOperationalExcellenceTraining1,
  "1-menzies-ce-leadership-crucial-cultivating-collective-efficacy": MenziesCELeadershipTraining1,
  "2-menzies-ce-leadership-how-to-foster-collective-efficacy": MenziesCELeadershipTraining2,
  "1-menzies-ce-teams-collective-efficacy-in-teams": MenziesCETeamsTraining1,
  "2-menzies-ce-teams-tips-for-fostering-collective-efficacy": MenziesCETeamsTraining2,
  "1-dei-inclusive-culture-creating-inclusive-culture": DEIInclusiveCultureTraining1,
  "2-dei-inclusive-culture-insights": DEIInclusiveCultureTraining2,
  "1-team-norms-why-team-norms": TeamNormsTraining1,
  "2-team-norms-make-team-norms": TeamNormsTraining2,
  "1-rt4s-purpose-goals-related-concepts-different-functions": RT4SPurposeGoalsTraining1,
  "2-rt4s-purpose-goals-compelling-purpose": RT4SPurposeGoalsTraining2,
  "1-civil-conversations-civility-in-workplace-is-important": CivilConversationsTraining1,
  "1-menzies-expectations-clarifying-expectations": MenziesExpectationsTraining1,
  "2-menzies-expectations-build-discipline": MenziesExpectationsTraining2,
  "1-school-appreciations-why-appreciation-matters": SchoolAppreciationsTraining1,
  "2-school-appreciations-cultivate-appreciation-all-the-time": SchoolAppreciationsTraining2,
}

const ShareModal = ({ shareLink }) => (
  <>
    <div className="text-gray-8 text-normal pb-xs">
      To share this article, copy the URL below and share by email or your favorite messaging platform.
    </div>
    <div className="share-link text-gray-8 text-normal bg-gray-1 border border-gray-4 border-radius p-small">
      {shareLink}
    </div>
    <CopyButton className="mt-large" buttonClassName="tertiary" clipboardText={shareLink}>
      <LinkIcon />
      Copy link
    </CopyButton>
  </>
)

const KitTrainingArticle = ({ kitInstance, prepLink, className }) => {
  const { currentIndex } = useSteps()
  const { title, slug, audio_src } = prepLink
  const { setModal } = useModal()

  const ArticleComponent = SLUG_TO_COMPONENT_MAP[slug]
  const showModal = () =>
    setModal({
      title: "Share this article",
      content: <ShareModal shareLink={prepLink.share_link} />,
    })

  return (
    <main className={cn("align-left full-width", className)}>
      <ThemeHeader kitInstance={kitInstance} className="pb-small" />
      <HorizontalRule />
      <div className="border-left-line my-large">
        <View $alignItems="center" $justifyContent="space-between" className="pt-xs">
          <div>
            {!!prepLink.sub_title
              ? prepLink.sub_title
              : `Training #${currentIndex} of ${kitInstance.kit.prep_links.length}`}
          </div>
          {!!prepLink.share_link && (
            <Button className={cn("tertiary", className)} onClick={showModal}>
              Share
              <ShareIcon />
            </Button>
          )}
        </View>
        <h1 className="text-gray-9 py-xs">{title}</h1>
      </div>
      <HorizontalRule />
      {!!audio_src && <AudioPlayer src={audio_src} className="py-large" />}
      <ArticleComponent kitInstance={kitInstance} />

      <StepNavigation />
    </main>
  )
}

export default styled(KitTrainingArticle)`
  .border-left-line {
    position: relative;
    padding-left: 1.5rem;
  }
  .border-left-line:before {
    content: "";
    height: 100%;
    width: 8px;
    border-radius: 2px;
    display: block;
    position: absolute;
    left: 0;
    background: var(--orange-4);
  }
`
