import cn from "classnames"
import { Form, Formik } from "formik"
import { groupBy, mapValues } from "lodash-es"
import { useState, useMemo } from "react"
import { styled } from "styled-components"

import { KIT_STATUS, getKitStatus, sortKits, sortDemoKits } from "domains/LeaderKit/KitsTableofContents/utils"
import { MINI_SUB_TYPE } from "domains/MiniKit/MiniKitsTableofContents/utils"
import { AdvancedSelectField } from "forms/fields"
import { View } from "ui"
import { useEffectAfterChange } from "ui/hooks"
import { intersperse } from "utils/array"

const FILTER_KEYS = {
  AVAILABLE: "available",
  COMPLETED: "completed",
  IN_PROGRESS: "in_progress",
  MINI_KITS: "mini_kits",
  ALL_SUBTYPES: "all_subtypes",
  CONNECT: "connect",
  PLAY: "play",
  UNDERSTAND: "understand",
}

const MiniKitFilters = styled(function MiniKitFilters({
  userKits,
  unavailableKits,
  kitInstanceMap,
  setVisibleKits,
  user,
  className,
}) {
  const [activeSubTypeFilter, setActiveSubTypeFilter] = useState(FILTER_KEYS.ALL_SUBTYPES)
  const [activeStatusFilter, setActiveStatusFilter] = useState(FILTER_KEYS.MINI_KITS)
  const isDemoModeActive = user.is_demo_mode_active
  const decoratedUnavailableKits = useMemo(
    () => unavailableKits.map((kit) => ({ ...kit, unavailable: true })),
    [unavailableKits]
  )
  const availableAndUnavailableKits = useMemo(
    () => [...userKits, ...decoratedUnavailableKits],
    [userKits, decoratedUnavailableKits]
  )
  const visibleStatusFilters = useMemo(
    () =>
      [
        {
          text: "Featured",
          key: FILTER_KEYS.MINI_KITS,
          statusKits: [...availableAndUnavailableKits],
        },
        {
          text: "Available",
          key: FILTER_KEYS.AVAILABLE,
          statusKits: userKits.filter((kit) =>
            [KIT_STATUS.AVAILABLE, KIT_STATUS.IN_PROGRESS].includes(getKitStatus(kitInstanceMap[kit.slug], kit))
          ),
        },
        {
          text: "Completed",
          key: FILTER_KEYS.COMPLETED,
          statusKits: userKits.filter((kit) => getKitStatus(kitInstanceMap[kit.slug], kit) === KIT_STATUS.COMPLETE),
        },
      ].filter(({ statusKits }) => statusKits.length > 0),
    [userKits, availableAndUnavailableKits, kitInstanceMap]
  )
  const visibleStatusFiltersMap = useMemo(
    () => mapValues(groupBy(visibleStatusFilters, "key"), (filters) => filters[0]),
    [visibleStatusFilters]
  )

  const visibleSubTypeFilters = useMemo(
    () =>
      [
        {
          text: "All",
          key: FILTER_KEYS.ALL_SUBTYPES,
          subTypeKits: [...availableAndUnavailableKits],
        },
        {
          text: "Connect",
          key: FILTER_KEYS.CONNECT,
          subTypeKits: availableAndUnavailableKits.filter((kit) => kit.sub_type === MINI_SUB_TYPE.CONNECT),
        },
        {
          text: "Understand",
          key: FILTER_KEYS.UNDERSTAND,
          subTypeKits: availableAndUnavailableKits.filter((kit) => kit.sub_type === MINI_SUB_TYPE.UNDERSTAND),
        },
        {
          text: "Play",
          key: FILTER_KEYS.PLAY,
          subTypeKits: availableAndUnavailableKits.filter((kit) => kit.sub_type === MINI_SUB_TYPE.PLAY),
        },
      ].filter(({ subTypeKits }) => subTypeKits.length > 0),
    [availableAndUnavailableKits]
  )
  const visibleSubTypeFiltersMap = useMemo(
    () => mapValues(groupBy(visibleSubTypeFilters, "key"), (filters) => filters[0]),
    [visibleSubTypeFilters]
  )

  const visibleSubTypeMap = visibleSubTypeFilters.map(({ text, key }) => (
    <span
      key={key}
      onClick={() => setActiveSubTypeFilter(key)}
      className={cn("filter-option text-semi-bold cursor-pointer", {
        "active-filter": key === activeSubTypeFilter,
      })}
    >
      {text}
    </span>
  ))

  useEffectAfterChange(() => {
    const { subTypeKits = [] } = visibleSubTypeFiltersMap[activeSubTypeFilter] ?? {}
    const { statusKits = [] } = visibleStatusFiltersMap[activeStatusFilter] ?? {}
    const kits = subTypeKits.filter((kitSubType) => statusKits.includes(kitSubType))
    if (isDemoModeActive) {
      setVisibleKits(sortDemoKits(kits, kitInstanceMap))
    } else {
      setVisibleKits(sortKits(kits, kitInstanceMap))
    }
  }, [
    activeStatusFilter,
    activeSubTypeFilter,
    setVisibleKits,
    visibleSubTypeFiltersMap,
    visibleStatusFiltersMap,
    kitInstanceMap,
    isDemoModeActive,
  ])

  const onStatusChange = ({ value }) => {
    const { statusKits = [] } = visibleStatusFiltersMap[value] ?? {}
    const { subTypeKits = [] } = visibleSubTypeFiltersMap[activeSubTypeFilter] ?? {}
    const kits = subTypeKits.filter((kitSubType) => statusKits.includes(kitSubType))
    setActiveStatusFilter(value)
    setVisibleKits(kits)
  }

  return (
    <View
      $flexDirectionMobile="column"
      $gap="16px"
      $justifyContent="space-between"
      $alignItemsMobile="flex-start"
      className={cn(className, "text-gray-7")}
    >
      <View>
        <Formik initialValues={{ key: visibleStatusFiltersMap[activeStatusFilter].key }}>
          <Form>
            <AdvancedSelectField
              className="medium"
              name="key"
              onChange={onStatusChange}
              width={200}
              options={visibleStatusFilters.map((visibleStatusFilter) => ({
                value: visibleStatusFilter.key,
                label: visibleStatusFilter.text,
              }))}
            />
          </Form>
        </Formik>
      </View>
      <View $alignSelf="center" $justifyContent="flex-end" $justifyContentMobile="flex-start">
        {intersperse(visibleSubTypeMap, <span className="px-xs">|</span>)}
      </View>
    </View>
  )
})`
  .filter-option:hover {
    color: var(--gray-9);
  }

  .active-filter {
    color: var(--gray-9);
    border-bottom: 2px solid var(--orange-2);
  }
`

export default MiniKitFilters
