import cn from "classnames"
import { styled } from "styled-components"

import { EmailColoredIcon, SlackIcon } from "../icons"

import { EnvelopeOpenIcon } from "icons/FontAwesomeIcons"
import { View } from "ui"

const CONTACT_OPTIONS = [
  {
    icon: EmailColoredIcon,
    label: "Send an email to",
    labelLink: "hello@risingteam.com",
    href: "mailto:hello@risingteam.com",
    fontAwesomeIconAvailable: true,
  },
  {
    icon: SlackIcon,
    labelLink: "Send us a message in our Slack channel",
    href: "https://join.slack.com/t/rising-team-huddle/shared_invite/zt-116r7x46a-wfNVXRIQzgkF~XvggIOCzQ",
    team_lead_only: true,
  },
]

const ContactOptions = ({ user = null, className }) => (
  <div className={cn("contact-options", className)}>
    {CONTACT_OPTIONS.filter(({ team_lead_only }) => !team_lead_only || user?.is_a_team_lead).map(
      ({ label, labelLink, href, fontAwesomeIconAvailable, icon: Icon }) => (
        <View
          className="contact-options-individual mt-large"
          $flexDirection="row"
          $justifyContent="center"
          $alignItems="center"
          $gap="8px"
          $radius="8px"
          $alignSelf="center"
          key={href}
        >
          {fontAwesomeIconAvailable ? (
            <EnvelopeOpenIcon className="email-icon" />
          ) : (
            <Icon className="icon" size="32px" />
          )}
          <h5 className="text-bold text-center link">
            {!!label && <span>{label} </span>}
            <a href={href} target="_blank" rel="noopener noreferrer">
              {labelLink}
            </a>
          </h5>
        </View>
      )
    )}
  </div>
)

export default styled(ContactOptions)`
  display: flex;
  flex-direction: column;

  .contact-options-individual {
    width: max-content;
    box-shadow: var(--blur-4);
    padding: 16px 65px;

    .email-icon {
      width: 32px;
      height: 32px;
      color: var(--secondary-yellow, #ffd257);
    }
  }
`
