import cn from "classnames"
import { useField } from "formik"
import { get } from "lodash-es"

import { useSessionOrTeamExerciseInstances } from "domains/Exercise/resource"
import { getSortedExerciseAnswers } from "domains/Exercise/results_utils"
import { useKitSession } from "domains/KitSession/KitSessionContext"
import { useHasTeamFeature } from "domains/Results/utils"
import { FlexibleRowCheckboxGroupField } from "forms/fields"
import { Loading } from "ui"
import { SHARED_FLAGS } from "ui/hooks/useFeatures"

const ExerciseTextReferenceCheckboxGroupField = ({
  name,
  text_field_id,
  options,
  kit,
  saveOnChange,
  className,
  ...props
}) => {
  const { team } = useKitSession()
  const { enabled: sessionRealtimeUpdates } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_ANSWER_UPDATE)
  const { data: exerciseInstances = [], isInitialLoading } = useSessionOrTeamExerciseInstances(kit.exercise.slug, {
    refetchInterval: 30000,
    sessionRealtimeUpdates,
  })
  const [field] = useField(name)

  if (isInitialLoading) {
    return <Loading />
  }

  const saveGroupOnChange = (subFieldName, subFieldValue) => {
    const subFieldKey = subFieldName.split(".")[1]
    const newValue = {
      ...field.value,
      [subFieldKey]: {
        value: subFieldValue,
      },
    }
    saveOnChange(name, newValue)
  }

  const answers = getSortedExerciseAnswers(exerciseInstances, text_field_id)
  if (!answers.length) {
    return <p className="text-gray-8 text-italic">No response given.</p>
  }

  return (
    <div className={cn("space-y-medium", className)} {...props}>
      {answers.map((answer) => {
        const answerValue = get(answer.data, ["value"], answer)
        return (
          <div key={answer.id} className="border-bottom border-gray-5 pb-medium">
            <h4 className="mb-medium">{answerValue}</h4>
            <FlexibleRowCheckboxGroupField
              name={`${name}.answer_${answer.id}.value`}
              options={options}
              saveOnChange={saveGroupOnChange}
            />
          </div>
        )
      })}
    </div>
  )
}

export default ExerciseTextReferenceCheckboxGroupField
