import { useKitSession } from "../KitSessionContext"

import { Accordion } from "components"
import { StepContent, SidebarContainer, FacilitatorTip } from "domains/KitSession/components"
import ParticipantReflections from "domains/KitSession/components/ParticipantReflections"
import { useHasTeamFeature } from "domains/Results/utils"
import { useTeamReflections, useKitParticipants } from "resources/monthly_kit"
import { sortUsersByShortName } from "resources/users"
import { Loading, TextContainer } from "ui"
import { SHARED_FLAGS } from "ui/hooks/useFeatures"

const ReflectionsResultsStep = ({ kitInstance }) => {
  const { team } = useKitSession()
  const { enabled: sessionRealtimeUpdatesV2 } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_REPLACE_POLLING)
  const { data: participants } = useKitParticipants({ kitInstance, sessionRealtimeUpdates: sessionRealtimeUpdatesV2 })
  const { data: reflections, isFetching } = useTeamReflections({
    kitInstanceId: kitInstance.id,
    refetchInterval: 30000,
    sessionRealtimeUpdates: sessionRealtimeUpdatesV2,
  })

  if (!participants || (isFetching && !reflections)) {
    return <Loading />
  }

  const sortedParticipants = sortUsersByShortName({ users: participants })

  return (
    <StepContent>
      <SidebarContainer>
        <TextContainer>
          <p className="text-gray-9 mb-xl mr-small">
            Now we’ll share our main takeaways from the session and anything we each want to do differently based on
            what we learned. Not everyone has to share if they don’t want to.
          </p>
        </TextContainer>
        <FacilitatorTip className="sidebar-right" icon="share-screen" />
      </SidebarContainer>
      {sortedParticipants.map((teamMember) => (
        <Accordion
          title={<h3 className="text-capitalize">{teamMember.short_name || teamMember.email}</h3>}
          key={teamMember.id}
          isOpen={false}
        >
          <ParticipantReflections reflections={reflections} teamMember={teamMember} />
        </Accordion>
      ))}
    </StepContent>
  )
}

export default ReflectionsResultsStep
