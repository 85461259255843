import cn from "classnames"
import { useState } from "react"
import { styled } from "styled-components"

import { Button, Loading } from "ui"
import { useSelectedUser } from "ui/SelectedUserContext"

const SelectedUserRevealButton = ({
  sortedUsers,
  className,
  children,
  buttonText,
  revealContent = false,
  ...props
}) => {
  const { selectedUser } = useSelectedUser()
  if (!selectedUser || !sortedUsers) {
    return <Loading />
  }
  const buttons = sortedUsers.map((user) => (
    <RevealButton
      key={user.id}
      className={className}
      children={children}
      buttonText={buttonText}
      revealContent={revealContent}
      hidden={user.id !== selectedUser.id}
      {...props}
    />
  ))
  return <>{buttons}</>
}

const RevealButton = styled(function RevealButton({
  hidden,
  className,
  children,
  buttonText,
  revealContent = false,
  ...props
}) {
  const [isContentRevealed, setContentRevealed] = useState(revealContent)

  const revealContentClick = () => {
    setContentRevealed(true)
  }

  if (hidden) {
    return null
  }

  return (
    <>
      {!!isContentRevealed ? (
        <div>{children}</div>
      ) : (
        <Button className={cn(className, "tertiary reveal-button")} onClick={revealContentClick} {...props}>
          {buttonText}
        </Button>
      )}
    </>
  )
})`
  .reveal-button {
    box-shadow: var(--blur-4);
  }
`

export default SelectedUserRevealButton
