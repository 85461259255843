import cn from "classnames"
import { useState } from "react"
import { Navigate, Outlet, useLocation } from "react-router-dom"
import Sticky from "react-stickynode"
import { styled } from "styled-components"

import { AccountTypeContext, useSetAccountType } from "./AccountTypeContext"
import Header from "./Header"
import { PreviewOverlayContext } from "./PreviewOverlayContext"

import RTKitViewBase from "components/RTKitViewBase"
import { XmarkIcon } from "icons/FontAwesomeIcons"
import { useTeam } from "resources/teams"
import { useUser, useUpdateCurrentUser } from "resources/users"
import { Button, Tooltip, View } from "ui"
import { useEffectAfterChange, useEffectAfterLocationChange } from "ui/hooks"
import SelectedTeamContext from "ui/SelectedTeamContext"
import { useCurrentTheme } from "ui/ThemeUpdateContext"
import { getTimeZone } from "utils/date"
import { handleErrorsWithAlerting } from "utils/errors"
import { buildUrl } from "utils/string"

const RTKitView = styled(function RTKitView({ className, sidebar = null, hideSidebarIf = null }) {
  const { data: user } = useUser({ userId: "me" })
  const [accountType, setAccountType] = useState()
  const [previewOverlay, setPreviewOverlay] = useState()
  const [previewOverlayText, setPreviewOverlayText] = useState()
  const [onPreviewExitClick, setOnPreviewExitClick] = useState()
  const [selectedTeamId, setSelectedTeamId] = useState()
  const { data: selectedTeam } = useTeam({ teamId: selectedTeamId })
  const theme = useCurrentTheme()
  const { pathname, search } = useLocation()

  // set the account type if there is a selected team or use the user default only if there is not already an account type
  const defaultAccountType = selectedTeam?.account_type ?? (!accountType && user?.default_account_type)
  useSetAccountType({ accountType: defaultAccountType, shouldSet: !!defaultAccountType, setAccountType })

  // Whenever the user navigates from a /setup page to a non-setup page,
  // set their setup_exited_at field in database if it isn't set already:
  const { mutateAsync: updateCurrentUser } = useUpdateCurrentUser()
  useEffectAfterLocationChange((location, prevLocation) => {
    if (prevLocation?.pathname.startsWith("/setup") && !location.pathname.startsWith("/setup")) {
      handleErrorsWithAlerting(
        () => updateCurrentUser({ setup_exited_at__set_if_null: true }),
        "Error updating setup_exited_at for user"
      )
    }
  })

  useEffectAfterChange(() => {
    if (user && !user.time_zone) {
      const timeZone = getTimeZone()
      if (timeZone) {
        updateCurrentUser({ time_zone: timeZone })
      }
    }
  }, [user, updateCurrentUser])

  if (!user) {
    return null
  }

  if (!user.first_name?.trim().length || !user.last_name?.trim().length) {
    const registerNameUrl = buildUrl(["get-started", "register-name"], { urlQueryParams: { next: pathname + search } })
    return <Navigate to={registerNameUrl} replace />
  }

  if (!user.has_an_active_account) {
    if (pathname.startsWith("/session") || pathname.startsWith("/startsession")) {
      return <Navigate to="/code" replace />
    }
    return <Navigate to="/select-or-update-payment" replace />
  }

  const hideSidebar = hideSidebarIf?.(user)
  const sidebarVisible = !!sidebar && !hideSidebar

  return (
    <AccountTypeContext.Provider value={{ accountType, setAccountType }}>
      <PreviewOverlayContext.Provider value={{ setPreviewOverlay, setPreviewOverlayText, setOnPreviewExitClick }}>
        <SelectedTeamContext.Provider value={{ selectedTeamId, setSelectedTeamId }}>
          <div className={cn(className, theme.themeClassname, { "preview-frame": previewOverlay })}>
            {!theme.hideHeader && <Header sidebarVisible={sidebarVisible} />}
            {!!previewOverlay && (
              <Sticky top={0} className="preview-sticky-container">
                <div className="preview-text-container">
                  <Tooltip bottom float title={previewOverlayText} className="preview-tooltip">
                    <div className="preview-text text-fg text-semi-bold bg-orange-3">
                      <View $alignItems="center">
                        Preview Mode
                        <Button onClick={onPreviewExitClick} className="link ml-small">
                          <XmarkIcon color="var(--fg)" className="ml-xs mr-none" />
                        </Button>
                      </View>
                    </div>
                  </Tooltip>
                </div>
              </Sticky>
            )}
            <RTKitViewBase sidebar={!!sidebarVisible && sidebar}>
              <Outlet />
            </RTKitViewBase>
          </div>
        </SelectedTeamContext.Provider>
      </PreviewOverlayContext.Provider>
    </AccountTypeContext.Provider>
  )
})`
  .preview-sticky-container {
    position: absolute;
    width: 100%;
    z-index: var(--z-frame);

    .preview-text-container {
      border-top: 4px solid var(--orange-3);
      z-index: var(--z-frame);
    }

    .preview-tooltip {
      width: fit-content;
      margin: 0 auto;
    }

    .preview-text {
      width: fit-content;
      padding: 2px 30px 6px;
      border-radius: 0 0 8px 8px;
    }
  }

  &.preview-frame::after {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 4px solid var(--orange-3);
    z-index: var(--z-frame);
    pointer-events: none;
  }

  // Avoid showing the preview frame/text when printing:
  @media print {
    .preview-sticky-container,
    &.preview-frame::after {
      display: none;
    }
  }
`

export default RTKitView
