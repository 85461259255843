import { isEmpty, join } from "lodash-es"
import { styled } from "styled-components"

import { mashupExercises, getTalentsData } from "../results_utils/talents"

import { Tooltip } from "ui"

const TalentsGrid = styled(function TalentsGrid({ exerciseInstances, userIdMap, className }) {
  if (!exerciseInstances?.length) {
    return null
  }

  const { peopleWithTalent } = mashupExercises(exerciseInstances, userIdMap)
  const talentsData = getTalentsData(exerciseInstances[0])
  const talents = Object.keys(talentsData)

  return (
    <div className={className}>
      <h2 className="text-bold">Talent grid</h2>
      <p className="mb-xs">These are the people on your team who have very high or high talent in each area</p>
      <div className="talent-grid">
        {talents.map((talent) => (
          <Tooltip
            top
            float
            className="talent-grid-box px-large py-small"
            key={talent}
            title={talentsData[talent].shortDescription}
          >
            <h4>{talentsData[talent].title}</h4>
            <div className="text-small mt-xs text-gray-8">
              <PeopleNames people={peopleWithTalent[talent]} />
            </div>
          </Tooltip>
        ))}
      </div>
    </div>
  )
})`
  .talent-grid {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: 1fr 1fr 1fr;

    @media (max-width: ${({ theme }) => theme.mobileLandscapeMax}) {
      grid-template-columns: 1fr;
    }

    @media (min-width: ${({ theme }) => theme.tabletMin}) and (max-width: ${({ theme }) => theme.tabletMax}) {
      grid-template-columns: 1fr 1fr;
    }
  }

  .talent-grid-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    border: 1px solid var(--gray-4);
    box-sizing: border-box;
    box-shadow: 0 0 2px rgb(0 0 0 / 20%);
    border-radius: 4px;
  }
`

const PeopleNames = ({ people }) => {
  if (isEmpty(people)) {
    return "(None)"
  }

  const peopleNames = people.map((p) => p.short_name)
  return join(peopleNames, ", ")
}

export default TalentsGrid
