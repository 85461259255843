import cn from "classnames"
import { Formik } from "formik"
import { styled } from "styled-components"

import { ExerciseComponent } from "../ExerciseComponents"

import ExerciseForm from "domains/Exercise/ExerciseForm"
import { getExerciseAnswer, getExerciseComponent } from "domains/Exercise/results_utils"
import useExerciseForm from "domains/Exercise/useExerciseForm"
import { TextContainer, Loading, AnswerDisplayContent } from "ui"

const CareerHorizonsChartingPathNextStepsEdit = ({ kitInstance, exerciseInstance, className }) => {
  const exercise = kitInstance.kit.exercise
  const { initialValues, isFetching, onSubmit, saveOnChange } = useExerciseForm({
    teamId: kitInstance.team_id,
    slug: exercise.slug,
    version: exercise.version,
  })
  const path_field_id = getExerciseAnswer(exerciseInstance, "path_to_share")?.split(".")[0]
  const next_steps_component = getExerciseComponent(exerciseInstance, `${path_field_id}_next_steps`)

  if (isFetching) {
    return <Loading />
  }

  if (!next_steps_component) {
    return null
  }

  return (
    <div className={cn("text-gray-9", className)}>
      <TextContainer>
        <div className="mb-xl">
          <Formik initialValues={initialValues} onSubmit={onSubmit}>
            <ExerciseForm>
              <ExerciseComponent
                key={next_steps_component.identifier}
                component={next_steps_component.component}
                identifier={next_steps_component.identifier}
                sentence={next_steps_component.results_title}
                components={next_steps_component.components}
                button_text={next_steps_component.button_text}
                initialValue={next_steps_component.initialValue}
                kit={kitInstance.kit}
                saveOnChange={saveOnChange}
                className="mb-large"
              />
            </ExerciseForm>
          </Formik>
        </div>
      </TextContainer>
    </div>
  )
}

const CareerHorizonsChartingPathNextStepsReadOnly = styled(function CareerHorizonsChartingPathNextStepsReadOnly({
  exercise,
  className,
}) {
  const path_field_id = getExerciseAnswer(exercise, "path_to_share")?.split(".")[0]
  const next_steps = getExerciseAnswer(exercise, `${path_field_id}_next_steps`)
  const next_steps_component = getExerciseComponent(exercise, `${path_field_id}_next_steps`)

  if (!next_steps) {
    return null
  }

  const answers = next_steps.filter((step) => step.next_step.value !== "")

  return (
    <div className={cn("text-gray-9", className)}>
      <TextContainer>
        <p className="text-field-label">{next_steps_component.results_title}</p>
        {answers?.length > 0 ? (
          <>
            <div className="answer-list">
              {answers.map(({ next_step }, idx) => (
                <AnswerDisplayContent answer={next_step.value} key={idx} />
              ))}
            </div>
          </>
        ) : (
          <div className="text-normal">
            <p>No results to display</p>
          </div>
        )}
      </TextContainer>
    </div>
  )
})`
  .answer-list .answer {
    margin-bottom: var(--spacing-1);
  }
`

export { CareerHorizonsChartingPathNextStepsEdit, CareerHorizonsChartingPathNextStepsReadOnly }
