import { useField } from "formik"
import { styled } from "styled-components"

import { useTeamLevelExerciseInstance, useSessionExerciseInstances } from "domains/Exercise/resource"
import { getExerciseAnswer } from "domains/Exercise/results_utils"
import { EditableStickyNotesWithFavoritedUsers } from "domains/KitSession/components/StickyNotes"
import { useKitSession } from "domains/KitSession/KitSessionContext"
import { useHasTeamFeature } from "domains/Results/utils"
import { useKitParticipants } from "resources/monthly_kit"
import { useUser } from "resources/users"
import { Loading } from "ui"
import Grid from "ui/Grid"
import { useEffectAfterChange } from "ui/hooks"
import { SHARED_FLAGS } from "ui/hooks/useFeatures"

const ExerciseStickyNoteOverallTeamFavorites = ({ className, name, exerciseIdentifier, saveOnChange }) => {
  const { kitInstance, team } = useKitSession()
  const { data: user } = useUser({ userId: "me" })
  const { data: kitParticipants } = useKitParticipants({ kitInstance })
  const isTeamLead = user.id === team.team_lead_id
  const { enabled: sessionRealtimeUpdates } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_ANSWER_UPDATE)
  const {
    data: exerciseInstances,
    isFetching: isFetchingUserExerciseInstances,
    isInitialLoading: isInitialLoadingExerciseInstances,
  } = useSessionExerciseInstances(kitInstance?.id, {
    sessionRealtimeUpdates,
    refetchInterval: 30000,
  })
  const {
    data: teamExerciseInstance,
    isFetching: isFetchingTeamExerciseInstance,
    isInitialLoading: isInitialLoadingTeamExerciseInstances,
  } = useTeamLevelExerciseInstance({
    sessionRealtimeUpdates,
    teamId: team?.id,
    kitInstanceId: kitInstance?.id,
    slug: kitInstance.kit.exercise.slug,
    ...(!isTeamLead && { refetchInterval: 30000 }),
  })

  const [{ value: teamNormStickyNoteFavorites }, _, { setValue }] = useField(name)

  useEffectAfterChange(() => {
    if (!isFetchingUserExerciseInstances && !isFetchingTeamExerciseInstance) {
      const allFavoriteNotes = getTeamStickyNotes({
        exerciseInstances,
        exerciseIdentifier,
        teamExerciseInstance,
        name,
      })
      const teamNorms = Object.values(allFavoriteNotes)
        ?.filter(({ favoritedUsers }) => favoritedUsers?.length > 0)
        ?.sort(
          (firstStickyNote, secondStickyNote) =>
            secondStickyNote?.favoritedUsers?.length - firstStickyNote?.favoritedUsers?.length
        )
      setValue(teamNorms)
    }
  }, [
    exerciseInstances,
    teamExerciseInstance,
    isFetchingUserExerciseInstances,
    isFetchingTeamExerciseInstance,
    name,
    exerciseIdentifier,
    saveOnChange,
    setValue,
  ])

  if (isInitialLoadingExerciseInstances || isInitialLoadingTeamExerciseInstances) {
    return <Loading />
  }

  const handleOnFavoriteClick = (stickyNoteIndex) => {
    const isCurrentlyTeamNormFavorited = teamNormStickyNoteFavorites[stickyNoteIndex]?.isFavorite === true

    const updatedTeamNormFavorites = teamNormStickyNoteFavorites.map((stickyNote, idx) =>
      idx === stickyNoteIndex
        ? { ...teamNormStickyNoteFavorites[stickyNoteIndex], isFavorite: !isCurrentlyTeamNormFavorited }
        : stickyNote
    )
    setValue(updatedTeamNormFavorites)
    saveOnChange(name, updatedTeamNormFavorites)
  }

  function handleOnStickyNoteChange() {
    const updatedUserStickyNotes = teamNormStickyNoteFavorites.filter((stickyNote) => stickyNote.value.length > 0)
    if (updatedUserStickyNotes.length === 0) {
      saveOnChange(name, [{ value: "" }])
    } else {
      saveOnChange(name, updatedUserStickyNotes)
    }
  }

  return (
    <Grid className={className} $columns="3" $columnsMobile="1" $columnsTablet="2" $gap="12px">
      <EditableStickyNotesWithFavoritedUsers
        key={teamNormStickyNoteFavorites?.length}
        name={name}
        stickyNotes={teamNormStickyNoteFavorites}
        isTeamLead={isTeamLead}
        kitParticipants={kitParticipants}
        handleOnFavoriteClick={handleOnFavoriteClick}
        handleOnStickyNoteChange={handleOnStickyNoteChange}
      />
    </Grid>
  )
}

const getTeamStickyNotes = ({ exerciseInstances, exerciseIdentifier, teamExerciseInstance, name }) => {
  const allFavoriteNotes = {}
  const teamStickyNotes = getExerciseAnswer(teamExerciseInstance, name)
  !!teamStickyNotes &&
    teamStickyNotes.forEach((stickyNote) => {
      if (!allFavoriteNotes[stickyNote.value]) {
        allFavoriteNotes[stickyNote.value] = {
          ...stickyNote,
        }
      }
    })
  const userNoteAlreadyInTeamStickyNotes = Object.values(allFavoriteNotes)?.map(
    (stickyNote) => stickyNote?.originalValue
  )
  exerciseInstances.forEach((exerciseInstance) => {
    const currentUserStickyNotes = getExerciseAnswer(exerciseInstance, exerciseIdentifier)?.filter(
      (stickyNote) => stickyNote?.isUserOverallTeamFavorite
    )
    currentUserStickyNotes?.forEach((stickyNote) => {
      if (!userNoteAlreadyInTeamStickyNotes.includes(stickyNote.value)) {
        if (!allFavoriteNotes[stickyNote.value]) {
          allFavoriteNotes[stickyNote.value] = {
            value: stickyNote.value,
            isFavorite: false,
            favoritedUsers: stickyNote?.isUserOverallTeamFavorite ? [exerciseInstance.user_id] : [],
            originalValue: stickyNote.value,
          }
        } else if (
          !allFavoriteNotes[stickyNote.value]?.favoritedUsers?.includes(exerciseInstance.user_id) &&
          stickyNote?.isUserOverallTeamFavorite
        ) {
          allFavoriteNotes[stickyNote.value].favoritedUsers = [
            ...allFavoriteNotes[stickyNote.value].favoritedUsers,
            exerciseInstance.user_id,
          ]
        }
      }
    })
  })
  return allFavoriteNotes
}

export default styled(ExerciseStickyNoteOverallTeamFavorites)``
