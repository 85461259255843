import cn from "classnames"

import { getExerciseAnswer } from "domains/Exercise/results_utils"
import { TextContainer, AnswerDisplayContent } from "ui"

const FeedbackFlexUserResults = ({ exercise, teamLeadExercise, selectedUser, userIdMap, className }) => {
  const flex_feedback_action = getExerciseAnswer(exercise, "flex_feedback_action")
  const flex_feedback_appreciated = getExerciseAnswer(exercise, "flex_feedback_appreciated_because")
  const assignmentUserId = getExerciseAnswer(teamLeadExercise, `flex_feedback_assignment_${selectedUser.id}`)
  const assignmentUser = userIdMap[assignmentUserId]
  const assignmentUserDisplayValue = assignmentUser ? assignmentUser.short_name : "[not selected]"

  return (
    <div className={cn("text-gray-9", className)}>
      <TextContainer>
        <h3 className="mb-small">Feedback for {assignmentUserDisplayValue}</h3>
        <AnswerDisplayContent title="I noticed that:" answer={flex_feedback_action} />
        <AnswerDisplayContent title="I appreciated it because:" answer={flex_feedback_appreciated} />
      </TextContainer>
    </div>
  )
}

export default FeedbackFlexUserResults
