import cn from "classnames"
import { Link } from "react-router-dom"

import { getKitTypeInfo } from "domains/LeaderKit/utils"
import { Logo, View } from "ui"
import { useCurrentTheme } from "ui/ThemeUpdateContext"

const ThemeHeader = ({ kitInstance, className }) => {
  const theme = useCurrentTheme()

  const kitDefinition = kitInstance.kit
  const kitHeader = getKitTypeInfo(kitDefinition.type).header
  const homeUrl = kitInstance.home_url

  return (
    <Link className={cn("no-underline", className)} to={homeUrl}>
      <View>
        {!!theme.hideHeader && <Logo showName={false} />}
        <div>
          <div className="text-rising-orange text-small text-semi-bold">{kitHeader}</div>
          <h3 className="text-gray-9 text-semi-bold">{kitDefinition.title}</h3>
        </div>
      </View>
    </Link>
  )
}

export default ThemeHeader
