import { Form } from "formik"
import { useTimezoneSelect } from "react-timezone-select"

import { FormMessage } from "forms"
import { AdvancedSelectField, TextField } from "forms/fields"
import { View } from "ui"

const UserAccountForm = ({ saveOnChange }) => {
  const { options: timeZoneOptions } = useTimezoneSelect({})
  return (
    <Form name="account-profile-form" autoComplete="off" className="space-y-large">
      <TextField label="First name *" name="first_name" autoFocus size="medium" saveOnChange={saveOnChange} />
      <TextField label="Last name *" name="last_name" size="medium" autoComplete="off" saveOnChange={saveOnChange} />
      <View as="label" $flexDirection="column">
        <div className="text-field-label label mb-xs">Time zone *</div>
        <AdvancedSelectField
          name="time_zone"
          width={330}
          menuWidth={330}
          saveOnChange={saveOnChange}
          options={timeZoneOptions}
        />
      </View>
      <FormMessage />
    </Form>
  )
}

export default UserAccountForm
