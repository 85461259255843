import { useUpdateKitStepCompletedUsers } from "resources/monthly_kit"
import { useEffectAfterFirstRender } from "ui/hooks"

const MarkStepCompleted = ({ kitInstance, sessionStep }) => {
  const { mutateAsync: updateKitStepCompletedUsers } = useUpdateKitStepCompletedUsers({
    kitInstanceId: kitInstance.id,
    stepPath: sessionStep.path,
  })
  useEffectAfterFirstRender(async () => {
    await updateKitStepCompletedUsers()
  })
  return null
}

export default MarkStepCompleted
