import { Form } from "formik"

import { FormMessage } from "forms"
import { TextField, SelectField, PreferredPronounsField, HearUsThroughField, JobFunctionsField } from "forms/fields"
import { SCHOOL_JOB_FUNCTIONS } from "forms/fields/JobFunctionsField"
import { NextButton } from "ui"

const org_types = [
  { value: "primary_school", label: "Primary school" },
  { value: "secondary_school", label: "Secondary school" },
  { value: "k_p_12", label: "K/P-12" },
  { value: "special_school", label: "Special school" },
  { value: "senior_secondary", label: "Senior secondary" },
  { value: "other", label: "Other" },
]

const SchoolProfileForm = ({ nextButtonText }) => (
  <Form name="account-free-trial-details-form" autoComplete="off">
    <div className="space-y-large">
      <TextField label="Your school or organisation name" name="org_name" size="large" />
      <SelectField label="How many years have you been in education?" name="years_in_industry" size="large">
        <option disabled value="" />
        <option value="Less than 1 year">Less than 1 year</option>
        <option value="1 to 2 years">1 to 2 years</option>
        <option value="2 to 5 years">2 to 5 years</option>
        <option value="5 to 10 years">5 to 10 years</option>
        <option value="More than 10 years">More than 10 years</option>
      </SelectField>
      <SelectField label="How many years have you been leading teams?" name="years_of_management" size="large">
        <option disabled value="" />
        <option value="Less than 1 year">Less than 1 year</option>
        <option value="1 to 2 years">1 to 2 years</option>
        <option value="2 to 5 years">2 to 5 years</option>
        <option value="5 to 10 years">5 to 10 years</option>
        <option value="More than 10 years">More than 10 years</option>
      </SelectField>
      <SelectField label="Which best describes your setting?" name="org_type" size="large">
        <option disabled value="" />
        {org_types.map((item) => (
          <option value={item.value} key={item.value}>
            {item.label}
          </option>
        ))}
      </SelectField>
      <JobFunctionsField
        label="What is your job function"
        name="job_function"
        size="large"
        jobFunctions={SCHOOL_JOB_FUNCTIONS}
      />
      <PreferredPronounsField label="How would you like us to refer to you?" name="pronouns" size="large" />
      <HearUsThroughField label="How did you hear about us?" name="hear_us_through" size="large" />

      <FormMessage />
    </div>

    <NextButton submit={true} text={nextButtonText} className="mt-xl mb-xs" />
  </Form>
)

export default SchoolProfileForm

export { org_types }
