import { MARKETING_PRICING_URL } from "domains/GetStarted/resource"
import { PageTitle } from "ui"

const SelectPayment = () => (
  <div className="main-container">
    <PageTitle>Get Started</PageTitle>
    <h2 className="mb-large">Your subscription is not complete</h2>
    <p className="mb-xs">
      Your Rising Team account is set up, but we still we need a pricing plan and payment details to activate your
      subscription.
    </p>
    <a href={MARKETING_PRICING_URL}>Select a pricing plan</a>
  </div>
)

export default SelectPayment
