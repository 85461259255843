import { useState } from "react"
import { Navigate, useNavigate } from "react-router-dom"

import NameAndProfileForm from "./NameAndProfileForm"

import { getFirebaseAuthInfo } from "domains/Authentication/firebase"
import { handleErrors } from "forms"
import { useUpdateCurrentUser, useUser } from "resources/users"
import { Loading, PageTitle } from "ui"
import { useQueryParams, useEffectAfterChange } from "ui/hooks"

const RegisterName = () => {
  const { next } = useQueryParams()
  const navigate = useNavigate()
  const navigateNextUrl = next ?? "/"

  const { data: user, isFetching: userIsFetching } = useUser({ userId: "me" })
  const isFirstNameSet = !!user?.first_name?.trim().length
  const isLastNameSet = !!user?.last_name?.trim().length
  const [initialFirstName, setInitialFirstName] = useState(null)
  const [initialLastName, setInitialLastName] = useState(null)
  const { mutateAsync: updateCurrentUser } = useUpdateCurrentUser()

  useEffectAfterChange(() => {
    if (!userIsFetching && !isFirstNameSet && !isLastNameSet) {
      const { isAuthenticatedWithFirebase, firebaseUserDisplayName } = getFirebaseAuthInfo()
      if (isAuthenticatedWithFirebase && firebaseUserDisplayName) {
        const [first, ...rest] = firebaseUserDisplayName.split(" ") ?? []
        const firstName = first ?? ""
        const lastName = rest?.join(" ") ?? ""
        setInitialFirstName(firstName)
        setInitialLastName(lastName)
      }
    }
  }, [userIsFetching, isFirstNameSet, isLastNameSet])

  if (!user && userIsFetching) {
    return <Loading />
  }

  if (!!(isFirstNameSet && isLastNameSet)) {
    return <Navigate to={navigateNextUrl} replace />
  }

  const showProfile = !user.is_a_team_lead // team leads will go through onboarding to collect profile info
  const onSubmit = handleErrors(async (values) => {
    await updateCurrentUser(values)
    navigate(navigateNextUrl)
  })

  return (
    <div>
      <PageTitle>Create your account</PageTitle>
      <h2>Create your account</h2>
      <NameAndProfileForm
        user={user}
        showFirstAndLastName={true}
        showProfile={showProfile}
        onSubmit={onSubmit}
        initialFirstName={initialFirstName}
        initialLastName={initialLastName}
      />
    </div>
  )
}

export default RegisterName
