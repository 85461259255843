import { CopyIcon } from "icons/FontAwesomeIcons"
import { View, Button } from "ui"

const PeerTemplate = ({ kit }) => (
  <>
    <h2 className="text-gray-9 mt-xl mb-medium">The PEER Framework</h2>
    <p className="mb-medium">
      The PEER framework is a helpful tool for remembering the elements that make feedback useful. Want to use it
      outside of a Rising Team session? Make a copy of a Google doc below.
    </p>
    <View>
      <a href={kit.assets.peer_google_doc_link} target="_blank" rel="noopener noreferrer">
        <Button className="tertiary mb-xl">
          <CopyIcon />
          Copy the Google doc
        </Button>
      </a>
    </View>
  </>
)

export default PeerTemplate
