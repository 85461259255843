import { getExerciseComponent, getExerciseAnswer, formatExercisePieChartData } from "domains/Exercise/results_utils"
import { PieSliceChart, TextContainer } from "ui"
import { getAriaAdditionalInfoFunc, getRatingColorFunc } from "ui/PieSliceRatingUtils"

const PieSliceGroupRatingFieldPieChart = ({ identifier, pieGroupId, exerciseInstance, className }) => {
  if (!exerciseInstance) {
    return null
  }

  const ratingValues = getExerciseAnswer(exerciseInstance, identifier)
  const sliceValues = getExerciseAnswer(exerciseInstance, pieGroupId)
  const otherValue = getExerciseAnswer(exerciseInstance, `${pieGroupId}_other`)
  const { items, colors } = getExerciseComponent(exerciseInstance, pieGroupId)

  if (!sliceValues || !Object.values(sliceValues).some((value) => value > 0)) {
    return (
      <TextContainer>
        <p>It looks like you haven't selected any slices to rate.</p>
      </TextContainer>
    )
  }

  const selectedItems = items.filter(({ identifier }) => sliceValues[identifier] > 0)
  const data = formatExercisePieChartData(selectedItems, sliceValues, otherValue)
  const keys = selectedItems.map(({ identifier }) => identifier)
  const getColor = getRatingColorFunc(keys, ratingValues, colors)
  const getAriaAdditionalInfo = getAriaAdditionalInfoFunc(ratingValues)

  return (
    <PieSliceChart
      data={data}
      colors={getColor}
      arcLabelsTextColor="white"
      getAriaAdditionalInfo={getAriaAdditionalInfo}
      className={className}
    />
  )
}

export default PieSliceGroupRatingFieldPieChart
