import cn from "classnames"
import { styled } from "styled-components"

import View from "./View"

const SidebarCallout = ({ children, className }) => (
  <View
    $flexDirection="column"
    $alignItems="center"
    $justifyContent="center"
    className={cn("text-gray-8 text-center bg-yellow-tint border-radius-xl p-medium", className)}
  >
    {children}
  </View>
)

export default styled(SidebarCallout)`
  min-height: 368px;
  width: 328px;
`
