import { useState } from "react"
import { styled } from "styled-components"

import { getExerciseAnswer, getTeamLead, getUserExerciseInstance } from "../results_utils"

import CrisisResponseResponderTypeGrid from "./CrisisResponseResponderTypeGrid"

import { RESPONDER_TYPES_DATA, getChartConfig } from "domains/Exercise/results_utils/crisis_response"
import { createFocusUserFunc, createGetColorFunc } from "domains/Exercise/results_utils/radarchart"
import { CrisisResponsePracticeResponse } from "domains/KitSession/SessionStepComponents/CrisisResponseScenarioResultStep"
import { AnswerDisplayContent, RadarChart } from "ui"

const CrisisResponseSuperpower = styled(function CrisisResponseSuperpower({ className }) {
  return (
    <div className={className}>
      {Object.values(RESPONDER_TYPES_DATA).map((responderType) => (
        <div
          key={responderType.title}
          className="responder-type-box border border-gray-4 border-radius text-center px-xxl py-large"
        >
          <h4 className="text-gray-9 mb-xs">{responderType.title}</h4>
          <p className="responder-role text-italic text-gray-8 text-semi-bold mb-xs">{responderType.role}</p>
          <p className="text-gray-8">{responderType.description}</p>
        </div>
      ))}
    </div>
  )
})`
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 16px;

  @media (min-width: ${({ theme }) => theme.desktopMin}) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .responder-type-box {
    box-shadow: 0 1px 2px rgb(0 0 0 / 20%);

    .responder-role {
      font-size: 1.0625rem;
    }

    .point-bar {
      width: 100px;
      height: 16px;
    }
  }
`

const CrisisResponseChart = styled(function CrisisResponseChart({ userIdMap, exerciseInstances, ...props }) {
  const [focusedUsername, setFocusedUsername] = useState(null)
  const chartConfig = getChartConfig(exerciseInstances, userIdMap)

  const getColorFunc = createGetColorFunc(focusedUsername)
  const focusUser = createFocusUserFunc({ focusedUsername, setFocusedUsername })

  return <RadarChart {...props} {...chartConfig} showLegend={true} onKeyClick={focusUser} getColorFunc={getColorFunc} />
})`
  .chart-container {
    border: none;

    @media (min-width: ${({ theme }) => theme.tabletMin}) {
      flex: 4;
    }
  }
`

const CrisisResponsePracticeScenario = ({ userIdMap, exerciseInstances, team }) => {
  const teamLead = getTeamLead(userIdMap, team)
  const exercise = getUserExerciseInstance(exerciseInstances, teamLead)
  const team_scenario = getExerciseAnswer(exercise, "team_scenario")

  return (
    <>
      <h3 className="text-bold text-gray-9 mb-xs">Your team scenario</h3>
      <AnswerDisplayContent className="mb-large" answer={team_scenario} />
      <CrisisResponsePracticeResponse participants={userIdMap} exerciseInstances={exerciseInstances} team={team} />
    </>
  )
}

const CrisisResponseTeamSummary = ({
  userIdMap,
  exerciseInstances,
  team,
  teamResults = false,
  practiceScenario = true,
  className,
}) => (
  <div className={className}>
    {teamResults ? (
      <>
        <h2 className="text-gray-9 mb-xl">Responder types</h2>
        <CrisisResponseChart userIdMap={userIdMap} exerciseInstances={exerciseInstances} />
        <CrisisResponseResponderTypeGrid
          exerciseInstances={exerciseInstances}
          userIdMap={userIdMap}
          className="mb-xl"
        />
        {!!practiceScenario && (
          <>
            <h2 className="text-gray-9 mb-large">The practice scenario</h2>
            <CrisisResponsePracticeScenario userIdMap={userIdMap} exerciseInstances={exerciseInstances} team={team} />
          </>
        )}
      </>
    ) : (
      <>
        <h2 className="text-gray-9 mb-xl">Team summary</h2>
        <CrisisResponseChart userIdMap={userIdMap} exerciseInstances={exerciseInstances} />
        <h3 className="text-gray-9 mt-xxl mb-medium">The Responder Types</h3>
        <CrisisResponseSuperpower className="mb-xl" />
      </>
    )}
  </div>
)

export default CrisisResponseTeamSummary

export { CrisisResponseSuperpower, CrisisResponseChart }
