import { Form, Formik, ErrorMessage, FormikContext } from "formik"
import { useState, useContext } from "react"
import { styled } from "styled-components"

import { Accordion } from "components"
import SaveButton from "domains/Admin/SaveButton"
import { getTeamLeadOptions, canUserManageAccount } from "domains/Admin/utils"
import { Yup, handleErrors } from "forms"
import { EmailField, ValidatedField, AdvancedSelectField } from "forms/fields"
import { useGetAccountTeamLeads } from "resources/billing"
import { useUpdateTeam } from "resources/teams"
import { View } from "ui"
import useFeatures from "ui/hooks/useFeatures"

const radioIconStyles = `
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 3px solid white;
  box-shadow: 0 0 0 1px var(--gray-9);
`
const UncheckedRadioIcon = styled.div`
  ${radioIconStyles}
  background-color: transparent;
`
const CheckedRadioIcon = styled.div`
  ${radioIconStyles}
  background-color: var(--rising-green);
`

const TeamLeadField = ({
  className,
  team,
  account,
  user,
  initialEmailAccordionOpen = true,
  onEmailAccordionOpen = null,
  onIdAccordionOpen = null,
}) => {
  const features = useFeatures()
  const { data: leads } = useGetAccountTeamLeads(canUserManageAccount(account, user, features) ? account.id : null)
  const { mutateAsync: updateTeam } = useUpdateTeam({ team, currentUser: user })
  const [emailAccordionOpen, setEmailAccordionOpen] = useState(initialEmailAccordionOpen)
  const [isSaving, setIsSaving] = useState(false)
  const [isSaved, setIsSaved] = useState(false)
  const [isError, setIsError] = useState(false)
  const formikContext = useContext(FormikContext)

  const teamLeadOptions = getTeamLeadOptions(account, leads, user, features, team).map(({ id, name, email }) => ({
    value: id,
    label: name || email,
  }))

  const initialValues = {
    team_lead_id: null,
    team_lead_email: "",
  }

  const teamLeadId = Yup.number().nullable()
  const teamLeadEmail = Yup.string()
  const schema = Yup.object().shape({
    team_lead_email: emailAccordionOpen ? teamLeadEmail.required("Please enter an email.") : teamLeadEmail,
    team_lead_id: emailAccordionOpen ? teamLeadId : teamLeadId.required("Please select a new team lead"),
  })

  const onSubmit = handleErrors(
    async (values) => {
      const { team_lead_email, team_lead_id } = values
      setIsSaved(false)
      setIsSaving(true)
      await updateTeam({
        ...(emailAccordionOpen ? { team_lead_email } : { team_lead_id }),
        // don't send both team_lead_id and team_lead_email at once
      })
      setIsSaving(false)
      setIsSaved(true)
    },
    (error) => {
      setIsError(true)
      return error
    }
  )

  function reset(setErrors) {
    setIsSaving(false)
    setIsSaved(false)
    setIsError(false)
    setErrors({})
  }

  const renderField = ({ setValues, setErrors, values }) => (
    <>
      <Accordion
        externalOpenState={emailAccordionOpen}
        beforeOpen={() => {
          reset(setErrors)
          setEmailAccordionOpen(true)
          onEmailAccordionOpen?.()
        }}
        closedIcon={UncheckedRadioIcon}
        openIcon={CheckedRadioIcon}
        title={() => <>Invite new team lead by email</>}
      >
        <View className="mb-medium">
          <ValidatedField
            base={EmailField}
            className="team-lead-email-field mr-large"
            name="team_lead_email"
            size="medium"
            autoComplete="off"
            value={values.team_lead_email}
            onClick={() => reset(setErrors)}
            onClear={() => setValues({ ...values, team_lead_email: "" })}
          />
          {!formikContext && (
            <SaveButton saving={isSaving} saved={!!isSaved && "Invite sent!"} error={isError}>
              Send invite
            </SaveButton>
          )}
        </View>
      </Accordion>
      <Accordion
        externalOpenState={!emailAccordionOpen}
        beforeOpen={() => {
          reset(setErrors)
          setEmailAccordionOpen(false)
          onIdAccordionOpen?.()
        }}
        closedIcon={UncheckedRadioIcon}
        openIcon={CheckedRadioIcon}
        title={() => <>Assign an existing user as team lead</>}
      >
        <View>
          <div onClick={() => reset(setErrors)}>
            <AdvancedSelectField
              className="team-lead-select mr-large"
              name="team_lead_id"
              placeholder="Pick a team lead"
              options={teamLeadOptions}
              width={330} // same as default input width
            />
            <ErrorMessage component="div" className="text-danger mt-xs" data-cy="field-message" name="team_lead_id" />
          </div>
          {!formikContext && (
            <SaveButton saving={isSaving} saved={!!isSaved && "Assigned!"} error={isError}>
              Assign
            </SaveButton>
          )}
        </View>
      </Accordion>
    </>
  )

  return (
    <div className={className}>
      {formikContext ? (
        renderField(formikContext)
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={onSubmit}
        >
          {({ values, setValues, setErrors }) => <Form>{renderField({ setValues, setErrors, values })}</Form>}
        </Formik>
      )}
    </div>
  )
}

export default TeamLeadField
