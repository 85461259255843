import cn from "classnames"
import { Fragment } from "react"

import { getExerciseAnswer } from "../results_utils"

import { Table } from "components"
import { useSessionExerciseInstances } from "domains/Exercise/resource"
import { useKitSession } from "domains/KitSession/KitSessionContext"
import { useHasTeamFeature } from "domains/Results/utils"
import { useKitParticipants } from "resources/monthly_kit"
import { Loading, View } from "ui"
import { SHARED_FLAGS } from "ui/hooks/useFeatures"
import Podium from "ui/Podium"

const GuessingGameRanking = ({ identifier, className }) => {
  const { kitInstance, team } = useKitSession()
  const { enabled: sessionRealtimeUpdatesV2 } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_REPLACE_POLLING)
  const { data: participants } = useKitParticipants({ kitInstance, sessionRealtimeUpdates: sessionRealtimeUpdatesV2 })
  const { enabled: sessionRealtimeUpdates } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_ANSWER_UPDATE)

  const { data: exerciseInstances } = useSessionExerciseInstances(kitInstance?.id, {
    sessionRealtimeUpdates,
    refetchInterval: 10000,
  })

  if (!exerciseInstances || !participants) {
    return <Loading />
  }
  const topScorers = createQuizResults({ identifier, exerciseInstances, participants })

  return (
    topScorers?.length >= 3 && (
      <Fragment>
        <p className="mb-small">Give a round of applause for the top 3 who got the most right guesses!</p>
        <h2 className="mb-medium">Top 3 correct guessers</h2>
        <View className={cn(className, "m-auto")} $maxWidth="600px" $flexDirection="column">
          <View $flexDirection="column" className="mb-medium">
            <Podium topScorers={topScorers} />
          </View>
          <View $flexDirection="column" className="mb-medium" $justifyContent="center" $alignItems="center">
            <Table
              notFound="No records found."
              columns={[
                { id: "name", Header: "Top guessers", accessor: "name" },
                { id: "percentage", Header: "Correct guesses", accessor: "percentage" },
              ]}
              rows={topScorers}
            />
          </View>
        </View>
      </Fragment>
    )
  )
}

function createQuizResults({ identifier, exerciseInstances, participants }) {
  const sortedUserScores = exerciseInstances
    .map((exerciseInstance) => {
      const userAnswers = getExerciseAnswer(exerciseInstance, identifier)
      const user = participants.find((participant) => participant.id === exerciseInstance.user_id)
      let score = 0
      for (const userAnswer of userAnswers ?? []) {
        if (Number(userAnswer.userAnswer) === Number(userAnswer.correctAnswer)) {
          score += 1
        }
      }
      return {
        name: user?.short_name,
        score,
        percentage: !userAnswers?.length ? "0%" : `${Math.floor((score / userAnswers.length) * 100)}%`,
      }
    })
    .filter((user) => user.score > 0)
    .sort((a, b) => b.score - a.score)

  return sortedUserScores.length > 3 ? sortedUserScores.slice(0, 3) : sortedUserScores
}

export default GuessingGameRanking
