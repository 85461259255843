import cn from "classnames"
import { matchSorter } from "match-sorter"
import { useState, useRef, useMemo } from "react"
import { useTable, useSortBy } from "react-table"
import { styled } from "styled-components"

import SearchHighlight from "components/SearchHighlight"
import { AngleUpIcon, AngleDownIcon } from "icons/FontAwesomeIcons"
import { Tooltip } from "ui"
import { useEffectAfterChange } from "ui/hooks"

const LINE_NUMBER_COLUMN_ID = "line-number"
// For performance reasons, only render max 100 table rows while searching:
// (as search string grows, desired results will move within the first 100)
const MAX_SEARCH_RESULTS = 100
// For performance reasons, only highlight search-matching text in table rows
// if number of results is below or equal to this threshold:
const MAX_HIGHLIGHTED_SEARCH_RESULTS = 100

const TABLE_ROW_HEIGHT = 44
const DEFAULT_MAX_TABLE_HEIGHT = 465

function callIfFunction(value, ...args) {
  return typeof value === "function" ? value(...args) : value
}

const Table = ({
  className,
  columns,
  rows,
  query,
  defaultSort = null,
  empty = "No data.",
  notFound = "No results found.",
  isNumbered = false,
  maxColumnWidth = null,
  maxHeight: _maxHeight = DEFAULT_MAX_TABLE_HEIGHT,
  maxHeightNumberOfRows: _maxHeightNumberOfRows = null,
  // max-height calculated to show 1/2 row at bottom to indicate more rows below
  // NOTE: linters may complain that this prop is unused, but it actually IS used in
  //       styled CSS. We should keep it here for clarity of component prop signature.
}) => {
  const filterString = query?.join(" ") ?? ""
  const tableRef = useRef()
  const [tableWidth, setTableWidth] = useState(0)

  useEffectAfterChange(() => {
    setTableWidth(tableRef.current.offsetWidth)
  }, [tableRef, columns, rows, query, maxColumnWidth])

  // Add line numbers if desired:
  const columnsMemo = useMemo(() => {
    const hasOwnLineNumberColumn = columns.some((c) => c.id === LINE_NUMBER_COLUMN_ID)
    return !isNumbered || hasOwnLineNumberColumn
      ? columns
      : [
          {
            id: LINE_NUMBER_COLUMN_ID,
            Header: "",
            searchable: false,
          },
          ...columns,
        ]
  }, [columns, isNumbered])

  // Pre-sort/filter rows using matchSorter for best-match order while filtering:
  const rowsMemo = useMemo(() => {
    // Add index to each row, used for sort/filter-resilient line number scheme below:
    const identifiedRows = rows.map((row, index) => ({ ...row, index }))
    if (identifiedRows?.length && filterString.length) {
      const max = MAX_SEARCH_RESULTS
      const keys = columns
        .filter((c) => c.searchable)
        .map((c) => (typeof c.searchable === "function" ? c.searchable : c.accessor))
      return matchSorter(identifiedRows, filterString, { keys }).slice(0, max)
    } else {
      return identifiedRows ?? []
    }
  }, [columns, rows, filterString])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows: rowData,
    prepareRow,
  } = useTable(
    {
      columns: columnsMemo,
      data: rowsMemo,
      ...(!defaultSort
        ? {}
        : {
            initialState: {
              // don't sort by column while filtering; we want to allow matchSorter to
              // order items in "best match" order according to its heuristics:
              sortBy: filterString.length ? [] : defaultSort,
            },
          }),
    },
    useSortBy
  )

  // Set up line numbering
  // Rows should remain consistently numbered even when re-ordered due to filtering
  // and sorting (eg. a row that started off at position "1" should always have
  // number "1" next to it, regardless of how the table is filtered or sorted).
  const [lineNumberMap, setLineNumberMap] = useState({})
  useMemo(() => {
    // Only update lineNumberMap if rowData length increases; ie. more rows have been
    // loaded. If rowData.length decreases it it due to table filtering, and we want
    // to avoid updating lineNumberMap to ensure consistent row numbering.
    if (Object.keys(lineNumberMap).length < rowData.length) {
      setLineNumberMap(Object.fromEntries(rowData.map((row, idx) => [row.original.index, idx + 1])))
    }
  }, [lineNumberMap, rowData])

  const getSortIcon = (column) => {
    const { isSorted, isSortedDesc } = column
    let Icon = isSortedDesc ? AngleUpIcon : AngleDownIcon
    if (column.reverseHeaderSortArrow) {
      Icon = isSortedDesc ? AngleDownIcon : AngleUpIcon
    }
    return !isSorted ? null : <Icon className="ml-xs" />
  }

  return (
    <div className={className} style={{ "--table-width": `${tableWidth}px` }}>
      <table className="text-normal" ref={tableRef} {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, idx) => (
            <tr key={idx} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, idx) => (
                <th
                  className={cn({
                    shrink: column.shrink,
                    "text-nowrap": !column.wrapHeader,
                  })}
                  key={idx}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  {column.render("Header")} {getSortIcon(column)}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {!rowData.length && (
            <tr>
              <td colSpan={columns.length + 1}>{filterString.length ? notFound : empty}</td>
            </tr>
          )}
          {rowData.map((row, rowIdx) => {
            prepareRow(row)
            return (
              <tr key={rowIdx} {...row.getRowProps()}>
                {row.cells.map((cell, cellIdx) => {
                  const noHighlighting = !!(
                    !filterString?.length ||
                    rowData.length > MAX_HIGHLIGHTED_SEARCH_RESULTS ||
                    !cell.column.searchable
                  )
                  return (
                    <td
                      key={cellIdx}
                      {...cell.getCellProps()}
                      className={cn(callIfFunction(cell.column.className, row.original), {
                        "text-nowrap": !cell.column.wrap,
                      })}
                      title={callIfFunction(cell.column.title, row.original)}
                      onClick={() => cell.column.onClick?.(row.original)}
                    >
                      <Tooltip left wrap title={callIfFunction(cell.column.tooltip, row.original)}>
                        {cell.column.id === LINE_NUMBER_COLUMN_ID ? (
                          lineNumberMap[row.original.index] ?? null
                        ) : noHighlighting ? (
                          cell.value ?? null
                        ) : (
                          <span>
                            <SearchHighlight
                              matches={query}
                              text={cell.value ?? null}
                              highlight={(text, idx) => (
                                <span key={idx} className="text-danger">
                                  {text}
                                </span>
                              )}
                              minLength={3} // only highlight matches longer than 3 chars
                            />
                          </span>
                        )}
                      </Tooltip>
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
      <div className="bottom-shadow-background"></div>
      <div className="bottom-shadow"></div>
    </div>
  )
}

function getMaxHeight(props) {
  let maxHeight
  if (typeof props.maxHeightNumberOfRows === "number") {
    maxHeight = props.maxHeightNumberOfRows * TABLE_ROW_HEIGHT + 2
  } else if (props.maxHeightNumberOfRows != null) {
    throw new Error("Table.js: maxHeightNumberOfRows prop should be a number.")
  } else {
    maxHeight = props.maxHeight ?? DEFAULT_MAX_TABLE_HEIGHT
  }
  return typeof maxHeight === "number" ? `${maxHeight}px` : maxHeight
}

export default styled(Table)`
  --row-height: ${TABLE_ROW_HEIGHT}px;
  --max-height: ${getMaxHeight};

  width: 100%;
  border: 1px solid #ddd;
  border-radius: 8px;
  background: var(--gray-2);
  position: relative;
  color: var(--gray-8);

  /// General Table Styles ///

  table {
    width: 100%;
    border-spacing: 0; // no white lines between table cells
  }

  tr {
    height: var(--row-height);
  }

  th {
    padding: 8px 12px;

    &.shrink {
      width: 1px; // make column "shrink" to minimum width to contain header text
      padding-right: 15px; // adjust to ensure header text remains centered
    }
  }

  tr,
  td {
    ${({ maxColumnWidth }) => (maxColumnWidth ? `max-width: ${maxColumnWidth}px;` : "")}
  }

  td {
    padding: 4px 12px;
  }

  --z-table-shadow: var(--z-above-zero);
  --z-table-shadow-mask: calc(var(--z-above) + var(--z-table-shadow));
  --z-table-header: calc(var(--z-above) + var(--z-table-shadow-mask));
  --z-table-vertical-lines: calc(var(--z-above) + var(--z-table-header));

  th,
  td {
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;

    &:not(:last-child)::after {
      content: "";
      position: absolute;
      pointer-events: none;
      top: 0;
      right: 0;
      width: 1px;
      height: 100%;
      background: var(--gray-5);
      z-index: var(--z-table-vertical-lines);
    }
  }

  td:first-child:last-child {
    text-align: center; // empty/notFound row
  }

  tbody tr:nth-child(even) {
    background: var(--gray-2);
  }

  tbody tr:nth-child(odd) {
    background: white;
  }

  /// Scrollable Table Styles ///

  overflow-y: scroll;
  max-height: var(--max-height);

  table,
  thead {
    background: var(--gray-2);
  }

  thead {
    position: sticky;
    top: 0;
    z-index: var(--z-table-header);
  }

  /// Top Shadow Styles ///

  &::before {
    z-index: var(--z-table-shadow-mask);
    position: absolute;
    top: var(--row-height);
    left: 0;
    width: max(var(--table-width), 100%);
    height: 10px;
    background: white;
    content: "";
    pointer-events: none;
  }

  &::after {
    z-index: var(--z-table-shadow);
    position: sticky;
    bottom: calc(var(--max-height) - var(--row-height) - 2px);
    left: 0;
    content: "";
    width: 100%;
    height: 1px; // necessary to display shadow
    box-shadow: 0 1px 6px black;
    background: var(--gray-4);
    pointer-events: none;
    display: block;
  }

  /// Bottom Shadow Styles ///

  --gray-middle: #fdfdfd;

  tbody tr:nth-child(even):last-child {
    background: linear-gradient(var(--gray-2), var(--gray-middle) 50%);
  }

  tbody tr:nth-child(odd):last-child {
    background: linear-gradient(white, var(--gray-middle) 50%);
  }

  .bottom-shadow {
    position: sticky;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 1px; // necessary to display shadow
    box-shadow: 0 -1px 6px var(--gray-9);
    pointer-events: none;
  }

  .bottom-shadow-background {
    z-index: var(--z-table-shadow);
    position: relative;
    width: max(var(--table-width), 100%);
    height: 10px;
    background: var(--gray-middle);
    pointer-events: none;
    margin-bottom: -2px;
    margin-top: -10px;
  }
`
