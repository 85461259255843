import { styled } from "styled-components"

import { View, Button } from "ui"

const PaperAirplanes = styled(function PaperAirplanes({ className, kit }) {
  return (
    <div className={className}>
      <h2 className="text-gray-9 mt-xl mb-medium">Not Just Props</h2>
      <p className="mb-medium">
        We hope you enjoyed the Build a Fleet Team Challenge and saw how approaches and results for the same task can
        vary, especially when expectations leave room for interpretation. And we learned that there are many ways to
        fold a paper plane! Use the templates below to expand your paper plane making abilities, or to simply make
        another meeting more fun.
      </p>
      <View>
        {kit.bonus_page.paper_airplane_templates.map(({ template, title }) => (
          <img key={title} src={template} alt={title} className="template-card border-radius mr-large mb-large" />
        ))}
      </View>
      <a
        href={`https://static.risingteam.com/kit_assets/${kit.bonus_page.paper_airplane_templates_download_slug}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button className="tertiary mb-xl">Download them all</Button>
      </a>
    </div>
  )
})`
  .template-card {
    width: 160px;
    height: 160px;
  }
`

export default PaperAirplanes
