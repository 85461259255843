import { Navigate, useParams, Route, Routes } from "react-router-dom"

import KitTrainingArticles from "./KitTrainingArticles"
import VideoWalkthrough from "./VideoWalkthrough"

import { NotFound } from "components"
import { Loading } from "ui"
import { useKitInstanceByID } from "ui/SelectedTeamContext"

const KitTraining = () => {
  const { id } = useParams()
  const { kitInstance, isFetching } = useKitInstanceByID(id)

  if (!kitInstance) {
    return isFetching ? <Loading /> : <NotFound />
  }

  const defaultPrepSlug = kitInstance.kit.prep_links.find((link) => link.slug)
  const defaultPath = defaultPrepSlug ? `${kitInstance.prep_url}articles/${defaultPrepSlug}` : kitInstance.home_url

  return (
    <div className="main-container full-width">
      <Routes>
        <Route path="articles/*" element={<KitTrainingArticles kitInstance={kitInstance} />} />
        <Route path="video-walkthrough" element={<VideoWalkthrough kitInstance={kitInstance} />} />
        <Route path="*" ignore element={<Navigate to={defaultPath} replace />} />
      </Routes>
    </div>
  )
}

export default KitTraining
