import Resizer from "react-image-file-resizer"

import { checkNamedArguments } from "utils/function"

const SUPPORTED_RESIZING_IMAGE_TYPES = ["JPEG", "PNG", "WEBP"]

function getImageType({ file }: { file: File }): string {
  checkNamedArguments("getImageType", arguments, { required: { file } })
  return (file.type.split("/")[1] ?? file.type).toUpperCase()
}

// client side image resizer
function resizeImage({ file }: { file: File }): Promise<string | File | Blob | ProgressEvent<FileReader>> {
  checkNamedArguments("resizeImage", arguments, { required: { file } })
  const imageType = getImageType({ file })
  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      1920, // maxWidth
      1080, // maxHeight
      imageType, // compressFormat
      100, // quality (set to 100 if no compression for jpeg)
      0, // rotation (set to 0 for no rotation)
      resolve,
      "file" // outputType
    )
  })
}

export { resizeImage, getImageType, SUPPORTED_RESIZING_IMAGE_TYPES }
