import { Formik } from "formik"
import { pick, mergeWith } from "lodash-es"
import { styled } from "styled-components"

import ProfileForm from "./ProfileForm"

import { Yup } from "forms"
import { profileSchemas } from "forms/yup"
import { StripesBandDesktopIcon } from "icons"
import { View } from "ui"

const profileSchema = Yup.object().shape({
  ...pick(profileSchemas, [
    "org_name",
    "years_of_management",
    "team_location_type",
    "job_function",
    "pronouns",
    "hear_us_through",
    "phone_number",
  ]),
})

const OnboardingProfilePage = styled(function OnboardingProfilePage({
  user,
  headerText,
  subtext,
  nextButtonText,
  hideStripes,
  onSubmit,
  className,
}) {
  const initialValues = mergeWith(
    {
      org_name: "",
      years_of_management: "",
      team_location_type: "",
      job_function: "",
      pronouns: "",
      hear_us_through: "",
      phone_number: "",
    },
    user.profile,
    (objValue, srcValue) => (srcValue != null ? srcValue : objValue)
  )

  return (
    <>
      <View $flex={1} $flexDirection="column" className={className}>
        {!!headerText && <h1 className="text-large text-gray-9 mt-xs mb-large">{headerText}</h1>}
        {!!subtext && <p className="text-gray-8 mb-large">{subtext}</p>}
        {!hideStripes && (
          <div className="right-column show-on-desktop">
            <StripesBandDesktopIcon className="stripes-band-icon-desktop" />
          </div>
        )}
        <Formik initialValues={initialValues} validationSchema={profileSchema} onSubmit={onSubmit}>
          <ProfileForm nextButtonText={nextButtonText} />
        </Formik>
      </View>
    </>
  )
})`
  .right-column {
    width: 200px;
    position: absolute;
    top: 0;
    right: 0;
  }

  .stripes-band-icon-desktop {
    width: 100%;
    height: auto;
    vertical-align: top;
  }
`

export default OnboardingProfilePage
