import cn from "classnames"
import { Form, Formik } from "formik"
import { useState } from "react"
import { styled } from "styled-components"

import TeamModal from "domains/Admin/TeamModal"
import { AdvancedSelectField } from "forms/fields"
import { useGetAccount } from "resources/billing"
import { sortUserTeams, useScheduleModeTeams, useTeamSelectorTeams } from "resources/teams"
import { Loading, View } from "ui"

const TeamSelector = ({
  selectedTeam,
  onTeamChange,
  user,
  width = 260,
  fullWidth = false,
  showSingleTeam = false,
  showCreateNewTeam = false,
  className,
}) => {
  const { data: teams, isFetching } = useTeamSelectorTeams()

  if (!teams && isFetching) {
    return <Loading inline />
  }

  return (
    <BaseTeamSelector
      teams={teams}
      selectedTeam={selectedTeam}
      onTeamChange={onTeamChange}
      user={user}
      width={width}
      fullWidth={fullWidth}
      showSingleTeam={showSingleTeam}
      showCreateNewTeam={showCreateNewTeam}
      className={className}
    />
  )
}

const ScheduleModeTeamSelector = ({
  selectedTeam,
  onTeamChange,
  user,
  width = 260,
  fullWidth = false,
  showSingleTeam = false,
  className,
}) => {
  const { data: teams, isFetching } = useScheduleModeTeams()

  if (!teams && isFetching) {
    return <Loading inline />
  }

  return (
    <BaseTeamSelector
      teams={teams}
      selectedTeam={selectedTeam}
      onTeamChange={onTeamChange}
      user={user}
      width={width}
      fullWidth={fullWidth}
      showSingleTeam={showSingleTeam}
      className={className}
    />
  )
}

const BaseTeamSelector = styled(function BaseTeamSelector({
  teams,
  selectedTeam,
  onTeamChange,
  user,
  width,
  fullWidth = false,
  showSingleTeam = false,
  showCreateNewTeam = false,
  className,
}) {
  const [isTeamModalOpen, setIsTeamModalOpen] = useState(false)
  const { data: account } = useGetAccount(selectedTeam?.account_id)

  const isDemo = !!selectedTeam.demo
  const showTeamSelector = showSingleTeam
    ? isDemo || (teams && teams.length > 0)
    : isDemo || (teams && teams.length > 1)

  if (!showTeamSelector) {
    return null
  }

  const sortedTeams = sortUserTeams({ user, teams })
  const CREATE_NEW_TEAM = "CREATE_NEW_TEAM"
  const options = [
    ...sortedTeams.map((team) => ({
      value: team.id,
      label: team.name,
    })),
    ...(showCreateNewTeam && user.can_create_teams ? [{ label: "+ Create Another Team", value: CREATE_NEW_TEAM }] : []),
  ]

  return (
    // set the key with the selectedTeam id to force re-rendering if the selectedTeam changes
    <View
      key={`team-selector-${selectedTeam?.id}`}
      $alignItems="center"
      className={cn(className, { "full-width": fullWidth })}
      data-testid="team-select"
    >
      <span className="text-semi-bold mr-medium">Team:</span>
      {teams.length > 1 || (showCreateNewTeam && user.can_create_teams) ? (
        <Formik initialValues={{ id: selectedTeam?.id }}>
          <Form className={cn({ "full-width": fullWidth })}>
            <AdvancedSelectField
              name="id"
              key={isTeamModalOpen}
              className={cn("team-select", { "full-width": fullWidth })}
              onChange={({ value }) => {
                if (value === CREATE_NEW_TEAM) {
                  setIsTeamModalOpen(true)
                } else {
                  onTeamChange({ value })
                }
              }}
              width={fullWidth ? "100%" : width}
              options={options}
              formatOptionLabel={(option) =>
                option.value === CREATE_NEW_TEAM ? (
                  <span className="text-semi-bold text-blue-2">+ Create Another Team</span>
                ) : (
                  option.label
                )
              }
              selectedTeam={selectedTeam}
            />
          </Form>
        </Formik>
      ) : (
        <span className="team-select">{selectedTeam?.name}</span>
      )}
      <TeamModal
        account={account}
        user={user}
        showCreateNewTeam={showCreateNewTeam}
        onTeamChange={onTeamChange}
        isOpen={!!isTeamModalOpen}
        onRequestClose={() => setIsTeamModalOpen(false)}
      />
    </View>
  )
})`
  width: auto;

  .team-select {
    display: inline-block;

    .advanced-select__single-value {
      font-size: 16px;
      margin-left: 0;
    }
  }
`

export { TeamSelector, ScheduleModeTeamSelector }
