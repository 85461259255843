import cn from "classnames"
import { Form, Formik } from "formik"
import { range } from "lodash-es"

import { getExerciseAnswer } from "domains/Exercise/results_utils"
import { LineRatingField } from "forms/fields"
import { TextContainer, AnswerDisplayContent } from "ui"

const CrisisResponsePreviousCrisisUserResults = ({ exercise, hideIfNoAnswers, className }) => {
  const crisis_what_happened = getExerciseAnswer(exercise, "previous_crisis_what_happened")
  const crisis_how_challenging = getExerciseAnswer(exercise, "previous_crisis_how_challenging")
  const crisis_challenging = getExerciseAnswer(exercise, "previous_crisis_challenging")
  const crisis_get_through = getExerciseAnswer(exercise, "previous_crisis_get_through")

  const initialValues = {
    previous_crisis_how_challenging: { value: crisis_how_challenging?.toString() || "" },
  }

  const has_reflections_answers =
    crisis_what_happened || crisis_how_challenging || crisis_challenging || crisis_get_through

  if (hideIfNoAnswers && !has_reflections_answers) {
    return null
  }

  return (
    <div className={cn("text-gray-9", className)}>
      <h2 className="text-bold text-gray-9 mt-xl mb-medium">Reflections</h2>
      <TextContainer className="text-gray-9">
        {!!(crisis_what_happened || !hideIfNoAnswers) && (
          <AnswerDisplayContent title="The crisis" answer={crisis_what_happened} />
        )}
        {!!(crisis_how_challenging || !hideIfNoAnswers) && (
          <Formik initialValues={initialValues} enableReinitialize>
            <Form>
              <div className="text-field-label mt-large mb-xs">How challenging was it?</div>
              <LineRatingField
                numOptions={10}
                name="previous_crisis_how_challenging"
                axisLabels={["Small hiccup", "Life or death"]}
                ariaLabels={range(10).map((idx) => {
                  const baseLabel = `${idx + 1} out of 10`
                  const suffix = idx === 0 ? ".  Small hiccup" : idx === 9 ? ". Life or death" : ""
                  return `${baseLabel}${suffix}`
                })}
                className="mb-xl"
                showNumbers={true}
                disabled={true}
              />
            </Form>
          </Formik>
        )}
        {
          !!crisis_challenging && (
            <AnswerDisplayContent title="What made it challenging" answer={crisis_challenging} />
          ) /* don't use hideIfNoAnswers for this one - question was removed from exercise */
        }
        {!!(crisis_get_through || !hideIfNoAnswers) && (
          <AnswerDisplayContent title="What helped " answer={crisis_get_through} />
        )}
      </TextContainer>
    </div>
  )
}

export default CrisisResponsePreviousCrisisUserResults
