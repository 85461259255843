import { first, last } from "lodash-es"

import { getExerciseAnswer } from "../results_utils"
import AnswerDisplayBox from "../ResultsComponents/AnswerDisplayBox"

import { View } from "ui"

const RadioGroupRange = ({ identifier, exerciseComponent, exerciseInstance }) => {
  const choiceOptions = exerciseComponent.options
  const userSelections = getExerciseAnswer(exerciseInstance, identifier) ?? []
  const answers = choiceOptions.map(({ short_label, value }) => ({
    title: short_label,
    value: userSelections.includes(value),
  }))

  return (
    <div className="mb-xl">
      <View className="mb-xxs">
        <View className="text-small text-bold text-gray-9" $justifyContent="space-between" $flex="3">
          <div>{first(choiceOptions)?.short_label}</div>
          <div>{last(choiceOptions)?.short_label}</div>
        </View>
      </View>
      <AnswerDisplayBox answers={answers} className="mb-small" />
    </div>
  )
}

export default RadioGroupRange
