import { Fragment } from "react"
import { Link, useLocation } from "react-router-dom"

import SetupButton from "domains/Setup/SetupButton"
import SetupNavigation from "domains/Setup/SetupNavigation"
import SetupStep from "domains/Setup/SetupStep"
import { useWindowSize } from "ui/hooks"

const SetupMenu = ({ className, steps, step }) => {
  const { pathname } = useLocation()
  const { isMobileLandscapeOrSmaller } = useWindowSize()
  return (
    <div className={className}>
      {steps.map(({ title, path, completed }) => (
        <Fragment key={path}>
          <Link to={`../${path}`} className="block mb-medium">
            <SetupButton active={pathname.includes(path)} completed={completed}>
              {title}
            </SetupButton>
          </Link>
          {!!isMobileLandscapeOrSmaller && pathname.includes(path) && <SetupStep steps={steps} step={step} />}
        </Fragment>
      ))}
      {!!isMobileLandscapeOrSmaller && <SetupNavigation step={steps} />}
    </div>
  )
}

export default SetupMenu
