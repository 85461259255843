import cn from "classnames"
import { addWeeks, formatISO } from "date-fns"
import { Formik, Form, useFormikContext } from "formik"
import { styled } from "styled-components"

import { Yup } from "forms"
import { DateField, RichTextField } from "forms/fields"
import { useReflection, useUpdateReflection } from "resources/monthly_kit"
import { Loading } from "ui"

const ReflectionsForm = ({ kitInstance, calendarReminderText, className }) => {
  const { data } = useReflection({ kitInstanceId: kitInstance.id })

  if (!data) {
    return <Loading />
  }

  const oneWeekFromNow = addWeeks(new Date(), 1)
  const initialValues = {
    reflection_text: data.reflection_text ?? "",
    action_items: data.action_items ?? "",
    reminder_date: data.reminder_date ?? formatISO(oneWeekFromNow),
  }

  return (
    <div className={cn("space-y-xl", className)}>
      <Formik initialValues={initialValues} validationSchema={ReflectionSchema}>
        <ReflectionsInnerForm
          kitInstance={kitInstance}
          calendarReminderText={calendarReminderText}
          reflectionId={data.id}
        />
      </Formik>
    </div>
  )
}

const ReflectionsInnerForm = ({ kitInstance, calendarReminderText, reflectionId }) => {
  const { values } = useFormikContext()
  const { mutateAsync: updateReflection } = useUpdateReflection({ reflectionId })

  const saveOnChange = async () => {
    const date = values.reminder_date && new Date(values.reminder_date)
    const reminder_date = date && `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`

    await updateReflection({ ...values, reminder_date })
  }

  const reflectionLabel = (
    <div>
      Reflection: {""}
      <span className="text-thin">{kitInstance.kit.reflection_prompt}</span>
    </div>
  )
  const actionItemsLabel = (
    <div>
      Action Item: {""}
      <span className="text-thin">{kitInstance.kit.action_items_prompt}</span>
    </div>
  )

  return (
    <Form style={{ display: "flex", flexDirection: "column" }} className="space-y-large">
      <RichTextField name="reflection_text" saveOnChange={saveOnChange} label={reflectionLabel} />
      <RichTextField name="action_items" saveOnChange={saveOnChange} label={actionItemsLabel} />
      <DateField
        name="reminder_date"
        dateFormat="yyyy-MM-dd"
        saveOnChange={saveOnChange}
        label={calendarReminderText ?? "When would you like to be reminded of this action item?"}
        size="medium"
        className="reminder-date"
      />
    </Form>
  )
}

const ReflectionSchema = Yup.object().shape({
  reflection_text: Yup.string(),
  action_items: Yup.string(),
  reminder_date: Yup.date(),
})

export default styled(ReflectionsForm)`
  .reminder-date {
    .label {
      font-weight: 700;
    }

    .react-datepicker-wrapper {
      width: 240px;
    }
  }
`
