import { HorizontalRule } from "ui"

const ErrorPage = () => (
  <div className="main-container">
    <h2>Oops, there was an issue displaying this page.</h2>
    <HorizontalRule />
    <p>Please try refreshing the page or visiting a different url.</p>
  </div>
)

export default ErrorPage
