import ResultsBlocks from "../ResultsBlocks/ResultsBlocks"

import StandaloneNavigation from "./StandaloneNavigation"

import { renderBlocks, blockComponents } from "components/Blocks"
import { CareerHorizonsResults, TalentsResults } from "domains/Exercise/Standalone/ResultsPages"
import { useUser } from "resources/users"
import { Loading, View } from "ui"
import SelectedUserContext from "ui/SelectedUserContext"

const EXERCISE_SLUG_TO_REVIEW_COMPONENT = {
  talents: TalentsResults,
  "career-horizons": CareerHorizonsResults,
}

const hasResultsPage = (exerciseInstance) => {
  if (!!EXERCISE_SLUG_TO_REVIEW_COMPONENT[exerciseInstance?.slug]) {
    return true
  }
  if (exerciseInstance?.definition.standalone_results_blocks) {
    return true
  }

  return false
}

export { hasResultsPage }

export default function StandaloneResults({ kit, exerciseInstance }) {
  const { data: user } = useUser({ userId: "me" })

  if (!exerciseInstance || !user) {
    return <Loading />
  }

  const resultBlocks = exerciseInstance.definition.standalone_results_blocks

  if (resultBlocks) {
    return (
      <SelectedUserContext.Provider value={{ selectedUser: user }}>
        <div className="text-rising-orange text-small text-bold">STANDALONE EXERCISE</div>
        <ResultsBlocks blocks={resultBlocks} exerciseInstances={[exerciseInstance]} sortedUsers={[user]} />
        <StandaloneNavigation kit={kit} exerciseInstance={exerciseInstance} />
        {!!kit.footer_blocks && (
          <View $justifyContent="center">{renderBlocks(kit.footer_blocks, blockComponents)}</View>
        )}
      </SelectedUserContext.Provider>
    )
  }

  const ResultsPage = EXERCISE_SLUG_TO_REVIEW_COMPONENT[exerciseInstance.slug]

  return (
    <div>
      <ResultsPage user={user} exerciseInstance={exerciseInstance} />
      <StandaloneNavigation kit={kit} exerciseInstance={exerciseInstance} />
    </div>
  )
}
