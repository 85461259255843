import { Form, Formik } from "formik"
import { QRCode } from "react-qrcode-logo"

import { SelectField, TextField } from "forms/fields"
import { useCurrentTheme } from "ui/ThemeUpdateContext"

const QRCreator = () => {
  const theme = useCurrentTheme()
  const qrCodePaddingQuietZone = 0

  const initialValues = {
    url: "https://risingteam.com",
    size: 250,
  }

  const sizeOptions = [50, 75, 100, 150, 200, 250, 300]

  return (
    <Formik initialValues={initialValues}>
      {(props) => (
        <Form autoComplete="off" className="space-y-large">
          <h1>QR Creator</h1>
          <TextField name="url" label="Type a URL here" size="full-width" />
          <SelectField name="size" label="Size" size="medium">
            {sizeOptions.map((size) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </SelectField>
          {!!props?.values?.url && (
            <QRCode
              value={props.values.url}
              size={props.values.size}
              fgColor={theme.gray9}
              quietZone={qrCodePaddingQuietZone}
              ecLevel="M"
              qrStyle="dots"
              logoImage={`${import.meta.env.VITE_PUBLIC_URL}/logo192.png`}
              removeQrCodeBehindLogo={true}
            />
          )}
        </Form>
      )}
    </Formik>
  )
}

export default QRCreator
