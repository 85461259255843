import cn from "classnames"
import { styled } from "styled-components"
import type { RTTheme } from "types"

import Tooltip from "./Tooltip"

const RTBar = styled(function RTBar({
  id,
  color,
  background,
  height,
  width,
  tooltip,
  className,
}: {
  id: string
  color?: string
  background?: string
  height: string
  width: string
  tooltip?: string
  className: string
}) {
  const bar = (
    <div
      id={id}
      style={{
        background, // NOTE: This must go before backgroundColor
        backgroundColor: color, // NOTE: This must go after background
        height,
        width,
      }}
      className={cn(className, "bar-segment")}
    ></div>
  )

  return tooltip ? (
    <Tooltip top float wrapInView={false} maxWidth={350} title={tooltip}>
      {bar}
    </Tooltip>
  ) : (
    bar
  )
})`
  &.bar-segment {
    &:not(:last-child) {
      // add space between bars without moving the right edge of aggregate bars:
      ${({ theme }: { theme: RTTheme }) =>
        theme.isWidescreen ? "border-right: 6px solid white;" : "border-right: 1px solid white;"}
    }

    &:first-child {
      ${({ theme }: { theme: RTTheme }) =>
        theme.isWidescreen
          ? "border-radius: var(--border-radius-small) 0 0 var(--border-radius-small);"
          : "border-radius: var(--border-radius-xs) 0 0 var(--border-radius-xs);"}

    }

    &:last-child {
      ${({ theme }: { theme: RTTheme }) =>
        theme.isWidescreen
          ? "border-radius: 0 var(--border-radius-small) var(--border-radius-small) 0;"
          : "border-radius: 0 var(--border-radius-xs) var(--border-radius-xs) 0;"}

    &:only-child {
      ${({ theme }: { theme: RTTheme }) =>
        theme.isWidescreen ? "border-radius: var(--border-radius-small);" : "border-radius: var(--border-radius-xs);"}
    }
  }
`

export default RTBar
