import { Navigate, Route } from "react-router-dom"

import KitTrainingArticle from "./KitTrainingArticle"

import RouteSteps from "components/Steps"
import { View } from "ui"

const KitTrainingArticles = ({ kitInstance }) => {
  if (!kitInstance) {
    return null
  }

  const prepLinks = kitInstance.kit.prep_links.filter((link) => link.slug)

  return (
    <>
      <View $flex={1} $flexDirection="column">
        <RouteSteps>
          <Route path={kitInstance.home_url} key="home" />
          {prepLinks.map((prepLink) => (
            <Route
              key={prepLink.slug}
              path={prepLink.slug}
              element={<KitTrainingArticle kitInstance={kitInstance} prepLink={prepLink} />}
            />
          ))}
          <Route path={kitInstance.home_url} key="home" />
          {!!prepLinks.length && <Route path="*" ignore element={<Navigate to={prepLinks[0].slug} replace />} />}
        </RouteSteps>
      </View>
    </>
  )
}

export default KitTrainingArticles
