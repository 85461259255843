import cn from "classnames"
import { first, last } from "lodash-es"
import { Fragment } from "react"
import { styled } from "styled-components"

import { getSortedTruncatedUserAnswers } from "../results_utils"
import AnswerDisplayBox from "../ResultsComponents/AnswerDisplayBox"

import { View } from "ui"

const RadioGroupMultiUserRangeGrid = styled(function RadioGroupMultiUserRangeGrid({
  identifier,
  exerciseInstances,
  exerciseComponent,
  sortedUsers,
  maxNumResults = null,
  className,
}) {
  return (
    <div className={cn("mb-xl", className)}>
      <div></div>
      <View aria-hidden={true} className="text-small text-bold text-gray-9" $justifyContent="space-between" $flex="3">
        <div>{first(exerciseComponent.options)?.short_label}</div>
        <div>{last(exerciseComponent.options)?.short_label}</div>
      </View>
      {getSortedTruncatedUserAnswers(exerciseInstances, sortedUsers, identifier, maxNumResults).map(
        ([user, answer]) => (
          <Fragment key={user.id}>
            <div className="user-label text-gray-9 text-normal text-thin">{user.short_name}</div>
            <AnswerDisplayBox
              answers={exerciseComponent.options.map(({ short_label, value }) => ({
                title: short_label,
                value: answer.includes(value),
              }))}
            />
          </Fragment>
        )
      )}
    </div>
  )
})`
  display: grid;
  grid-template-columns: auto 1fr;
  row-gap: var(--spacing-3);
  column-gap: var(--spacing-7);

  .user-label {
    text-align: right;
    align-self: center;
  }

  @media (max-width: ${({ theme }) => theme.mobileMax}) {
    grid-template-columns: 1fr;
    row-gap: var(--spacing-0);

    .user-label {
      text-align: left;
    }
  }
`

export default RadioGroupMultiUserRangeGrid
