import cn from "classnames"
import { styled } from "styled-components"

import OfferExpiration from "./OfferExpiration"
import SubscribeOffersForm from "./SubscribeOffersForm"

import { NotFound } from "components"
import { useHubSpotModal } from "components/HubSpotModal"
import { BoltIcon, ClockIcon } from "icons/FontAwesomeIcons"
import {
  createBillingAccount,
  createCheckoutSessionAndRedirect,
  SubscriptionType,
  useGetAccountOffers,
  useGetOwnedAccount,
} from "resources/billing"
import { useUser } from "resources/users"
import { Button, Loading } from "ui"
import { useQueryParams } from "ui/hooks"

const SubscribeOffers = styled(function SubscribeOffers({ className }) {
  const { data: user } = useUser({ userId: "me" })
  const { data: ownedAccountOrNull, isFetching: billingIsFetching } = useGetOwnedAccount()
  const isSHRMPromo = ownedAccountOrNull?.is_shrm_promo
  const { data: accountOffers, isFetching: accountOffersIsFetching } = useGetAccountOffers(
    isSHRMPromo ? { customBilling: "shrm-24" } : {}
  )
  const queryParams = useQueryParams()
  const freeTrial = typeof queryParams.free_trial !== "undefined"
  const { setModal: setHubSpotModal } = useHubSpotModal()

  if (!user) {
    return null
  }

  if (!user.can_view_subscribe_tab) {
    return <NotFound />
  }

  if ((!ownedAccountOrNull && billingIsFetching) || (!accountOffers && accountOffersIsFetching)) {
    return <Loading />
  }

  const onOfferSelected = async ({ yearly, numTeams, numMembers, timeLimitedOffer, nonProfit, productTier }) => {
    if (user.is_staff) {
      return
    }

    const billingAccount = ownedAccountOrNull ?? (await createBillingAccount())
    const billingData = {
      subscriptionType: yearly ? SubscriptionType.yearly : SubscriptionType.monthly,
      quantity: numTeams || numMembers,
      productTier,
      freeTrial,
      timeLimitedOffer,
      nonProfit,
      ...(isSHRMPromo
        ? {
            customBilling: "shrm-24",
          }
        : {}),
    }

    await createCheckoutSessionAndRedirect({
      billingData,
      billingAccountId: billingAccount.id,
      checkoutCancelUrl: window.location.href,
      fromDemo: true,
    })
  }

  return (
    <div className={cn(className, "p-xxxl pb-xl main-container")}>
      <h1 className="text-gray-9 mb-large">Subscribe</h1>
      <h2 className="text-gray-9 mb-medium">
        {accountOffers.time_limited_offer ? (
          <>
            <ClockIcon color="var(--rising-yellow)" className="mr-xs" />
            Activate your special offer
          </>
        ) : (
          <>
            <BoltIcon color="var(--rising-yellow)" className="mr-xs" />
            {isSHRMPromo ? "Cultivate More Civility, Build Stronger Teams" : "Build stronger teams, get better results"}
          </>
        )}
      </h2>
      {!!isSHRMPromo && (
        <div className="mb-medium space-y-medium">
          <div className="space-y-small">
            <p>
              We hope you enjoyed the free Civil Conversations session! Rising Team helps organizations of all sizes and
              industries foster deeper human connection and build stronger, more cohesive teams.
            </p>
            <p>
              Explore plans for access to the full Rising Team platform and select the best option for your needs. Ask
              us about non-profit and volume-based discounts.
            </p>
          </div>
          <Button onClick={setHubSpotModal}>Request custom pricing</Button>
        </div>
      )}
      <OfferExpiration className="text-gray-8 inline-block" accountOffers={accountOffers} />{" "}
      <SubscribeOffersForm accountOffers={accountOffers} onOfferSelected={onOfferSelected} isSHRMPromo={isSHRMPromo} />
    </div>
  )
})`
  @media (max-width: ${({ theme }) => theme.mobileMax}) {
    text-align: center;
  }
`

export default SubscribeOffers
