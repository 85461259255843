import { TalentsChart, TalentsGrid } from "domains/Exercise/ResultsComponents"

const TalentsExerciseReport = ({ exerciseInstances, userIdMap }) => (
  <div>
    <TalentsChart userIdMap={userIdMap} exerciseInstances={exerciseInstances} showLegend={true} className="mb-xl" />
    <TalentsGrid exerciseInstances={exerciseInstances} userIdMap={userIdMap} className="mb-xl" />
  </div>
)

export default TalentsExerciseReport
