import { styled } from "styled-components"

import { View, TextContainer, PurifyInnerHTMLDiv } from "ui"

const ImageGrid = styled(function ImageGrid({ className, kitInstance }) {
  return (
    <div className={className}>
      <div className="text-gray-9 mt-xl mb-medium">
        <h2>{kitInstance.kit.bonus_page.image_list_header}</h2>
      </div>
      <div className="text-gray-9 mb-large">
        <PurifyInnerHTMLDiv className="mb-large">{kitInstance.kit.bonus_page.image_list_desc}</PurifyInnerHTMLDiv>
      </div>
      <TextContainer maxWidth={650}>
        <View className="backgrounds-grid" $flexDirection="row" $justifyContent="flex-start">
          {kitInstance.kit.bonus_page.image_list.map(({ image, slug }) => (
            <div key={slug}>
              <img src={image} className="my-xxs" alt={slug} />
            </div>
          ))}
        </View>
      </TextContainer>
    </div>
  )
})`
  img {
    width: 305px;
    border-radius: 6px;
  }

  .backgrounds-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(305px, 1fr));
    gap: var(--spacing-medium);
  }
`

export default ImageGrid
