import { CareerNewsHeadlines } from "./components"

const PublicNewsGeneratorBonus = () => (
  <main className="align-left full-width">
    <div className="bonus-wrapper">
      <div className="main-container full-width">
        <h2>News Headline Generator</h2>
        <CareerNewsHeadlines hideDescription={true} />
      </div>
    </div>
  </main>
)

export default PublicNewsGeneratorBonus
