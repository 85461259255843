import CareerHorizonsThinkingBigIntro from "./CareerHorizonsThinkingBigIntro"
import CrisisResponsePracticeScenarioIntro from "./CrisisResponsePracticeScenarioIntro"
import FiveSensesIntro from "./FiveSensesIntro"
import KeepLetGoIntro from "./KeepLetGoIntro"
import LearnerAgilityBehaviorsIntro from "./LearnerAgilityBehaviorsIntro"
import LearnerAgilityLearningPreferencesIntro from "./LearnerAgilityLearningPreferencesIntro"
import LifelinesIntro from "./LifelinesIntro"
import MotivatorsCareerIntro from "./MotivatorsCareerIntro"
import MotivatorsDailyIntro from "./MotivatorsDailyIntro"

import { getOrError } from "utils/object"

export {
  CareerHorizonsThinkingBigIntro,
  CrisisResponsePracticeScenarioIntro,
  FiveSensesIntro,
  KeepLetGoIntro,
  LearnerAgilityBehaviorsIntro,
  LearnerAgilityLearningPreferencesIntro,
  LifelinesIntro,
  MotivatorsCareerIntro,
  MotivatorsDailyIntro,
}

export default function getIntroComponentOrError(name) {
  const components = {
    CareerHorizonsThinkingBigIntro,
    CrisisResponsePracticeScenarioIntro,
    FiveSensesIntro,
    KeepLetGoIntro,
    LearnerAgilityBehaviorsIntro,
    LearnerAgilityLearningPreferencesIntro,
    LifelinesIntro,
    MotivatorsCareerIntro,
    MotivatorsDailyIntro,
  }
  return getOrError(components, name, "Intro component not found")
}
