import RegisterIndex from "domains/GetStarted/Register"
import { usePromo } from "domains/GetStarted/resource"
import { useGetPromoCode } from "domains/Promo/utils"

const PromoIndex = ({ promoCode: promoCodeProp }) => {
  const promoCodeParam = useGetPromoCode()
  const promoCode = promoCodeProp ?? promoCodeParam
  const { data: promo, isInitialLoading: promoIsInitialLoading } = usePromo(promoCode)

  if (promoIsInitialLoading) {
    return null
  }

  if (!promo) {
    return <div className="p-large">Promo code not valid</div>
  }

  return (
    <div className="main-container full-width">
      <RegisterIndex promoCode={promoCode} />
    </div>
  )
}

export default PromoIndex
