import * as Sentry from "@sentry/browser"
import { Formik, Form, useFormikContext } from "formik"
import { useNavigate } from "react-router"
import { styled } from "styled-components"

import { handleErrors } from "forms"
import { ToggleSwitchField } from "forms/fields"
import { useToggleDemoMode } from "resources/teams"
import { useUser } from "resources/users"
import { Loading } from "ui"

const DemoToggle = styled(function DemoToggle({ className }) {
  const navigate = useNavigate()
  const toggleDemoModeNav = (active) => navigate(active ? "/demo" : "/")
  const { mutateAsync: toggleDemoMode } = useToggleDemoMode({ onSuccess: toggleDemoModeNav })
  const { data: user } = useUser({ userId: "me" })

  if (!user) {
    return <Loading />
  }

  const onSubmit = handleErrors(toggleDemoMode, async (data, _values, formik, err) => {
    await formik.setValues({ active: user.is_demo_mode_active })
    await formik.setErrors({ active: data?.detail ?? "An error occurred." })
    Sentry.captureException(err)
    return null // do not use default handleErrors behavior
  })

  return (
    <div className={className}>
      <Formik initialValues={{ active: user.is_demo_mode_active }} onSubmit={onSubmit}>
        <Form title="Demo">
          <DemoSwitch />
        </Form>
      </Formik>
    </div>
  )
})`
  form {
    display: flex;
    margin-right: 0.5rem;
  }
`

const DemoSwitch = () => {
  const { submitForm } = useFormikContext()
  return <ToggleSwitchField name="active" errorMessagePosition="left" saveOnChange={submitForm} />
}

export default DemoToggle
