import { Form } from "formik"

import { FormMessage } from "forms"
import {
  TextField,
  EmailField,
  PasswordField,
  HiddenField,
  PreferredPronounsField,
  JobFunctionsField,
  ToggleField,
  JobPositionsField,
} from "forms/fields"
import CompanySizeField from "forms/fields/CompanySizeField"
import { JOB_FUNCTIONS, SHRM_JOB_FUNCTIONS } from "forms/fields/JobFunctionsField"
import { SHRM_JOB_POSITIONS } from "forms/fields/JobPositionsField"
import { ArrowRightIcon } from "icons/FontAwesomeIcons"
import { View, SubmitButton } from "ui"

const TeamLeadRegisterForm = ({
  invited = false,
  inputFieldSize = "medium",
  showMinimalRegisterForm = true,
  jobFunctions = null,
  jobPositions = null,
  submitButtonText = null,
  isSHRMInvite = false,
}) => (
  <Form name="register" autoComplete="off" className="space-y-large">
    {!!invited && <EmailField disabled label="Email address" name="email" size={inputFieldSize} autoComplete="off" />}
    <TextField label="First name" name="first_name" autoFocus size={inputFieldSize} />
    <TextField label="Last name" name="last_name" size={inputFieldSize} autoComplete="off" />
    {!!isSHRMInvite && (
      <>
        <TextField label="Company name" name="profile.org_name" size={inputFieldSize} autoComplete="off" />
        <CompanySizeField label="Company size" name="profile.org_size" size={inputFieldSize} autoComplete="off" />
        <JobPositionsField
          name="profile.job_position"
          label="Job position"
          size={inputFieldSize}
          jobPositions={jobPositions ?? SHRM_JOB_POSITIONS}
        />
        <JobFunctionsField
          name="profile.job_function"
          label="Job function"
          size={inputFieldSize}
          jobFunctions={jobFunctions ?? SHRM_JOB_FUNCTIONS}
        />
      </>
    )}
    {!invited && <EmailField label="Email address" name="email" size={inputFieldSize} autoComplete="off" />}
    <PasswordField
      label="Create a password"
      name="password"
      placeholder="Password"
      size={inputFieldSize}
      autoComplete="new-password"
      eye
      passwordHint
    />
    {!showMinimalRegisterForm && (
      <>
        <PreferredPronounsField name="profile.pronouns" label="How should we refer to you? (optional)" size="large" />
        <JobFunctionsField
          name="profile.job_function"
          label="Your job function (optional)"
          size="large"
          jobFunctions={jobFunctions ?? JOB_FUNCTIONS}
        />
      </>
    )}
    <FormMessage />
    <View className="pt-medium">
      <SubmitButton>
        <span>{submitButtonText ?? "Let’s do this"}</span>
        <ArrowRightIcon />
      </SubmitButton>
    </View>
  </Form>
)

const TeamMemberRegisterForm = ({
  jobFunctions = null,
  invited = false,
  showMinimalRegisterForm = false,
  additionalContent = null,
}) => (
  <Form name="register" autoComplete="off" className="space-y-large mt-large">
    {invited ? (
      <EmailField disabled label="Email address" name="email" size="medium" autoComplete="off" />
    ) : (
      <HiddenField name="email" />
    )}
    <TextField
      label="First name *"
      name="first_name"
      placeholder="Enter your first name here"
      autoFocus
      size="medium"
    />
    <TextField
      label="Last name *"
      name="last_name"
      placeholder="Enter your last name here"
      size="medium"
      autoComplete="off"
    />
    {!showMinimalRegisterForm && (
      <>
        <PreferredPronounsField name="profile.pronouns" label="How would you like us to refer to you?" size="medium" />
        <JobFunctionsField
          name="profile.job_function"
          label="Your job function *"
          size="medium"
          jobFunctions={jobFunctions ?? JOB_FUNCTIONS}
        />
      </>
    )}
    <PasswordField
      label="Create a password *"
      name="password"
      placeholder="Enter your password here"
      size="medium"
      autoComplete="new-password"
      eye
    />
    {!showMinimalRegisterForm && <ToggleField name="profile.is_team_lead">Do you manage or lead a team?</ToggleField>}
    <FormMessage />
    {!!additionalContent && <div>{additionalContent}</div>}
    <View className="pt-large pt-xxxxl-mobile-only">
      <SubmitButton>
        <span>Continue</span>
        <ArrowRightIcon />
      </SubmitButton>
    </View>
  </Form>
)

export { TeamLeadRegisterForm, TeamMemberRegisterForm }
