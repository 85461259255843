import { styled } from "styled-components"

import { useKitSession } from "../KitSessionContext"

import { useHasTeamFeature } from "domains/Results/utils"
import { UsersIcon } from "icons/FontAwesomeIcons"
import { useKitParticipants } from "resources/monthly_kit"
import { useUser } from "resources/users"
import { Callout, Loading, View } from "ui"
import { SHARED_FLAGS } from "ui/hooks/useFeatures"

const TeamMemberWelcomeStepInfo = ({ className }) => {
  const { team, kitInstance, preview } = useKitSession()
  const { data: user } = useUser({ userId: "me" })

  const { enabled: sessionRealtimeUpdatesV2 } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_REPLACE_POLLING)
  const { data: participants } = useKitParticipants({
    kitInstance,
    refetchInterval: 3000,
    sessionRealtimeUpdates: sessionRealtimeUpdatesV2,
  })

  if (!user) {
    return <Loading />
  }

  if (preview || !participants) {
    return null
  }

  return (
    <Callout className={className}>
      <ParticipantIcons participants={participants} className="mb-xs" />
      <ParticipantsNames currentUser={user} team={team} participants={participants} />
    </Callout>
  )
}

const ParticipantIcons = ({ participants, className }) => (
  <View $alignItems="center" className={className}>
    <UsersIcon className="fa-2x text-rising-green mr-small" />
    <h2 className="mr-xs">{participants.length}</h2>
    <h5>In session</h5>
  </View>
)

const ParticipantsNames = ({ currentUser, team, participants, className }) => {
  const formatTooltip = (user) => `${user.name}, ${user.email}`
  return (
    <div className={className}>
      <span title={formatTooltip(currentUser)}>You</span>
      {participants
        .filter((user) => user.id !== currentUser.id)
        .map((user) => (
          <span key={user.id} title={formatTooltip(user)}>
            , {user.short_name}
            {user.id === team.team_lead_id && " (team lead)"}
          </span>
        ))}
      {participants.length === 1 && <span> (When others join, they will appear here)</span>}
    </div>
  )
}

export default styled(TeamMemberWelcomeStepInfo)`
  @media not all and (max-width: ${({ theme }) => theme.mobileLandscapeMax}) {
    .welcome-left-info-pane {
      padding-right: 2rem;
      border-right: 2px solid var(--gray-4);
      margin-right: 2rem;
    }
  }

  @media (max-width: ${({ theme }) => theme.mobileLandscapeMax}) {
    .welcome-info-panes {
      flex-direction: column;
    }

    .welcome-left-info-pane {
      padding-bottom: 1.5rem;
      border-bottom: 2px solid var(--gray-4);
      margin-bottom: 1.5rem;
    }
  }
`
