import cn from "classnames"
import { useState } from "react"

import { Accordion } from "components"
import TeamLeadField from "domains/Admin/TeamLeadField"
import TeamMemberField from "domains/Admin/TeamMemberField"
import TeamMembersTable from "domains/Admin/TeamMembersTable"
import TeamNameField from "domains/Admin/TeamNameField"
import { canUserEditAccountTeam, canUserSeeExtendedFeaturesStaffOnly } from "domains/Admin/utils"
import { useTeamInfo } from "domains/Results/utils"
import { Button, View, Tooltip, colors } from "ui"
import useFeatures from "ui/hooks/useFeatures"

const EditTeamForm = ({
  className,
  team,
  account,
  user,
  isInModal = false,
  hideTeamLeadField = false,
  onMembersExpand = null,
  onMembersCollapse = null,
}) => {
  const features = useFeatures()
  const { getLead, getLeadNameOrEmail, getNonLeadMembers, isLeadInvitePending } = useTeamInfo()
  const [leadAccordionOpen, setLeadAccordionOpen] = useState(false)
  const [memberAccordionOpen, setMemberAccordionOpen] = useState(false)
  if (!canUserEditAccountTeam(account, user, team, features)) {
    return null
  }
  const OptionalAccordion = ({ children, disableAccordion, ...props }) =>
    disableAccordion ? children : <Accordion {...props}>{children}</Accordion>
  return (
    <div className={className}>
      <TeamNameField team={team} currentUser={user} className="mb-large" />
      {!hideTeamLeadField && (
        <Accordion
          externalOpenState={leadAccordionOpen}
          beforeToggle={() => {
            if (!leadAccordionOpen) {
              onMembersCollapse?.()
            } else {
              onMembersExpand?.()
            }
            setLeadAccordionOpen(!leadAccordionOpen)
            setMemberAccordionOpen(false)
          }}
          title={({ isOpen }) => (
            <View $alignItems="baseline">
              <div className="mr-small text-semi-bold text-nowrap">Team lead:</div>
              <Tooltip bottom title={getLead(team)?.name ? getLead(team)?.email : null}>
                <span className="mr-xxs" title={getLead(team)?.name ? null : `No name set for ${getLead(team)?.email}`}>
                  {getLeadNameOrEmail(team)}
                </span>
              </Tooltip>
              {isLeadInvitePending(team) ? <span className="text-nowrap">(invite pending)</span> : ""}
              {isOpen ? (
                <Button className="link-blue ml-large p-xs">Hide</Button>
              ) : (
                <Button className="link-blue ml-large p-xs">Change</Button>
              )}
            </View>
          )}
        >
          <div className="text-semi-bold mb-small">Assign a new team lead</div>
          <TeamLeadField className="mb-large" team={team} user={user} account={account} />
        </Accordion>
      )}
      <OptionalAccordion
        className={cn({ "mt-medium": hideTeamLeadField })}
        disableAccordion={!isInModal}
        noIndent
        externalOpenState={!leadAccordionOpen && memberAccordionOpen}
        beforeToggle={() => {
          if (!memberAccordionOpen) {
            onMembersExpand?.()
          } else {
            onMembersCollapse?.()
          }
          setMemberAccordionOpen(!memberAccordionOpen)
          setLeadAccordionOpen(false)
        }}
        title={({ isOpen }) => (
          <View $alignItems="baseline">
            <div className="mr-small text-semi-bold text-nowrap">Team members:</div>
            <Tooltip
              bottom
              wrap
              disabled={isOpen}
              title={
                getNonLeadMembers(team)
                  .map((u) => u.name || u.email)
                  .filter(Boolean)
                  .join(", ") || "No members"
              }
            >
              {team.members.length - 1} total
            </Tooltip>{" "}
            {isOpen ? (
              <Button className="link-blue ml-large p-xs">Hide</Button>
            ) : (
              <Button className="link-blue ml-large p-xs">View / Edit</Button>
            )}
          </View>
        )}
      >
        <TeamMembersTable className="mb-large" team={team} isInModal={isInModal} />
        {!hideTeamLeadField && <TeamMemberField className="mb-large" team={team} />}
      </OptionalAccordion>
      {!!hideTeamLeadField && <TeamMemberField className="mb-large" team={team} />}
      {canUserSeeExtendedFeaturesStaffOnly(user, features) && (
        <Button
          color={colors.risingBlue}
          className="link-semi-bold mt-large p-xs"
          onClick={() => alert("TODO(admin): NOT YET IMPLEMENTED")}
        >
          Archive team
        </Button>
      )}
    </div>
  )
}

export default EditTeamForm
