import cn from "classnames"
import { useSearchParams } from "react-router-dom"
import { styled } from "styled-components"

import { useSyncSessionEnabled } from "domains/KitSession/utils"
import { LinkIcon, LinkSlashIcon } from "icons/FontAwesomeIcons"
import { Button, Tooltip } from "ui"

export const SyncSessionToggle = ({ onEnable: onEnableParam = () => {}, className }) => {
  const [_, setSearchParams] = useSearchParams()
  const syncSessionEnabled = useSyncSessionEnabled()
  const onEnable = () => {
    setSearchParams({})
    onEnableParam()
  }
  const onDisable = () => setSearchParams({ "page-sync": "false" })

  const tooltipCopy = syncSessionEnabled
    ? "Page Sync enabled. Your browser will automatically follow the session leader."
    : "Page Sync disabled. Your browser will not automatically follow the session leader until you enable it again."

  const toggleSessionSync = () => {
    if (syncSessionEnabled) {
      onDisable()
    } else {
      onEnable()
    }
  }

  // Note: Using wrapInView={false} below prevents an issue where the tooltip
  // won't close after flipping the toggle on mobile.
  return (
    <Tooltip top title={tooltipCopy} wrapInView={false}>
      <Button className={cn("tertiary", { active: syncSessionEnabled }, className)} onClick={toggleSessionSync}>
        {syncSessionEnabled ? (
          <LinkIcon className="page-sync-icon text-blue-2" />
        ) : (
          <LinkSlashIcon className="page-sync-icon" />
        )}
      </Button>
    </Tooltip>
  )
}

export default styled(SyncSessionToggle)`
  height: 40px;
  width: 40px;
  padding: var(--spacing-1) !important;
  border: none !important;
  border-radius: 50% !important;

  &.active {
    background-color: var(--blue-tint);

    &:hover {
      .page-sync-icon {
        color: var(--rising-blue);
      }
    }
  }
`
