import { keyBy } from "lodash-es"

import { StepContent, SessionSelectedUsersShareView } from "../components"
import { useKitSession } from "../KitSessionContext"

import { useSessionExerciseInstances } from "domains/Exercise/resource"
import { getTeamLead, getUserExerciseInstance } from "domains/Exercise/results_utils"
import { FeedbackFlexUserResults } from "domains/Exercise/ResultsComponents"
import { useHasTeamFeature } from "domains/Results/utils"
import { useKitParticipants } from "resources/monthly_kit"
import { TextContainer, Loading } from "ui"
import { SHARED_FLAGS } from "ui/hooks/useFeatures"
import { useSelectedUser } from "ui/SelectedUserContext"

const FeedbackFlexShareStep = ({ sessionStep, className }) => {
  const { kitInstance, team } = useKitSession()
  const { enabled: sessionRealtimeUpdates } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_ANSWER_UPDATE)

  const { data: exerciseInstances, isFetching } = useSessionExerciseInstances(kitInstance?.id, {
    sessionRealtimeUpdates,
    refetchInterval: 10000,
  })

  if (isFetching && !exerciseInstances) {
    return <Loading />
  }

  return (
    <StepContent className={className}>
      <TextContainer className="text-gray-9">
        <h2 className="text-gray-9 mb-medium">Deliver the feedback</h2>
        <p className="mb-medium">
          Now each person will get a turn to share their feedback with their recipient. If you are receiving feedback,
          your only job is to say thank you. After everyone has shared, take a moment and share how that felt.
        </p>
      </TextContainer>
      <SessionSelectedUsersShareView
        sessionStep={sessionStep}
        kitInstance={kitInstance}
        minMinutesPerUser={2}
        maxMinutesPerUser={3}
      >
        <UserResults exerciseInstances={exerciseInstances} kitInstance={kitInstance} team={team} />
      </SessionSelectedUsersShareView>
    </StepContent>
  )
}

const UserResults = ({ exerciseInstances, kitInstance, team }) => {
  const { enabled: sessionRealtimeUpdatesV2 } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_REPLACE_POLLING)
  const { data: allParticipants } = useKitParticipants({
    kitInstance,
    sessionRealtimeUpdates: sessionRealtimeUpdatesV2,
  })
  const { selectedUser } = useSelectedUser()
  const selectedExercise = getUserExerciseInstance(exerciseInstances, selectedUser)

  if (!selectedExercise) {
    return null
  }

  const participantIdMap = keyBy(allParticipants, "id")
  const teamLead = getTeamLead(allParticipants, team)
  const teamLeadExercise = exerciseInstances.find(({ user_id }) => user_id === teamLead.id)

  return (
    <FeedbackFlexUserResults
      selectedUser={selectedUser}
      teamLeadExercise={teamLeadExercise}
      exercise={selectedExercise}
      userIdMap={participantIdMap}
    />
  )
}

export default FeedbackFlexShareStep
