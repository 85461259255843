import { StepContent, SidebarContainer, FacilitatorTip } from "../components"
import { useKitSession } from "../KitSessionContext"

import { Accordion } from "components"
import { getTeamLead } from "domains/Exercise/results_utils"
import { useHasTeamFeature } from "domains/Results/utils"
import { LockIcon, UnlockIcon } from "icons/FontAwesomeIcons"
import { useKitParticipants } from "resources/monthly_kit"
import { useUser } from "resources/users"
import { BulletedList, TextContainer, Timer, Loading } from "ui"
import { positiveIntOrZero } from "ui/Duration"
import { useTimer } from "ui/hooks"
import { SHARED_FLAGS } from "ui/hooks/useFeatures"

const ExpectationsClarifyRequestStep = ({ sessionStep }) => {
  const { kitInstance, team } = useKitSession()
  const { enabled: sessionRealtimeUpdatesV2 } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_REPLACE_POLLING)
  const { data: participants } = useKitParticipants({ kitInstance, sessionRealtimeUpdates: sessionRealtimeUpdatesV2 })
  const { minutes } = sessionStep
  const { data: user } = useUser({ userId: "me" })

  const startSeconds = minutes * 60
  const startMilliseconds = startSeconds * 1000
  const { timeLeft, play, pause, reset, isRunning } = useTimer(startMilliseconds)
  const durationSeconds = positiveIntOrZero(timeLeft / 1000)

  if (!user || !participants) {
    return <Loading />
  }

  const teamLead = getTeamLead(participants, team)

  return (
    <StepContent>
      <SidebarContainer>
        <TextContainer className="text-gray-9 mr-xl">
          <h2 className="mb-medium">Step 1: Request and measurement</h2>
          <p className="mb-medium">
            The first step on the ROAD is to clarify what needs to be done and define how success will be measured.
          </p>
          <p className="mb-medium">
            <span className="text-bold">Aeronautics Team:</span> The Goal is to build a fleet of identical paper planes.
            Take a moment to identify and write down any questions you have about the request. Then go around the room,
            asking {teamLead.name} your questions.
          </p>
          <BulletedList className="text-gray-9 mb-large">
            <li>What else do you need to know to commit to this project?</li>
            <li>What assumptions do you have about this project? Anything worth clarifying?</li>
          </BulletedList>
          <p className="text-gray-9 mb-medium">
            <span className="text-bold">Account Rep:</span> Review the Client Requirements and be prepared to answer the
            team’s questions.
          </p>
          <p className="text-gray-9 mb-medium">When the team has come to resolution, move to the next step.</p>
          <p className="text-gray-9 mb-medium">Take {minutes} minutes to discuss.</p>

          {teamLead.id === user.id && (
            <>
              <Timer
                className="mb-large"
                durationSeconds={durationSeconds}
                isRunning={isRunning}
                onPlay={play}
                onPause={pause}
                onReset={reset}
              />

              <h3 className="mb-medium">Client Requirements (only you can see this)</h3>
              <Accordion
                className="text-gray-8"
                isOpen={false}
                title={<h4 className="text-rising-blue">Click to reveal</h4>}
                closedIcon={LockIcon}
                openTitle={<h4 className="text-rising-blue">Click to hide</h4>}
                openIcon={UnlockIcon}
                iconColor="var(--rising-yellow)"
                iconSize="32px"
              >
                <BulletedList className="text-gray-9 mb-large">
                  <li>A fleet equals the total number of team members, plus one.</li>
                  <li>The Client will not pay for additional planes.</li>
                  <li>Paper can be any size or color (but the more identical the planes are, the more $$$)</li>
                  <li>‘Identical’ is based on size, shape, and color.</li>
                  <li>The planes need to fly at least 4 feet.</li>
                  <li>Anyone can make the planes (even the Account Rep)</li>
                  <li>The team will have 4 minutes to build the planes.</li>
                  <li>
                    It is the Account Rep’s job to help keep time and assess how well the planes meet the requirements.
                  </li>
                </BulletedList>
              </Accordion>
            </>
          )}
        </TextContainer>
        <FacilitatorTip
          className="sidebar-right"
          tip="Only you have a timer on your screens, so you will need  to act as the time keeper."
        />
      </SidebarContainer>
    </StepContent>
  )
}

export default ExpectationsClarifyRequestStep
