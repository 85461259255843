import { useKitSession } from "../KitSessionContext"

import { useSessionExerciseInstances } from "domains/Exercise/resource"
import ResultsBlocks from "domains/Exercise/ResultsBlocks/ResultsBlocks"
import { useHasTeamFeature } from "domains/Results/utils"
import { useKitParticipants } from "resources/monthly_kit"
import { sortUsersByShortName } from "resources/users"
import { Loading } from "ui"
import { SHARED_FLAGS } from "ui/hooks/useFeatures"

const ResultsBlocksContainer = ({ content }) => {
  const { kitInstance, team } = useKitSession()
  const { enabled: sessionRealtimeUpdates } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_ANSWER_UPDATE)
  const { enabled: sessionRealtimeUpdatesV2 } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_REPLACE_POLLING)
  const { data: participants } = useKitParticipants({ kitInstance, sessionRealtimeUpdates: sessionRealtimeUpdatesV2 })

  const { data: exerciseInstances, isFetching } = useSessionExerciseInstances(kitInstance?.id, {
    sessionRealtimeUpdates,
    refetchInterval: 30000,
  })

  if (!participants || (!exerciseInstances && isFetching)) {
    return <Loading />
  }

  const sortedParticipants = sortUsersByShortName({ users: participants })

  return <ResultsBlocks blocks={content} exerciseInstances={exerciseInstances} sortedUsers={sortedParticipants} />
}

export default ResultsBlocksContainer
