import * as Sentry from "@sentry/browser"

// To use this with an async function, simply `await` the
// handleErrorsWithAlerting call:
//
// await handleErrorsWithAlerting(myAsyncFunc)
// OR
// const result = await handleErrorsWithAlerting(myAsyncFunc)
//
// This works because myAsyncFunc will return a promise which will
// be directly returned by handleErrorsWithAlerting. The `await`
// keywords above will then await the result of this promise.
// In the error case we'll end up running `await null`, which
// is a safe no-op.
//
// This functionality was tested here: https://jsfiddle.net/evnp_/nLkc3q72
function handleErrorsWithAlerting(func, description = null) {
  try {
    return func()
  } catch (error) {
    if (!description) {
      Sentry.captureException(error)
    } else {
      Sentry.captureException(error, (scope) => {
        scope.setTransactionName(description)
        return scope
      })
    }
    return null
  }
}

// disabled since we'll add more util exports in here; remove when there's more than one
// eslint-disable-next-line import/prefer-default-export
export { handleErrorsWithAlerting }
