import { styled } from "styled-components"

const View = styled.div`
  display: flex;
  width: ${({ $width }) => $width};
  min-width: ${({ $minWidth }) => $minWidth};
  max-width: ${({ $maxWidth }) => $maxWidth};
  height: ${({ $height }) => $height};
  min-height: ${({ $minHeight }) => $minHeight};
  max-height: ${({ $maxHeight }) => $maxHeight};
  border-radius: ${({ $radius }) => $radius};
  ${({ $flexDirection, $flexDirectionWidescreen, theme }) =>
    $flexDirectionWidescreen && theme.isWidescreen
      ? `flex-direction: ${$flexDirectionWidescreen};`
      : `flex-direction: ${$flexDirection};`}
  align-items: ${({ $alignItems }) => $alignItems};
  align-content: ${({ $alignContent }) => $alignContent};
  justify-content: ${({ $justifyContent }) => $justifyContent};
  flex-shrink: ${({ $flexShrink }) => $flexShrink};
  flex-grow: ${({ $flexGrow }) => $flexGrow};
  flex-basis: ${({ $flexBasis }) => $flexBasis};
  flex-wrap: ${({ $flexWrap }) => $flexWrap};
  grid-area: ${({ $gridArea }) => $gridArea};
  ${({ $flex }) => `flex: ${$flex};`}
  align-self:  ${({ $alignSelf }) => $alignSelf};
  gap: ${({ $gap }) => $gap};
  /* stylelint-disable-next-line declaration-empty-line-before */

  ${({ $flexDirectionMobile, theme }) =>
    $flexDirectionMobile ? `@media (max-width: ${theme.mobileMax}) { flex-direction: ${$flexDirectionMobile}; }` : ""}

  ${({ $flexBasisMobile, theme }) =>
    $flexBasisMobile ? `@media (max-width: ${theme.mobileMax}) { flex-basis: ${$flexBasisMobile}; }` : ""}

  ${({ $flexDirectionTablet, theme }) =>
    $flexDirectionTablet ? `@media (max-width: ${theme.tabletMax}) { flex-direction: ${$flexDirectionTablet}; }` : ""}

  ${({ $alignItemsMobile, theme }) =>
    $alignItemsMobile ? `@media (max-width: ${theme.mobileMax}) { align-items: ${$alignItemsMobile}; }` : ""}

    ${({ $alignItemsTablet, theme }) =>
    $alignItemsTablet ? `@media (max-width: ${theme.tabletMax}) { align-items: ${$alignItemsTablet}; }` : ""}

  ${({ $justifyContentTablet, theme }) =>
    $justifyContentTablet
      ? `@media (max-width: ${theme.tabletMax}) { justify-content: ${$justifyContentTablet}; }`
      : ""}

  ${({ $justifyContentMobile, theme }) =>
    $justifyContentMobile
      ? `@media (max-width: ${theme.mobileMax}) { justify-content: ${$justifyContentMobile}; }`
      : ""}

  ${({ $gapMobile, theme }) => ($gapMobile ? `@media (max-width: ${theme.mobileMax}) { gap: ${$gapMobile}; }` : "")}
`

View.defaultProps = {
  $width: "100%",
  $alignItems: "flex-start",
  $justifyContent: "flex-start",
  $flexDirection: "row",
  $radius: "0",
  $flexShrink: "initial",
  $flexBasis: "initial",
  $flexWrap: "initial",
  $height: "initial",
  $alignSelf: "initial",
  $alignContent: "center",
  $gap: "normal",
}

export default View
