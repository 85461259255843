import { Button } from "ui"

const RoadTemplate = ({ kit }) => (
  <>
    {kit.bonus_page.bonus_desc.map(({ paragraph_text }, idx) => (
      <p key={idx} className="mb-medium">
        {paragraph_text}
      </p>
    ))}
    <h3 className="mb-medium">The OX Playbook</h3>
    <img src={kit.assets.boh_ox_playbook_image_url} alt="Operational Excellence Playbook cover" className="mb-medium" />
    <a href={kit.assets.boh_ox_playbook_download_url} target="_blank" rel="noopener noreferrer">
      <Button className="tertiary">Download the OX Playbook</Button>
    </a>
  </>
)

export default RoadTemplate
