import cn from "classnames"
import { styled } from "styled-components"

import { View, Tooltip } from "ui"

const AnswerGridBox = ({ title, answers, ...props }) => (
  <View $alignItems="stretch" {...props}>
    {!!title && <div className="title text-gray-9 text-small text-thin text-align-right mr-small">{title}</div>}
    <View $alignItems="stretch" $justifyContent="center" className="answers">
      {answers.map(({ title, value }, idx) => (
        <Tooltip
          top
          float
          className={cn("answer border border-gray-4", { "answer-highlight": value })}
          key={idx}
          title={title}
        />
      ))}
    </View>
  </View>
)

export default styled(AnswerGridBox)`
  .answer {
    height: 24px;
    flex-grow: 1;
    background-color: var(--gray-2);

    &:first-child {
      border-top-left-radius: var(--border-radius);
      border-bottom-left-radius: var(--border-radius);
    }

    &:last-child {
      border-top-right-radius: var(--border-radius);
      border-bottom-right-radius: var(--border-radius);
    }
  }

  .answer-highlight {
    background-color: var(--rising-yellow);
  }

  .title {
    flex: 1;
  }

  .answers {
    flex: 3;
  }
`
