import cn from "classnames"
import { useState } from "react"

import { processLearningStylesData, LEARNER_AGILITY_DATA } from "../results_utils/learner-agility"

import { View, Button } from "ui"

const TOP_COUNT = 3

const LearnerAgilityLearningPreferencesResults = ({ exercise, isCompact = true, className }) => {
  const [viewAll, setViewAll] = useState(false)
  const { sortedLearningStyleTypes } = processLearningStylesData(exercise)
  const visibleListeningStyles = viewAll ? sortedLearningStyleTypes : sortedLearningStyleTypes.slice(0, TOP_COUNT)

  return (
    <div className={cn("text-gray-9", className)}>
      {visibleListeningStyles.map((learningStyle, index) => {
        const learningStylesData = LEARNER_AGILITY_DATA[learningStyle]
        const bulletPoints = learningStylesData.descriptionBulletPoints
        return (
          <View key={learningStyle} className="mb-xl" $flexDirection="column">
            {isCompact ? (
              <>
                <p className="text-gray-9 text-bold">
                  {index + 1}. <span className="text-capitalize">{learningStyle}</span>
                </p>
                <p className="text-normal text-gray-9">{learningStylesData.description}</p>
              </>
            ) : (
              <>
                <h4 className="text-rising-orange">
                  {index + 1}. <span className="text-capitalize">{learningStyle}</span>
                </h4>
                <p className="text-normal text-gray-9 my-small">{learningStylesData.description}</p>
                {!!bulletPoints && (
                  <ul className="ml-xl">
                    {bulletPoints.map((item, idx) => (
                      <li key={learningStyle + idx}>{item}</li>
                    ))}
                  </ul>
                )}
              </>
            )}
          </View>
        )
      })}
      {!isCompact && (
        <Button className="link text-button" onClick={() => setViewAll(!viewAll)}>
          <h4 className="text-bold">{!viewAll ? "View all" : "Show less"}</h4>
        </Button>
      )}
    </div>
  )
}

export default LearnerAgilityLearningPreferencesResults
