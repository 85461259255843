import cn from "classnames"
import { isEmpty } from "lodash-es"
import { styled } from "styled-components"

import { mashupExercises, getTalentsData } from "../results_utils/talents"

import { Tooltip } from "ui"

const TalentOverlaps = styled(function TalentOverlaps({ userIdMap, exercisesToCompare, className }) {
  const talentsData = getTalentsData(exercisesToCompare[0])
  const talents = Object.keys(talentsData)
  const { processedExercises } = mashupExercises(exercisesToCompare, userIdMap)

  const workTogetherTalents = talents.filter(
    (talent) =>
      !isEmpty(processedExercises) &&
      processedExercises.every(({ talentBucketIndices }) => talentBucketIndices[talent] >= 2)
  )
  const supportTalents = talents.filter(
    (talent) =>
      !isEmpty(processedExercises) &&
      processedExercises.every(({ talentBucketIndices }) => talentBucketIndices[talent] <= 1)
  )
  const helpEachOtherTalents = talents.filter(
    (talent) =>
      !isEmpty(processedExercises) &&
      processedExercises.some(({ talentBucketIndices }) => talentBucketIndices[talent] >= 2) &&
      processedExercises.some(({ talentBucketIndices }) => talentBucketIndices[talent] <= 1)
  )
  const helpEachOtherTalentsWithUser = helpEachOtherTalents.map((talent) => {
    const canHelpUserId = processedExercises.find(({ talentBucketIndices }) => talentBucketIndices[talent] >= 2)
      .exercise.user_id
    const canHelpUser = userIdMap[canHelpUserId]
    return { talent, canHelpUser }
  })

  return (
    <div className={cn("mb-xl text-gray-9", className)}>
      <div>
        <h3>Areas of overlap:</h3>
        <p>
          Areas you both have very high or high talent, so you could have fun doing these activities together (or either
          person could enjoy doing them alone).
        </p>
        <ul>
          {isEmpty(workTogetherTalents) ? (
            <li className="text-gray-9">None&mdash;you have complementary rather than overlapping talents</li>
          ) : (
            workTogetherTalents.map((talent) => (
              <li key={talent}>
                <Tooltip top float title={talentsData[talent].shortDescription}>
                  {talentsData[talent].title}
                </Tooltip>
              </li>
            ))
          )}
        </ul>
      </div>
      <div>
        <h3>Divide and conquer:</h3>
        <p>
          Activities where one of you has high talent and the other one doesn’t, so you can help each other by doing the
          work you each enjoy more.
        </p>
        <ul>
          {isEmpty(helpEachOtherTalentsWithUser) ? (
            <li>(None)</li>
          ) : (
            helpEachOtherTalentsWithUser.map(({ talent, canHelpUser }) => (
              <li key={talent}>
                <Tooltip top float title={talentsData[talent].shortDescription}>
                  {talentsData[talent].title} ({canHelpUser.short_name})
                </Tooltip>
              </li>
            ))
          )}
        </ul>
      </div>
      <div>
        <h3>Get support on:</h3>
        <p>
          Areas that are less enjoyable and more draining for both of you. Good time to get support from others on the
          team or in other teams.
        </p>
        <ul>
          {isEmpty(supportTalents) ? (
            <li>(None)</li>
          ) : (
            supportTalents.map((talent) => (
              <li key={talent}>
                <Tooltip top float title={talentsData[talent].shortDescription}>
                  {talentsData[talent].title}
                </Tooltip>
              </li>
            ))
          )}
        </ul>
      </div>
    </div>
  )
})`
  display: grid;
  grid-gap: 50px;
  grid-template-columns: 1fr 1fr 1fr;

  ul {
    margin-left: 24px;
  }

  @media (max-width: ${({ theme }) => theme.tabletMax}) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: ${({ theme }) => theme.mobileLandscapeMax}) {
    grid-template-columns: 1fr;
  }
`

export default TalentOverlaps
