import { useField } from "formik"
import { useState } from "react"

import { EditableStickyNotes } from "domains/KitSession/components/StickyNotes"
import { CheckIcon, PlusIcon } from "icons/FontAwesomeIcons"
import { Button, View, Modal, colors } from "ui"
import Grid from "ui/Grid"
import { useEffectAfterFirstRender } from "ui/hooks"

const ExerciseStickyNotesBrainstormIdeas = ({ className, name, saveOnChange, preDefinedStickyNotes }) => {
  const [{ value: userStickyNotes }, { initialValue }, { setValue }] = useField(name)
  const [isOpen, setIsOpen] = useState(false)

  useEffectAfterFirstRender(() => {
    if (!initialValue?.length) {
      setValue([{ value: "" }])
    }
  })

  function handleOnChange() {
    const updatedUserStickyNotes = userStickyNotes.filter((stickyNote) => stickyNote.value.length > 0)
    if (updatedUserStickyNotes.length === 0) {
      saveOnChange(name, [{ value: "" }])
    } else {
      saveOnChange(name, updatedUserStickyNotes)
    }
  }

  function handleOnAddAnother() {
    const updatedUserStickyNotes = !!userStickyNotes?.length ? [...userStickyNotes] : []
    updatedUserStickyNotes.push({ value: "" })
    setValue(updatedUserStickyNotes)
  }

  function handleOnRemove(selectedNoteIndex) {
    const updatedUserStickyNotes = userStickyNotes.filter((_, index) => index !== selectedNoteIndex)
    if (updatedUserStickyNotes.length === 0) {
      setValue([{ value: "" }])
      saveOnChange(name, [{ value: "" }])
    } else {
      setValue(updatedUserStickyNotes)
      saveOnChange(name, updatedUserStickyNotes)
    }
  }

  function handlePickFromList(pickedNote) {
    const updatedUserStickyNotes = [...userStickyNotes, { value: pickedNote.trim() }].filter(
      (stickyNote) => stickyNote.value.length > 0
    )

    setValue(updatedUserStickyNotes)
    saveOnChange(name, updatedUserStickyNotes)
  }

  return (
    <View $flexDirection="column" $gap="16px" className={className}>
      <Grid className={className} $columns="3" $columnsMobile="1" $columnsTablet="2" $gap="12px">
        <EditableStickyNotes
          name={name}
          stickyNotes={userStickyNotes}
          saveOnChange={handleOnChange}
          handleOnRemove={handleOnRemove}
        />
      </Grid>
      <View className="my-small" $alignItems="center">
        <Button className="secondary" color={colors.risingBlue} onClick={handleOnAddAnother}>
          Add another
        </Button>
        <Button className="secondary" color={colors.risingBlue} onClick={() => setIsOpen(true)}>
          Pick from list
        </Button>
        <Modal
          isOpen={isOpen}
          onRequestClose={() => setIsOpen(false)}
          size="large"
          title="Pick from list"
          content={
            <>
              <p className="mb-medium"> Here are some examples used by other teams. </p>
              {preDefinedStickyNotes.map(({ value: currentPreDefinedNote }, index) => {
                const selectedNoteIndex = userStickyNotes?.findIndex(
                  (stickyNote) => stickyNote.value === currentPreDefinedNote
                )

                return (
                  <View
                    key={index}
                    $alignItems="center"
                    $justifyContent="space-between"
                    className="p-small bg-yellow-tint lift-4 border-radius-small mt-medium"
                  >
                    <p>{currentPreDefinedNote}</p>
                    {selectedNoteIndex !== -1 ? (
                      <Button onClick={() => handleOnRemove(selectedNoteIndex)}>
                        <CheckIcon />
                      </Button>
                    ) : (
                      <Button onClick={() => handlePickFromList(currentPreDefinedNote)}>
                        <PlusIcon />
                      </Button>
                    )}
                  </View>
                )
              })}
              <Button onClick={() => setIsOpen(false)} className="tertiary mt-large">
                Close
              </Button>
            </>
          }
        />
      </View>
    </View>
  )
}

export default ExerciseStickyNotesBrainstormIdeas
