import cn from "classnames"
import { addSeconds, format as formatDate, startOfDay } from "date-fns"
import { styled } from "styled-components"

import Button from "./Button"
import { useCurrentTheme } from "./ThemeUpdateContext"
import View from "./View"

import { PlayIcon, PauseIcon, RepeatIcon } from "icons/FontAwesomeIcons"
import { useEffectAfterChange } from "ui/hooks"

const Timer = styled(function Timer({
  durationSeconds,
  isRunning,
  onPlay,
  onPause,
  onReset,
  showTimerControls = true,
  isMiniKit = false,
  ariaLabel = "Timer",
  className,
}) {
  const PlayPauseIcon = isRunning ? PauseIcon : PlayIcon
  const formattedTime = formatDate(addSeconds(startOfDay(new Date()), durationSeconds), "m:ss")
  const theme = useCurrentTheme()

  useEffectAfterChange(() => {
    if (durationSeconds < 1 && isRunning) {
      onPause()
    }
  }, [durationSeconds, isRunning, onPause])

  return (
    <View
      className={cn(className, {
        "widescreen-timer": !!theme.isWidescreen,
      })}
      $width="auto"
      $alignItems="center"
    >
      <span
        role="timer"
        aria-label={ariaLabel}
        className={cn("time-remaining text-center text-semi-bold border-radius", {
          "text-fg": !isMiniKit,
          "bg-rising-green": !isMiniKit && 1 <= durationSeconds,
          "bg-rising-yellow": !isMiniKit && durationSeconds < 1,
          "px-xs": !!theme.isWidescreen,
          "p-xxs": !theme.isWidescreen,
          "mr-xxs": showTimerControls && !!theme.isWidescreen,
          "mr-xs": showTimerControls && !theme.isWidescreen,
          "border bg-white": !!isMiniKit,
          "mini-timer-green": !!isMiniKit && durationSeconds > 15,
          "mini-timer-orange": !!isMiniKit && durationSeconds <= 15 && durationSeconds > 1,
          "mini-timer-red": !!isMiniKit && durationSeconds <= 1,
        })}
      >
        {formattedTime}
      </span>
      {!!showTimerControls && (
        <>
          <Button
            className={cn("timer-button tertiary p-xs border-radius", {
              "mr-xxs": !!theme.isWidescreen,
              "mr-xs": !theme.isWidescreen,
            })}
            aria-label={isRunning ? "Pause" : "Play"}
            onClick={isRunning ? onPause : onPlay}
          >
            <PlayPauseIcon className="text-gray-8 button-icon" />
          </Button>
          <Button
            className={cn("timer-button tertiary p-xs ml-none border-radius", {
              "mr-xxs": !!theme.isWidescreen,
              "mr-xs": !theme.isWidescreen,
            })}
            aria-label="Reset"
            onClick={onReset}
          >
            <RepeatIcon className="text-gray-8 button-icon" />
          </Button>
        </>
      )}
    </View>
  )
})`
  .time-remaining {
    min-width: 75px;
    font-size: 1.5rem;
    line-height: 1.5rem;

    &.mini-timer-green {
      border-color: var(--rising-green);
      color: var(--rising-green);
    }

    &.mini-timer-orange {
      border-color: var(--rising-orange);
      color: var(--rising-orange);
    }

    &.mini-timer-red {
      border-color: var(--danger);
      color: var(--danger);
    }
  }

  .button-icon {
    width: 16px;
    height: 16px;
  }

  .timer-button {
    height: 32px;

    // TODO: remove padding rule once dev/prod specificity bug is resolved
    padding: var(--spacing-1);
  }

  &.widescreen-timer {
    .time-remaining {
      min-width: 160px;
      font-size: 3rem;
      line-height: 4.25rem;
    }

    .button-icon {
      width: 48px;
      height: 48px;
    }

    .timer-button {
      height: 68px;
    }
  }
`

export default Timer
