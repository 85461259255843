import cn from "classnames"
import { useMemo } from "react"
import ReactWordcloud from "react-wordcloud"
import { styled } from "styled-components"

import { Loading } from "ui"
import theme from "ui/theme"
import { plural } from "utils/string"

const TextFieldAggregatedMultiUserWordCloud = styled(function TextFieldAggregatedMultiUserWordCloud({
  identifier,
  useAggregatedResultsData,
  additional_data,
  minFontSize,
  maxFontSize,
  refetch_interval,
  height = null,
  className,
}) {
  const { data: aggregatedResults, isFetching } = useAggregatedResultsData({
    component: "TextFieldAggregatedMultiUserWordCloud",
    identifiers: [identifier],
    additional_data,
    refetch_interval,
  })

  const options = useMemo(
    () => ({
      deterministic: true,
      enableTooltip: false,
      rotations: 1,
      rotationAngles: [0, 0],
      fontSizes: [minFontSize, maxFontSize],
      fontFamily: theme.normalFontFamily,
      fontWeight: "bold",
      colors: ["var(--rising-blue)", "var(--green-2)", "var(--orange-2)", "var(--yellow-2)"],
    }),
    [minFontSize, maxFontSize]
  )

  const words = useMemo(() => aggregatedResults?.data, [aggregatedResults?.data])

  if (!aggregatedResults && isFetching) {
    return <Loading />
  }

  if (!aggregatedResults) {
    return null
  }

  const sortedTopWords = words.sort((a, b) => b.value - a.value).slice(0, 8)
  const topWordsText = sortedTopWords.map(({ text, value }) => `${text}, appeared ${plural(value, "time")}`).join(". ")
  const ariaLabel = `Top phrases: ${topWordsText}`

  return (
    <div className={cn(className, { "word-cloud-container": !!height })} aria-label={ariaLabel}>
      <ReactWordcloud aria-hidden options={options} words={words} />
    </div>
  )
})`
  &.word-cloud-container {
    height: ${({ height }) => height};
  }
`

export default TextFieldAggregatedMultiUserWordCloud
