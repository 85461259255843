import cn from "classnames"
import { Link, useParams } from "react-router-dom"
import { styled } from "styled-components"

import components from "./components"

import { NotFound, ThemeHeader } from "components"
import { UnlockIcon } from "icons/FontAwesomeIcons"
import { View, Loading } from "ui"
import { useKitInstanceByID } from "ui/SelectedTeamContext"

const Bonus = ({ className }) => {
  const { id } = useParams()
  const { kitInstance, isFetching } = useKitInstanceByID(id)

  if (!kitInstance) {
    return isFetching ? <Loading /> : <NotFound />
  }

  const kit = kitInstance.kit
  if (!kit?.bonus_page) {
    return null
  }

  const bonusComponents = kit.bonus_page.bonus_components?.map((component) => components[component.name])
  const homeUrl = kitInstance.home_url
  const hasResults = !!kitInstance.session_completed_at && !!kit.results_pages?.length

  return (
    <main className={cn("align-left full-width", className)}>
      <div className="bonus-wrapper">
        <div className="main-container full-width">
          <ThemeHeader kitInstance={kitInstance} className="pb-xxs" />
          <div className="bonus-header mt-large mb-xl">
            <UnlockIcon color="var(--rising-yellow)" className="fa-2x mr-medium unlock-icon" />
            <h1 className="text-gray-9 text-large text-bold mt-small">{kit.bonus_page.bonus_page_header}</h1>
          </div>
          {!!kit.bonus_page.bonus_page_desc && (
            <div className="mb-large">
              {kit.bonus_page.bonus_page_desc.map((description, index) => (
                <p className="mb-xs" key={index}>
                  {description.paragraph_text}
                </p>
              ))}
            </div>
          )}
          {bonusComponents ? (
            bonusComponents.map((BonusComponent, idx) => (
              <BonusComponent kit={kit} kitInstance={kitInstance} key={idx} />
            ))
          ) : (
            <div>Coming Soon</div>
          )}
          <View className="mt-xl">
            {hasResults ? (
              <Link to={kitInstance.results_url}>See your team results</Link>
            ) : (
              <Link to={homeUrl}>Back to session homepage</Link>
            )}
          </View>
        </div>
      </div>
    </main>
  )
}

export default styled(Bonus)`
  .bonus-header {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .chat-bubble {
    min-width: 250px;
  }

  .bonus-wrapper {
    display: flex;
    flex-direction: row;
  }

  button a:link {
    text-decoration: none;
  }

  .emoji-row-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    img {
      width: 48px;
      height: 48px;
    }
  }

  .description-wrapper {
    display: flex;
    flex-direction: row;
  }

  @media (max-width: ${({ theme }) => theme.tabletMax}) {
    position: relative;

    .bonus-title {
      color: var(--gray-9);
      line-height: 2.25rem;
    }

    .unlock-icon {
      font-size: 48px;
    }

    .chat-bubble,
    hr {
      display: none;
    }
  }
`
