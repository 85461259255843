import cn from "classnames"
import { styled } from "styled-components"

import { TrafficLightStop, TrafficLightSlow, TrafficLightGo } from "icons/FontAwesomeIcons"
import { View } from "ui"
import { useWindowSize } from "ui/hooks"

const NumPeopleCompletedTrafficSign = styled(function NumPeopleCompletedTrafficSign({ current, total, className }) {
  const { isMobileOrSmaller } = useWindowSize()
  const trafficLightClasses = cn("traffic-light-icon", {
    "mb-small": !!isMobileOrSmaller,
    "mb-large": !isMobileOrSmaller,
  })
  const getTrafficStatusIcon = (current, total) => {
    if (current / total < 0.5) {
      return <TrafficLightStop color="var(--orange-3)" className={trafficLightClasses} />
    } else if (current / total >= 0.5 && current / total < 1) {
      return <TrafficLightSlow color="var(--yellow-2)" className={trafficLightClasses} />
    } else {
      return <TrafficLightGo color="var(--green-3)" className={trafficLightClasses} />
    }
  }

  const trafficStatusIcon = getTrafficStatusIcon(current, total || 1)

  return (
    <View
      className={cn(className, "full-width", "mb-medium")}
      $flexDirection="column"
      $alignItems="center"
      $justifyContent="center"
    >
      {trafficStatusIcon}
      {current === total ? (
        <>
          <h4 className="text-gray-9">Your team is ready.</h4>
          <p>Proceed to the next step.</p>
        </>
      ) : (
        <>
          <p>Wait until your team is ready. </p>
          <h4 className="text-gray-9">
            {current ?? 1} out of {total} are ready
          </h4>
        </>
      )}
    </View>
  )
})`
  position: relative;
  z-index: var(--z-above-zero);

  .traffic-light-icon {
    height: 100px;
    width: 100px;
  }
`

export default NumPeopleCompletedTrafficSign
