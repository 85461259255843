import { isEmpty } from "lodash-es"
import { useState } from "react"
import { styled } from "styled-components"

import { Accordion } from "components"
import { KIT_TYPE } from "domains/LeaderKit/utils"
import { SparklesIcon } from "icons/FontAwesomeIcons"
import {
  useSessionSurveyRollup,
  useSessionFeedbackSummary,
  useSessionFeedback,
  getSessionFeedback,
} from "resources/monthly_kit"
import { useUser } from "resources/users"
import { AnswerDisplayContent, Button, Loading } from "ui"
import { useEffectAfterChange } from "ui/hooks"
import useFeatures, { FLAGS } from "ui/hooks/useFeatures"

const MiniSessionSurveyReport = styled(function SessionSurveyReport({
  kitInstance,
  selectedKit,
  teamId,
  teamTags,
  accountId,
  accountTags,
  multiAccountIds,
  includeArchivedTeams,
  className,
}) {
  const kitInstanceId = kitInstance?.id
  const kitSlug = selectedKit?.slug
  const kitType = selectedKit?.type

  const { [FLAGS.SESSION_FEEDBACK_AI_SUMMARY]: showSessionFeedbackSummary } = useFeatures()
  const { data: miniSessionSurveyRollup, isFetching: isFetchingMiniSessionSurveyRollup } = useSessionSurveyRollup({
    kitInstanceId,
    kitSlug,
    kitType: "mini",
    teamId,
    teamTags,
    accountId,
    accountTags,
    multiAccountIds,
    includeArchivedTeams,
  })
  const {
    data: sessionFeedbackSummary,
    isInitialLoading: sessionFeedbackSummaryIsLoading,
    refetch: getSessionFeedbackSummary,
  } = useSessionFeedbackSummary({
    kitInstanceId,
    kitSlug,
    kitType: "mini",
    teamId,
    teamTags,
    accountId,
    accountTags,
    multiAccountIds,
    includeArchivedTeams,
    enabled: false,
  })
  const { data: initialSessionFeedback } = useSessionFeedback({
    kitInstanceId,
    kitSlug,
    kitType: "mini",
    teamId,
    teamTags,
    accountId,
    accountTags,
    multiAccountIds,
    includeArchivedTeams,
  })
  const { data: user } = useUser({ userId: "me" })
  const [sessionFeedback, setSessionFeedback] = useState()

  useEffectAfterChange(() => {
    if (initialSessionFeedback) {
      setSessionFeedback(initialSessionFeedback)
    }
  }, [initialSessionFeedback])

  const onLoadMoreSessionFeedback = async () => {
    const moreSessionFeedback = await getSessionFeedback({
      kitInstanceId,
      kitSlug,
      kitType,
      teamId,
      teamTags,
      accountId,
      accountTags,
      multiAccountIds,
      includeArchivedTeams,
      startIndex: sessionFeedback.start_index + sessionFeedback.page_size,
    })
    setSessionFeedback({
      ...moreSessionFeedback,
      feedback_list: [...sessionFeedback.feedback_list, ...moreSessionFeedback.feedback_list],
    })
  }

  if ((!miniSessionSurveyRollup && isFetchingMiniSessionSurveyRollup) || !user) {
    return <Loading />
  }

  const surveys = kitInstance?.post_session_surveys?.filter((survey) => Object.values(survey).some((value) => value))

  if ((!surveys || surveys?.length === 0) && isEmpty(miniSessionSurveyRollup)) {
    return (
      <div className={className}>
        <h3 className="mb-medium text-gray-9">Post session survey responses</h3>
        No data
      </div>
    )
  }

  const isMini = selectedKit?.type === KIT_TYPE.MINI
  const {
    team_count,
    completed_session_count,
    session_participant_count,
    unique_session_participant_count,
    response_count,
    think_about_session,
  } = miniSessionSurveyRollup

  return (
    <div className={className}>
      {(!!isMini || !kitType) && (
        <>
          <h2 className="mb-medium text-gray-9">Minis</h2>
          <div className="mb-medium">
            {!teamId && (
              <div>
                <span className="text-semi-bold">Teams:</span> {team_count}
              </div>
            )}
            <div>
              <span className="text-semi-bold">Sessions completed:</span> {completed_session_count}
            </div>
            <div>
              <span className="text-semi-bold">Session participants:</span> {session_participant_count}
            </div>
            <div>
              <span className="text-semi-bold">Unique session participants:</span> {unique_session_participant_count}
            </div>
            <div>
              <span className="text-semi-bold">Survey Responses:</span> {response_count}
            </div>
          </div>
          <h3 className="mb-medium text-gray-9">Post session survey responses</h3>
          <div>
            The question is on a 1-5 scale where 3 is neutral, 4 is liked the session, and 5 is liked the session a lot.
          </div>
          <table>
            <thead>
              <tr>
                <th></th>
                <th>
                  Think about this session
                  <br />
                  (1-5)
                </th>
              </tr>
            </thead>
            <tbody>
              {!!surveys && (
                <>
                  {surveys.map((survey, idx) => (
                    <tr key={idx}>
                      <td className="text-nowrap">Response {idx + 1}</td>
                      <td>{survey.think_about_session}</td>
                    </tr>
                  ))}
                  <tr>
                    <th colSpan="5"></th>
                  </tr>
                </>
              )}
              <tr>
                <td className="text-nowrap">Median Score</td>
                <td>{think_about_session?.median}</td>
              </tr>
              <tr>
                <td className="text-nowrap">Average Score</td>
                <td>{think_about_session?.avg}</td>
              </tr>
              <tr>
                <td className="text-nowrap">% Who Liked the Session</td>
                <td>{think_about_session?.pct_agree}</td>
              </tr>
            </tbody>
          </table>

          <h3 className="mt-xl mb-medium text-gray-9">Open ended comments</h3>
          {!sessionFeedback ? (
            <Loading />
          ) : isEmpty(sessionFeedback.feedback_list) ? (
            "No data"
          ) : (
            <Accordion
              className="text-gray-9"
              title="Was there anything you especially liked about this session or thought could be improved?"
            >
              {!!showSessionFeedbackSummary && !!user.is_staff && (
                <div className="mb-large">
                  {!!sessionFeedbackSummary || !!sessionFeedbackSummaryIsLoading ? (
                    <>
                      <h4>
                        AI Generated Summary <SparklesIcon className="text-rising-yellow" />
                      </h4>
                      <div className="pl-large">
                        <AnswerDisplayContent
                          answer={
                            !!sessionFeedbackSummary
                              ? sessionFeedbackSummary?.improve_experience_feedback_summary
                              : "Generating..."
                          }
                        />
                      </div>
                    </>
                  ) : (
                    <Button className="tertiary" onClick={() => getSessionFeedbackSummary()}>
                      Generate AI Summary <SparklesIcon className="text-rising-yellow" />
                    </Button>
                  )}
                </div>
              )}
              <div className="pl-large">
                {sessionFeedback.feedback_list.map(
                  (feedback, idx) => feedback && <AnswerDisplayContent key={idx} answer={feedback} />
                )}
                {sessionFeedback.start_index + sessionFeedback.page_size < sessionFeedback.total_count && (
                  <Button className="tertiary" onClick={onLoadMoreSessionFeedback}>
                    Load more
                  </Button>
                )}
              </div>
            </Accordion>
          )}
        </>
      )}
    </div>
  )
})`
  table {
    border-collapse: collapse;
  }

  th,
  td {
    border: 1px solid var(--gray-7);
    padding: 4px 12px;
    text-align: center;
    color: var(--gray-9);
  }

  th {
    background-color: var(--gray-2);
  }

  tr {
    height: 36px;
  }
`

export default MiniSessionSurveyReport
