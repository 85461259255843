import * as Sentry from "@sentry/browser"
import { initializeApp } from "firebase/app"
import { getAuth, getIdToken, onAuthStateChanged, signOut } from "firebase/auth"
import { getDatabase, connectDatabaseEmulator } from "firebase/database"
import { auth as firebaseUI } from "firebaseui"
import "firebaseui/dist/firebaseui.css"

import { getNumSecondsSinceEpoch } from "utils/date"
import { buildUrl } from "utils/string"

// Firebase Auth Token Management Strategy
// ---------------------------------------
// For Firebase Auth'd users we need to add user ID tokens to every request in the
// form of an "Authorization: 'Bearer <token>'" header, but since we only want
// _some_ users to authenticate with Firebase Auth, this poses a problem:
//
// If a user isn't using Firebase Auth at authentication, we don't want
// their requests to rely on the Firebase Auth SDK at all, and we definitely don't
// want their requests to await for Firebase Auth to initialize and get the latest
// refreshed user ID token.
//
// To deal with this, whenever a user is signed in via Firebase Auth, we persist
// the latest Firebase Auth user ID token to localStorage, with an expiry date
// attached matching the time it will expire on the Firebase side (1 hour from
// refresh). If the a token does not exist in localStorage, our request code knows
// that a user is not authed via Firebase and will never try to refresh a token.
// Upon sign-out, the token is removed entirely from localStorage.
//
// On page reload, we can immediately send the persisted tokens with requests so
// authentication will work without waiting for the Firebase Auth SDK to initialize
// (and will continue to work after the SDK is fully initialized).

const FIREBASE_AUTH_TOKEN_PERSISTENCE_KEY = "RISINGTEAM_FIREBASE_AUTH_TOKEN"
const FIREBASE_AUTH_USER_NAME_PERSISTENCE_KEY = "RISINGTEAM_FIREBASE_AUTH_USER_NAME"
const FIREBASE_AUTH_TOKEN_EXPIRY_SECONDS = 55 * 60 // 1 hour minus 5 minute buffer
// 1 hour is the default token expiry period, see:
// https://firebase.google.com/docs/auth/admin/manage-sessions

// Private util functions:
function _isTokenExpired(expiry) {
  return !Number.isInteger(expiry) || expiry < getNumSecondsSinceEpoch()
}
function _persistFirebaseUserIdTokenWithNewExpiry(token) {
  window.localStorage.setItem(
    FIREBASE_AUTH_TOKEN_PERSISTENCE_KEY,
    JSON.stringify({
      token,
      expiry: getNumSecondsSinceEpoch() + FIREBASE_AUTH_TOKEN_EXPIRY_SECONDS,
    })
  )
}
function _persistFirebaseUserName(user) {
  const name = user?.displayName?.trim() ?? ""
  if (name) {
    window.sessionStorage.setItem(FIREBASE_AUTH_USER_NAME_PERSISTENCE_KEY, name)
    // Use sessionStorage so this will be cleaned up naturally when tab closes.
    // We don't need to persist it any longer than that since it's just used
    // for pre-populating input fields during user account creation.
  }
}

// TODO: jey: remove after we switch all sso providers to same domain
function isUsingSameAuthDomain() {
  const url = new URL(window.location)
  return url.searchParams.get("useSameAuthDomain") === "true"
}

// TODO: jey: remove after we switch all sso providers to same domain
function redirectWithSameAuthDomainIfNeeded({ features, provider, next }) {
  if (isUsingSameAuthDomain()) {
    return false
  }

  const flagName = `use_same_auth_domain_${provider}`
  const shouldUseSameAuthDomain = !!features[flagName]
  if (!shouldUseSameAuthDomain) {
    return false
  }

  const url = new URL(
    buildUrl([window.location.origin, "auth", "sso", provider], {
      urlQueryParams: { next, useSameAuthDomain: "true" },
    })
  )
  window.location = url
  return true
}

let _firebaseApp = null
function initializeFirebase() {
  const authDomain = isUsingSameAuthDomain() ? window.location.hostname : import.meta.env.VITE_APP_FIREBASE_AUTH_DOMAIN

  // Tests may not have access to VITE_APP_FIREBASE_API_KEY, in which case we'll
  // skip Firebase Auth initialization entirely. If we do want to test flows that
  // involve Firebase auth, we'll set up the auth emulator to do so.
  // https://firebase.google.com/docs/emulator-suite/connect_auth
  if (import.meta.env.VITE_APP_FIREBASE_API_KEY) {
    _firebaseApp = initializeApp({
      apiKey: import.meta.env.VITE_APP_FIREBASE_API_KEY,
      authDomain,
      projectId: import.meta.env.VITE_APP_FIREBASE_PROJECT_ID,
      storageBucket: import.meta.env.VITE_APP_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: import.meta.env.VITE_APP_FIREBASE_MESSAGING_SENDER_ID,
      appId: import.meta.env.VITE_APP_FIREBASE_APP_ID,
      databaseURL: import.meta.env.VITE_APP_FIREBASE_DATABASE_URL,
    })

    const db = getDatabase()
    if (["development", "test"].includes(import.meta.env.VITE_NODE_ENV)) {
      connectDatabaseEmulator(db, "localhost", 9000)
    }
  }
}

function getFirebaseAuthInstance() {
  if (!import.meta.env.VITE_APP_FIREBASE_API_KEY) {
    return null // in test environment, skip Firebase integration entirely; see comment above
  } else if (!_firebaseApp) {
    throw new Error("firebase.js: You must call initializeFirebase before calling getFirebaseAuthInstance.")
  } else {
    return getAuth()
  }
}

// Get the latest Firebase user ID token from localStorage, to be used in the
// "Authorization" request header for API requests.
// This function will _not_ refresh the token automatically if it is expired;
// instead it provides a { token, expired } return value and the caller must
// check `expired` and refresh the token separately if necessary.
function getFirebaseAuthInfo() {
  const strValue = window.localStorage.getItem(FIREBASE_AUTH_TOKEN_PERSISTENCE_KEY)
  let parsedValue = null
  try {
    parsedValue = JSON.parse(strValue)
  } catch (e) {
    // Pass, handle invalid persisted token value below.
  }

  const token = parsedValue?.token ?? null
  const expiry = parsedValue?.expiry ?? null

  if (!token || !Number.isInteger(expiry)) {
    // Token was invalid or missing - clear value from persistence:
    window.localStorage.removeItem(FIREBASE_AUTH_TOKEN_PERSISTENCE_KEY)
  }

  return {
    token,
    expired: token ? _isTokenExpired(expiry) : false,
    isAuthenticatedWithFirebase: !!token,
    // presence of token in localStorage indicates user is authed via Firebase
    firebaseUserDisplayName: window.sessionStorage.getItem(FIREBASE_AUTH_USER_NAME_PERSISTENCE_KEY) ?? "",
  }
}

// Run a function on the _next_ auth-state-change event only,
// and don't run it for any subsequent auth-state-change events after that:
function onNextAuthStateChangedOnly(func) {
  const unsubscribeOnAuthStateChanged = onAuthStateChanged(getFirebaseAuthInstance(), (user) => {
    // Remove handler immediately to ensure that it's only ever executed once per call:
    unsubscribeOnAuthStateChanged()
    return func(user)
  })
}

// Force a refresh the Firebase user ID token, and return a promise which
// resolves to the refreshed token - which will expire in 1 hour.
// Also sets the latest token and new expiry in localStorage.
function refreshFirebaseUserIdToken(token) {
  const previousToken = token ?? getFirebaseAuthInfo().token
  return new Promise((resolve) => {
    onNextAuthStateChangedOnly(async (user) => {
      if (!user) {
        return resolve(null)
      }
      const forceTokenRefresh = true // force refresh so we know next expiry time
      let refreshedToken
      try {
        refreshedToken = await getIdToken(user, forceTokenRefresh)
      } catch (err) {
        if (/firebase.*\(auth\/network-request-failed\)/i.test(err.message)) {
          // Gracefully handle "network request failed" errors since these can
          // happen arbitrarily when the network connection is interrupted.
          // This will generally result in a 401 (unauthenticated) request response
          // at which point our system will retry the token refresh and request.
          console.warn(err.message)
          return resolve(null)
        } else {
          throw err
        }
      }
      if (!refreshedToken) {
        throw new Error("firebase.js: getIdToken unexpectedly did not produce a token")
      }
      const currentToken = getFirebaseAuthInfo().token
      // Ensure authed state hasn't changed since we started awaiting latest token:
      if (previousToken === currentToken) {
        _persistFirebaseUserIdTokenWithNewExpiry(refreshedToken)
        return resolve(refreshedToken)
      } else {
        return resolve(currentToken)
      }
    })
  })
}

// Get the `Authorization: "Bearer <token>"` value needed to
// authenticate request against our backend via Firebase Auth.
// Returns null if the current user doesn't use Firebase Auth.
// Otherwise, returns a promise which resolves to the header key/value with
// correct token, after awaiting refresh of the token if necessary.
function getFirebaseAuthRequestHeaderPromise({ forceTokenRefresh = false } = {}) {
  let { isAuthenticatedWithFirebase, token, expired } = getFirebaseAuthInfo()
  if (!isAuthenticatedWithFirebase) {
    return null
  } else {
    return new Promise(async (resolve) => {
      if (isAuthenticatedWithFirebase) {
        if (expired || forceTokenRefresh) {
          // Attempt to refresh the Firebase user ID token if it was expired,
          // or if this is a retry due to request auth failure:
          try {
            token = await refreshFirebaseUserIdToken(token)
          } catch (e) {
            Sentry.captureException(e)
            token = null
          }
        }
        if (token) {
          resolve({ Authorization: `Bearer ${token}` })
        } else {
          resolve({})
        }
      }
    })
  }
}

// Sign out the current user from Firebase Auth, and clear ID token from localStorage.
async function signOutFirebaseUser() {
  window.localStorage.removeItem(FIREBASE_AUTH_TOKEN_PERSISTENCE_KEY)
  const auth = getFirebaseAuthInstance()
  if (auth) {
    await signOut(auth)
  }
}

// Start FirebaseUI using the provided HTML id as container element.
// `onSignIn` may be provided to execute code after user successfully auths.
function startFirebaseUI(authContainerId, { ssoProviders = [], ssoRedirectLogin = false, onSignIn = null }) {
  console.info("[Firebase 0ms] Starting Firebase UI.")

  const startMs = Date.now()
  const time = () => Date.now() - startMs
  let completedSignIn = false
  let currentUser = null

  function completeSignIn(user) {
    if (!completedSignIn && user) {
      console.info(`[Firebase ${time()}ms] Getting user ID token...`)
      user.getIdToken().then((token) => {
        console.info(`[Firebase ${time()}ms] Recieved user ID token, completing sign in...`)
        if (!completedSignIn && token) {
          completedSignIn = true
          _persistFirebaseUserIdTokenWithNewExpiry(token)
          _persistFirebaseUserName(user)
          onSignIn?.()
          console.info(`[Firebase ${time()}ms] Sign in completed.`)
        }
      })
    }
  }

  const uiConfig = {
    signInFlow: ssoRedirectLogin ? "redirect" : "popup",
    signInOptions: ssoProviders,
    tosUrl: "https://risingteam.com/terms",
    privacyPolicyUrl: "https://risingteam.com/privacy",
    callbacks: {
      signInSuccessWithAuthResult: (authResult) => {
        if (!currentUser && authResult.user) {
          console.info(`[Firebase ${time()}ms] signInSuccessWithAuthResult triggered for user:`, authResult.user)
          currentUser = authResult.user
          completeSignIn(authResult.user)
        }
        return false
        // Return false to prevent redirect here; our component code
        // will redirect to "next" query param if it was specified.
      },
    },
  }

  const auth = getFirebaseAuthInstance()
  const ui = firebaseUI.AuthUI.getInstance() ?? new firebaseUI.AuthUI(auth)

  // Register "backup" completeSignIn handler to help avoid long delays before
  // FirebaseUI calls signInSuccessWithAuthResult on some mobile devices:
  const unsubscribeOnAuthStateChanged = onAuthStateChanged(getFirebaseAuthInstance(), (user) => {
    if (!currentUser && user) {
      console.info(`[Firebase ${time()}ms] onAuthStateChanged triggered for user:`, user)
      currentUser = user
      completeSignIn(user)
      // After sign-in successfully completed, remove handler so we don't run it again:
      unsubscribeOnAuthStateChanged()
    }
  })

  ui.start(`#${authContainerId}`, uiConfig)

  return ui
}

function getFirebaseRealtimeDatabase() {
  if (!import.meta.env.VITE_APP_FIREBASE_API_KEY) {
    return null // in test environment, skip Firebase integration entirely; see comment above
  } else if (!_firebaseApp) {
    throw new Error("firebase.js: You must call initializeFirebase before calling getFirebaseRealtimeDatabase.")
  } else {
    return getDatabase()
  }
}

export {
  initializeFirebase,
  getFirebaseAuthInfo,
  refreshFirebaseUserIdToken,
  getFirebaseAuthRequestHeaderPromise,
  signOutFirebaseUser,
  startFirebaseUI,
  getFirebaseRealtimeDatabase,
  isUsingSameAuthDomain,
  redirectWithSameAuthDomainIfNeeded,
}
