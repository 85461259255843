import { useRef, useState } from "react"

import { useKitSession } from "../KitSessionContext"

import SharingNow from "./SharingNow"
import StepTitle from "./StepTitle"

import { getTeamLead } from "domains/Exercise/results_utils"
import { useSyncSessionEnabled } from "domains/KitSession/utils"
import { KIT_GROUP_SIZE } from "domains/LeaderKit/utils"
import { useHasTeamFeature } from "domains/Results/utils"
import {
  useRealtimeSelectedShareoutUser,
  useKitParticipants,
  useUpdateSelectedShareoutUser,
} from "resources/monthly_kit"
import { sortUsersByShortName, useUser } from "resources/users"
import { useEffectAfterChange } from "ui/hooks"
import { SHARED_FLAGS } from "ui/hooks/useFeatures"
import Loading from "ui/Loading"
import SelectedUserContext from "ui/SelectedUserContext"
import { REALTIME_KEY_PATH_PREFIX } from "utils/query"

const EMPTY_USER = { id: "" }

const SessionSelectedUsersShareView = (props) => {
  const { kitInstance, team } = useKitSession()
  const { enabled: sessionRealtimeUpdatesV2 } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_REPLACE_POLLING)
  const { data: participants } = useKitParticipants({ kitInstance, sessionRealtimeUpdates: sessionRealtimeUpdatesV2 })

  if (!participants) {
    return null
  }

  return <ViewWithParticipants participants={participants} team={team} {...props} />
}

const ViewWithParticipants = ({
  participants,
  team,
  sessionStep,
  totalMinutes,
  minMinutesPerUser,
  maxMinutesPerUser,
  initialSelectionEmpty,
  kitInstance,
  labelText = "Sharing now:",
  children,
}) => {
  const { enabled: realtimeShareoutUserActive } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_SHAREOUT_USER)
  const realtimeShareoutUserKey = REALTIME_KEY_PATH_PREFIX + sessionStep.path
  const forceFullUserNames = kitInstance?.kit?.group_size === KIT_GROUP_SIZE.JUMBO
  const { data: user } = useUser({ userId: "me" })
  const isTeamLead = user.id === team.team_lead_id
  const { data: selectedShareoutUser, isInitialLoading } = useRealtimeSelectedShareoutUser({
    kitInstanceId: kitInstance?.id,
    key: realtimeShareoutUserKey,
    forceFullUserNames,
    enabled: !!(kitInstance && realtimeShareoutUserActive),
  })
  const { mutateAsync: updateSelectedShareoutUser } = useUpdateSelectedShareoutUser({
    kitInstanceId: kitInstance?.id,
    key: realtimeShareoutUserKey,
  })
  const syncSessionEnabled = useSyncSessionEnabled()
  const componentRef = useRef(null)
  const timerRef = useRef()
  const teamLead = getTeamLead(participants, team)
  const initialManuallySelectedUser = initialSelectionEmpty ? EMPTY_USER : teamLead || participants[0]
  const [manuallySelectedUser, setManuallySelectedUser] = useState(initialManuallySelectedUser)
  const sortedParticipants = sortUsersByShortName({ users: participants })

  const onSelectUser = (user) => {
    setManuallySelectedUser(user)
    realtimeShareoutUserActive && isTeamLead && syncSessionEnabled && updateSelectedShareoutUser(user.id)
    timerRef.current?.resetAndPlay?.()
    componentRef.current.scrollIntoView()
  }

  useEffectAfterChange(() => {
    if (!!realtimeShareoutUserActive && !!syncSessionEnabled) {
      componentRef?.current?.scrollIntoView()
    }
  }, [selectedShareoutUser, syncSessionEnabled, realtimeShareoutUserActive])

  const teamSize = participants.length

  const timePerMember = teamShareMemberTime({
    teamSize,
    totalMinutes: totalMinutes ?? sessionStep.minutes,
    minMinutesPerUser: minMinutesPerUser ?? 1,
    maxMinutesPerUser,
  })

  if (realtimeShareoutUserActive && isInitialLoading) {
    return <Loading />
  }

  const selectedRealtimeUser = !!selectedShareoutUser ? selectedShareoutUser : teamLead || participants[0]
  const selectedUser =
    realtimeShareoutUserActive && syncSessionEnabled && !isTeamLead ? selectedRealtimeUser : manuallySelectedUser

  return (
    <div ref={componentRef}>
      <h3 className="text-gray-9 text-bold">{labelText}</h3>
      <StepTitle
        sessionStep={sessionStep}
        minutes={timePerMember * teamSize}
        startSeconds={timePerMember * 60}
        timerRef={timerRef}
        showTimerLead
        className="mb-large"
      >
        <SharingNow users={sortedParticipants} selectedUser={selectedUser} onSelectUser={onSelectUser} />
      </StepTitle>
      {!!selectedUser && (
        <SelectedUserContext.Provider value={{ selectedUser }}>{children}</SelectedUserContext.Provider>
      )}
    </div>
  )
}

const teamShareMemberTime = ({ teamSize, totalMinutes, maxMinutesPerUser, minMinutesPerUser }) => {
  if (!totalMinutes) {
    return minMinutesPerUser
  }

  const calculatedMinutes = Math.floor(totalMinutes / teamSize)
  if (maxMinutesPerUser && calculatedMinutes > maxMinutesPerUser) {
    return maxMinutesPerUser
  }
  if (calculatedMinutes < minMinutesPerUser) {
    return minMinutesPerUser
  }
  return calculatedMinutes
}

export default SessionSelectedUsersShareView
