import { positiveIntOrZero } from "./Duration"
import { useTimer } from "./hooks"
import Timer from "./Timer"

const DefaultTimer = ({ minutes, showTimerControls, autoStart = false, className }) => {
  const startMilliseconds = minutes * 60 * 1000
  const timerConfig = autoStart ? { isRunning: true, timePassed: 0, playClickedTime: Date.now() } : {}
  const { timeLeft, play, pause, reset, isRunning } = useTimer(startMilliseconds, timerConfig)

  const durationSeconds = positiveIntOrZero(timeLeft / 1000)

  if (!minutes) {
    return null
  }

  return (
    <>
      <Timer
        durationSeconds={durationSeconds}
        isRunning={isRunning}
        onPlay={play}
        onPause={pause}
        onReset={reset}
        showTimerControls={showTimerControls}
        className={className}
      />
      {durationSeconds < 1 && (
        <h3 className="show-on-tablet-or-desktop text-gray-8 ml-small mt-xs text-nowrap">Time’s up!</h3>
      )}
    </>
  )
}

export default DefaultTimer
