import cn from "classnames"

import Footer from "./Footer"
import Sidebar from "./Sidebar"

import { View } from "ui"

const RTKitViewBase = ({ sidebar, children }) => (
  <View
    className={cn("full-width mx-auto", { "screen-max-width": !!sidebar, "content-max-width": !sidebar })}
    $flexDirection="column"
    $alignItems="center"
  >
    <View $flex={1}>
      {!!sidebar && <Sidebar selected={sidebar} />}
      <View className="rtkitview" $flex={1}>
        {children}
      </View>
    </View>
    <Footer className="mt-large" />
  </View>
)

export default RTKitViewBase
