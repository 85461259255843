import { Formik } from "formik"
import { pick } from "lodash-es"
import { Link } from "react-router-dom"
import { useTimezoneSelect } from "react-timezone-select"

import UserAccountForm from "./UserAccountForm"

import TeamsTable from "domains/Admin/TeamsTable"
import { Yup } from "forms"
import { userSchemas } from "forms/yup"
import { useTeams } from "resources/teams"
import { useUser, useUpdateCurrentUser } from "resources/users"
import { Button } from "ui"

const userAccountSchema = Yup.object().shape({
  ...pick(userSchemas, ["first_name", "last_name", "time_zone"]),
})

const UserAccount = ({ hideTitle = false, onDone = null }) => {
  const { parseTimezone } = useTimezoneSelect({})
  const { data: user } = useUser({ userId: "me" })
  const { data: teams } = useTeams()

  const { mutateAsync: updateCurrentUser } = useUpdateCurrentUser()

  if (!user) {
    return null
  }

  const time_zone = parseTimezone(user.time_zone ?? "")?.value ?? ""
  const initialValues = {
    first_name: user.first_name,
    last_name: user.last_name,
    time_zone,
  }

  const saveOnChange = async (key, values) => {
    if (key.startsWith("profile.")) {
      await updateCurrentUser({
        profile: { [key.slice(8)]: values },
      })
    } else {
      await updateCurrentUser({ [key]: values })
    }
  }

  return (
    <div className="main-container full-width">
      {!hideTitle && <h1 className="mb-large">Account</h1>}
      <Formik initialValues={initialValues} validationSchema={userAccountSchema}>
        <UserAccountForm saveOnChange={saveOnChange} />
      </Formik>
      {/* Only show teams table here to non-leads. */}
      {/* Team leads will manage their teams via the /admin page instead. */}
      {
        // TODO(admin) replace this with !user.can_see_admin_tab when we have that
        !(user.is_a_team_lead || user.can_manage_billing_for_an_account) && (
          <>
            <h3 className="mt-xxl">Your teams</h3>
            <TeamsTable className="my-medium" user={user} teams={teams} showInviteStatus={false} />
          </>
        )
      }
      {!!onDone && (
        <Button className="primary mt-xl" onClick={onDone}>
          Done
        </Button>
      )}
      <div className="mt-xl">
        Need help or have feedback? <Link to="/contact">Contact Us</Link>
      </div>
    </div>
  )
}

export default UserAccount
