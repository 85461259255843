const TERM_DESCRIPTIONS = {
  // Motivators:
  advancement: "Progress toward longer-term career goals",
  autonomy: "Ownership and agency over my work",
  compensation: "Financial reward or benefits",
  contribution: "My time and expertise adds value",
  flexibility: "Choice on when and where I work",
  growth: "Learning and expanding my skills",
  mastery: "I am able to do work I excel at",
  people: "Working with people I like or respect",
  purpose: "Mission and impact",
  recognition: "Being noticed or appreciated",
  responsibility: "Scope of work and influence",
  values: "My values align with the organization's",
}

// disabled since we'll add more util exports in here; remove when there's more than one
// eslint-disable-next-line import/prefer-default-export
export { TERM_DESCRIPTIONS }
