import { Form, Formik } from "formik"
import { useNavigate } from "react-router"

import { ALL_TEAMS, TEAM_OTHER } from "domains/Reports/constants"
import EngagementSurveyResults from "domains/Reports/EngagementSurveyReport/EngagementSurveyResults"
import { getReportUrl, getTagSelectedLabel, getTaggedItemsDescription, TaggedItemsTooltip } from "domains/Reports/utils"
import { MultiSelectField } from "forms/fields"
import TaggedSelectField, { TagOptionLabel } from "forms/fields/TaggedSelectField"
import { useReportAccountTeams } from "resources/billing"
import { useAddTeamTag, useRemoveTeamTag, useRemoveTeamTagSuffix, sortUserTeams } from "resources/teams"
import { View, HorizontalRule } from "ui"
import { useQueryParams } from "ui/hooks"
import useFeatures, { FLAGS } from "ui/hooks/useFeatures"
import { plural, parseArrayQueryParam } from "utils/string"

const EngagementSurveyReport = ({ className, user, accountId, includeArchivedTeams, isAdminForSelectedAccount }) => {
  const features = useFeatures()
  const {
    teamId: teamIdQueryParam,
    teamTags: teamTagsQueryParam,
    kitSlug,
    hideIncompleteSurveyeeResponses: hideIncompleteSurveyeeResponsesQueryParam,
    ...remainingQueryParams
  } = useQueryParams()
  const hideIncompleteSurveyeeResponses = hideIncompleteSurveyeeResponsesQueryParam?.toLowerCase() === "true"
  const { data: teams } = useReportAccountTeams({
    accountId,
    includeArchivedTeams,
    includeEngagementSurveyCounts: true,
  })
  const navigate = useNavigate()
  const { mutateAsync: addTeamTag } = useAddTeamTag({ includeArchivedTeams })
  const { mutateAsync: removeTeamTag } = useRemoveTeamTag({ includeArchivedTeams })
  const { mutateAsync: removeTeamTagSuffix } = useRemoveTeamTagSuffix({ includeArchivedTeams })

  if (!teams) {
    return null
  }

  const showAllTeamsOption = !!(teams && teams.length > 1)
  const showTeamOtherOption = !!(isAdminForSelectedAccount || features[FLAGS.SUPERUSER_REPORTS])

  const allTeamsSelected = teamIdQueryParam === ALL_TEAMS
  const teamOtherSelected = teamIdQueryParam === TEAM_OTHER
  const teamId = !allTeamsSelected ? teamIdQueryParam : null

  const sortedTeams = sortUserTeams({ user, teams })

  const parsedTeamId = allTeamsSelected ? null : parseInt(teamId)
  const selectedTeam = parsedTeamId ? sortedTeams.find((team) => team.id === parsedTeamId) : null
  let teamIdFormValue = selectedTeam?.id ?? ALL_TEAMS
  if (allTeamsSelected) {
    teamIdFormValue = ALL_TEAMS
  } else if (teamOtherSelected) {
    teamIdFormValue = TEAM_OTHER
  }

  const teamTags = parseArrayQueryParam(teamTagsQueryParam)
  const teamTagsFormValue = teamTags.map((tag) => ({ value: tag, label: tag }))
  // Get tags from all teams, make them unique, then sort them alphabetically:
  const allTeamTags = Array.from(new Set(sortedTeams.flatMap((team) => team.tags))).sort((a, b) => a.localeCompare(b))
  const hasOnlyOneOrZeroTag = allTeamTags.length < 2
  const teamsMatchingTags = sortedTeams.filter((team) => teamTags.some((tag) => team.tags.includes(tag)))

  const onTeamChange = ({ value, setValues }) => {
    const newValues = { teamId: value, teamTags: [] }
    setValues(newValues)
    navigate(getReportUrl({ kitSlug, ...newValues, ...remainingQueryParams }))
  }

  const onTeamTagsChange = ({ value, setValues }) => {
    const newValues = { teamTags: value, teamId: ALL_TEAMS }
    setValues(newValues)
    navigate(getReportUrl({ kitSlug, ...newValues, ...remainingQueryParams }))
  }

  return (
    <div className={className}>
      <Formik initialValues={{ teamId: teamIdFormValue, teamTags: teamTagsFormValue }} enableReinitialize>
        {({ setValues }) => (
          <Form>
            <View $alignItems="flex-start" className="mb-medium">
              <b className="kit-report-info-label mt-xs">Team:</b>
              <TaggedItemsTooltip type="team" tags={teamTags} matches={teamsMatchingTags}>
                <TaggedSelectField
                  name="teamId"
                  className="medium"
                  key={teamTags.join(",")} // update control text when tags change
                  width={330}
                  placeholder="Select team"
                  noOptionsMessage={() => "No teams with survey results"}
                  onChange={({ value }) => onTeamChange({ value, setValues })}
                  onTagAdd={(teamId, tag) => addTeamTag({ teamId, tag })}
                  onTagRemove={(teamId, tag) => removeTeamTag({ teamId, tag })}
                  onTagRemoveSuffix={(teamId, tag, suffix) => removeTeamTagSuffix({ teamId, tag, suffix })}
                  onTagClick={(tag) =>
                    onTeamTagsChange({
                      setValues,
                      value: teamTags.includes(tag) ? teamTags.filter((t) => t !== tag) : [...teamTags, tag],
                    })
                  }
                  options={[
                    ...(!showAllTeamsOption
                      ? []
                      : [
                          {
                            value: ALL_TEAMS,
                            label: "All Team Surveys",
                            formattedSelectedLabel: getTagSelectedLabel(teamTags, teamsMatchingTags),
                          },
                        ]),
                    ...(!showTeamOtherOption
                      ? []
                      : [
                          {
                            value: TEAM_OTHER,
                            label: "Other",
                            formattedSelectedLabel: getTagSelectedLabel(teamTags, teamsMatchingTags),
                          },
                        ]),
                    ...sortedTeams.map((team) => ({
                      value: team.id,
                      label: `${team.name} ${team.survey_count ? `(${plural(team.survey_count, "survey")})` : ""}`,
                      tags: team.tags,
                    })),
                  ]}
                />
              </TaggedItemsTooltip>
              {!!allTeamTags.length && (
                <MultiSelectField
                  name="teamTags"
                  className="medium ml-medium"
                  showSelectAll={!hasOnlyOneOrZeroTag}
                  optionFilled
                  selectedFilled
                  width={330}
                  value={teamTagsFormValue}
                  onChange={({ value }) => onTeamTagsChange({ value, setValues })}
                  placeholder="Or select team tags (optional)"
                  options={allTeamTags?.map((tag) => ({
                    value: tag,
                    label: tag,
                  }))}
                  formatOptionLabel={(option) => <TagOptionLabel tag={option.label} />}
                  noOptionsMessage={() => "No other tags"}
                  selectedDescription={(selectedTags) =>
                    getTaggedItemsDescription("team", selectedTags, teamsMatchingTags)
                  }
                />
              )}
            </View>
          </Form>
        )}
      </Formik>
      <HorizontalRule />
      <EngagementSurveyResults
        accountId={accountId}
        selectedTeam={selectedTeam}
        allTeamsSelected={allTeamsSelected}
        teamOtherSelected={teamOtherSelected}
        teamTags={teamTags}
        teamsMatchingTags={teamsMatchingTags}
        includeArchivedTeams={includeArchivedTeams}
        hideIncompleteSurveyeeResponses={hideIncompleteSurveyeeResponses}
      />
    </div>
  )
}

export default EngagementSurveyReport
