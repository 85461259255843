import { Button, PageTitle, colors } from "ui"
import MailToLink from "ui/MailToLink"

const UpdatePayment = ({ payInvoiceUrl }) => {
  if (!payInvoiceUrl) {
    return null
  }

  const onBillingClick = (payInvoiceUrl) => async () => {
    window.open(payInvoiceUrl, "_newtab")
  }

  const refreshPage = () => {
    window.location.reload()
  }

  return (
    <div className="main-container">
      <PageTitle>Update Payment</PageTitle>
      <h2 className="mb-large">Update your billing info</h2>
      <p>
        We were unable to charge the card on file for your account. Please update your billing information to continue
        your Rising Team subscription and have access to your results and reports.
      </p>
      <Button
        color={colors.risingBlue}
        className="link inline-block text-normal my-small"
        onClick={onBillingClick(payInvoiceUrl)}
      >
        View invoice and update payment
      </Button>
      <p>
        After you update your payment,{" "}
        <Button color={colors.risingBlue} className="link inline-block text-normal" onClick={refreshPage}>
          refresh
        </Button>{" "}
        this page. It may take a couple of minutes to register your payment.
      </p>
      <p>
        If you have any questions or issues, please contact us at <MailToLink email="support@risingteam.com" />.
      </p>
    </div>
  )
}

export default UpdatePayment
