import CloseIcon from "./CloseIcon"
import DoubleStarIcon from "./DoubleStarIcon"
import EmailColoredIcon from "./EmailColoredIcon"
import { EnvelopeOpenTextIcon } from "./FontAwesomeIcons"
import * as FONT_AWESOME_ICONS from "./FontAwesomeIcons"
import LogoIcon from "./LogoIcon"
import LogoTextIcon from "./LogoTextIcon"
import PieSliceMultipleIcon from "./PieSliceMultipleIcon"
import PieSliceNoneIcon from "./PieSliceNoneIcon"
import PieSliceOneIcon from "./PieSliceOneIcon"
import ReorderIcon from "./ReorderIcon"
import SingleStarIcon from "./SingleStarIcon"
import SlackIcon from "./SlackIcon"
import StripesBandDesktopIcon from "./StripesBandDesktopIcon"
import StripesBandIcon from "./StripesBandIcon"
import TripleStarIcon from "./TripleStarIcon"

import { getOrError } from "utils/object"

// We only allow usage of certain icons via blocks, because we want to
// gradually move towards a setup where most icons in use are FontAwesome icons.
// Add exceptions here for certain icons that are absolutely necessary:
const blockIcons = {
  ...FONT_AWESOME_ICONS,
  PieSliceMultipleIcon,
  PieSliceNoneIcon,
  PieSliceOneIcon,
}

export {
  blockIcons,
  EnvelopeOpenTextIcon, // This is used by psych-safety user manual exercise; don't remove
  CloseIcon,
  LogoIcon,
  LogoTextIcon,
  PieSliceMultipleIcon,
  PieSliceNoneIcon,
  PieSliceOneIcon,
  ReorderIcon,
  SlackIcon,
  EmailColoredIcon,
  StripesBandIcon,
  StripesBandDesktopIcon,
  SingleStarIcon,
  DoubleStarIcon,
  TripleStarIcon,
}

export default function getIconOrError(name) {
  const icons = {
    CloseIcon,
    LogoIcon,
    PieSliceMultipleIcon,
    PieSliceNoneIcon,
    PieSliceOneIcon,
    ReorderIcon,
    SlackIcon,
    EmailColoredIcon,
    EnvelopeOpenTextIcon,
    StripesBandIcon,
    StripesBandDesktopIcon,
    SingleStarIcon,
    DoubleStarIcon,
    TripleStarIcon,
    ...FONT_AWESOME_ICONS,
  }
  return getOrError(icons, name, "Icon not found")
}
