import { useParams } from "react-router"

import { NotFound } from "components"
import { trackLeaderAction } from "resources/monthly_kit"
import { Loading } from "ui"
import { useEffectAfterFirstRender, useQueryParams } from "ui/hooks"

const ALLOWED_STATUSES = ["done", "snooze", "skip"]

const LeaderActions = () => {
  const { action, status } = useParams()
  const { id, token } = useQueryParams()
  const isAllowedStatus = ALLOWED_STATUSES.includes(status)

  useEffectAfterFirstRender(() => {
    if (isAllowedStatus) {
      trackLeaderAction({ id, token, action, status })
      window.location.replace(`https://risingteam.com/leader-actions/${status}`)
    }
  })

  if (!isAllowedStatus) {
    return <NotFound />
  }

  return <Loading />
}

export default LeaderActions
