import { Form, Formik } from "formik"
import { Navigate, useNavigate } from "react-router"

import { KIT_GROUP_SIZE } from "domains/LeaderKit/utils"
import { ALL_TEAMS, ALL_KITS } from "domains/Reports/constants"
import { getReportUrl, getTaggedItemsDescription, getTagSelectedLabel, TaggedItemsTooltip } from "domains/Reports/utils"
import { AdvancedSelectField, MultiSelectField } from "forms/fields"
import TaggedSelectField, { TagOptionLabel } from "forms/fields/TaggedSelectField"
import { useReportAccountTeams } from "resources/billing"
import { useReportKits } from "resources/monthly_kit"
import {
  useAddTeamTag,
  useRemoveTeamTag,
  useRemoveTeamTagSuffix,
  sortUserTeams,
  useReportTeamKitInstanceBySlug,
} from "resources/teams"
import { Loading, View, HorizontalRule } from "ui"
import { useQueryParams } from "ui/hooks"
import { parseArrayQueryParam } from "utils/string"

const ReportWithTeamAndKitControls = ({
  className,
  ReportComponent,
  user,
  accountId,
  accountTags = [],
  multiAccountIds = [],
  includeArchivedTeams = false,
  kitInstanceQueryEnabled = false,
  hideTeamControls = false,
  hideKitControls = false,
  isTeamSelectionOptional = false,
  isKitSelectionOptional = false,
}) => {
  const {
    teamId: teamIdQueryParam,
    teamTags: teamTagsQueryParam,
    kitSlug: kitSlugQueryParam,
    ...remainingQueryParams
  } = useQueryParams()

  const { mutateAsync: addTeamTag } = useAddTeamTag({ includeArchivedTeams })
  const { mutateAsync: removeTeamTag } = useRemoveTeamTag({ includeArchivedTeams })
  const { mutateAsync: removeTeamTagSuffix } = useRemoveTeamTagSuffix({ includeArchivedTeams })

  const allKitsSelected = kitSlugQueryParam === ALL_KITS
  const allTeamsSelected = teamIdQueryParam === ALL_TEAMS
  const kitSlug = !allKitsSelected ? kitSlugQueryParam : null
  const teamId = !allTeamsSelected ? teamIdQueryParam : null
  const teamTags = parseArrayQueryParam(teamTagsQueryParam)

  const { data: teams, isFetching: isFetchingTeams } = useReportAccountTeams({
    accountId,
    accountTags,
    multiAccountIds,
    includeArchivedTeams,
  })
  const { data: kitInstance, isFetching: isFetchingKitInstance } = useReportTeamKitInstanceBySlug({
    teamId,
    kitSlug,
    includeArchivedTeams,
    enabled: !!kitInstanceQueryEnabled,
  })
  const { data: kitDefinitions, isFetching: isFetchingKitDefinitions } = useReportKits({
    accountId,
    accountTags,
    multiAccountIds,
    teamId,
    teamTags,
    includeArchivedTeams,
  })
  const navigate = useNavigate()

  if ((!teams && isFetchingTeams) || (!kitDefinitions && isFetchingKitDefinitions)) {
    return <Loading />
  }

  if (kitInstanceQueryEnabled && !kitInstance && isFetchingKitInstance) {
    return <Loading />
  }

  const onTeamChange = ({ value }) => {
    navigate(getReportUrl({ teamId: value, kitSlug: kitSlugQueryParam, ...remainingQueryParams }))
  }

  const onTeamTagsChange = ({ value }) => {
    navigate(getReportUrl({ teamTags: value, kitSlug: kitSlugQueryParam, ...remainingQueryParams }))
  }

  const onKitChange = ({ value }) => {
    const kitSlug = value
    navigate(getReportUrl({ teamId: teamIdQueryParam, teamTags, kitSlug, ...remainingQueryParams }))
  }

  const showAllKitsOption = kitDefinitions && kitDefinitions.length > 1
  const showAllTeamsOption = teams && teams.length > 1

  const sortedTeams = sortUserTeams({ user, teams })
  const teamsMatchingTags = sortedTeams.filter((team) => teamTags.some((tag) => team.tags.includes(tag)))

  const parsedTeamId = allTeamsSelected ? null : parseInt(teamId)
  const selectedTeam = parsedTeamId && teams?.find((team) => team.id === parsedTeamId)
  const selectedKit = kitDefinitions?.find((kit) => kit.slug === kitSlug)

  const defaultTeamIdValue = showAllTeamsOption ? ALL_TEAMS : sortedTeams[0]?.id ?? null
  const defaultKitSlugValue = showAllKitsOption ? ALL_KITS : kitDefinitions?.[0]?.slug ?? null

  const teamIdFormValue = selectedTeam?.id ?? defaultTeamIdValue
  const kitSlugFormValue = allKitsSelected ? kitSlugQueryParam : selectedKit?.slug ?? null

  // If neither a single team nor ALL_TEAMS is selected,
  // redirect with a valid teamId param instead of showing blank report:
  if (!allTeamsSelected && !selectedTeam) {
    const firstTeamId = sortedTeams[0]?.id
    if (sortedTeams.length === 1 && teamId !== firstTeamId) {
      // Redirect to set only available team as teamId URL param:
      return <Navigate to={getReportUrl({ teamId: firstTeamId, teamTags, ...remainingQueryParams })} replace />
    } else if (showAllTeamsOption && teamId !== ALL_TEAMS) {
      // Redirect to set ALL_TEAMS as teamId URL param:
      return <Navigate to={getReportUrl({ teamId: ALL_TEAMS, teamTags, ...remainingQueryParams })} replace />
    } else if (teamId) {
      // Redirect to remove invalid teamId URL param:
      return <Navigate to={getReportUrl({ teamTags, ...remainingQueryParams })} replace />
    }
  }

  // Clear kitSlug param from url if it's currently invalid:
  const hasKitSlugParam = !!kitSlugQueryParam
  const hasInvalidAllKitsParam = allKitsSelected && !showAllKitsOption
  const hasInvalidKitSlugParam = kitSlugQueryParam && !allKitsSelected && !selectedKit
  if (hasKitSlugParam && (hasInvalidAllKitsParam || hasInvalidKitSlugParam)) {
    // Redirect to remove invalid kitSlug URL param:
    return <Navigate to={getReportUrl({ teamId, teamTags, ...remainingQueryParams })} replace />
  }

  // If no teamId selected (and no team tags selected), set default teamId url param.
  // If no kitSlug selected, set default kitSlug url param.
  const setDefaultTeamIdParam = !teamIdQueryParam && !teamTags?.length && !!defaultTeamIdValue
  const setDefaultKitSlugParam = !kitSlugQueryParam && !!defaultKitSlugValue
  if (setDefaultTeamIdParam || setDefaultKitSlugParam) {
    // Redirect to update teamId and/or kitSlug params as necessary:
    return (
      <Navigate
        to={getReportUrl({
          ...remainingQueryParams,
          teamTags,
          kitSlug: kitSlugQueryParam || defaultKitSlugValue,
          teamId: teamTags?.length ? teamIdQueryParam : teamIdQueryParam || defaultTeamIdValue,
        })}
        replace
      />
    )
  }

  const allTeamTags = Array.from(new Set((teams ?? []).flatMap((team) => team.tags ?? []))).sort()
  const teamTagsFormValue = teamTags.map((tag) => ({ value: tag, label: tag }))

  return (
    <div className={className}>
      <Formik
        initialValues={{
          teamId: teamIdFormValue,
          teamTags: teamTagsFormValue,
          kitSlug: kitSlugFormValue,
        }}
        enableReinitialize
      >
        <Form>
          {!hideTeamControls && (
            <View $alignItems="flex-start" className="mb-medium">
              <b className="kit-report-info-label mt-xs">Team:</b>
              <TaggedItemsTooltip type="team" tags={teamTags} matches={teamsMatchingTags}>
                <TaggedSelectField
                  name="teamId"
                  className="medium"
                  key={teamTags.join(",")} // update control text when tags change
                  width={330}
                  placeholder="Select team"
                  onChange={onTeamChange}
                  onTagAdd={(teamId, tag) => addTeamTag({ teamId, tag })}
                  onTagRemove={(teamId, tag) => removeTeamTag({ teamId, tag })}
                  onTagRemoveSuffix={(teamId, tag, suffix) => removeTeamTagSuffix({ teamId, tag, suffix })}
                  onTagClick={(tag) =>
                    onTeamTagsChange({
                      value: teamTags.includes(tag) ? teamTags.filter((t) => t !== tag) : [...teamTags, tag],
                    })
                  }
                  options={[
                    ...(showAllTeamsOption
                      ? [
                          {
                            value: ALL_TEAMS,
                            label: "All teams",
                            formattedSelectedLabel: getTagSelectedLabel(teamTags, teamsMatchingTags),
                          },
                        ]
                      : []),
                    ...sortedTeams.map((team) => ({
                      value: team.id,
                      label: team.name,
                      tags: showAllTeamsOption && team.tags,
                    })),
                  ]}
                />
              </TaggedItemsTooltip>
              {!!allTeamTags.length && !!showAllTeamsOption && (
                <MultiSelectField
                  name="teamTags"
                  className="medium ml-medium"
                  showSelectAll
                  optionFilled
                  selectedFilled
                  width={300}
                  value={teamTagsFormValue}
                  onChange={onTeamTagsChange}
                  placeholder="Or select team tags (optional)"
                  options={allTeamTags.map((tag) => ({
                    value: tag,
                    label: tag,
                  }))}
                  formatOptionLabel={(option) => <TagOptionLabel tag={option.label} />}
                  noOptionsMessage={() => "No other tags"}
                  selectedDescription={(selectedTags) =>
                    getTaggedItemsDescription("team", selectedTags, teamsMatchingTags)
                  }
                />
              )}
            </View>
          )}
          {!hideKitControls && !!(teamIdQueryParam || !!teamTags.length) && (
            <View $alignItems="center" className="mb-medium">
              <b className="kit-report-info-label">Kit:</b>
              <AdvancedSelectField
                className="medium"
                name="kitSlug"
                width={330}
                onChange={onKitChange}
                placeholder={!kitDefinitions?.length ? "No completed kits" : "Select kit"}
                isDisabled={!kitDefinitions?.length}
                options={[
                  ...(showAllKitsOption ? [{ value: ALL_KITS, label: "All kits" }] : []),
                  ...(kitDefinitions?.map((kit) => ({
                    value: kit.slug,
                    label: `${kit.title}${kit.group_size === KIT_GROUP_SIZE.JUMBO ? " (Jumbo)" : ""}`,
                  })) ?? []),
                ]}
              />
            </View>
          )}
        </Form>
      </Formik>
      <HorizontalRule />
      {!!(isKitSelectionOptional || kitSlugFormValue) &&
        !!(isTeamSelectionOptional || teamIdFormValue || teamTags.length) && (
          <ReportComponent
            kitInstance={kitInstance ?? null}
            selectedKit={selectedKit}
            kitSlug={kitSlug}
            selectedTeam={selectedTeam}
            teamId={selectedTeam?.id}
            teamTags={teamTags}
            accountId={accountId}
            accountTags={accountTags}
            multiAccountIds={multiAccountIds}
            includeArchivedTeams={includeArchivedTeams}
          />
        )}
    </div>
  )
}

export default ReportWithTeamAndKitControls
