// Note: Keep in sync with LLMType in backend/rtai/constants.py
enum LLMType {
  GPT_4o = "gpt-4o",
  GOOGLE_GEMINI_PRO_1_5 = "gemini-1.5-pro-latest",
  ANTHROPIC_CLAUDE_3_OPUS = "claude-3-opus-20240229",
  LLAMA3_70B = "llama3-70b-8192",
  GPT_4 = "gpt-4",
  GPT_3_5 = "gpt-3.5-turbo",
  GPT_4o_VANILLA = "gpt-4o-vanilla",
  GOOGLE_GEMINI_PRO_1_5_VANILLA = "gemini-1.5-pro-vanilla",
  ANTHROPIC_CLAUDE_3_OPUS_VANILLA = "claude-3-opus-vanilla",
}

// Note: Keep in sync with LLMName in backend/rtai/constants.py
enum LLMName {
  GPT_4o = "GPT-4o",
  GOOGLE_GEMINI_PRO_1_5 = "Gemini Pro 1.5",
  ANTHROPIC_CLAUDE_3_OPUS = "Claude 3 Opus",
  LLAMA3_70B = "LLaMA3 70b",
  GPT_4 = "GPT-4",
  GPT_3_5 = "GPT-3.5",
  GPT_4o_VANILLA = "GPT-4o Vanilla",
  GOOGLE_GEMINI_PRO_1_5_VANILLA = "Gemini Pro 1.5 Vanilla",
  ANTHROPIC_CLAUDE_3_OPUS_VANILLA = "Claude 3 Opus Vanilla",
}

// Note: Keep in sync with VANILLA_LLM_TYPES in backend/rtai/constants.py
const VANILLA_LLM_TYPES = [
  LLMType.GPT_4o_VANILLA,
  LLMType.GOOGLE_GEMINI_PRO_1_5_VANILLA,
  LLMType.ANTHROPIC_CLAUDE_3_OPUS_VANILLA,
]

// Note: Keep in sync with ArtiSender in backend/rtai/constants.py
enum ArtiSender {
  USER = "user",
  BOT = "bot",
}

// Note: Keep in sync with ArtiHistoryOrder in backend/rtai/constants.py
enum ArtiHistoryOrder {
  FREQUENCY = "frequency",
  RECENCY = "recency",
  RATING = "rating",
}

const DEFAULT_ARTI_HISTORY_ORDER = ArtiHistoryOrder.RECENCY

const ARTI_RATING_NA_VALUE = -1

// Note: Keep in sync with NO_ONE_MEMBER in backend/rtai/constants.py
const NO_ONE_MEMBER = {
  id: 0,
  short_name: "N/A",
  full_name: "No one in particular",
  pronouns: ["they", "them", "their", "theirs", "they/them/their"],
}

export {
  LLMType,
  LLMName,
  VANILLA_LLM_TYPES,
  ArtiSender,
  ArtiHistoryOrder,
  DEFAULT_ARTI_HISTORY_ORDER,
  ARTI_RATING_NA_VALUE,
  NO_ONE_MEMBER,
}
