import Routes, { AdminRedirectRoute } from "domains/Authentication/Routes"
import { isCITestEnv, isDevelopmentEnv } from "utils/env"

function isSSORedirectLoginFlowEnabled() {
  if (isDevelopmentEnv() || isCITestEnv()) {
    // Redirect disabled for the time being in dev environment due to issues preventing login.
    return false
  }
  return true
}

export { AdminRedirectRoute, isSSORedirectLoginFlowEnabled }

export default Routes
