import cn from "classnames"
import { ellipsis } from "polished"
import { useState } from "react"
import { styled } from "styled-components"
import { useInterval } from "use-interval"

import { Loading } from "ui"
import { useWindowSize } from "ui/hooks"

const MAX_COLUMNS = 5
const MAX_COLUMNS_TABLET = 2
const MAX_COLUMNS_MOBILE = 1
const defaultColumns = 4
const defaultRows = 4
const defaultLines = 1
const defaultRowGap = "var(--spacing-1)"
const defaultColumnGap = "var(--spacing-4)"

const TextFieldAggregatedMultiUserScroll = styled(function TextFieldAggregatedMultiUserScroll({
  identifiers,
  useAggregatedResultsData,
  refetch_interval,
  columns = defaultColumns,
  rows = defaultRows,
  lines = defaultLines, // eslint-disable-line @typescript-eslint/no-unused-vars -- used in css
  rowGap = defaultRowGap, // eslint-disable-line @typescript-eslint/no-unused-vars -- used in css
  columnGap = defaultColumnGap, // eslint-disable-line @typescript-eslint/no-unused-vars -- used in css
  scrollInterval = 5000,
  loop = false,
  autoScroll = true,
  className,
}) {
  const { data: aggregatedResults, isFetching } = useAggregatedResultsData({
    component: "TextFieldAggregatedMultiUserScroll",
    identifiers,
    refetch_interval,
  })

  const { isMobileLandscapeOrSmaller, isTabletOrSmaller } = useWindowSize()
  const maxDisplayColumns = isMobileLandscapeOrSmaller
    ? MAX_COLUMNS_MOBILE
    : isTabletOrSmaller
    ? MAX_COLUMNS_TABLET
    : MAX_COLUMNS
  const numDisplayColumns = Math.min(columns, maxDisplayColumns)

  const [idx, setIdx] = useState(0)
  const maxDisplayCount = numDisplayColumns * rows

  const { answer_list: answerList = [] } = aggregatedResults ?? {}
  const answerListShifted = [...answerList.slice(idx), ...answerList.slice(0, idx)]
  const answerListDisplay = answerListShifted.slice(0, maxDisplayCount).reverse()

  const enoughAnswers = answerList.length >= maxDisplayCount
  const endOfList = idx + maxDisplayCount >= answerList.length
  const shouldScroll = enoughAnswers && autoScroll && (loop || !endOfList)

  useInterval(
    () => {
      if (idx >= answerList.length - 1) {
        setIdx(0)
      } else {
        setIdx(idx + 1)
      }
    },
    shouldScroll ? scrollInterval : null
  )

  if (!aggregatedResults && isFetching) {
    return <Loading />
  }

  if (!aggregatedResults) {
    return null
  }

  return (
    <div className={cn(className, "text-gray-1 bg-gray-9 px-large py-medium border-radius-medium")}>
      {answerListDisplay.map((answer, idx) => (
        <p key={`${answer}_${idx}`} className="answer-item text-medium text-semi-bold">
          {answer}
        </p>
      ))}
    </div>
  )
})`
  display: grid;
  grid-template-columns: repeat(${({ columns = defaultColumns }) => Math.min(columns, MAX_COLUMNS)}, 1fr);
  grid-template-rows: repeat(${({ rows = defaultRows }) => rows}, 1fr);
  grid-auto-flow: column;
  gap: ${({ rowGap = defaultRowGap }) => rowGap} ${({ columnGap = defaultColumnGap }) => columnGap};

  @media (max-width: ${({ theme }) => theme.tabletMax}) {
    grid-template-columns: repeat(${({ columns = defaultColumns }) => Math.min(columns, MAX_COLUMNS_TABLET)}, 1fr);
  }

  @media (max-width: ${({ theme }) => theme.mobileLandscapeMax}) {
    grid-template-columns: repeat(${({ columns = defaultColumns }) => Math.min(columns, MAX_COLUMNS_MOBILE)}, 1fr);
  }

  .answer-item {
    margin: auto 0;
    ${({ lines = defaultLines }) => ellipsis(null, lines)}
  }
`

export default TextFieldAggregatedMultiUserScroll
