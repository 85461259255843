import cn from "classnames"
import { useRef } from "react"
import { styled } from "styled-components"

import { StyledInput } from "forms/fields/InputField"
import { MagnifyingGlassIcon, XmarkIcon } from "icons/FontAwesomeIcons"
import { View } from "ui"

const SearchInput = ({ className, query, setQuery, placeholder, ...props }) => {
  const inputRef = useRef()
  const Icon = query?.length ? XmarkIcon : MagnifyingGlassIcon
  return (
    <View className={className} $alignItems="center" {...props}>
      <StyledInput
        ref={inputRef}
        name="search"
        placeholder={placeholder}
        onInput={(ev) =>
          setQuery(
            ev.target.value
              .toLowerCase()
              .split(/\s+/)
              .filter((term) => term.length)
          )
        }
      />
      <Icon
        className={cn("text-gray-5", { "fa-xl": query?.length })}
        onClick={() => {
          setQuery([])
          inputRef.current.value = null
          inputRef.current.focus()
        }}
      />
    </View>
  )
}

export default styled(SearchInput)`
  width: auto;
  margin-right: 11px;

  svg {
    width: 22px;
    margin-left: -32px;
    cursor: pointer;
    transition: color 0.1s ease;

    &:hover {
      color: var(--gray-7);
    }
  }
`
