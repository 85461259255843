import { useMutation, useQuery, useQueryClient, type QueryClient, type MutationFunction } from "@tanstack/react-query"
import qs from "qs"
import type {
  AccountType,
  KitGroupSize,
  UserData,
  KitInstanceData,
  ReflectionData,
  SurveyData,
  SSOProviderData,
} from "types"

import api from "api"
import { KIT_GROUP_SIZE, KIT_LINK_KEYS, KIT_TYPE } from "domains/LeaderKit/utils"
import { addShortNameFieldToUsers } from "resources/users"
import { isProductionEnv, isStagingEnv } from "utils/env"
import { checkNamedArguments } from "utils/function"
import { fetcher, getRealtimeCacheKey, useRealtimeQuery } from "utils/query"
import { buildUrl } from "utils/string"

function getAppCodeURL({ short = true, protocol = true } = {}): string {
  checkNamedArguments("getAppCodeURL", arguments, { optional: { short, protocol } })
  const protocolStr = protocol ? `${window.location.protocol}//` : ""
  if (isProductionEnv()) {
    return short ? `${protocolStr}rt.codes` : `${protocolStr}app.risingteam.com/code`
  } else if (isStagingEnv()) {
    return short ? `${protocolStr}staging.rt.codes` : `${protocolStr}staging-kit.risingteam.com/code`
  } else {
    return buildUrl([protocolStr, window.location.host, "code"], {
      useTrailingSlash: false,
      useLeadingSlash: false,
    })
  }
}

function getResultsUrl({
  slug,
  teamId = null,
  resultsPageSlug = null,
}: {
  slug: KitSlug
  teamId?: TeamID | null
  resultsPageSlug?: string | null
}): string {
  checkNamedArguments("getResultsUrl", arguments, { required: { slug }, optional: { teamId, resultsPageSlug } })
  return `/team/results/kit/${slug}/page/${resultsPageSlug ?? ""}?team_id=${teamId ?? ""}`
}

function getStandaloneExerciseUrl({
  slug,
  teamId,
  onFinishUrl = null,
  stepNumber = null,
}: {
  slug: KitSlug
  teamId: TeamID
  onFinishUrl?: string | null
  stepNumber?: number | null
}): string {
  checkNamedArguments("getStandaloneExerciseUrl", arguments, {
    required: { slug, teamId },
    optional: { stepNumber, onFinishUrl },
  })
  const queryParams = { team_id: teamId, ...(onFinishUrl ? { onFinishUrl } : {}) }
  const path = stepNumber ?? ""
  return `/kit/${slug}/exercise/latest/${path}?${qs.stringify(queryParams)}`
}

function augmentKitData(data: KitInstanceData): KitInstanceData {
  return {
    ...data,
    [KIT_LINK_KEYS.SESSION_URL]: `/session/${data.slug}/${data.id}/`,
    [KIT_LINK_KEYS.SESSION_PREVIEW_URL]: `/session/${data.slug}/${data.id}/?preview=true`,
    [KIT_LINK_KEYS.SESSION_START_URL]: `/startsession/${data.id}/`,
    [KIT_LINK_KEYS.HOME_URL]:
      data?.kit?.type === KIT_TYPE.MINI ? `/minis?team_id=${data.team_id}` : `/kit/${data.slug}/${data.id}/`,
    [KIT_LINK_KEYS.PREP_URL]: `/kit/${data.slug}/${data.id}/prep/`,
    [KIT_LINK_KEYS.REVIEW_URL]: `/kit/${data.slug}/${data.id}/review/`,
    [KIT_LINK_KEYS.RESULTS_URL]: getResultsUrl({ slug: data.slug, teamId: data.team_id }),
    [KIT_LINK_KEYS.BONUS_URL]: `/bonus/kit/${data.slug}/${data.id}/bonus/`,
    [KIT_LINK_KEYS.TEAM_HOME_URL]: `/team?team_id=${data.team_id}`,
    // NOTE: Don't add functions here. For some reason it makes our e2e tests flaky.
  }
}

function getKitInstance({ kitInstanceId }: { kitInstanceId: KitInstanceID }) {
  checkNamedArguments("getKitInstance", arguments, { required: { kitInstanceId } })
  return async (): Promise<KitInstanceData> => {
    const { data } = await api.get(`/monthly_kit/kit_instances/${kitInstanceId}/`)
    return augmentKitData(data)
  }
}

function useKitInstance({ kitInstanceId }: { kitInstanceId: KitInstanceID }) {
  checkNamedArguments("useKitInstance", arguments, { required: { kitInstanceId } })
  return useQuery(getKitInstanceCacheKey({ kitInstanceId }), getKitInstance({ kitInstanceId }), {
    enabled: !!kitInstanceId,
  })
}

function getOrCreateKitInstance({
  slug,
  teamId,
  addPromoKit = null,
}: {
  slug: KitSlug
  teamId: TeamID
  addPromoKit?: string | null
}) {
  checkNamedArguments("getOrCreateKitInstance", arguments, { required: { slug, teamId }, optional: { addPromoKit } })
  return async (): Promise<KitInstanceData> => {
    const { data } = await api.post(buildUrl(["visible_teams", teamId, "get_or_create_kit_instance"]), {
      slug,
      ...(addPromoKit ? { add_promo_kit: addPromoKit } : {}),
    })
    return augmentKitData(data)
  }
}

function useGetOrCreateKitInstance({
  slug,
  teamId,
  enabled = true,
  onError = null,
  addPromoKit = null,
}: {
  slug: KitSlug
  teamId: TeamID
  enabled?: boolean
  onError?: ((err: unknown) => void) | null
  addPromoKit?: string | null
}) {
  checkNamedArguments("useGetOrCreateKitInstance", arguments, {
    required: { slug, teamId },
    optional: { enabled, onError, addPromoKit },
  })
  const queryClient = useQueryClient()
  return useQuery(
    ["monthly_kit", "kit_instances", "get_or_create", slug, teamId],
    getOrCreateKitInstance({ slug, teamId, addPromoKit }),
    {
      enabled: !!enabled && !!teamId,
      onSuccess: (data: KitInstanceData) => updateKitInstanceQueryCache({ data, queryClient }),
      ...(onError ? { onError } : {}),
    }
  )
}

function useUpdateOrCreateKitInstance({ slug, teamId }: { slug: KitSlug; teamId: TeamID }) {
  checkNamedArguments("useUpdateOrCreateKitInstance", arguments, { required: { slug, teamId } })
  const queryClient = useQueryClient()
  return useMutation(getOrCreateKitInstance({ slug, teamId }), {
    onSuccess: (data: KitInstanceData): KitInstanceData => {
      updateKitInstanceQueryCache({ data, queryClient })
      return data
    },
  })
}

function getTeamKitInstances({ teamId }: { teamId: TeamID }) {
  checkNamedArguments("getTeamKitInstances", arguments, { required: { teamId } })
  return async (): Promise<KitInstanceData[]> => {
    const { data: kitInstances } = await api.get(`/visible_teams/${teamId}/kit_instances/`)
    return kitInstances.map((instance: KitInstanceData) => augmentKitData(instance))
  }
}

function useTeamKitInstances({ teamId }: { teamId: TeamID }) {
  checkNamedArguments("useTeamKitInstances", arguments, { required: { teamId } })
  return useQuery(getKitInstancesCacheKey({ teamId }), getTeamKitInstances({ teamId }), {
    enabled: !!teamId,
  })
}

async function startKitSession({ kitInstanceId }: { kitInstanceId: KitInstanceID }): Promise<KitInstanceData> {
  checkNamedArguments("startKitSession", arguments, { required: { kitInstanceId } })
  const { data } = await api.post(`/monthly_kit/kit_instances/${kitInstanceId}/start_session/`)
  return augmentKitData(data)
}

function useStartKitSession() {
  const queryClient = useQueryClient()
  return useMutation(startKitSession, {
    onSuccess: (data: KitInstanceData) => updateKitInstanceQueryCache({ data, queryClient }),
  })
}

async function regenerateKitSessionCode({ kitInstanceId }: { kitInstanceId: KitInstanceID }): Promise<KitInstanceData> {
  checkNamedArguments("regenerateKitSessionCode", arguments, { required: { kitInstanceId } })
  const { data } = await api.post(`/monthly_kit/kit_instances/${kitInstanceId}/regenerate_code/`)
  return augmentKitData(data)
}

function useRegenerateKitSessionCode() {
  const queryClient = useQueryClient()
  return useMutation(regenerateKitSessionCode, {
    onSuccess: (data: KitInstanceData) => updateKitInstanceQueryCache({ data, queryClient }),
  })
}

async function restartKitSessionTestOnly({
  kitInstanceId,
}: {
  kitInstanceId: KitInstanceID
}): Promise<KitInstanceData> {
  checkNamedArguments("restartKitSessionTestOnly", arguments, { required: { kitInstanceId } })
  const { data } = await api.post(`/monthly_kit/kit_instances/${kitInstanceId}/restart_session_testonly/`)
  return augmentKitData(data)
}

function useRestartKitSessionTestOnly() {
  const queryClient = useQueryClient()
  return useMutation(restartKitSessionTestOnly, {
    onSuccess: (data: KitInstanceData) => updateKitInstanceQueryCache({ data, queryClient }),
  })
}

async function completeKitSession({ kitInstanceId }: { kitInstanceId: KitInstanceID }): Promise<KitInstanceData> {
  checkNamedArguments("completeKitSession", arguments, { required: { kitInstanceId } })
  const { data } = await api.post(`/monthly_kit/kit_instances/${kitInstanceId}/complete_session/`)
  return augmentKitData(data)
}

function useCompleteKitSession() {
  return useMutation(completeKitSession)
}

function getSessionPicture({ kitInstanceId }: { kitInstanceId: KitInstanceID }) {
  checkNamedArguments("getSessionPicture", arguments, { required: { kitInstanceId } })
  return async (): Promise<string> => {
    const { data } = await api.get(`/monthly_kit/kit_instances/${kitInstanceId}/get_session_picture/`)
    return data
  }
}

function useSessionPicture({
  kitInstanceId,
  refetchInterval = false,
  sessionRealtimeUpdates = false,
}: {
  kitInstanceId: KitInstanceID
  refetchInterval?: number | false
  sessionRealtimeUpdates?: boolean
}) {
  checkNamedArguments("useSessionPicture", arguments, {
    required: { kitInstanceId },
    optional: { sessionRealtimeUpdates, refetchInterval },
  })
  const sessionPictureWithPolling = useQuery(
    getSessionPictureCacheKey({ kitInstanceId }),
    getSessionPicture({ kitInstanceId }),
    {
      enabled: !!kitInstanceId && !sessionRealtimeUpdates,
      refetchInterval,
      refetchIntervalInBackground: true,
    }
  )
  const sessionPictureWithoutPolling = useRealtimeQuery({
    realtimeCacheKey: getRealtimeCacheKey({ kitInstanceId, key: "session_picture" }),
    queryCacheKey: getSessionPictureCacheKey({ kitInstanceId }),
    queryFn: getSessionPicture({ kitInstanceId }),
    enabled: !!kitInstanceId && !!sessionRealtimeUpdates,
  })
  if (sessionRealtimeUpdates) {
    return sessionPictureWithoutPolling
  }
  return sessionPictureWithPolling
}

function getKitParticipants({
  kitInstanceId,
  forceFullUserNames = false,
}: {
  kitInstanceId: KitInstanceID
  forceFullUserNames?: boolean
}) {
  checkNamedArguments("getKitParticipants", arguments, {
    required: { kitInstanceId },
    optional: { forceFullUserNames },
  })
  return async (): Promise<UserData[]> => {
    const { data } = await api.get(`/monthly_kit/kit_instances/${kitInstanceId}/get_participants/`)
    return addShortNameFieldToUsers({ users: data, forceFullUserNames })
  }
}

function useKitParticipants({
  kitInstance,
  refetchInterval = false,
  sessionRealtimeUpdates = false,
  onSuccess = null,
  enabled = true,
}: {
  kitInstance: KitInstanceData
  refetchInterval?: number | false
  sessionRealtimeUpdates?: boolean
  onSuccess?: ((data: unknown) => void) | null
  enabled?: boolean
}) {
  checkNamedArguments("useKitParticipants", arguments, {
    required: { kitInstance },
    optional: { sessionRealtimeUpdates, refetchInterval, onSuccess, enabled },
  })
  const kitInstanceId = kitInstance?.id
  const forceFullUserNames = kitInstance?.kit?.group_size === KIT_GROUP_SIZE.JUMBO
  const kitParticipantsWithPolling = useQuery(
    getKitParticipantsCacheKey({ kitInstanceId }),
    getKitParticipants({ kitInstanceId, forceFullUserNames }),
    {
      enabled: !!kitInstanceId && !sessionRealtimeUpdates,
      refetchInterval,
      refetchIntervalInBackground: true,
      ...(onSuccess ? { onSuccess } : {}),
    }
  )
  const kitPartipantsWithoutPolling = useRealtimeQuery({
    realtimeCacheKey: getRealtimeCacheKey({ kitInstanceId, key: "session_participants" }),
    queryCacheKey: getKitParticipantsCacheKey({ kitInstanceId }),
    queryFn: getKitParticipants({ kitInstanceId, forceFullUserNames }),
    enabled: !!kitInstanceId && !!sessionRealtimeUpdates,
    onSuccess,
  })
  if (!!sessionRealtimeUpdates) {
    return kitPartipantsWithoutPolling
  }
  return kitParticipantsWithPolling
}

function getKitParticipantCount({ kitInstanceId }: { kitInstanceId: KitInstanceID }) {
  checkNamedArguments("getKitParticipantCount", arguments, { required: { kitInstanceId } })
  return async (): Promise<number> => {
    const { data } = await api.get(`/monthly_kit/kit_instances/${kitInstanceId}/get_participant_count/`)
    return data
  }
}

function useKitParticipantCount({
  kitInstance,
  refetchInterval = false,
  sessionRealtimeUpdates = false,
  onSuccess = null,
}: {
  kitInstance: KitInstanceData
  refetchInterval?: number | false
  sessionRealtimeUpdates?: boolean
  onSuccess?: ((data: unknown) => void) | null
}) {
  checkNamedArguments("useKitParticipantCount", arguments, {
    required: { kitInstance },
    optional: { sessionRealtimeUpdates, refetchInterval, onSuccess },
  })
  const kitInstanceId = kitInstance?.id
  const cacheKey = ["monthly_kit", "kit_instance", kitInstanceId, "kit_participant_count"]
  const kitParticipantsWithPolling = useQuery(cacheKey, getKitParticipantCount({ kitInstanceId }), {
    enabled: !!kitInstanceId && !sessionRealtimeUpdates,
    refetchInterval,
    ...(onSuccess ? { onSuccess } : {}),
  })
  const kitPartipantsWithoutPolling = useRealtimeQuery({
    realtimeCacheKey: getRealtimeCacheKey({ kitInstanceId, key: "session_participants" }),
    queryCacheKey: cacheKey,
    queryFn: getKitParticipantCount({ kitInstanceId }),
    enabled: !!kitInstanceId,
    onSuccess,
  })
  if (!!sessionRealtimeUpdates) {
    return kitPartipantsWithoutPolling
  }
  return kitParticipantsWithPolling
}

function updateKitInstance({ kitInstanceId }: { kitInstanceId: KitInstanceID }) {
  checkNamedArguments("updateKitInstance", arguments, { required: { kitInstanceId } })
  return async (formData: FormData, options: Parameters<typeof api.patch>[2]): Promise<KitInstanceData> => {
    const { data } = await api.patch(`/monthly_kit/kit_instances/${kitInstanceId}/`, formData, options)
    return augmentKitData(data)
  }
}

function updateSessionPicture({ kitInstanceId }: { kitInstanceId: KitInstanceID }) {
  checkNamedArguments("updateSessionPicture", arguments, { required: { kitInstanceId } })
  return async (file: string | Blob): Promise<KitInstanceData> => {
    const formData = new FormData()
    formData.append("session_picture", file)
    const headers = {
      "Content-Type": "multipart/form-data",
    }
    return await updateKitInstance({ kitInstanceId })(formData, { headers })
  }
}

function useUpdateSessionPicture({ kitInstanceId }: { kitInstanceId: KitInstanceID }) {
  checkNamedArguments("useUpdateSessionPicture", arguments, { required: { kitInstanceId } })
  const queryClient = useQueryClient()
  return useMutation(updateSessionPicture({ kitInstanceId }), {
    onSuccess: () => queryClient.refetchQueries(getSessionPictureCacheKey({ kitInstanceId })),
  })
}

type UseMutationFn = Parameters<typeof useMutation>[0]

function updateOrCreateKitInstanceBySlug({ slug, teamId }: { slug: KitSlug; teamId: TeamID }) {
  checkNamedArguments("updateOrCreateKitInstanceBySlug", arguments, { required: { slug, teamId } })
  return (async (formData: FormData, options: Parameters<typeof api.patch>[2]): Promise<KitInstanceData> => {
    const { data } = await api.patch(
      `/manage_team/${teamId}/update_or_create_kit_instance_by_slug/`,
      { ...formData, slug },
      options
    )
    return augmentKitData(data)
  }) as unknown as UseMutationFn
}

function useUpdateOrCreateKitInstanceBySlug({ slug, teamId }: { slug: KitSlug; teamId: TeamID }) {
  checkNamedArguments("useUpdateOrCreateKitInstanceBySlug", arguments, { required: { slug, teamId } })
  const queryClient = useQueryClient()
  return useMutation(updateOrCreateKitInstanceBySlug({ teamId, slug }), {
    onSuccess: (data: KitInstanceData) => updateKitInstanceQueryCache({ data, queryClient }),
  })
}

async function joinKitInstanceById({ kitInstanceId }: { kitInstanceId: KitInstanceID }): Promise<KitInstanceData> {
  checkNamedArguments("joinKitInstanceById", arguments, { required: { kitInstanceId } })
  const { data } = await api.post(`/monthly_kit/kit_instances/${kitInstanceId}/join_session_by_id/`)
  return augmentKitData(data)
}

function useJoinKitInstanceById() {
  const queryClient = useQueryClient()
  return useMutation(joinKitInstanceById, {
    onSuccess: (data: KitInstanceData) => updateKitInstanceQueryCache({ data, queryClient }),
  })
}

async function validateSessionCode({ code, email }: { code: string; email: string }): Promise<{
  user_exists: boolean
  is_demo: boolean
  sso_providers: SSOProviderData[]
  account_type: AccountType
  kit_group_size: KitGroupSize
}> {
  checkNamedArguments("validateSessionCode", arguments, { required: { code, email } })
  const formData = { session_code: code, email }
  const { data } = await api.post("/monthly_kit/kit_instances/validate_session_code/", formData)
  return data
}

async function joinKitInstanceByCode({ code }: { code: string }): Promise<KitInstanceData> {
  checkNamedArguments("joinKitInstanceByCode", arguments, { required: { code } })
  const { data } = await api.post(`/monthly_kit/kit_instances/join_session/?session_code=${code}`)
  return augmentKitData(data)
}

function useJoinKitInstanceByCode() {
  const queryClient = useQueryClient()
  return useMutation(joinKitInstanceByCode, {
    onSuccess: (data: KitInstanceData) => updateKitInstanceQueryCache({ data, queryClient }),
  })
}

function getOrCreateReflection({ kitInstanceId }: { kitInstanceId: KitInstanceID }) {
  checkNamedArguments("getOrCreateReflection", arguments, { required: { kitInstanceId } })
  return async (): Promise<ReflectionData> => {
    const { data } = await api.post(`/monthly_kit/kit_instances/${kitInstanceId}/get_or_create_session_reflection/`)
    return data
  }
}
function useReflection({ kitInstanceId }: { kitInstanceId: KitInstanceID }) {
  checkNamedArguments("useReflection", arguments, { required: { kitInstanceId } })
  return useQuery(getReflectionCacheKey({ kitInstanceId }), getOrCreateReflection({ kitInstanceId }), {
    enabled: !!kitInstanceId,
  })
}

function updateReflection({ reflectionId }: { reflectionId: ReflectionID }): MutationFunction<ReflectionData> {
  checkNamedArguments("updateReflection", arguments, { required: { reflectionId } })
  return (async (formData: FormData, options: Parameters<typeof api.patch>[2]) => {
    const { data } = await api.patch(`/monthly_kit/session_reflections/${reflectionId}/`, formData, options)
    return data
  }) as MutationFunction<ReflectionData>
}

function useUpdateReflection({ reflectionId }: { reflectionId: ReflectionID }) {
  checkNamedArguments("useUpdateReflection", arguments, { required: { reflectionId } })
  const queryClient = useQueryClient()
  return useMutation(updateReflection({ reflectionId }), {
    onSuccess: (data: ReflectionData) => {
      queryClient.setQueryData(getReflectionCacheKey({ kitInstanceId: data.kit_instance_id }), data)
    },
  })
}

function getTeamReflections({ kitInstanceId }: { kitInstanceId: KitInstanceID }) {
  checkNamedArguments("getTeamReflections", arguments, { required: { kitInstanceId } })
  return async (): Promise<ReflectionData[]> => {
    const { data } = await api.get(`/monthly_kit/kit_instances/${kitInstanceId}/get_team_session_reflections/`)
    return data
  }
}
function useTeamReflections({
  kitInstanceId,
  refetchInterval = false,
  sessionRealtimeUpdates = false,
}: {
  kitInstanceId: KitInstanceID
  refetchInterval?: number | false
  sessionRealtimeUpdates?: boolean
}) {
  checkNamedArguments("useTeamReflections", arguments, {
    required: { kitInstanceId },
    optional: { refetchInterval, sessionRealtimeUpdates },
  })
  const teamReflectionsWithPolling = useQuery(
    getTeamReflectionsCacheKey({ kitInstanceId }),
    getTeamReflections({ kitInstanceId }),
    {
      enabled: !!kitInstanceId && !sessionRealtimeUpdates,
      refetchInterval,
      refetchIntervalInBackground: true,
    }
  )
  const teamReflectionsWithoutPolling = useRealtimeQuery({
    realtimeCacheKey: getRealtimeCacheKey({ kitInstanceId, key: "session_reflections" }),
    queryCacheKey: getTeamReflectionsCacheKey({ kitInstanceId }),
    queryFn: getTeamReflections({ kitInstanceId }),
    enabled: !!kitInstanceId && !!sessionRealtimeUpdates,
  })
  if (sessionRealtimeUpdates) {
    return teamReflectionsWithoutPolling
  }
  return teamReflectionsWithPolling
}

function getKitStepCompletedUsers({ kitInstanceId, stepPath }: { kitInstanceId: KitInstanceID; stepPath: string }) {
  checkNamedArguments("getKitStepCompletedUsers", arguments, { required: { kitInstanceId, stepPath } })
  return async (): Promise<UserData[]> => {
    const { data } = await api.get(
      `/monthly_kit/kit_instances/${kitInstanceId}/get_step_completed_users/?step=${stepPath}`
    )
    return data
  }
}

function useKitStepCompletedUsers({
  kitInstanceId,
  stepPath,
  refetchInterval = 3000,
  refetchIntervalInBackground = true,
  sessionRealtimeUpdates = false,
}: {
  kitInstanceId: KitInstanceID
  stepPath: string
  refetchInterval?: number | false
  refetchIntervalInBackground?: boolean
  sessionRealtimeUpdates?: boolean
}) {
  checkNamedArguments("useKitStepCompletedUsers", arguments, {
    required: { kitInstanceId, stepPath },
    optional: { sessionRealtimeUpdates, refetchInterval, refetchIntervalInBackground },
  })
  const realtimeCacheKey = "step_completed_users/" + stepPath
  const kitStepCompletedUsersWithPolling = useQuery(
    getKitStepCompletedUsersCacheKey({ kitInstanceId, stepPath }),
    getKitStepCompletedUsers({ kitInstanceId, stepPath }),
    {
      enabled: !!(kitInstanceId && stepPath && !sessionRealtimeUpdates),
      refetchInterval,
      refetchIntervalInBackground,
    }
  )
  const kitStepCompletedUsersWithoutPolling = useRealtimeQuery({
    realtimeCacheKey: getRealtimeCacheKey({ kitInstanceId, key: realtimeCacheKey }),
    queryCacheKey: getKitStepCompletedUsersCacheKey({ kitInstanceId, stepPath }),
    queryFn: getKitStepCompletedUsers({ kitInstanceId, stepPath }),
    enabled: !!kitInstanceId && !!stepPath && sessionRealtimeUpdates,
  })

  if (!!sessionRealtimeUpdates) {
    return kitStepCompletedUsersWithoutPolling
  }
  return kitStepCompletedUsersWithPolling
}

function updateKitStepCompletedUsers({ kitInstanceId, stepPath }: { kitInstanceId: KitInstanceID; stepPath: string }) {
  checkNamedArguments("updateKitStepCompletedUsers", arguments, { required: { kitInstanceId, stepPath } })
  return async () => {
    const { data } = await api.post(
      `/monthly_kit/kit_instances/${kitInstanceId}/set_step_completed_users/?step=${stepPath}`
    )
    return data
  }
}

function useUpdateKitStepCompletedUsers({
  kitInstanceId,
  stepPath,
}: {
  kitInstanceId: KitInstanceID
  stepPath: string
}) {
  checkNamedArguments("useUpdateKitStepCompletedUsers", arguments, { required: { kitInstanceId, stepPath } })
  return useMutation(updateKitStepCompletedUsers({ kitInstanceId, stepPath }))
}

function getOrCreatePostSessionSurvey({ kitInstanceId }: { kitInstanceId: KitInstanceID }) {
  checkNamedArguments("getOrCreatePostSessionSurvey", arguments, { required: { kitInstanceId } })
  return async (): Promise<SurveyData> => {
    const { data } = await api.post(`/monthly_kit/kit_instances/${kitInstanceId}/get_or_create_post_session_survey/`)
    return data
  }
}

function usePostSessionSurvey({ kitInstanceId }: { kitInstanceId: KitInstanceID }) {
  checkNamedArguments("usePostSessionSurvey", arguments, { required: { kitInstanceId } })
  return useQuery(getPostSessionSurveyCacheKey({ kitInstanceId }), getOrCreatePostSessionSurvey({ kitInstanceId }), {
    enabled: !!kitInstanceId,
  })
}

function updatePostSessionSurvey({ surveyId }: { surveyId: SurveyID }): MutationFunction<SurveyData> {
  checkNamedArguments("updatePostSessionSurvey", arguments, { required: { surveyId } })
  return (async (formData: FormData, options: Parameters<typeof api.patch>[2]): Promise<SurveyData> => {
    const { data } = await api.patch(`/monthly_kit/post_session_surveys/${surveyId}/`, formData, options)
    return data
  }) as MutationFunction<SurveyData>
}

function useUpdatePostSessionSurvey({ surveyId }: { surveyId: SurveyID }) {
  checkNamedArguments("useUpdatePostSessionSurvey", arguments, { required: { surveyId } })
  const queryClient = useQueryClient()
  return useMutation(updatePostSessionSurvey({ surveyId }), {
    onSuccess: (data: SurveyData) => {
      queryClient.setQueryData(getPostSessionSurveyCacheKey({ kitInstanceId: data.kit_instance_id }), data)
    },
  })
}

function getRaisedHands({
  kitInstanceId,
  key,
  forceFullUserNames = false,
}: {
  kitInstanceId: KitInstanceID
  key: string
  forceFullUserNames?: boolean
}) {
  checkNamedArguments("getRaisedHands", arguments, {
    required: { kitInstanceId, key },
    optional: { forceFullUserNames },
  })
  return async (): Promise<UserData[]> => {
    const { data } = await api.get(`/monthly_kit/kit_instances/${kitInstanceId}/raised_hands/`, { params: { key } })
    return addShortNameFieldToUsers({ users: data, forceFullUserNames })
  }
}

function useRaisedHands({
  kitInstance,
  key,
  sessionRealtimeUpdates = false,
  refetchInterval = null,
}: {
  kitInstance: KitInstanceData
  key: string
  sessionRealtimeUpdates: boolean
  refetchInterval?: number | null
}) {
  checkNamedArguments("useRaisedHands", arguments, {
    required: { kitInstance, key },
    optional: { sessionRealtimeUpdates, refetchInterval },
  })
  const kitInstanceId = kitInstance?.id
  const forceFullUserNames = kitInstance?.kit?.group_size === KIT_GROUP_SIZE.JUMBO
  const realtimeCacheKey = getRaisedHandsRealtimeCacheKey({ key })
  const raisedHandsWithPolling = useQuery(
    getRaisedHandsCacheKey({ kitInstanceId, key }),
    getRaisedHands({ kitInstanceId, key, forceFullUserNames }),
    {
      enabled: !!(kitInstanceId && !!key && !sessionRealtimeUpdates),
      ...(refetchInterval ? { refetchInterval } : {}),
    }
  )

  const raisedHandsWithoutPolling = useRealtimeQuery({
    realtimeCacheKey: getRealtimeCacheKey({ kitInstanceId, key: realtimeCacheKey }),
    queryCacheKey: getRaisedHandsCacheKey({ kitInstanceId, key }),
    queryFn: getRaisedHands({ kitInstanceId, key, forceFullUserNames }),
    enabled: !!kitInstanceId && !!realtimeCacheKey && !!sessionRealtimeUpdates,
  })
  if (!!sessionRealtimeUpdates) {
    return raisedHandsWithoutPolling
  }
  return raisedHandsWithPolling
}

function updateRaisedHand({ kitInstanceId, key }: { kitInstanceId: KitInstanceID; key: string }) {
  checkNamedArguments("updateRaisedHand", arguments, { required: { kitInstanceId, key } })
  return async (raised: boolean): Promise<UserData[]> => {
    const { data } = await api.post(`/monthly_kit/kit_instances/${kitInstanceId}/set_raised_hand`, { key, raised })
    return data
  }
}

function useUpdateRaisedHand({ kitInstance, key }: { kitInstance: KitInstanceData; key: string }) {
  checkNamedArguments("useUpdateRaisedHand", arguments, { required: { kitInstance, key } })
  const queryClient = useQueryClient()
  const kitInstanceId = kitInstance?.id
  return useMutation(updateRaisedHand({ kitInstanceId, key }), {
    onSuccess: (data: UserData[]) => {
      queryClient.setQueryData(getRaisedHandsCacheKey({ kitInstanceId, key }), data)
    },
  })
}

function getRaisedHandsCacheKey({
  kitInstanceId,
  key,
}: {
  kitInstanceId: KitInstanceID
  key: string
}): Array<string | number> {
  checkNamedArguments("getRaisedHandsCacheKey", arguments, { required: { kitInstanceId, key } })
  return ["monthly_kit", "kit_instance", kitInstanceId, "raised_hands", key]
}

function getRaisedHandsRealtimeCacheKey({ key }: { key: string }) {
  return `raised_hands/${key}`
}

function getSelectedShareoutUser({
  kitInstanceId,
  key,
  forceFullUserNames = false,
}: {
  kitInstanceId: KitInstanceID
  key: string
  forceFullUserNames?: boolean
}) {
  checkNamedArguments("getSelectedShareoutUser", arguments, {
    required: { kitInstanceId, key },
    optional: { forceFullUserNames },
  })
  return async (): Promise<UserData | null> => {
    const { data } = await api.get(`/monthly_kit/kit_instances/${kitInstanceId}/get_current_shareout_user/`, {
      params: { key },
    })
    if (!data) {
      return null
    } else {
      const [user] = addShortNameFieldToUsers({ users: [data], forceFullUserNames })
      return user ?? null
    }
  }
}

function useRealtimeSelectedShareoutUser({
  kitInstanceId,
  key,
  forceFullUserNames = false,
  enabled = false,
}: {
  kitInstanceId: KitInstanceID
  key: string
  forceFullUserNames?: boolean
  enabled?: boolean
}) {
  checkNamedArguments("useRealtimeSelectedShareoutUser", arguments, {
    required: { kitInstanceId, key },
    optional: { forceFullUserNames, enabled },
  })
  const selectedSharoutUserKey = "shareout_user/" + key
  return useRealtimeQuery({
    realtimeCacheKey: getRealtimeCacheKey({ kitInstanceId, key: selectedSharoutUserKey }),
    queryCacheKey: getSelectedShareoutUserCacheKey({ kitInstanceId, key: selectedSharoutUserKey }),
    queryFn: getSelectedShareoutUser({ kitInstanceId, key, forceFullUserNames }),
    enabled: !!kitInstanceId && !!enabled,
  })
}

function updateSelectedShareoutUser({ kitInstanceId, key }: { kitInstanceId: KitInstanceID; key: string }) {
  checkNamedArguments("updateSelectedShareoutUser", arguments, { required: { kitInstanceId, key } })
  return async (user_id: number): Promise<string> => {
    const { data } = await api.post(`/monthly_kit/kit_instances/${kitInstanceId}/set_current_shareout_user`, {
      key,
      user_id,
    })
    return data
  }
}

function useUpdateSelectedShareoutUser({ kitInstanceId, key }: { kitInstanceId: KitInstanceID; key: string }) {
  checkNamedArguments("useUpdateSelectedShareoutUser", arguments, { required: { kitInstanceId, key } })
  return useMutation(updateSelectedShareoutUser({ kitInstanceId, key }))
}

function getSelectedShareoutUserCacheKey({
  kitInstanceId,
  key,
}: {
  kitInstanceId: KitInstanceID
  key: string
}): Array<string | number> {
  checkNamedArguments("getSelectedShareoutUserCacheKey", arguments, { required: { kitInstanceId, key } })
  return ["monthly_kit", "kit_instance", kitInstanceId, "shareout_selected_user", key]
}

function getSelectedSlideIndex({ kitInstanceId, key }: { kitInstanceId: KitInstanceID; key: string }) {
  checkNamedArguments("getSelectedSlideIndex", arguments, { required: { kitInstanceId, key } })
  return async (): Promise<number> => {
    const { data } = await api.get(`/monthly_kit/kit_instances/${kitInstanceId}/get_current_slideshow_index/`, {
      params: { key },
    })
    return data
  }
}

function useRealtimeSelectedSlideIndex({
  kitInstanceId,
  key,
  enabled = false,
}: {
  kitInstanceId: KitInstanceID
  key: string
  enabled?: boolean
}) {
  checkNamedArguments("useRealtimeSelectedSlideIndex", arguments, {
    required: { kitInstanceId, key },
    optional: { enabled },
  })
  const selectedSlideIndexKey = "slideshow_index/" + key
  return useRealtimeQuery({
    realtimeCacheKey: getRealtimeCacheKey({ kitInstanceId, key: selectedSlideIndexKey }),
    queryCacheKey: getSelectedSlideIndexCacheKey({ kitInstanceId, key: selectedSlideIndexKey }),
    queryFn: getSelectedSlideIndex({ kitInstanceId, key }),
    enabled: !!kitInstanceId && !!enabled,
  })
}

function updateSelectedSlideIndex({ kitInstanceId, key }: { kitInstanceId: KitInstanceID; key: string }) {
  checkNamedArguments("updateSelectedSlideIndex", arguments, { required: { kitInstanceId, key } })
  return async (slide_index: number): Promise<number> => {
    const { data } = await api.post(`/monthly_kit/kit_instances/${kitInstanceId}/set_current_slideshow_index`, {
      key,
      slide_index,
    })
    return data
  }
}

function useUpdateSelectedSlideIndex({ kitInstanceId, key }: { kitInstanceId: KitInstanceID; key: string }) {
  checkNamedArguments("useUpdateSelectedSlideIndex", arguments, { required: { kitInstanceId, key } })
  return useMutation(updateSelectedSlideIndex({ kitInstanceId, key }))
}

function getSelectedSlideIndexCacheKey({
  kitInstanceId,
  key,
}: {
  kitInstanceId: KitInstanceID
  key: string
}): Array<string | number> {
  checkNamedArguments("getSelectedSlideIndexCacheKey", arguments, { required: { kitInstanceId, key } })
  return ["monthly_kit", "kit_instance", kitInstanceId, "slideshow_selected_index", key]
}

function getSlideRevealStatus({ kitInstanceId, key }: { kitInstanceId: KitInstanceID; key: string }) {
  checkNamedArguments("getSlideRevealStatus", arguments, { required: { kitInstanceId, key } })
  return async (): Promise<number> => {
    const { data } = await api.get(`/monthly_kit/kit_instances/${kitInstanceId}/get_current_slide_reveal_status/`, {
      params: { key },
    })
    return data
  }
}

function useRealtimeSlideRevealStatus({
  kitInstanceId,
  key,
  enabled = false,
}: {
  kitInstanceId: KitInstanceID
  key: string
  enabled?: boolean
}) {
  checkNamedArguments("useRealtimeSlideRevealStatus", arguments, {
    required: { kitInstanceId, key },
    optional: { enabled },
  })
  const slideRevealStatusKey = "slide_reveal_status/" + key
  return useRealtimeQuery({
    realtimeCacheKey: getRealtimeCacheKey({ kitInstanceId, key: slideRevealStatusKey }),
    queryCacheKey: getSlideRevealStatusCacheKey({ kitInstanceId, key: slideRevealStatusKey }),
    queryFn: getSlideRevealStatus({ kitInstanceId, key }),
    enabled: !!kitInstanceId && !!enabled,
  })
}

function updateSlideRevealStatus({ kitInstanceId, key }: { kitInstanceId: KitInstanceID; key: string }) {
  checkNamedArguments("updateSlideRevealStatus", arguments, { required: { kitInstanceId, key } })
  return async (reveal: boolean): Promise<number> => {
    const { data } = await api.post(`/monthly_kit/kit_instances/${kitInstanceId}/set_current_slide_reveal_status`, {
      key,
      reveal,
    })
    return data
  }
}

function useUpdateSlideRevealStatus({ kitInstanceId, key }: { kitInstanceId: KitInstanceID; key: string }) {
  checkNamedArguments("useUpdateSlideRevealStatus", arguments, { required: { kitInstanceId, key } })
  return useMutation(updateSlideRevealStatus({ kitInstanceId, key }))
}

function getSlideRevealStatusCacheKey({
  kitInstanceId,
  key,
}: {
  kitInstanceId: KitInstanceID
  key: string
}): Array<string | number> {
  checkNamedArguments("getSlideRevealStatusCacheKey", arguments, { required: { kitInstanceId, key } })
  return ["monthly_kit", "kit_instance", kitInstanceId, "slide_reveal_status", key]
}

function getKitInstancesCacheKey({ teamId }: { teamId: TeamID }): Array<string | number> {
  checkNamedArguments("getKitInstancesCacheKey", arguments, { required: { teamId } })
  return ["monthly_kit", "kit_instance", "team_instances", teamId]
}

function getKitInstanceCacheKey({ kitInstanceId }: { kitInstanceId: KitInstanceID }): Array<string | number> {
  checkNamedArguments("getKitInstanceCacheKey", arguments, { required: { kitInstanceId } })
  return ["monthly_kit", "kit_instances", parseInt(kitInstanceId as string)]
}

function getReflectionCacheKey({ kitInstanceId }: { kitInstanceId: KitInstanceID }): Array<string | number> {
  checkNamedArguments("getReflectionCacheKey", arguments, { required: { kitInstanceId } })
  return ["monthly_kit", "kit_instance", parseInt(kitInstanceId as string), "reflections"]
}

function getTeamReflectionsCacheKey({ kitInstanceId }: { kitInstanceId: KitInstanceID }): Array<string | number> {
  checkNamedArguments("getTeamReflectionsCacheKey", arguments, { required: { kitInstanceId } })
  return ["monthly_kit", "kit_instance", parseInt(kitInstanceId as string), "team_reflections"]
}

function getPostSessionSurveyCacheKey({ kitInstanceId }: { kitInstanceId: KitInstanceID }): Array<string | number> {
  checkNamedArguments("getPostSessionSurveyCacheKey", arguments, { required: { kitInstanceId } })
  return ["monthly_kit", "kit_instance", parseInt(kitInstanceId as string), "post_session_survey"]
}

function getKitStepCompletedUsersCacheKey({
  kitInstanceId,
  stepPath,
}: {
  kitInstanceId: KitInstanceID
  stepPath: string
}): Array<string | number> {
  checkNamedArguments("getKitStepCompletedUsersCacheKey", arguments, { required: { kitInstanceId, stepPath } })
  return ["monthly_kit", "kit_instance", parseInt(kitInstanceId as string), "completed_users", stepPath]
}

function getSessionPictureCacheKey({ kitInstanceId }: { kitInstanceId: KitInstanceID }): Array<string | number> {
  checkNamedArguments("getSessionPictureCacheKey", arguments, { required: { kitInstanceId } })
  return ["monthly_kit", "kit_instance", parseInt(kitInstanceId as string), "session_picture"]
}

function getKitParticipantsCacheKey({ kitInstanceId }: { kitInstanceId: KitInstanceID }): Array<string | number> {
  checkNamedArguments("getKitParticipantsCacheKey", arguments, { required: { kitInstanceId } })
  return ["monthly_kit", "kit_instance", parseInt(kitInstanceId as string), "kit_participants"]
}

function getSessionCurrentStepCacheKey({ kitInstanceId }: { kitInstanceId: KitInstanceID }): Array<string | number> {
  checkNamedArguments("getSessionCurrentStepCacheKey", arguments, { required: { kitInstanceId } })
  return ["monthly_kit", "kit_instances", parseInt(kitInstanceId as string), "session_current_step"]
}

function updateKitInstanceQueryCache({ data, queryClient }: { data: KitInstanceData; queryClient: QueryClient }): void {
  checkNamedArguments("updateKitInstanceQueryCache", arguments, { required: { data, queryClient } })
  queryClient.setQueryData(getKitInstanceCacheKey({ kitInstanceId: data.id }), data)
}

function getAggregatedResultsDataForKitInstance({
  kitInstanceId,
  component,
  identifiers,
  has_sensitive_data,
  additional_data,
}: {
  kitInstanceId: KitInstanceID
  component: string
  identifiers: string[]
  has_sensitive_data: boolean
  additional_data: unknown
}) {
  checkNamedArguments("getAggregatedResultsDataForKitInstance", arguments, {
    required: {
      kitInstanceId,
      component,
      identifiers,
      has_sensitive_data,
      additional_data,
    },
  })
  return async (): Promise<TODO> => {
    const { data } = await api.post(`/monthly_kit/kit_instances/${kitInstanceId}/get_aggregated_results_data/`, {
      component,
      identifiers,
      has_sensitive_data,
      additional_data,
    })
    return data
  }
}

function getUseGetAggregatedResultsDataForKitInstanceFunction({
  kitInstanceId,
  sessionRealtimeUpdates = false,
}: {
  kitInstanceId: KitInstanceID
  sessionRealtimeUpdates?: boolean
}) {
  checkNamedArguments("getUseGetAggregatedResultsDataForKitInstanceFunction", arguments, {
    required: { kitInstanceId },
    optional: { sessionRealtimeUpdates },
  })
  return ({
    component,
    identifiers,
    has_sensitive_data,
    additional_data,
    refetch_interval = null,
  }: {
    component: string
    identifiers: string[]
    has_sensitive_data: boolean
    additional_data: unknown
    refetch_interval?: number | false | null
  }) => {
    const aggregatedResultsDataWithPolling = useQuery(
      getAggregatedResultsDataForKitInstanceCacheKey({ kitInstanceId, component, identifiers, additional_data }),
      getAggregatedResultsDataForKitInstance({
        kitInstanceId,
        component,
        identifiers,
        has_sensitive_data,
        additional_data,
      }),
      {
        ...(refetch_interval ? { refetchInterval: refetch_interval } : {}),
        enabled: !!kitInstanceId && !sessionRealtimeUpdates,
      }
    )
    const aggregatedResultsDataWithoutPolling = useRealtimeQuery({
      realtimeCacheKey: getRealtimeCacheKey({
        kitInstanceId,
        key: "exercise_answers",
      }),
      queryCacheKey: getAggregatedResultsDataForKitInstanceCacheKey({
        kitInstanceId,
        component,
        identifiers,
        additional_data,
      }),
      queryFn: getAggregatedResultsDataForKitInstance({
        kitInstanceId,
        component,
        identifiers,
        has_sensitive_data,
        additional_data,
      }),
      enabled: !!kitInstanceId && !!sessionRealtimeUpdates,
    })
    if (!!sessionRealtimeUpdates) {
      return aggregatedResultsDataWithoutPolling
    }
    return aggregatedResultsDataWithPolling
  }
}

function getAggregatedResultsDataForKitInstanceCacheKey({
  kitInstanceId,
  component,
  identifiers,
  additional_data,
}: {
  kitInstanceId: number | string
  component: string
  identifiers: string[]
  additional_data: unknown
}) {
  kitInstanceId = Number.isInteger(kitInstanceId) ? kitInstanceId : parseInt(kitInstanceId as string)
  return [
    "monthly_kit",
    "kit_instance",
    kitInstanceId,
    "get_aggregated_results_data",
    component,
    identifiers,
    additional_data,
  ]
}

function getKitDefinition({ slug, teamId }: { slug: KitSlug; teamId: TeamID }) {
  checkNamedArguments("getKitDefinition", arguments, { required: { slug, teamId } })
  return async (): Promise<TODO> => {
    const { data } = await api.get(`/teams/${teamId}/kit_definition_for_slug/?search_slug=${slug}`)
    return data
  }
}

function useKitDefinition({ slug, teamId }: { slug: KitSlug; teamId: TeamID }) {
  checkNamedArguments("useKitDefinition", arguments, { required: { slug, teamId } })
  return useQuery(["monthly_kit", "kit_definition", teamId, slug], getKitDefinition({ teamId, slug }), {
    enabled: !!(teamId && slug),
  })
}

function getBasicKitInfos({ teamId }: { teamId: TeamID }) {
  checkNamedArguments("getBasicKitInfos", arguments, { required: { teamId } })
  return async (): Promise<TODO> => {
    const { data } = await api.get(`/visible_teams/${teamId}/basic_kit_infos/`)
    return data
  }
}

function useBasicKitInfos({ teamId }: { teamId: TeamID }) {
  checkNamedArguments("useBasicKitInfos", arguments, { required: { teamId } })
  return useQuery(["monthly_kit", "basic_kit_info", teamId], getBasicKitInfos({ teamId }), {
    enabled: !!teamId,
  })
}

function getMiniBasicKitInfos({ teamId }: { teamId: TeamID }) {
  checkNamedArguments("getMiniBasicKitInfos", arguments, { required: { teamId } })
  return async (): Promise<TODO> => {
    const { data } = await api.get(`/visible_teams/${teamId}/mini_basic_kit_infos/`)
    return data
  }
}

function useBasicMiniKitInfos({ teamId }: { teamId: TeamID }) {
  checkNamedArguments("useBasicMiniKitInfos", arguments, { required: { teamId } })
  return useQuery(["monthly_kit", "basic_kit_info", "minis", teamId], getMiniBasicKitInfos({ teamId }), {
    enabled: !!teamId,
  })
}

function getUnavailableKits({ teamId }: { teamId: TeamID }) {
  checkNamedArguments("getUnavailableKits", arguments, { required: { teamId } })
  return async (): Promise<TODO> => {
    const { data } = await api.get(`/visible_teams/${teamId}/unavailable_kits/`)
    return data
  }
}

function useUnavailableKits({ teamId }: { teamId: TeamID }) {
  checkNamedArguments("useUnavailableKits", arguments, { required: { teamId } })
  return useQuery(["monthly_kit", "basic_kit_info", teamId, "unavailable_kits"], getUnavailableKits({ teamId }), {
    enabled: !!teamId,
  })
}

function getMiniUnavailableKits({ teamId }: { teamId: TeamID }) {
  checkNamedArguments("getMiniUnavailableKits", arguments, { required: { teamId } })
  return async (): Promise<TODO> => {
    const { data } = await api.get(`/visible_teams/${teamId}/mini_unavailable_kits/`)
    return data
  }
}

function useMiniUnavailableKits({ teamId }: { teamId: TeamID }) {
  checkNamedArguments("useMiniUnavailableKits", arguments, { required: { teamId } })
  return useQuery(
    ["monthly_kit", "basic_kit_info", teamId, "unavailable_kits", "minis"],
    getMiniUnavailableKits({ teamId }),
    {
      enabled: !!teamId,
    }
  )
}

function getScheduleNextCacheKey({ teamId }: { teamId: TeamID }): Array<string | number> {
  checkNamedArguments("getScheduleNextCacheKey", arguments, { required: { teamId } })
  return ["monthly_kit", "basic_kit_info", "schedule_next", teamId]
}

function getScheduleNextKits({
  teamId,
  includeScheduled = false,
  skipIfHasScheduled = false,
  limit = 3,
}: {
  teamId: TeamID
  includeScheduled?: boolean
  skipIfHasScheduled?: boolean
  limit?: number
}) {
  checkNamedArguments("getScheduleNextKits", arguments, {
    required: { teamId },
    optional: { includeScheduled, skipIfHasScheduled, limit },
  })
  return async (): Promise<TODO> => {
    const { data } = await api.get(
      buildUrl(["manage_team", teamId, "basic_kit_infos_to_schedule_next"], {
        urlQueryParams: { include_scheduled: includeScheduled, skip_if_has_scheduled: skipIfHasScheduled, limit },
      })
    )
    return data
  }
}

function useScheduleNextKits({
  teamId,
  enabled = true,
  includeScheduled = false,
  skipIfHasScheduled = false,
  limit = 3,
}: {
  teamId: TeamID
  enabled?: boolean
  includeScheduled?: boolean
  skipIfHasScheduled?: boolean
  limit?: number
}) {
  checkNamedArguments("useScheduleNextKits", arguments, {
    required: { teamId },
    optional: { enabled, includeScheduled, skipIfHasScheduled, limit },
  })
  return useQuery(
    getScheduleNextCacheKey({ teamId }),
    getScheduleNextKits({ teamId, includeScheduled, skipIfHasScheduled, limit }),
    {
      enabled: !!teamId && !!enabled,
    }
  )
}

interface TimerParams {
  playClickedTime: number
  timePassed: number
  isRunning: boolean
  complete: boolean
}

interface KitReportParams {
  accountId?: AccountID | null
  accountTags?: string[] | null
  multiAccountIds?: Array<AccountID> | null
  teamId?: TeamID | null
  teamTags?: string[] | null
  includeArchivedTeams?: boolean
}

interface KitInstanceReportParams extends KitReportParams {
  kitInstanceId?: KitInstanceID | null
  kitSlug?: string | null
  kitType?: string | null
}

interface KitInstanceReportParamsWithEnabled extends KitInstanceReportParams {
  enabled?: boolean
}

interface KitInstanceReportParamsWithStartIndex extends KitInstanceReportParams {
  startIndex?: number
}

type KitInstanceReportParamsWithEnabledAndStartIndex = KitInstanceReportParamsWithEnabled &
  KitInstanceReportParamsWithStartIndex

function buildKitReportURL({
  pathArray,
  accountId = null,
  accountTags = null,
  multiAccountIds = null,
  teamId = null,
  teamTags = null,
  kitInstanceId = null,
  kitSlug = null,
  kitType = null,
  includeArchivedTeams = false,
  urlQueryParams = {},
}: KitInstanceReportParams & {
  pathArray: Array<string | number>
  urlQueryParams?: Record<string, string | number | boolean | Array<string | number | boolean>>
}) {
  checkNamedArguments("buildKitReportURL", arguments, {
    required: { pathArray },
    optional: {
      accountId,
      accountTags,
      multiAccountIds,
      teamId,
      teamTags,
      kitInstanceId,
      kitSlug,
      kitType,
      includeArchivedTeams,
      urlQueryParams,
    },
  })
  // Don't send both multi-account IDs and tags at the same time; IDs take precedence:
  let config = { urlQueryParams }
  if (multiAccountIds?.length) {
    config = { urlQueryParams: { ...urlQueryParams, multi_account_ids: multiAccountIds } }
  } else if (accountTags?.length) {
    config = { urlQueryParams: { ...urlQueryParams, account_tags: accountTags } }
  }
  config = {
    urlQueryParams: {
      ...config.urlQueryParams,
      ...(accountId ? { account_id: accountId } : {}),
      ...(teamId ? { team_id: teamId } : {}),
      ...(teamTags?.length ? { team_tags: teamTags } : {}),
      ...(kitInstanceId ? { kit_instance_id: kitInstanceId } : {}),
      ...(kitSlug ? { kit_slug: kitSlug } : {}),
      ...(kitType ? { kit_type: kitType } : {}),
      ...(includeArchivedTeams ? { include_archived_teams: includeArchivedTeams } : {}),
    },
  }
  return buildUrl(["monthly_kit", "kit_instance_reports", ...pathArray], config)
}

function useReportKits({
  accountId,
  accountTags = null,
  multiAccountIds = null,
  teamId = null,
  teamTags = null,
  includeArchivedTeams = false,
}: KitReportParams) {
  checkNamedArguments("useReportKits", arguments, {
    required: { accountId },
    optional: {
      accountTags,
      multiAccountIds,
      teamId,
      teamTags,
      includeArchivedTeams,
    },
  })
  const url = buildKitReportURL({
    pathArray: ["available_kits"],
    accountId,
    accountTags,
    multiAccountIds,
    teamId,
    teamTags,
    includeArchivedTeams,
  })
  return useQuery([url], () => fetcher({ url }), {
    enabled: !!(accountId || accountTags?.length || multiAccountIds?.length),
  })
}

function useSessionSurveyRollup({
  accountId = null,
  accountTags = null,
  multiAccountIds = null,
  teamId = null,
  teamTags = null,
  kitInstanceId = null,
  kitSlug = null,
  kitType = null,
  includeArchivedTeams = false,
}: KitInstanceReportParams) {
  checkNamedArguments("useSessionSurveyRollup", arguments, {
    optional: {
      accountId,
      accountTags,
      multiAccountIds,
      teamId,
      teamTags,
      kitInstanceId,
      kitSlug,
      kitType,
      includeArchivedTeams,
    },
  })
  const url = buildKitReportURL({
    pathArray: ["session_survey_rollup"],
    accountId,
    accountTags,
    multiAccountIds,
    teamId,
    teamTags,
    kitInstanceId,
    kitSlug,
    kitType,
    includeArchivedTeams,
  })
  return useQuery([url], () => fetcher({ url }), { enabled: !!accountId })
}

async function getSessionFeedback({
  accountId = null,
  accountTags = null,
  multiAccountIds = null,
  teamId = null,
  teamTags = null,
  kitInstanceId = null,
  kitSlug = null,
  kitType = null,
  includeArchivedTeams = false,
  startIndex = 0,
}: KitInstanceReportParamsWithStartIndex) {
  checkNamedArguments("getSessionFeedback", arguments, {
    optional: {
      accountId,
      accountTags,
      multiAccountIds,
      teamId,
      teamTags,
      kitInstanceId,
      kitSlug,
      kitType,
      includeArchivedTeams,
      startIndex,
    },
  })
  const url = buildKitReportURL({
    pathArray: ["fetch_session_feedback"],
    accountId,
    accountTags,
    multiAccountIds,
    teamId,
    teamTags,
    kitInstanceId,
    kitSlug,
    kitType,
    includeArchivedTeams,
    urlQueryParams: { start_index: startIndex },
  })
  return fetcher({ url })
}

function useSessionFeedback({
  accountId = null,
  accountTags = null,
  multiAccountIds = null,
  teamId = null,
  teamTags = null,
  kitInstanceId = null,
  kitSlug = null,
  kitType = null,
  includeArchivedTeams = false,
  startIndex = 0,
}: KitInstanceReportParamsWithStartIndex) {
  checkNamedArguments("useSessionFeedback", arguments, {
    optional: {
      accountId,
      accountTags,
      multiAccountIds,
      teamId,
      teamTags,
      kitInstanceId,
      kitSlug,
      kitType,
      includeArchivedTeams,
      startIndex,
    },
  })
  const url = buildKitReportURL({
    pathArray: ["fetch_session_feedback"],
    accountId,
    accountTags,
    multiAccountIds,
    teamId,
    teamTags,
    kitInstanceId,
    kitSlug,
    kitType,
    includeArchivedTeams,
    urlQueryParams: { start_index: startIndex },
  })
  return useQuery([url], () => fetcher({ url }))
}

function useSessionFeedbackSummary({
  accountId = null,
  accountTags = null,
  multiAccountIds = null,
  teamId = null,
  teamTags = null,
  kitInstanceId = null,
  kitSlug = null,
  kitType = null,
  includeArchivedTeams = false,
  enabled = true,
}: KitInstanceReportParamsWithEnabled) {
  checkNamedArguments("useSessionFeedbackSummary", arguments, {
    optional: {
      accountId,
      accountTags,
      multiAccountIds,
      teamId,
      teamTags,
      kitInstanceId,
      kitSlug,
      kitType,
      includeArchivedTeams,
      enabled,
    },
  })
  const url = buildKitReportURL({
    pathArray: ["session_feedback_summary"],
    accountId,
    accountTags,
    multiAccountIds,
    teamId,
    teamTags,
    kitInstanceId,
    kitSlug,
    kitType,
    includeArchivedTeams,
  })
  return useQuery([url], () => fetcher({ url }), { enabled: !!accountId && !!enabled })
}

function getSessionReflections({
  accountId = null,
  accountTags = null,
  multiAccountIds = null,
  teamId = null,
  teamTags = null,
  kitInstanceId = null,
  kitSlug = null,
  kitType = null,
  includeArchivedTeams = false,
  startIndex = 0,
}: KitInstanceReportParamsWithStartIndex) {
  checkNamedArguments("getSessionReflections", arguments, {
    optional: {
      accountId,
      accountTags,
      multiAccountIds,
      teamId,
      teamTags,
      kitInstanceId,
      kitSlug,
      kitType,
      includeArchivedTeams,
      startIndex,
    },
  })
  const url = buildKitReportURL({
    pathArray: ["fetch_session_reflections"],
    accountId,
    accountTags,
    multiAccountIds,
    teamId,
    teamTags,
    kitInstanceId,
    kitSlug,
    kitType,
    includeArchivedTeams,
    urlQueryParams: { start_index: startIndex },
  })
  return fetcher({ url })
}

function useSessionReflections({
  accountId = null,
  accountTags = null,
  multiAccountIds = null,
  teamId = null,
  teamTags = null,
  kitInstanceId = null,
  kitSlug = null,
  kitType = null,
  includeArchivedTeams = false,
  startIndex = 0,
  enabled = true,
}: KitInstanceReportParamsWithEnabledAndStartIndex) {
  checkNamedArguments("useSessionReflections", arguments, {
    optional: {
      accountId,
      accountTags,
      multiAccountIds,
      teamId,
      teamTags,
      kitInstanceId,
      kitSlug,
      kitType,
      includeArchivedTeams,
      startIndex,
      enabled,
    },
  })
  const url = buildKitReportURL({
    pathArray: ["fetch_session_reflections"],
    accountId,
    accountTags,
    multiAccountIds,
    teamId,
    teamTags,
    kitInstanceId,
    kitSlug,
    kitType,
    includeArchivedTeams,
    urlQueryParams: { start_index: startIndex },
  })
  return useQuery([url], () => fetcher({ url }), { enabled: !!(enabled && accountId) })
}

function getActionItemAISummary({ url }: { url: string }) {
  checkNamedArguments("getActionItemAISummary", arguments, { required: { url } })
  return async (): Promise<TODO> => {
    const { data } = await api.get(url)
    return data
  }
}

function useActionItemAISummaryRollup({
  accountId = null,
  accountTags = null,
  multiAccountIds = null,
  teamId = null,
  teamTags = null,
  kitInstanceId = null,
  kitSlug = null,
  includeArchivedTeams = false,
  enabled = false,
}: KitInstanceReportParamsWithEnabled) {
  checkNamedArguments("useActionItemAISummaryRollup", arguments, {
    optional: {
      accountId,
      accountTags,
      multiAccountIds,
      teamId,
      teamTags,
      kitInstanceId,
      kitSlug,
      includeArchivedTeams,
      enabled,
    },
  })
  const url = buildKitReportURL({
    pathArray: ["generate_action_items_summary"],
    accountId,
    accountTags,
    multiAccountIds,
    teamId,
    teamTags,
    kitInstanceId,
    kitSlug,
    includeArchivedTeams,
  })
  return useQuery([url], getActionItemAISummary({ url }), {
    enabled,
  })
}

function getReflectionTextAISummary({ url }: { url: string }) {
  checkNamedArguments("getReflectionTextAISummary", arguments, { required: { url } })
  return async function (): Promise<TODO> {
    const { data } = await api.get(url)
    return data
  }
}

function useReflectionDataAISummaryRollup({
  accountId = null,
  accountTags = null,
  multiAccountIds = null,
  teamId = null,
  teamTags = null,
  kitInstanceId = null,
  kitSlug = null,
  includeArchivedTeams = false,
  enabled = false,
}: KitInstanceReportParamsWithEnabled) {
  checkNamedArguments("useReflectionDataAISummaryRollup", arguments, {
    optional: {
      accountId,
      accountTags,
      multiAccountIds,
      teamId,
      teamTags,
      kitInstanceId,
      kitSlug,
      includeArchivedTeams,
      enabled,
    },
  })
  const url = buildKitReportURL({
    pathArray: ["generate_reflections_text_summary"],
    accountId,
    accountTags,
    multiAccountIds,
    teamId,
    teamTags,
    kitInstanceId,
    kitSlug,
    includeArchivedTeams,
  })
  return useQuery([url], getReflectionTextAISummary({ url }), {
    enabled,
  })
}

function useKitInstanceReportExerciseInstances({ kitInstanceId }: { kitInstanceId: KitInstanceID }) {
  checkNamedArguments("useKitInstanceReportExerciseInstances", arguments, { required: { kitInstanceId } })
  const url = buildKitReportURL({ pathArray: [kitInstanceId, "team_and_user_exercise_instances"] })
  return useQuery([url], () => fetcher({ url }))
}

async function setSessionCurrentStep({
  kitInstanceId,
  currentStep,
  queryClient,
}: {
  kitInstanceId: KitInstanceID
  currentStep: string
  queryClient: QueryClient
}) {
  checkNamedArguments("setSessionCurrentStep", arguments, { required: { kitInstanceId, currentStep, queryClient } })
  queryClient.setQueryData(getKitInstanceCacheKey({ kitInstanceId }), (kitInstance?: Record<string, unknown>) => ({
    ...(kitInstance ?? {}),
    session_current_step: currentStep,
  }))
  await api.post(`/monthly_kit/kit_instances/${kitInstanceId}/set_session_current_step/`, { current_step: currentStep })
}

function getSessionCurrentStepQueryFn({ kitInstanceId }: { kitInstanceId: KitInstanceID }) {
  checkNamedArguments("getSessionCurrentStepQueryFn", arguments, { required: { kitInstanceId } })
  return async function (): Promise<TODO> {
    const { data } = await api.get(`/monthly_kit/kit_instances/${kitInstanceId}/session_current_step/`)
    return data
  }
}

function useRealtimeSessionCurrentStep({
  kitInstanceId,
  enabled = true,
}: {
  kitInstanceId: KitInstanceID
  enabled?: boolean
}) {
  checkNamedArguments("useRealtimeSessionCurrentStep", arguments, { required: { kitInstanceId, enabled } })
  return useRealtimeQuery({
    realtimeCacheKey: getRealtimeCacheKey({ kitInstanceId, key: "current_step" }),
    queryCacheKey: getSessionCurrentStepCacheKey({ kitInstanceId }),
    queryFn: getSessionCurrentStepQueryFn({ kitInstanceId }),
    enabled: !!kitInstanceId && !!enabled,
  })
}

function getTimerCacheKey({ kitInstanceId, key }: { kitInstanceId: number | string; key: string }) {
  return ["monthly_kit", "kit_instance", kitInstanceId, "get_timer", key]
}

function useRealtimeTimer({
  kitInstanceId,
  key,
  enabled = true,
}: {
  kitInstanceId: KitInstanceID
  key: string
  enabled?: boolean
}) {
  checkNamedArguments("useRealtimeTimer", arguments, { required: { kitInstanceId, key }, optional: { enabled } })
  const timerKey = "timer/" + key
  return useRealtimeQuery({
    realtimeCacheKey: getRealtimeCacheKey({ kitInstanceId, key: timerKey }),
    queryCacheKey: getTimerCacheKey({ kitInstanceId, key: timerKey }),
    queryFn: getTimer({ kitInstanceId, key }),
    enabled: !!kitInstanceId && !!enabled,
  })
}

function getTimer({ kitInstanceId, key }: { kitInstanceId: number | string; key: string }) {
  return async () => {
    const { data } = await api.get(`/monthly_kit/kit_instances/${kitInstanceId}/get_timer/`, {
      params: { key },
    })
    return data
  }
}

function setTimer({ kitInstanceId, key }: { kitInstanceId: number | string; key: string }) {
  return async (timer: TimerParams) => {
    const { data } = await api.post(`/monthly_kit/kit_instances/${kitInstanceId}/set_timer`, {
      key,
      ...timer,
    })
    return data
  }
}

function useSetTimer({ kitInstanceId, key }: { kitInstanceId: number | string; key: string }) {
  return useMutation(setTimer({ kitInstanceId, key }))
}

async function trackLeaderAction({
  id,
  token,
  action,
  status,
}: {
  id: number
  token: string
  action: string
  status: string
}) {
  checkNamedArguments("trackLeaderAction", arguments, { required: { id, token, action, status } })
  await api.post("/monthly_kit/leader_actions/track/", { id, token, action, status })
}

export {
  getAppCodeURL,
  getResultsUrl,
  getStandaloneExerciseUrl,
  getKitInstancesCacheKey,
  useKitInstance,
  useGetOrCreateKitInstance,
  useUpdateOrCreateKitInstance,
  useTeamKitInstances,
  useBasicMiniKitInfos,
  useMiniUnavailableKits,
  useStartKitSession,
  useRegenerateKitSessionCode,
  useRestartKitSessionTestOnly,
  useCompleteKitSession,
  useSessionPicture,
  useKitParticipants,
  useKitParticipantCount,
  useUpdateSessionPicture,
  useUpdateOrCreateKitInstanceBySlug,
  useJoinKitInstanceById,
  validateSessionCode,
  useJoinKitInstanceByCode,
  useReflection,
  useUpdateReflection,
  useTeamReflections,
  useKitStepCompletedUsers,
  usePostSessionSurvey,
  useUpdatePostSessionSurvey,
  getUseGetAggregatedResultsDataForKitInstanceFunction,
  useKitDefinition,
  useBasicKitInfos,
  useUnavailableKits,
  getScheduleNextCacheKey,
  useScheduleNextKits,
  useSessionSurveyRollup,
  useSessionFeedbackSummary,
  getSessionReflections,
  useSessionReflections,
  useReportKits,
  useKitInstanceReportExerciseInstances,
  useRealtimeTimer,
  useSetTimer,
  setSessionCurrentStep,
  useRaisedHands,
  useUpdateRaisedHand,
  useRealtimeSelectedShareoutUser,
  useRealtimeSelectedSlideIndex,
  useRealtimeSlideRevealStatus,
  useUpdateSelectedShareoutUser,
  useUpdateSelectedSlideIndex,
  useUpdateSlideRevealStatus,
  useUpdateKitStepCompletedUsers,
  trackLeaderAction,
  useActionItemAISummaryRollup,
  useReflectionDataAISummaryRollup,
  useRealtimeSessionCurrentStep,
  getSessionFeedback,
  useSessionFeedback,
}
