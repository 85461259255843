import cn from "classnames"
import { Form, Formik, useFormikContext } from "formik"
import { isObject, size, mapValues, range } from "lodash-es"
import { useState } from "react"
import { Navigate, useLocation, useNavigate } from "react-router"
import { styled } from "styled-components"

import { SurveyQuestionType } from "./constants"

import { Accordion } from "components"
import { useAuth } from "domains/Authentication/resource"
import { TEAM_OTHER } from "domains/Reports/constants"
import { handleErrors, Yup, FormMessage } from "forms"
import { LineRatingField, SelectField, AdvancedSelectField, EmailField, HiddenField, RichTextField } from "forms/fields"
import { useEngagementSurveyInfo, postEngagementSurvey } from "resources/teams"
import { useUser } from "resources/users"
import { Loading, PageTitle, TextContainer, SubmitButton } from "ui"
import { useQueryParams, useEffectAfterChange } from "ui/hooks"
import { buildUrl, plural } from "utils/string"

const EngagementSurvey = styled(function EngagementSurvey({ className }) {
  const navigate = useNavigate()
  const { data: auth, isFetching: authIsFetching } = useAuth()
  const { data: user, isFetching: userIsFetching } = useUser({ userId: !!auth && "me" })
  const { token, uid, tid } = useQueryParams()
  const { data: surveyInfo, isFetching: infoIsFetching } = useEngagementSurveyInfo({ token, uid, tid })
  const { pathname, search } = useLocation()

  const teamOptions = surveyInfo?.team_options ?? []
  const defaultTeamValue = surveyInfo?.default_team_value ?? null
  const readOnlyTeamValue = surveyInfo?.read_only_team_value ?? null
  const [selectedTeamValue, setSelectedTeamValue] = useState(null)

  if (authIsFetching || userIsFetching || infoIsFetching || !surveyInfo) {
    return <Loading />
  }

  if (!surveyInfo.is_token_valid) {
    return <SurveyTokenExpired />
  }

  if (surveyInfo.login_required) {
    const loginUrl = buildUrl(["auth", "login"], { urlQueryParams: { next: pathname + search } })
    return <Navigate to={loginUrl} replace />
  }

  if (surveyInfo.invalid_email_domain) {
    return <InvalidEmailDomain user={user} />
  }

  const selectedTeamOption = teamOptions?.find(({ value }) => value === (selectedTeamValue ?? defaultTeamValue)) ?? null

  const onSubmit = handleErrors(async (values) => {
    const normalizedValues = mapValues(values, (val) => (isObject(val) ? val.value : val))
    const surveyData = { ...normalizedValues, uid, token }
    await postEngagementSurvey({ surveyData })
    navigate("/engagement-survey-complete")
  })

  const surveyQuestions = surveyInfo.survey_questions

  const surveySchema = Yup.object().shape({
    ...surveyQuestions.reduce((obj, field) => {
      obj[field.name] = getSurveyQuestionSchema(field)
      return obj
    }, {}),
    email: Yup.string().email().required("This field is required."),
    tid: Yup.string().required("This field is required."),
  })

  const initialValues = {
    ...surveyQuestions.reduce((obj, field) => {
      obj[field.name] = getSurveyQuestionInitialValue(field)
      return obj
    }, {}),
    email: user?.email || "",
    tid: !teamOptions.length ? TEAM_OTHER : defaultTeamValue ?? "",
  }

  return (
    <div className={cn("main-container full-width", className)}>
      <PageTitle>Team engagement survey</PageTitle>
      <h1 className="text-gray-9 mb-small">Team engagement survey</h1>
      {!!selectedTeamOption && <h2 className="text-gray-9 mb-small">{selectedTeamOption.name}</h2>}
      <TextContainer className="mt-large">
        <Formik initialValues={initialValues} validationSchema={surveySchema} onSubmit={onSubmit}>
          <EngagementSurveyForm
            user={user}
            surveyQuestions={surveyQuestions}
            teamOptions={teamOptions}
            readOnlyTeamValue={readOnlyTeamValue}
            onTeamChange={(value) => setSelectedTeamValue(value)}
          />
        </Formik>
      </TextContainer>
    </div>
  )
})`
  [name="email"] {
    width: 400px;
  }

  .agreement-scale .axis-label {
    width: 60px;
  }
`

const EngagementSurveyForm = styled(function EngagementSurveyForm({
  className,
  user,
  surveyQuestions,
  teamOptions,
  onTeamChange,
  readOnlyTeamValue = null,
}) {
  const { errors, setStatus, submitCount } = useFormikContext()

  useEffectAfterChange(() => {
    const numErrors = size(errors)
    if (numErrors > 0 && submitCount > 0) {
      const answerDesc = plural(numErrors, "invalid answer")
      setStatus({ non_field_errors: `${answerDesc}. Please fix before submitting.` })
    } else {
      setStatus(null)
    }
  }, [setStatus, submitCount, errors])

  return (
    <Form className={className}>
      <div className="text-gray-9 space-y-xl">
        <div className="space-y-small">
          {!teamOptions.length || readOnlyTeamValue ? (
            <HiddenField name="tid" />
          ) : (
            <>
              <div className="text-normal">
                <span className="text-semi-bold">Manager / team lead</span> (If your manager / team lead isn’t in the
                list below, type “Not listed”)
              </div>
              <AdvancedSelectField
                name="tid"
                placeholder="Select manager / team lead..."
                saveOnChange={(_name, value) => onTeamChange(value)}
                options={[
                  ...teamOptions,
                  {
                    value: TEAM_OTHER,
                    label: "Not listed",
                  },
                ]}
              />
            </>
          )}
        </div>
        <p className="text-gray-9 mb-medium">
          This 5-minute survey measures how well your team is doing on key engagement factors. We'll measure it before
          you start the Rising Team sessions and periodically as you go to see how things change.
        </p>
        <p className="text-gray-9 text-bold mb-medium">This data will be kept anonymous.</p>
        {surveyQuestions.map((field, index) => (
          <div key={field.name}>{getSurveyQuestionField({ field, index })}</div>
        ))}
        {user ? (
          <HiddenField name="email" />
        ) : (
          <div className="space-y-small">
            <div className="text-normal">
              <span className="text-semi-bold">Your work email</span> (This will only be used to link your data to your
              organization)
            </div>
            <EmailField name="email" autoComplete="off" disabled={!!user} />
          </div>
        )}
        <div>
          <SubmitButton>Submit</SubmitButton>
          <FormMessage />
        </div>
      </div>
    </Form>
  )
})`
  .form-message {
    font-weight: 600;
    margin-top: var(--spacing-4);
  }
`

const SurveyTokenExpired = () => (
  <>
    <PageTitle>Engagement survey</PageTitle>
    <div className="p-large">This survey link is invalid or has expired</div>
  </>
)

const InvalidEmailDomain = ({ user }) => (
  <>
    <PageTitle>Engagement survey</PageTitle>
    <div className="p-large">
      Your current login email <b>{user.email}</b> is invalid for this survey. Please login with your work email.
    </div>
  </>
)

const getSurveyQuestionSchema = (field) => {
  switch (field.type) {
    case SurveyQuestionType.AGREE_SCALE_7_POINTS:
    case SurveyQuestionType.LIKELY_SCALE_10_POINTS:
      const valueSchema = field.optional ? Yup.string() : Yup.string().required("This field is required.")
      return Yup.object().shape({ value: valueSchema })
    case SurveyQuestionType.MULTIPLE_CHOICE:
    case SurveyQuestionType.RICH_TEXT:
      return field.optional ? Yup.string() : Yup.string().required("This field is required.")
    default:
      return null
  }
}

const getSurveyQuestionInitialValue = (field) => {
  switch (field.type) {
    case SurveyQuestionType.AGREE_SCALE_7_POINTS:
    case SurveyQuestionType.LIKELY_SCALE_10_POINTS:
      return { value: "" }
    case SurveyQuestionType.MULTIPLE_CHOICE:
    case SurveyQuestionType.RICH_TEXT:
      return ""
    default:
      return null
  }
}

const getSurveyQuestionField = ({ field, index }) => {
  const label = `${index + 1}. ${field.label}${field.optional ? " (optional)" : ""}`

  switch (field.type) {
    case SurveyQuestionType.AGREE_SCALE_7_POINTS:
      return <AgreeScale7PointsQuestion field={field} label={label} />
    case SurveyQuestionType.LIKELY_SCALE_10_POINTS:
      return <LikelyScale10PointsQuestion field={field} label={label} />
    case SurveyQuestionType.MULTIPLE_CHOICE:
      return <MultipleChoiceQuestion field={field} label={label} />
    case SurveyQuestionType.RICH_TEXT:
      return <RichTextQuestion field={field} label={label} />
    default:
      return null
  }
}

const AgreeScale7PointsQuestion = ({ field, label }) => {
  const axisLabels = [
    "Strongly disagree",
    "Disagree",
    "Somewhat disagree",
    "Neutral",
    "Somewhat agree",
    "Agree",
    "Strongly agree",
  ]
  return (
    <div className="agreement-scale space-y-xs">
      <div className="text-field-label">{label}</div>
      <LineRatingField numOptions={7} name={field.name} axisLabels={axisLabels} ariaLabels={axisLabels} />
    </div>
  )
}

const LikelyScale10PointsQuestion = ({ field, label }) => (
  <div className="space-y-xs">
    <div className="text-field-label">{label}</div>
    <LineRatingField
      numOptions={10}
      name={field.name}
      axisLabels={["Highly unlikely", "Highly likely"]}
      ariaLabels={range(10).map((idx) => {
        const baseLabel = `${idx + 1} out of 10`
        const suffix = idx === 0 ? ".  Highly unlikely" : idx === 9 ? ". Highly likely" : ""
        return `${baseLabel}${suffix}`
      })}
      showNumbers={true}
    />
  </div>
)

const MultipleChoiceQuestion = ({ field, label }) => (
  <div className="space-y-small">
    <div className="text-field-label">{label}</div>
    <SelectField name={field.name} size="medium">
      <option disabled value="" />
      {field.options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </SelectField>
  </div>
)

const RichTextQuestion = ({ field, label }) => {
  if (field.optional) {
    return (
      <Accordion className="space-y-small" title={<div className="text-field-label">{label}</div>}>
        <RichTextField name={field.name} size="medium" />
      </Accordion>
    )
  }

  return (
    <div className="space-y-small">
      <div className="text-field-label">{label}</div>
      <RichTextField name="{field.name}" size="medium" />
    </div>
  )
}

export default EngagementSurvey
