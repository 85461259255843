import { Formik } from "formik"
import { find } from "lodash-es"
import { useState } from "react"

import { SelectField } from "forms/fields"
import { sortUsersByShortName } from "resources/users"
import { View } from "ui"
import SelectedUserContext from "ui/SelectedUserContext"

const SelectedUsersView = ({ users, children }) => {
  const sortedUsers = sortUsersByShortName({ users })
  const [selectedUserId, setSelectedUserId] = useState(sortedUsers[0].id)
  const initialValues = { current_team_member: selectedUserId }
  const selectedUser = find(users, (user) => user.id === selectedUserId)

  const handleUserChange = ({ target }) => {
    setSelectedUserId(parseInt(target.value))
  }

  return (
    <>
      <View $alignItems="flex-start" $flexWrap="wrap" className="mb-large">
        <View $flexDirection="row" $flexDirectionTablet="column" $flexDirectionMobile="column" $alignItems="flex-end">
          <Formik initialValues={initialValues}>
            <SelectField name="current_team_member" size="medium" value={selectedUserId} onChange={handleUserChange}>
              {sortedUsers.map(({ id, short_name }) => (
                <option key={id} value={id}>
                  {short_name}
                </option>
              ))}
            </SelectField>
          </Formik>
        </View>
      </View>
      {!!selectedUser && (
        <SelectedUserContext.Provider value={{ selectedUser }}>{children}</SelectedUserContext.Provider>
      )}
    </>
  )
}

export default SelectedUsersView
