import { getExerciseAnswer } from "../results_utils"

import { BulletedList } from "ui"

const RadioGroupList = ({ identifier, title, exerciseInstance, exerciseComponent, className }) => {
  const userSelections = getExerciseAnswer(exerciseInstance, identifier) ?? []
  const choiceOptions = exerciseComponent.options
  const formattedAnswers = choiceOptions
    .filter((option) => userSelections.includes(option.value))
    .map((option) => option.short_label)

  return <AnswerListDisplay answers={formattedAnswers} identifier={identifier} title={title} className={className} />
}

const AnswerListDisplay = ({ title, answers, identifier, className }) => {
  if (answers.length < 1) {
    return null
  }

  return (
    <div>
      {!!title && <p className="text-field-label mb-small">{title}</p>}
      <BulletedList className={className}>
        {answers.map((answer) => (
          <li key={`${identifier}_${answer}`}>{answer}</li>
        ))}
      </BulletedList>
    </div>
  )
}

export default RadioGroupList
