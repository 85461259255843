import { useKitSession } from "../KitSessionContext"

import { getTeamLead } from "domains/Exercise/results_utils"
import { useHasTeamFeature } from "domains/Results/utils"
import { useKitParticipants } from "resources/monthly_kit"
import { SHARED_FLAGS } from "ui/hooks/useFeatures"

const ParticipantBreakoutGroups = ({ numGroups, includeTeamLead }) => {
  const { kitInstance, team } = useKitSession()
  const { enabled: sessionRealtimeUpdatesV2 } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_REPLACE_POLLING)
  const { data: participants } = useKitParticipants({ kitInstance, sessionRealtimeUpdates: sessionRealtimeUpdatesV2 })
  const teamLead = getTeamLead(participants, team)

  if (!participants) {
    return null
  }

  const processedParticipants = participants.filter((participant) => !!includeTeamLead || participant !== teamLead)

  const groups = Array.from(Array(numGroups), () => [])

  processedParticipants.forEach((participant, idx) => groups[idx % groups.length].push(participant))

  return null
}

export default ParticipantBreakoutGroups
