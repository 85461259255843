import { Navigate, useLocation, useNavigate } from "react-router-dom"

import { useSetAccountTypeByTeam } from "components/AccountTypeContext"
import NameAndProfileForm from "domains/GetStarted/Register/NameAndProfileForm"
import { KIT_GROUP_SIZE } from "domains/LeaderKit/utils"
import { handleErrors } from "forms"
import { useTeam } from "resources/teams"
import { useUpdateCurrentUser, useUser } from "resources/users"
import { Loading, PageTitle } from "ui"

const TeamCodeProfile = ({ className }) => {
  const { data: user, isFetching: userIsFetching } = useUser({ userId: "me" })
  const { mutateAsync: updateCurrentUser } = useUpdateCurrentUser()
  const { state } = useLocation()
  const navigate = useNavigate()
  const { data: team } = useTeam({ teamId: state?.kitInstance?.team_id })

  useSetAccountTypeByTeam(team)

  if (!state || !state.kitInstance) {
    return <Navigate to="/code" replace />
  }

  if (userIsFetching) {
    return <Loading />
  }

  // If user has not filled out name, don't skip this step
  // If user has already filled out job function,
  // OR if this is a jumbo kit, skip the /code/profile step:
  const hasFirstAndLastName = !!(user.first_name && user.last_name)
  const hasJobFunction = !!user.profile?.job_function
  const isJumboKit = state.kitInstance.kit?.group_size === KIT_GROUP_SIZE.JUMBO
  if (hasFirstAndLastName && (hasJobFunction || isJumboKit)) {
    return <Navigate to={state.kitInstance.session_url} replace />
  }

  const showFirstAndLastName = !hasFirstAndLastName

  const onSubmit = handleErrors(async (values) => {
    await updateCurrentUser(values)
    navigate(state.kitInstance.session_url)
  })

  return (
    <div className={className}>
      <PageTitle>Get Started</PageTitle>
      <h2>Get Started</h2>
      <NameAndProfileForm
        user={user}
        showFirstAndLastName={showFirstAndLastName}
        showProfile={true}
        onSubmit={onSubmit}
      />
    </div>
  )
}

export default TeamCodeProfile
