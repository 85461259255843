import cn from "classnames"
import { times } from "lodash-es"
import { styled } from "styled-components"

import { View } from "ui"

const ITEM_SIZE_MOBILE = 24
const ITEM_SPACE_MOBILE = 6

const ITEM_SIZE_TABLET = 40
const ITEM_SPACE_TABLET = 8

const NumPeopleCompletedProgressBar = styled(function NumPeopleCompletedProgressBar({
  current,
  total,
  startText,
  endText,
  maxCircles = 20,
  className,
}) {
  return (
    <div className={className}>
      <View $flexWrap="wrap" className="overflow-hidden">
        {times(Math.min(total, maxCircles), (index) => (
          <View
            key={index}
            $justifyContent="center"
            $alignItems="center"
            className={cn("progress-item text-semi-bold mb-xs border border-gray-5", {
              "active text-semi-bold text-fg bg-rising-green": index < current,
              "text-thin text-gray-8 fg": !(index < current),
            })}
          >
            {index + 1}
            <div className="progress-item-bar text-gray-2 bg-gray-2 border border-gray-5" />
          </View>
        ))}
      </View>
      {total > 1 && (
        <View $justifyContent="space-between">
          <span className="start-text">{startText}</span>
          <span className="end-text">{endText}</span>
        </View>
      )}
    </div>
  )
})`
  position: relative;
  z-index: var(--z-above-zero);
  display: inline-block;
  font-size: 0.75rem;
  line-height: 1.5rem;

  .progress-item {
    position: relative;
    border-radius: 50%;

    @media (max-width: ${({ theme }) => theme.mobileLandscapeMax}) {
      width: ${ITEM_SIZE_MOBILE}px;
      height: ${ITEM_SIZE_MOBILE}px;
      margin-right: ${ITEM_SPACE_MOBILE}px;

      .progress-item-bar {
        height: ${ITEM_SPACE_MOBILE}px;
      }
    }

    @media (min-width: ${({ theme }) => theme.tabletMin}) {
      width: ${ITEM_SIZE_TABLET}px;
      height: ${ITEM_SIZE_TABLET}px;
      margin-right: ${ITEM_SPACE_TABLET}px;

      .progress-item-bar {
        height: ${ITEM_SPACE_TABLET}px;
      }
    }

    &:last-child {
      margin-right: 0;
    }

    .progress-item-bar {
      position: absolute;
      z-index: var(--z-below-zero);
      top: 50%;
      right: 50%;
      width: 100%;
      transform: translateY(-50%);
    }
  }

  .start-text {
    width: 60%;
  }
`

NumPeopleCompletedProgressBar.defaultProps = {
  startText: "Hang tight",
  endText: "GO!",
}

export default NumPeopleCompletedProgressBar
