import cn from "classnames"

import { Loading, View } from "ui"
import RTBar from "ui/RTBar"
import { useCurrentTheme } from "ui/ThemeUpdateContext"

const RadioGroupFieldAggregatedMultiUserStackedBar = ({
  identifier,
  useAggregatedResultsData,
  has_sensitive_data,
  additional_data,
  option_colors,
  className,
}) => {
  const { data: aggregatedResults, isFetching } = useAggregatedResultsData({
    component: "RadioGroupFieldAggregatedMultiUserStackedBar",
    identifiers: [identifier],
    has_sensitive_data,
    additional_data,
  })
  const theme = useCurrentTheme()

  if (!aggregatedResults && isFetching) {
    return <Loading />
  }

  if (!aggregatedResults) {
    return null
  }

  if (aggregatedResults.insufficient_data) {
    return (
      <p className="p-large bg-gray-1">
        To maintain anonymity, we only share results when there are at least three team members with complete results.
      </p>
    )
  }

  const optionsResults = Object.values(aggregatedResults.results)
  const totalVotes = optionsResults.reduce((acc, { votes }) => acc + votes, 0)

  return (
    <View aria-hidden className={cn("full-width align-self-center", className)}>
      {optionsResults.map(({ identifier, votes, first_20_usernames }, idx) => {
        const color = option_colors[idx]
        const percentage = (votes / totalVotes) * 100
        const namesStr = (votes <= 20 ? first_20_usernames : [...first_20_usernames, "..."]).join(", ")
        const tooltip = (
          <>
            <b>Total {votes}:</b> {namesStr}
          </>
        )

        return (
          percentage > 0 && (
            <RTBar
              key={identifier}
              color={color}
              height={theme.isWidescreen ? "48px" : "24px"}
              width={`${percentage}%`}
              tooltip={tooltip}
            />
          )
        )
      })}
    </View>
  )
}

export default RadioGroupFieldAggregatedMultiUserStackedBar
