import { useNavigate } from "react-router"

import { useIsSchoolAccountType } from "components/AccountTypeContext"
import { TeamSelector } from "components/TeamSelector"
import OnboardingProfilePage from "domains/GetStarted/CreateAccount/OnboardingProfilePage"
import SchoolOnboardingProfilePage from "domains/GetStarted/CreateAccount/SchoolOnboardingProfilePage"
import { handleErrors } from "forms"
import { useTeam } from "resources/teams"
import { useUpdateCurrentUser, useUser } from "resources/users"
import { View } from "ui"
import { useSelectedTeamIdWithTeamIdQueryParam } from "ui/SelectedTeamContext"

const KitsHomeTeamLeadOnboarding = () => {
  const { selectedTeamId } = useSelectedTeamIdWithTeamIdQueryParam()
  const { data: selectedTeam } = useTeam({ teamId: selectedTeamId })
  const { data: user } = useUser({ userId: "me" })
  const isSchool = useIsSchoolAccountType()
  const { mutateAsync: updateCurrentUser } = useUpdateCurrentUser()
  const navigate = useNavigate()

  if (!user || !selectedTeam) {
    return null
  }

  const onTeamChange = ({ value }) => {
    navigate(`/kit?team_id=${value}`)
  }

  const onSubmit = handleErrors(async (values) => {
    await updateCurrentUser({
      profile: values,
      team_lead_onboarding_complete: true,
    })
    navigate(`/kit?team_id=${selectedTeamId}`)
  })

  const ProfilePage = isSchool ? SchoolOnboardingProfilePage : OnboardingProfilePage

  return (
    <main className="align-left full-width">
      <div className="main-container">
        <View $justifyContent="space-between" $alignItems="center" $flexWrap="wrap" className="mb-medium">
          <h1>Kits</h1>
          <TeamSelector selectedTeam={selectedTeam} onTeamChange={onTeamChange} user={user} />
        </View>
        <ProfilePage
          user={user}
          subtext="Welcome to your new team! To help us design your experience, tell us more about you and your team."
          onSubmit={onSubmit}
          nextButtonText="Submit"
          hideStripes={true}
        />
      </div>
    </main>
  )
}

export default KitsHomeTeamLeadOnboarding
