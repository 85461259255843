import cn from "classnames"
import { FormikContext } from "formik"
import { useContext, useCallback } from "react"
import { useNavigate } from "react-router"
import { styled } from "styled-components"

import { useSteps } from "./StepsContext"

import { BackButton, NextButton, View } from "ui"
import { useKeyDown, useWindowSize } from "ui/hooks"

async function formSubmitCallback(formikContext, onNavigate, { preventNavigationOnFormError = true } = {}) {
  let cancelNavigation = false
  if (formikContext) {
    const formErrors = await formikContext.validateForm()
    if (preventNavigationOnFormError) {
      cancelNavigation = typeof formErrors === "object" && !!Object.entries(formErrors).length
    }
    // Submit the form automatically during navigation:
    await formikContext.submitForm()
    // (must submit whether or not there are form errors, to display field warnings)
  }
  if (!cancelNavigation) {
    await onNavigate?.() // execute original onNavigate if it was provided
  }
  return cancelNavigation
}

function StepNavigationNextButton({ submit, onNavigate, hide = false, ...props }) {
  const { next } = useSteps()
  const navigate = useNavigate()
  const formikContext = useContext(FormikContext)

  // if step includes a form, wire up form submit on [next] button press:
  const onNavigateSubmitForm = useCallback(
    () =>
      formSubmitCallback(formikContext, onNavigate, {
        preventNavigationOnFormError: true,
      }),
    [formikContext, onNavigate]
  )

  // keyboard-nav for steps: cmd/win + right-arrow --> NEXT
  // matching standard browser behavior of cmd/win + left-arrow --> BACK
  useKeyDown(
    {
      key: "ArrowRight",
      meta: true,
      skipIfTextFieldFocused: true,
      // if a text element is focused, navigation away might cause unsaved content loss
    },
    async () => {
      const cancelNavigation = await onNavigateSubmitForm()
      if (next && !cancelNavigation && !hide) {
        navigate(next)
      }
    }
  )

  return (next || submit) && !hide ? (
    <NextButton navigateTo={next} onNavigate={onNavigateSubmitForm} submit={submit} {...props} />
  ) : (
    <div />
  )
}

function StepNavigationBackButton({ onNavigate, hide = false, ...props }) {
  const { previous } = useSteps()
  const navigate = useNavigate()
  const formikContext = useContext(FormikContext)

  // if step includes a form, wire up form submit on [back] button press:
  const onNavigateSubmitForm = useCallback(
    () =>
      formSubmitCallback(formikContext, onNavigate, {
        preventNavigationOnFormError: false,
        // We always want to allow navigation back, whether or not there are
        // form errors; otherwise the user can get "stuck" in a part of the kit.
      }),
    [formikContext, onNavigate]
  )

  // keyboard-nav for steps: cmd/win + shift + left-arrow --> PREV
  // matching standard browser behavior of cmd/win + left-arrow --> BACK
  useKeyDown(
    {
      key: "ArrowLeft",
      meta: true,
      shift: true,
      preventDefault: false,
      stopPropagation: false,
      skipIfTextFieldFocused: true,
      // if a text element is focused, navigation away might cause unsaved content loss
    },
    async (ev) => {
      // only prevent standard browser-history nav if a previous step exists
      if (previous && !hide) {
        ev.preventDefault() // prevent standard browser-history nav
        await onNavigateSubmitForm()
        // don't check 'cancelNavigation' result here,
        // since back-navigation should never be cancelled
        navigate(previous)
      }
    }
  )

  return previous && !hide ? (
    <BackButton className="secondary" navigateTo={previous} onNavigate={onNavigateSubmitForm} {...props} />
  ) : (
    <div />
  )
}

const StepNavigation = styled(function StepNavigation({
  children,
  className,
  nextText,
  onNext,
  onBack,
  nextButtonProps = {},
  backButtonProps = {},
  rightContent,
}) {
  const { previous } = useSteps()
  const { isTabletOrSmaller } = useWindowSize()

  return (
    <div className={cn(className, { "no-previous-step": !previous })}>
      {!!previous && <div />}
      <View $justifyContent={previous ? "center" : "flex-start"} data-testid="session-step-navigation">
        {children ? (
          children
        ) : (
          <>
            <StepNavigationBackButton onNavigate={onBack} {...backButtonProps} />
            <StepNavigationNextButton text={nextText} onNavigate={onNext} {...nextButtonProps} />
          </>
        )}
      </View>
      <div className={cn("ml-auto", { "mt-large": isTabletOrSmaller })}>{rightContent}</div>
    </div>
  )
})`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  justify-content: center;
  width: 100%;
  min-height: 36px;

  &.no-previous-step {
    grid-template-columns: auto 1fr;
  }

  @media (max-width: ${({ theme }) => theme.mobileLandscapeMax}) {
    margin-top: 32px;
    margin-bottom: 8px; // Combines with main-container padding to make 32px
  }
  @media (max-width: ${({ theme }) => theme.tabletMax}) {
    grid-template-columns: 1fr;
  }
  @media (min-width: ${({ theme }) => theme.tabletMin}) {
    margin-top: 48px;
    margin-bottom: 8px; // Combines with main-container padding to make 48px
  }
`

export default StepNavigation

export { StepNavigationNextButton, StepNavigationBackButton, StepNavigation }
