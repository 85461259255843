import cn from "classnames"
import { useState } from "react"
import { styled } from "styled-components"

import { useEffectAfterChange } from "./hooks"
import View from "./View"

const COUNTDOWN_CONTENT = [
  {
    value: 0,
    label: "Go",
    color: "var(--green-3)",
  },
  {
    value: 1,
    label: "Set",
    color: "var(--yellow-2)",
  },
  {
    value: 2,
    label: "Ready",
    color: "var(--orange-3)",
  },
]

const CountdownTimer = ({ fromSeconds = 3, countFinished, className }) => {
  const [seconds, setSeconds] = useState(fromSeconds)

  useEffectAfterChange(() => {
    const triggerCountFinished = () => {
      setTimeout(() => {
        countFinished()
      }, 0)
    }

    const intervalId = setInterval(() => {
      setSeconds((prevSeconds) => {
        const newSeconds = prevSeconds - 1

        if (newSeconds < 0) {
          clearInterval(intervalId)
          countFinished && triggerCountFinished()
          return 0
        }

        return newSeconds
      })
    }, 1000)

    return () => clearInterval(intervalId)
  }, [countFinished, fromSeconds])

  const getColorFromProgress = () => {
    const content = COUNTDOWN_CONTENT.find((item) => item.value === seconds)
    return content ? content.color : ""
  }

  const getLabelFromSeconds = () => {
    const content = COUNTDOWN_CONTENT.find((item) => item.value === seconds)
    return content ? content.label : ""
  }

  return (
    <View
      $flexDirection="column"
      $justifyContent="center"
      $alignItems="center"
      $width="100%"
      $height="100%"
      $minHeight="400px"
      $gap="24px"
      className={cn(className, " pb-large mx-auto")}
    >
      <h1 className="text-huge text-bold" style={{ color: getColorFromProgress() }}>
        {seconds + 1}
      </h1>
      <h2 className="text-large text-semi-bold">{getLabelFromSeconds()}</h2>
    </View>
  )
}

export default styled(CountdownTimer)`
  border: 1px solid var(--gray-4);
  border-top: 64px solid var(--gray-4);
  border-radius: 8px;
`
