import cn from "classnames"
import { styled } from "styled-components"

import { LogoIcon, LogoTextIcon } from "../icons"

import View from "./View"

import { useIsSchoolAccountType } from "components/AccountTypeContext"

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 16px;
  font-size: 16px;

  .name {
    margin-left: 16px;
    font-size: 16px;
    font-weight: bold;
    color: var(--default);
  }

  &.column {
    flex-direction: column;
    align-items: flex-start;
    font-weight: bold;
    font-size: 24px;
    margin-top: 12px;
    margin-left: 0;
  }

  .logo-text-container {
    margin-left: var(--spacing-3);
  }

  .tag-container {
    margin-top: -10px;
  }

  .schools-tag-container {
    margin-top: -15px;
    margin-right: 4px;
  }
`

const Logo = ({ layout = "row", showName = false }) => {
  const isSchool = useIsSchoolAccountType()
  const isRow = layout === "row"

  return (
    <StyledWrapper className={cn("logo-container", { column: !isRow })}>
      <LogoIcon className="logo" />
      {!!showName && !isSchool && (
        <>
          <View className="logo-text-container" $flexDirection="column">
            <LogoTextIcon className="logo-text" />
            <div className="tag-container hide-on-mobile">
              <span className="text-xs text-rising-orange ml-xxs">Together we rise</span>
            </div>
          </View>
        </>
      )}
      {!!showName && !!isSchool && (
        <>
          <View className="logo-text-container" $flexDirection="column" $alignItems="end">
            <LogoTextIcon className="logo-text" />
            <div className="schools-tag-container">
              <span className="text-xs text-rising-orange ml-xxs">for schools</span>
            </div>
          </View>
        </>
      )}
    </StyledWrapper>
  )
}

export default Logo
