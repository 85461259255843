import { Form, Formik } from "formik"
import { pick } from "lodash-es"
import { useState } from "react"
import { Link } from "react-router-dom"

import { Yup, handleErrors, FormMessage } from "forms"
import { EmailField } from "forms/fields"
import { userSchemas } from "forms/yup"
import { freePromoKitSignup } from "resources/billing"
import { PageTitle, SubmitButton, View } from "ui"
import MailToLink from "ui/MailToLink"
import { buildUrl } from "utils/string"

const CSAOrgIntegration = () => (
  <FreeKitSignUp
    promo_name="csa_org_2023"
    title="Free Crisis Response kit for Chief of Staff Association"
    description="Thank you for attending the talk on Managing Workplace Crises. Enter your email to get access to your free Rising Team Crisis Response kit."
    kitName="Crisis Response"
  />
)

const Cornell2023Integration = () => (
  <FreeKitSignUp
    promo_name="cornell_2023"
    title="Free Motivators kit for Cornell Tech"
    description="Thank you for attending the talk on Leadership for an AI-enabled future. Enter your email to get access to your free Rising Team Motivators kit."
    kitName="Motivators"
  />
)

const FreeKitSignUp = ({ promo_name, title, description, kitName }) => {
  const [status, setStatus] = useState()
  const initialValues = { promo_name, email: "" }
  const schema = Yup.object().shape(pick(userSchemas, ["email"]))
  const onSubmit = handleErrors(async (values) => {
    const data = await freePromoKitSignup(values)
    setStatus(data.status)
  })

  return (
    <View>
      <div className="main-container full-width">
        <PageTitle>{title}</PageTitle>
        <div>
          <h1 className="text-gray-9 mb-large">{title}</h1>
          <p className="text-gray-9 mb-medium">{description}</p>
          <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={schema}>
            {({ values }) => (
              <>
                {!status ? (
                  <Form className="space-y-large">
                    <EmailField
                      label="Work email address"
                      name="email"
                      size="medium"
                      placeholder="Enter your work email here"
                    />
                    <SubmitButton />
                    <FormMessage />
                  </Form>
                ) : status === "already_has_kit" ? (
                  <>
                    You already have the {kitName} kit!{" "}
                    <Link to={buildUrl(["auth", "login"], { urlQueryParams: { email: values.email } })}>Log in</Link> to
                    access it.
                  </>
                ) : status === "emailed" ? (
                  <>
                    Submission received! Check your email for an invitation to your free kit from{" "}
                    <a href="mailto:jen@risingteam.com" target="_blank" rel="noopener noreferrer">
                      jen@risingteam.com
                    </a>
                  </>
                ) : status === "error" ? (
                  <>
                    Something went wrong. We will reach out to you. If you don't hear from us please contact
                    <MailToLink email="support@risingteam.com" />.
                  </>
                ) : (
                  <>
                    Something went wrong. Please try again or contact <MailToLink email="support@risingteam.com" />.
                  </>
                )}
              </>
            )}
          </Formik>
        </div>
      </div>
    </View>
  )
}

export { CSAOrgIntegration, Cornell2023Integration }
