import { kebabCase } from "lodash-es"
import { useRef, useState } from "react"

import { Button, View, TextInput } from "ui"
import { useEffectAfterChange } from "ui/hooks"

const CareerNewsHeadlines = ({ hideDescription }) => {
  const canvasRef = useRef()
  const [headline, setHeadline] = useState("Insert your future headline here!")
  const [subline, setSubline] = useState("Sample of ticker text with the latest update...")
  const [fileURL, setFileURL] = useState()
  const [downloadURL, setDownloadURL] = useState()
  const [futureDate, setDate] = useState("dd/mm/yyyy")

  useEffectAfterChange(() => {
    const canvas = canvasRef.current

    const onFinish = () => setDownloadURL(canvas.toDataURL("image/png"))
    drawCanvas({ canvas, headline, subline, futureDate, fileURL, onFinish })
  }, [headline, subline, futureDate, fileURL])

  const onFileChange = (e) => {
    const file = e.target.files[0]
    if (!file) {
      return
    }

    document.getElementById("uploadedFileName").innerHTML = file.name
    setFileURL(URL.createObjectURL(file))
  }

  const downloadFileName = `${kebabCase(headline)}.png`

  const uploadImage = () => {
    const fileUpload = document.getElementById("file")
    fileUpload.click()
  }

  return (
    <div>
      {!hideDescription && (
        <div className="mt-medium mb-large">
          We hope you enjoyed the Career Horizons session. Do you remember the headline you created during the Warmup?
          Enter it into the form below to create a reminder of that fun and ambitious vision for the future. Share the
          image with your friends and family, or with us here at Rising Team! Sharing goals is proven to make achieving
          them more likely and encourages others to dream bigger as well.{" "}
        </div>
      )}
      <div style={{ height: 400, width: 700 }}>
        <canvas ref={canvasRef} width={700} height={400} />
      </div>
      <hr></hr>
      <div> </div>
      <div className="space-y-medium">
        <div>
          <span></span>
        </div>
        <View $flexDirection="row" style={{ width: "100%" }}>
          <div style={{ width: "25%" }}>
            <div>
              <span className="text-field-label mt-xl text-bold">Upload your image</span>
            </div>
            <div></div>
            <Button className="tertiary small mt-medium" onClick={uploadImage}>
              Upload Image
            </Button>

            <input type="file" id="file" style={{ display: "none" }} onChange={onFileChange} />
          </div>

          <div style={{ width: "34%" }} className="mt-medium text-normal">
            <div>
              <br />
            </div>
            <span id="uploadedFileName">No image uploaded</span>
          </div>

          <div style={{ width: "41%" }}>
            <div>
              <span className="text-field-label mt-xl text-bold">Enter future date</span>
            </div>
            <TextInput className="mt-small" type="text" value={futureDate} onChange={(e) => setDate(e.target.value)} />
          </div>
        </View>

        <div>
          <span className="text-field-label mt-xl text-bold">Headline</span>
        </div>
        <TextInput
          type="text"
          maxLength={40}
          multiline={true}
          value={headline}
          onChange={(e) => setHeadline(e.target.value)}
        />
        <div className="text-gray-6 pl-xxs text-small">Max length of 45 characters</div>
        <div>
          <span className="text-field-label mt-xl text-bold">Ticker Line</span>
        </div>
        <TextInput type="text" maxLength={60} value={subline} onChange={(e) => setSubline(e.target.value)} />
        <div className="text-gray-6 pl-xxs text-small">Max length of 60 characters</div>

        <div>
          <Button
            as="a"
            className="inline-block mt-medium"
            download={downloadFileName}
            href={downloadURL}
            disabled={!downloadURL}
          >
            Download
          </Button>
        </div>
      </div>
    </div>
  )
}

const drawCanvas = ({ canvas, headline, subline, fileURL, futureDate, onFinish }) => {
  const context = canvas.getContext("2d")
  //render image
  const background = new Image()
  if (fileURL) {
    background.src = fileURL
  } else {
    background.crossOrigin = "anonymous"
    background.src = "https://static.risingteam.com/kit_assets/career-horizons/bonus-images/default-image-icon.png"
  }

  background.onload = () => {
    context.drawImage(background, 0, 0, 700, 400)

    const logoImage = new Image()
    logoImage.crossOrigin = "anonymous"
    logoImage.src = "https://static.risingteam.com/kit_assets/career-horizons/bonus-images/logo-white-200px.png"
    logoImage.onload = () => {
      context.drawImage(logoImage, 635, 26, 35, 35)

      // headline rhombus
      context.fillStyle = "#ffffff"
      context.beginPath()
      context.moveTo(50, 310)
      context.lineTo(670, 310)
      context.lineTo(650, 375)
      context.lineTo(70, 375)
      context.fill()
      context.fillStyle = "#000000"
      context.font = "italic bold 24px Arial"
      context.fillText(headline, 212, 340)

      context.fillStyle = "#4a4a4a"
      context.beginPath()
      context.moveTo(50, 350)
      context.lineTo(658, 350)
      context.lineTo(651, 375)
      context.lineTo(70, 375)
      context.fill()
      context.fillStyle = "#ffffff"
      context.font = "bold 16px Arial"
      context.fillText(subline, 210, 368)

      // render Breaking News rhombus
      context.fillStyle = "#ab0710"
      context.beginPath()
      context.moveTo(50, 300)
      context.lineTo(200, 300)
      context.lineTo(180, 385)
      context.lineTo(30, 385)
      context.fillStyle = "#f52727"
      context.fill()

      context.fillStyle = "#b82121"
      context.beginPath()
      context.moveTo(30, 385)
      context.lineTo(180, 385)
      context.lineTo(186, 360)
      context.lineTo(36, 360)
      context.fill()

      context.font = "italic 13px Arial"
      context.fillStyle = "#ffffff"
      context.fillText("2:25 PM - " + futureDate?.replaceAll("/", "."), 43, 377)

      // render BREAKING NEWS on rhombus
      context.fillStyle = "#ffffff"
      context.font = "italic bold 23px Arial"
      context.fillText("BREAKING", 60, 325)
      context.font = "italic bold 36px Arial"
      context.fillText("NEWS", 61, 354)

      onFinish()
    }
  }
}
export default CareerNewsHeadlines
