import { useQueryClient } from "@tanstack/react-query"
import { Navigate, Route } from "react-router"

import { useScrollToTopContext } from "components/ScrollToTop"
import RouteSteps from "components/Steps"
import SetupComplete from "domains/Setup/SetupComplete"
import SetupHome from "domains/Setup/SetupHome"
import SetupIncomplete from "domains/Setup/SetupIncomplete"
import allSteps from "domains/Setup/steps"
import { useUser, meUserCacheKey } from "resources/users"
import { PageTitle, Loading } from "ui"
import { useEffectAfterFirstRender, useEffectAfterLocationChange } from "ui/hooks"
import useFeatures, { FLAGS } from "ui/hooks/useFeatures"

function getStepPaths(step) {
  return step.components.map((_, idx) =>
    step.components.length <= 1 ? step.path : `${step.path}-${step.subPathSuffixes?.[idx] ?? idx + 1}`
  )
}

const Setup = () => {
  const queryClient = useQueryClient()
  const { data: user, isInitialLoading: isUserLoading } = useUser({ userId: "me" })
  const { setNoScrollToTopForPaths, resetNoScrollToTop } = useScrollToTopContext()

  useEffectAfterFirstRender(() => {
    setNoScrollToTopForPaths(allSteps.map(({ path }) => path))
    return () => resetNoScrollToTop()
  })

  // Refetch user data after every route change within /setup, to ensure we always
  // show up-to-date step completed status after user navigates between steps:
  useEffectAfterLocationChange(() => {
    queryClient.refetchQueries({ queryKey: meUserCacheKey })
  })

  // TODO(onboarding-launch): remove lines below
  const features = useFeatures()
  if (features.isFetching) {
    return <Loading />
  }
  if (!features[FLAGS.RTDEV_ONBOARDING]) {
    return <Navigate to="/kit" replace />
  }
  // TODO(onboarding-launch): remove lines above

  if (isUserLoading || !user?.setup_steps) {
    return <Loading />
  }

  // Combine step data from backend and frontend:
  const allStepMap = Object.fromEntries(allSteps.map((s) => [s.path, s]))
  const userSteps = user.setup_steps.map((step) => ({
    ...step,
    ...(allStepMap[step.path] ?? {}),
  }))

  const firstStep = userSteps[0]
  const firstStepPath = getStepPaths(firstStep)[0]

  const firstIncompleteStep = userSteps.find((step) => !step.completed)
  const firstIncompleteStepPath = firstIncompleteStep ? getStepPaths(firstIncompleteStep)[0] : null

  return (
    <>
      <PageTitle>Setup</PageTitle>
      <RouteSteps>
        {userSteps.flatMap((step) => {
          const stepPaths = getStepPaths(step)
          return [
            ...step.components.map((_, idx) => (
              <Route
                key={stepPaths[idx]}
                path={stepPaths[idx]}
                element={<SetupHome user={user} steps={userSteps} step={step} subStepIndex={idx} />}
              />
            )),
            // If there are multiple step components, include a generic route for the
            // base step.path which redirects to the first of the step's components:
            ...(step.components.length <= 1
              ? []
              : [
                  <Route
                    ignore
                    key={step.path}
                    path={step.path}
                    element={<Navigate replace to={`../${stepPaths[0]}`} />}
                  />,
                ]),
          ]
        })}
        <Route ignore path="/complete" element={<SetupComplete steps={userSteps} />} />
        <Route ignore path="/incomplete" element={<SetupIncomplete steps={userSteps} />} />
        {/* When navigating to /setup base url, redirect to first incomplete step: */}
        <Route ignore path="*" element={<Navigate replace to={firstIncompleteStepPath ?? firstStepPath} />} />
      </RouteSteps>
    </>
  )
}

export default Setup
