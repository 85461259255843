import { StepContent } from "../components"
import { useKitSession } from "../KitSessionContext"

import { getTeamLead } from "domains/Exercise/results_utils"
import { useHasTeamFeature } from "domains/Results/utils"
import { ExclamationCircleIcon } from "icons/FontAwesomeIcons"
import { useKitParticipants } from "resources/monthly_kit"
import { TextContainer, BulletedList, Loading } from "ui"
import { SHARED_FLAGS } from "ui/hooks/useFeatures"

const CrisisResponseScenarioThinkTeamMemberStep = ({ className }) => {
  const { kitInstance, team } = useKitSession()
  const { enabled: sessionRealtimeUpdatesV2 } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_REPLACE_POLLING)
  const { data: participants } = useKitParticipants({ kitInstance, sessionRealtimeUpdates: sessionRealtimeUpdatesV2 })

  if (!participants) {
    return <Loading />
  }

  const teamLead = getTeamLead(participants, team)

  return (
    <StepContent className={className}>
      <TextContainer maxWidth={660} className="text-gray-9">
        <h2 className="mb-medium">Think of a scenario</h2>
        <p className="mb-medium">
          Work as a team to identify a possible future crisis scenario that we will use to create a response plan.
        </p>
        <p className="mb-medium">
          Pick a scenario from your own workplace that is big enough to be interesting and has some potential
          constructive actions you can take.
        </p>

        <p className="mb-medium">Here are example scenarios that work well for this exercise:</p>
        <BulletedList className="mb-medium">
          <li>Your website goes down or gets hacked</li>
          <li>A critical team member leaves suddenly</li>
          <li>A project you invested a lot into gets cancelled to pursue something new</li>
        </BulletedList>
        <p className="text-field-label text-gray-9 mb-xs">
          <ExclamationCircleIcon color="var(--rising-yellow)" className="mr-xs" />
          {teamLead.name} will synthesize and capture the response for the group.
        </p>
        <p className="text-gray-8 mb-xs">Ask them to share their screen (if they are not already).</p>
      </TextContainer>
    </StepContent>
  )
}

export default CrisisResponseScenarioThinkTeamMemberStep
