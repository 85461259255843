import { Navigate } from "react-router"

import MiniKitsTableOfContents from "./MiniKitsTableofContents"

import { useTeam } from "resources/teams"
import { useUser } from "resources/users"
import { useSelectedTeamIdWithTeamIdQueryParam } from "ui/SelectedTeamContext"

const MiniKitHome = () => {
  const { selectedTeamId, redirectTeamId, needsRedirect } = useSelectedTeamIdWithTeamIdQueryParam()
  const { data: selectedTeam } = useTeam({ teamId: selectedTeamId })
  const { data: user } = useUser({ userId: "me" })

  if (redirectTeamId) {
    return <Navigate to={`/minis?team_id=${redirectTeamId}`} replace />
  }

  if (!user || !selectedTeam || needsRedirect) {
    return null
  }

  return (
    <main className="align-left full-width">
      <div className="main-container">
        <MiniKitsTableOfContents user={user} selectedTeam={selectedTeam} />
      </div>
    </main>
  )
}

export default MiniKitHome
