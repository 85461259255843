import { groupBy, mapValues } from "lodash-es"
import { useMemo, useState, useCallback } from "react"
import { Link, useNavigate, useLocation } from "react-router-dom"
import { styled } from "styled-components"

import { useIsMenziesAccountType } from "components/AccountTypeContext"
import TeamDetailsPanel from "components/TeamDetailsPanel"
import { TeamSelector, ScheduleModeTeamSelector } from "components/TeamSelector"
import { useLatestUserExerciseInstances } from "domains/Exercise/resource"
import { KitFilters, KitTile, ScheduleNext } from "domains/LeaderKit/KitsTableofContents/components"
import ScheduleModeToggle from "domains/LeaderKit/KitsTableofContents/ScheduleModeToggle"
import { getKitStatus, KIT_STATUS } from "domains/LeaderKit/KitsTableofContents/utils"
import { ArrowUpRightFromSquareIcon } from "icons/FontAwesomeIcons"
import { useGetUserAccount } from "resources/billing"
import { useBasicKitInfos, useScheduleNextKits, useTeamKitInstances, useUnavailableKits } from "resources/monthly_kit"
import { CalloutName, useGetCalloutStatus, useUpdateCalloutStatus } from "resources/users"
import { View, Button, Loading, HorizontalRule } from "ui"
import { useWindowSize, useEffectAfterChange } from "ui/hooks"
import useFeatures, { FLAGS, ACCOUNT_FLAGS } from "ui/hooks/useFeatures"
import { useModal } from "ui/ModalContext"
import { plural } from "utils/string"

const KitsTableOfContents = ({ user, selectedTeam }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const flags = useFeatures()
  const [isScheduleModeActive, setIsScheduleModeActive] = useState(!!location?.state?.fetchScheduleModeStatus)
  const { data: kitInstances } = useTeamKitInstances({ teamId: selectedTeam.id })
  const { data: basicKits } = useBasicKitInfos({ teamId: selectedTeam.id })
  const { data: unavailableKits } = useUnavailableKits({ teamId: selectedTeam.id })

  const { data: exerciseInstances } = useLatestUserExerciseInstances({
    teamId: selectedTeam.id,
    userId: user.id,
    enabled: !isScheduleModeActive,
  })
  const { data: scheduleNextKits } = useScheduleNextKits({ teamId: selectedTeam.id, enabled: !isScheduleModeActive })
  const isMenzies = useIsMenziesAccountType()
  const windowSize = useWindowSize()
  const { isMobileLandscapeOrLarger } = windowSize
  const [visibleKits, setVisibleKits] = useState([])
  const { data: userAccount } = useGetUserAccount(selectedTeam?.account_id)
  const isAdmin = user.can_manage_billing_for_an_account
  const isConnectAccount = !!userAccount?.is_connect_account
  const isCurrentTeamLead = selectedTeam.team_lead_id === user.id
  const isTrial = !!userAccount?.is_trial
  const hasArtiAccess = !!user.has_arti_access

  const calloutName = CalloutName.ARTI_INFO_MODAL
  const { data: hasViewedArtiInfoModal, isFetching: isFetchingHasViewedArtiInfoModal } = useGetCalloutStatus({
    userId: user.id,
    calloutName,
    enabled: hasArtiAccess,
  })
  const { mutateAsync: updateArtiInfoModalStatus } = useUpdateCalloutStatus({ userId: user.id, calloutName })
  const { setModal } = useModal()
  const showArtiInfoModal = useCallback(() => {
    setModal({
      title: "Meet aRTi",
      headerImage: "https://static.risingteam.com/rtai/arti-splash-image.jpg",
      content: <ArtiInfoModal closeModal={() => setModal(null)} />,
      maxWidth: "650px",
      maxHeight: "90vh",
    })
  }, [setModal])

  useEffectAfterChange(() => {
    if (hasArtiAccess && !isFetchingHasViewedArtiInfoModal && !hasViewedArtiInfoModal) {
      showArtiInfoModal()
      updateArtiInfoModalStatus({ hasViewedCallout: true })
    }
  }, [
    user,
    hasArtiAccess,
    showArtiInfoModal,
    hasViewedArtiInfoModal,
    isFetchingHasViewedArtiInfoModal,
    updateArtiInfoModalStatus,
  ])

  // Need useMemo otherwise this computation will slow down the page
  const kitInstanceMap = useMemo(
    () => mapValues(groupBy(kitInstances, "slug"), (instances) => instances[0]),
    [kitInstances]
  )

  // Need useMemo otherwise this computation will slow down the page
  const exerciseInstanceMap = useMemo(
    () => mapValues(groupBy(exerciseInstances, "slug"), (instances) => instances[0]),
    [exerciseInstances]
  )

  const onTeamChange = ({ value }) => {
    navigate(`/kit?team_id=${value}`, { state: { fetchScheduleModeStatus: isScheduleModeActive } })
  }

  const onSwitchScheduleMode = () => {
    setIsScheduleModeActive(!isScheduleModeActive)
  }

  if (!kitInstances || !basicKits || !unavailableKits || !userAccount) {
    return <Loading />
  }

  const hasMoreThan3Kits = basicKits.length > 3
  const isSHRMPromoAccount = !!userAccount?.features?.includes(ACCOUNT_FLAGS.SHRM_2023_PROMO)

  return (
    <>
      <View
        $flexDirectionMobile="column"
        $alignItems="center"
        $alignItemsMobile="flex-start"
        $gap="24px"
        $justifyContent="space-between"
        className="mb-medium"
      >
        <h1>Kits</h1>
        {!!isAdmin && (
          <ScheduleModeToggle
            isScheduleModeActive={!!isScheduleModeActive}
            onSwitchScheduleMode={onSwitchScheduleMode}
          />
        )}
      </View>
      {!!isCurrentTeamLead && !!isMobileLandscapeOrLarger && (
        <div className="mb-medium">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://risingteam.com/facilitator-guide"
            className="no-underline text-gray-7 text-semi-bold"
          >
            Facilitator guide <ArrowUpRightFromSquareIcon className="ml-xxs" />
          </a>
        </div>
      )}
      <div>
        {isSHRMPromoAccount && isCurrentTeamLead ? (
          <>
            Thanks for attending our SHRM session! Your FREE{" "}
            {plural(basicKits?.length, "kit is", { pluralForm: "kits are", showCount: false })} below and{" "}
            {plural(basicKits?.length, "is", { pluralForm: "are", showCount: false })} for use in small groups of 14 or
            fewer people. {plural(basicKits?.length, "It differs", { pluralForm: "They differ", showCount: false })}{" "}
            slightly from your SHRM experience. Questions? Reach us at{" "}
            <a href="mailto:hello@risingteam.com" target="_blank" rel="noopener noreferrer">
              hello@risingteam.com
            </a>
          </>
        ) : !!selectedTeam?.jumbo ? (
          <>Jumbo sessions are optimized for groups up to 1000 people.</>
        ) : (
          <>
            {isMenzies ? (
              <>
                Each Rising Team for Schools kit is based on research about collective efficacy and what drives
                successful teams in schools.{" "}
              </>
            ) : isConnectAccount ? (
              <>Each Rising Team Connection Kit is built to help your team connect more deeply on a human level. </>
            ) : (
              <>Each Rising Team kit is based on research about what drives successful teams. </>
            )}
            {isCurrentTeamLead ? (
              isMenzies ? (
                <>
                  We recommend starting with Psychological Safety and completing a session every 3-4 weeks to build
                  consistent growth and connection with your team.
                  <p className="mt-medium">Additional kits will become available next term.</p>
                </>
              ) : (
                <>
                  We recommend completing a session every 4-6 weeks to build consistent growth and connection with your
                  team.
                </>
              )
            ) : (
              <>
                If you missed a team session, you can complete the exercise portions of it by selecting “Take exercise”
                below.
              </>
            )}
          </>
        )}
        <>
          {!isScheduleModeActive ? (
            <TeamDetailsPanel
              TeamSelectorComponent={TeamSelector}
              selectedTeam={selectedTeam}
              onTeamChange={onTeamChange}
              user={user}
              showSingleTeam={true}
              showCreateNewTeam={!!flags[FLAGS.SHOW_CREATE_TEAM]}
            />
          ) : (
            <TeamDetailsPanel
              TeamSelectorComponent={ScheduleModeTeamSelector}
              selectedTeam={selectedTeam}
              onTeamChange={onTeamChange}
              user={user}
            />
          )}
        </>
      </div>
      <HorizontalRule margin="mb-small mt-none" height={1} />
      {!!scheduleNextKits && !!hasMoreThan3Kits && !selectedTeam?.jumbo && !isScheduleModeActive && (
        <ScheduleNext
          scheduleNextKits={scheduleNextKits}
          kitInstanceMap={kitInstanceMap}
          selectedTeam={selectedTeam}
          className="mb-xxl"
        />
      )}
      <KitFilters
        basicKits={basicKits}
        unavailableKits={unavailableKits}
        kitInstanceMap={kitInstanceMap}
        setVisibleKits={setVisibleKits}
        className="mb-medium"
        user={user}
      />
      <div className="full-width">
        <div className="space-y-medium">
          {visibleKits.map((kitInfo, index) => {
            const kitInstance = kitInstanceMap[kitInfo.slug]
            const kitStatus = getKitStatus(kitInstance, kitInfo)
            const exerciseInstance = exerciseInstanceMap[kitInfo.exercise.slug]
            const initiallyExpanded =
              isTrial || (!hasMoreThan3Kits && !kitInfo.unavailable) || kitStatus === KIT_STATUS.SCHEDULED

            return (
              <KitTile
                kitInfo={kitInfo}
                kitInstance={kitInstance}
                exerciseInstance={exerciseInstance}
                user={user}
                selectedTeam={selectedTeam}
                initiallyExpanded={initiallyExpanded}
                key={kitInfo.slug}
                index={index + 1}
                isConnectAccount={isConnectAccount}
                isScheduleModeActive={!!isScheduleModeActive}
                isCurrentTeamLead={isCurrentTeamLead}
              />
            )
          })}
        </div>
      </div>
    </>
  )
}

const ArtiInfoModal = ({ className, closeModal }) => (
  <div className={className}>
    <p className="mb-xl text-normal">
      aRTi is your personalized AI leadership coach, combining research-backed best practices with insights from Rising
      Team sessions. Experience how personalized coaching can transform your team&apos;s performance. Chat with aRTi
      today!
    </p>
    <View $justifyContent="center">
      <Link to="/arti" onClick={closeModal} className="mr-xxxl">
        <Button className="secondary">Chat now</Button>
      </Link>
      <Button className="text-button link-semi-bold" onClick={closeModal}>
        Maybe later
      </Button>
    </View>
  </div>
)

export default styled(KitsTableOfContents)`
  .kit-info-icon,
  .kit-info-tooltip {
    height: 16px;
    width: 16px;
  }

  @media (max-width: ${({ theme }) => theme.mobileMax}) {
    .learning-kits {
      flex: 1;
    }
  }
`
