import cn from "classnames"
import { get } from "lodash-es"

import { useSessionOrTeamExerciseInstances } from "domains/Exercise/resource"
import { getExerciseAnswer, getSortedExerciseAnswers } from "domains/Exercise/results_utils"
import { useKitSession } from "domains/KitSession/KitSessionContext"
import { useHasTeamFeature } from "domains/Results/utils"
import { FlexibleRowCheckboxGroupField } from "forms/fields"
import { Loading } from "ui"
import { TEAM_FLAGS } from "ui/hooks/useFeatures"

const TextReferenceCheckboxGroupReadOnlyField = ({
  identifier,
  text_field_id,
  kit,
  exerciseInstance,
  exerciseComponent,
  className,
}) => {
  const { team } = useKitSession()
  const { enabled: sessionRealtimeUpdates } = useHasTeamFeature(team, TEAM_FLAGS.RTDEV_REALTIME_ANSWER_UPDATE)
  const exerciseSlug = kit?.exercise?.slug ?? exerciseInstance?.slug
  const { data: exerciseInstances = [], isInitialLoading } = useSessionOrTeamExerciseInstances(exerciseSlug, {
    refetchInterval: 30000,
    sessionRealtimeUpdates,
  })

  if (isInitialLoading) {
    return <Loading />
  }

  const answer = getExerciseAnswer(exerciseInstance, identifier)
  const textFieldAnswers = getSortedExerciseAnswers(exerciseInstances, text_field_id)
  const componentOptions = exerciseComponent?.options ?? []

  return (
    <div className={cn("space-y-medium", className)}>
      {textFieldAnswers.map((textFieldAnswer) => {
        const textFieldAnswerValue = get(textFieldAnswer.data, ["value"], textFieldAnswer)
        const checkboxValues = get(answer[`answer_${textFieldAnswer.id}`], ["value"], [])
        const options = componentOptions.map((option) => ({
          ...option,
          checked: checkboxValues.includes(option.value),
        }))
        return (
          <div key={textFieldAnswer.id} className="border-bottom border-gray-5 pb-medium">
            <h4 className="mb-medium">{textFieldAnswerValue}</h4>
            <FlexibleRowCheckboxGroupField
              disabled
              name={`${identifier}.answer_${textFieldAnswer.id}.value`}
              options={options}
            />
          </div>
        )
      })}
    </div>
  )
}

export default TextReferenceCheckboxGroupReadOnlyField
