import { useField } from "formik"
import { useState } from "react"

import { SelectFavoriteStickyNotes } from "domains/KitSession/components/StickyNotes"
import Grid from "ui/Grid"
import { useEffectAfterFirstRender } from "ui/hooks"
import { plural } from "utils/string"

const USER_FAVORITE_STICKY_NOTE_LIMIT = 3

const ExerciseStickyNoteUserSelectFavorite = ({ className, name, saveOnChange }) => {
  const [{ value: userStickyNotes }, _, { setValue }] = useField(name)

  const [votesRemaining, setVotesRemaining] = useState(USER_FAVORITE_STICKY_NOTE_LIMIT)

  useEffectAfterFirstRender(() => {
    if (!!userStickyNotes?.length) {
      const initialUserStickyNotes = userStickyNotes
        ?.map((stickyNote) => ({ ...stickyNote, value: stickyNote?.value?.trim() }))
        ?.filter((stickyNote) => stickyNote?.value.length > 0)
      const initialVotesRemaining =
        USER_FAVORITE_STICKY_NOTE_LIMIT -
        (initialUserStickyNotes?.filter((stickyNote) => stickyNote?.isFavorite)?.length ?? 0)
      setVotesRemaining(initialVotesRemaining)
      setValue(initialUserStickyNotes)
      saveOnChange(name, initialUserStickyNotes)
    }
  })

  // Check with design what to do here
  if (!userStickyNotes || userStickyNotes.filter((stickyNote) => stickyNote.value.length > 0).length === 0) {
    return <h2>No sticky notes to display!</h2>
  }

  function handleOnFavoriteClick(userStickyNoteIndex) {
    const isCurrentlyUserFavorite = userStickyNotes[userStickyNoteIndex]?.isFavorite === true
    const favoriteUserStickyNotes = userStickyNotes.filter((stickyNote) => stickyNote?.isFavorite).length

    if (favoriteUserStickyNotes === USER_FAVORITE_STICKY_NOTE_LIMIT && !isCurrentlyUserFavorite) {
      return
    }

    const updatedUserStickyNotes = userStickyNotes.map((stickyNote, index) =>
      index === userStickyNoteIndex
        ? { ...userStickyNotes[userStickyNoteIndex], isFavorite: !isCurrentlyUserFavorite }
        : stickyNote
    )
    setVotesRemaining((prevVotesRemaining) =>
      isCurrentlyUserFavorite ? prevVotesRemaining + 1 : prevVotesRemaining - 1
    )
    setValue(updatedUserStickyNotes)
    saveOnChange(name, updatedUserStickyNotes)
  }

  return (
    <>
      <div className="text-semi-bold mb-medium">You have {plural(votesRemaining, "vote")} remaining.</div>
      <Grid className={className} $columns="3" $columnsMobile="1" $columnsTablet="2" $gap="12px">
        <SelectFavoriteStickyNotes
          name={name}
          stickyNotes={userStickyNotes}
          handleOnFavoriteClick={handleOnFavoriteClick}
        />
      </Grid>
    </>
  )
}

export default ExerciseStickyNoteUserSelectFavorite
