import { Route, Routes, Outlet, Navigate, useNavigate, useParams } from "react-router-dom"

import { useStartKitSession } from "resources/monthly_kit"
import { useTeam } from "resources/teams"
import { useUser } from "resources/users"
import { Loading } from "ui"
import { useEffectAfterChange } from "ui/hooks"
import { useKitInstanceByID } from "ui/SelectedTeamContext"

const StartSessionMain = () => {
  const { id } = useParams()
  const { kitInstance, isFetching: kitInstanceIsFetching } = useKitInstanceByID(id)
  const { data: user } = useUser({ userId: "me" })
  const { data: team } = useTeam({ teamId: kitInstance?.team_id })

  if (!kitInstance) {
    return kitInstanceIsFetching ? <Loading /> : <Navigate to="/code" replace />
  }

  if (!user || !team) {
    return null
  }

  if (team.preview) {
    return <Navigate to="/404" replace />
  }
  if (user.id !== team.team_lead_id) {
    return <Navigate to="/code" replace />
  }

  return <StartSessionTeamLead kitInstance={kitInstance} />
}

const StartSessionTeamLead = ({ kitInstance }) => {
  const { mutateAsync: startKitSession } = useStartKitSession()
  const navigate = useNavigate()

  useEffectAfterChange(() => {
    async function startAndRedirect() {
      await startKitSession({ kitInstanceId: kitInstance.id })
      navigate(kitInstance.session_url, { replace: true })
    }
    startAndRedirect()
  }, [kitInstance, startKitSession, navigate])

  return <Loading />
}

const StartSessionLayout = () => (
  <div className="main-container full-width">
    <Outlet />
  </div>
)

const StartSession = () => (
  <Routes>
    <Route element={<StartSessionLayout />}>
      <Route path="/:id" element={<StartSessionMain />} />
    </Route>
  </Routes>
)

export default StartSession
