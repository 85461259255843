import useEventListener from "@use-it/event-listener"
import { useLocation } from "react-router"
import { styled } from "styled-components"

import { Footer } from "components"
import FirebaseUI, { getFirebaseUIContainerElement } from "domains/Authentication/FirebaseUI"
import { LogoIcon } from "icons"
import { PageTitle, View, Background } from "ui"
import { onKeyEvent } from "utils/browser"

const SSOLogin = ({ className, provider, providerName, ssoRedirectLogin = false }) => {
  const { state } = useLocation()
  // On ENTER keypress, trigger SSO login flow:
  useEventListener(
    "keypress",
    onKeyEvent("enter", () => {
      getFirebaseUIContainerElement()?.querySelector("button")?.click()
    })
  )

  if (!provider || !providerName) {
    return null
  }

  const showLoginUI = !!state?.showLoginUI || !ssoRedirectLogin

  return (
    <>
      <PageTitle>Login</PageTitle>
      <Background className={className} $url="https://static.risingteam.com/kit_assets/login-bg.png">
        <View className="text-center main-container" $flex={1} $flexDirection="column" $alignItems="center">
          <View className="text-center" $flex={1} $flexDirection="column" $alignItems="center" $width="auto">
            <LogoIcon className="logo mb-large" />
            <h2 className="mb-xl text-bold">Welcome to Rising Team</h2>
            {!!showLoginUI && (
              <p className="mb-xl">
                Please click the button below to sign up
                <br /> or log in with your {providerName} account:
              </p>
            )}
            <FirebaseUI
              ssoRedirectLogin={ssoRedirectLogin}
              ssoProviders={[{ provider, providerName }]}
              hidden={!showLoginUI}
            />
          </View>
          <Footer />
        </View>
      </Background>
    </>
  )
}

export default styled(SSOLogin)`
  @media (max-width: ${({ theme }) => theme.mobileMax}) {
    background: var(--fg);
  }

  .main-container {
    margin: 80px auto 215px;
    padding: 50px 0 30px;
    max-width: 540px;
    background: white;
    opacity: 0.93;

    @media (min-width: ${({ theme }) => theme.mobileLandscapeMin}) {
      border: 1px solid var(--gray-5);
      box-sizing: border-box;
      box-shadow: 0 0 33px rgba(0 0 0 / 5%);
      border-radius: 15px;
    }

    .logo {
      width: 72px;
      height: 67px;
    }
  }
`
