import { useField } from "formik"
import { useMemo } from "react"

import { useExerciseParticipants } from "./exercise_component_utils"

import { SelectField } from "forms/fields"
import { sortUsersByShortName, useUser } from "resources/users"
import { useEffectAfterChange } from "ui/hooks"

const ExerciseParticipantSelectField = ({ saveOnChange, include_current_user, default_to_current_user, ...props }) => {
  const name = `${props.name}.value`
  const [field, { value }, { setValue }] = useField(name)
  const { data: currentUser } = useUser({ userId: "me" })
  const allParticipants = useExerciseParticipants()

  const sortedParticipants = useMemo(
    () => getSortedParticipants({ allParticipants, currentUser, include_current_user }),
    [allParticipants, currentUser, include_current_user]
  )

  useEffectAfterChange(() => {
    if (default_to_current_user && !value && currentUser) {
      setValue(currentUser.id)
      saveOnChange(name, currentUser.id)
    }
  }, [setValue, value, currentUser, name, saveOnChange, default_to_current_user])

  function handleOnBlur(evt) {
    field.onBlur(evt)
    const value = parseInt(evt.target.value)
    saveOnChange(name, value)
  }

  function handleOnChange(evt) {
    field.onChange(evt)
    const value = parseInt(evt.target.value)
    saveOnChange(name, value)
  }

  return (
    <SelectField name={name} onBlur={handleOnBlur} onChange={handleOnChange}>
      <option>Select one</option>
      {sortedParticipants.map(({ id, short_name }) => (
        <option key={id} value={id}>
          {short_name}
        </option>
      ))}
    </SelectField>
  )
}

const getSortedParticipants = ({ allParticipants, currentUser, include_current_user }) => {
  const otherParticipants = allParticipants?.filter((participant) => participant.id !== currentUser.id)
  return include_current_user
    ? sortUsersByShortName({ users: allParticipants })
    : sortUsersByShortName({ users: otherParticipants })
}

export default ExerciseParticipantSelectField
