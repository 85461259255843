import { useMemo } from "react"

import { useHasAccountFeature } from "domains/Admin/utils"
import { useGetUserAccount } from "resources/billing"
import { SHARED_FLAGS } from "ui/hooks/useFeatures"

function useHasTeamFeature(team, flag) {
  const isSharedFlag = Object.values(SHARED_FLAGS).includes(flag)
  const { data: account } = useGetUserAccount(!!isSharedFlag && team?.account_id)
  const { enabled: hasAccountFeature, isInitialLoading: accountFeatureIsLoading } = useHasAccountFeature(account, flag)

  if (!team?.features || (isSharedFlag && accountFeatureIsLoading)) {
    return {
      enabled: false,
      isInitialLoading: true,
    }
  }

  const hasTeamFeature = team.features.includes(flag) ?? false
  if (!isSharedFlag) {
    return {
      enabled: !!hasTeamFeature,
      isInitialLoading: false,
    }
  }

  return {
    enabled: !!(hasTeamFeature || hasAccountFeature),
    isInitialLoading: false,
  }
}

function getNonLeadMembers(team) {
  return team.members.filter((user) => user.id !== team.team_lead_id)
}

function getUserNameOrEmail(user) {
  return (user?.name?.length ? user?.name : user?.email) ?? null
}

function isMemberInvitePending(user) {
  return !!user?.invited_at && !user?.is_registered
}

function getLead(team, { cache = null } = {}) {
  let tl = cache?.get(team.team_lead_id)
  if (!tl) {
    tl = team.members.find((m) => m.id === team.team_lead_id)
    if (tl) {
      cache?.set(team.team_lead_id, tl)
    }
  }
  return tl ?? null
}

function getLeadName(team, { cache = null } = {}) {
  const tl = getLead(team, { cache })
  return tl?.name ?? null
}

function getLeadEmail(team, { cache = null } = {}) {
  const tl = getLead(team, { cache })
  return tl?.email ?? null
}

function getLeadNameOrEmail(team, { cache = null } = {}) {
  return getUserNameOrEmail(getLead(team, { cache }))
}

function isLeadInvitePending(team, { cache = null } = {}) {
  return isMemberInvitePending(getLead(team, { cache }))
}

function useTeamInfo() {
  // Provide getters for team lead information from a team object which cache
  // team lead results so that team members arrays are not repeatedly searched.
  const cache = useMemo(() => new Map(), [])
  return {
    getNonLeadMembers,
    isMemberInvitePending,
    getUserNameOrEmail,
    getLead: (team) => getLead(team, { cache }),
    getLeadName: (team) => getLeadName(team, { cache }),
    getLeadEmail: (team) => getLeadEmail(team, { cache }),
    getLeadNameOrEmail: (team) => getLeadNameOrEmail(team, { cache }),
    isLeadInvitePending: (team) => isLeadInvitePending(team, { cache }),
  }
}

export {
  useHasTeamFeature,
  getNonLeadMembers,
  getUserNameOrEmail,
  isMemberInvitePending,
  getLead,
  getLeadNameOrEmail,
  isLeadInvitePending,
  useTeamInfo,
}
