import { AsteriskIcon } from "icons/FontAwesomeIcons"
import { View } from "ui"

const MotivatorsCareerIntro = () => (
  <>
    <h2 className="mb-medium">Building your career motivators profile</h2>
    <p className="mb-medium">
      Now we're going to zoom out and explore what motivates you when making longer-term career decisions.
    </p>
    <View $alignItems="center" className="mb-medium">
      <AsteriskIcon className="fa-lg text-rising-yellow mr-small" />
      <p>
        Because of the potentially sensitive nature of this exercise,{" "}
        <span className="text-bold">all questions are optional</span> and this exercise will not include individual
        share outs.
      </p>
    </View>
    <p className="mb-xxxxl">
      We’ll look at an anonymous team rollup during the session. After the session, you and your team lead will be able
      to view your individual results on the Team tab. No one else from your team will see these results. We encourage
      you to have a follow up discussion with your manager reviewing these results so they can better understand and
      support you.
    </p>
  </>
)

export default MotivatorsCareerIntro
