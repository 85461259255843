function handleErrors(_onSubmit, handleErrorDataFn, _formik) {
  return async function (values, formik) {
    formik = formik || _formik
    const { setStatus, setErrors, setSubmitting } = formik
    setSubmitting(true)
    try {
      const response = await _onSubmit(values, formik)
      if (response?.message) {
        setStatus(response)
      }
    } catch (exception) {
      let { data, headers } = exception?.response ?? {}
      let handleData = (data) => setStatus(data)

      if (!data) {
        data = { message: exception.message }
      }

      if (headers?.["content-type"] === "application/json") {
        // For "errors/status" to persist use - setStatus(data)
        setStatus({})
        handleData = (data) => setErrors(data)
      } else if (headers?.["content-type"]?.startsWith("text/plain; charset=utf-8")) {
        data = { message: exception.message }
      } else {
        data = { message: "An error occurred." }
      }

      // Allow caller to perform custom processing on exception data:
      if (handleErrorDataFn) {
        const dataOrPromise = handleErrorDataFn(data, values, formik, exception)
        data = await Promise.resolve(dataOrPromise)
        // use Promise.resolve to collect result from handleErrorDataFn
        // properly regardless of whether or not it's an async function
      }

      // `data` may be returned from `handleErrorDataFn` as `null` if
      // caller wishes to `setErrors` themselves or perform other behavior:
      if (data) {
        handleData(data)
      }

      setSubmitting(false)
    }
  }
}

export default handleErrors
