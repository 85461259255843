import ContactOptions from "./ContactOptions"

import { HeaderLoggedOut } from "components/Header"
import RTKitViewBase from "components/RTKitViewBase"
import { HorizontalRule } from "ui"

const ServerError = () => (
  <>
    <HeaderLoggedOut className="content-max-width" />
    <RTKitViewBase>
      <div className="main-container">
        <h2>Oops! Something went wrong</h2>
        <HorizontalRule />
        <p>
          Sorry about that. Our team has been alerted, and we're working on fixing it as soon as possible. Please try
          again in a few minutes or{" "}
          <a href="mailto:hello@risingteam.com" target="_blank" rel="noopener noreferrer">
            contact us
          </a>{" "}
          and we'll assist you shortly.
        </p>
        <ContactOptions />
      </div>
    </RTKitViewBase>
  </>
)

export default ServerError
