import { Navigate, Route, Routes } from "react-router"

import ManuallyAddAccount from "./ManuallyAddAccount"
import QRCreator from "./QRCreator"

import { SimpleRouteLayout } from "domains/Authentication/Routes"
import { useUser } from "resources/users"
import { Loading, View } from "ui"
import { useFeatures } from "ui/hooks"
import { FLAGS } from "ui/hooks/useFeatures"

const RTOnlyLayout = () => {
  const { [FLAGS.RT_ONLY]: rt_only, isFetching } = useFeatures()
  const { data: user } = useUser({ userId: "me" })

  if (!user || (!rt_only && isFetching)) {
    return <Loading />
  }

  if (!user.is_staff || !rt_only) {
    return <Navigate to="/" replace />
  }

  return (
    <View>
      <div className="main-container full-width">
        <SimpleRouteLayout title="RT Only" />
      </div>
    </View>
  )
}

const RTOnly = () => (
  <Routes>
    <Route element={<RTOnlyLayout />}>
      <Route path="/manually_add_account" element={<ManuallyAddAccount />} />
      <Route path="/qr_creator" element={<QRCreator />} />
    </Route>
  </Routes>
)

export default RTOnly
