import { useQuery } from "@tanstack/react-query"

import api, { fetchApiPost } from "api"
import { DEFAULT_ARTI_HISTORY_ORDER } from "domains/Arti/constants"
import { addShortNameFieldToTeamMembers } from "resources/users"
import { checkNamedArguments } from "utils/function"
import { buildUrl } from "utils/string"

const cacheKeys = {
  teams: ["rtai", "teams"],
  historyTopExchanges(teamId = null, memberId = null, order = null, query = null) {
    const base = ["rtai", "history", "exchange", "top"]
    if (teamId || memberId || order || query) {
      return [
        ...base,
        {
          ...(teamId ? { teamId } : {}),
          ...(memberId ? { memberId } : {}),
          ...(order ? { order } : {}),
          ...(query ? { query } : {}),
        },
      ]
    } else {
      return base
    }
  },
  historySessionExchanges(exchangeId) {
    return ["rtai", "history", "exchange", exchangeId, "session"]
  },
}

function getArtiTeams() {
  return async () => {
    const url = buildUrl(["rtai", "teams"])
    const { data } = await api.get(url)
    return data.map((team) => addShortNameFieldToTeamMembers({ team }))
  }
}

function useArtiTeams({ enabled = true } = {}) {
  checkNamedArguments("useArtiTeams", arguments, { optional: { enabled } })
  return useQuery(cacheKeys.teams, getArtiTeams(), { enabled })
}

async function artiTeamMemberChatStreaming(
  teamId,
  memberId,
  message,
  { llm = null, sessionStartedAt = null, startExchangeId = null, prevExchangeId = null, testId = null } = {}
) {
  const url = buildUrl(["rtai", "team", teamId, "chat_stream"])
  return fetchApiPost(url, {
    member_id: memberId,
    message,
    llm,
    session_started_at: sessionStartedAt,
    start_exchange_id: startExchangeId,
    prev_exchange_id: prevExchangeId,
    test_id: testId,
  })
}

async function artiTeamMemberChatNonStreaming(
  teamId,
  memberId,
  message,
  { llm = null, sessionStartedAt = null, startExchangeId = null, prevExchangeId = null, testId = null } = {}
) {
  const url = buildUrl(["rtai", "team", teamId, "chat_stream"])
  const { data } = await api.post(url, {
    member_id: memberId,
    message,
    llm,
    session_started_at: sessionStartedAt,
    start_exchange_id: startExchangeId,
    prev_exchange_id: prevExchangeId,
    test_id: testId,
  })
  return data
}

async function preprocessArtiMemberData(teamId, memberId) {
  const url = buildUrl(["rtai", "team", teamId, "preprocess_member_data"])
  await api.post(url, { member_id: memberId })
}

async function updateArtiExchangeRating(exchangeId, values) {
  const url = buildUrl(["rtai", "history", "exchange", exchangeId, "chat_rating"])
  await api.post(url, values)
}

async function updateArtiExchangeInappropriateFlag(exchangeId, flagValue) {
  const url = buildUrl(["rtai", "history", "exchange", exchangeId, "chat_inappropriate_flag"])
  await api.post(url, { inappropriate_flag: flagValue })
}

async function updateExcludeFromManualReview(exchangeId, excludeFromManualReview) {
  const url = buildUrl(["rtai", "history", "exchange", exchangeId, "update_exclude_from_manual_review"])
  await api.post(url, { exclude_from_manual_review: excludeFromManualReview })
}

function getArtiHistoryTopExchanges({
  teamId = null,
  memberId = null,
  order = DEFAULT_ARTI_HISTORY_ORDER,
  query = null,
} = {}) {
  return async () => {
    const url = buildUrl(["rtai", "history", "exchange", "top"], {
      urlQueryParams: {
        order,
        ...(query ? { query } : {}),
        ...(teamId ? { teamId } : {}),
        ...(memberId ? { memberId } : {}),
      },
    })
    const { data } = await api.get(url)
    const { exchanges } = data
    return exchanges
  }
}

function useArtiHistoryTopExchanges({
  teamId = null,
  memberId = null,
  order = DEFAULT_ARTI_HISTORY_ORDER,
  query = null,
  enabled = true,
} = {}) {
  return useQuery(
    cacheKeys.historyTopExchanges(teamId, memberId, order, query),
    getArtiHistoryTopExchanges({ teamId, memberId, order, query }),
    { enabled: !!enabled }
  )
}

function getArtiHistorySessionExchanges(exchangeId) {
  return async () => {
    const url = buildUrl(["rtai", "history", "exchange", exchangeId, "session"])
    const { data } = await api.get(url)
    const { exchanges } = data
    return exchanges
  }
}

function useArtiHistorySessionExchanges(exchangeId, { enabled }) {
  return useQuery(cacheKeys.historySessionExchanges(exchangeId), getArtiHistorySessionExchanges(exchangeId), {
    enabled: !!enabled && !!exchangeId,
  })
}

function prependHistorySessionExchangeToQueryCache(queryClient, exchange, teamId = null, memberId = null) {
  const cacheKey = cacheKeys.historyTopExchanges(teamId, memberId)
  const existingList = queryClient.getQueryData(cacheKey, { exact: false }) ?? []
  const updatedList = [exchange, ...existingList]
  queryClient.setQueriesData(cacheKey, updatedList)
}

export {
  cacheKeys,
  useArtiTeams,
  artiTeamMemberChatStreaming,
  artiTeamMemberChatNonStreaming,
  preprocessArtiMemberData,
  updateArtiExchangeRating,
  updateArtiExchangeInappropriateFlag,
  updateExcludeFromManualReview,
  useArtiHistoryTopExchanges,
  useArtiHistorySessionExchanges,
  prependHistorySessionExchangeToQueryCache,
}
