import { useKitSession } from "./KitSessionContext"

import { renderBlocks, blockComponents } from "components/Blocks"
import { getTeamLead } from "domains/Exercise/results_utils"
import FeedbackPeerFramework from "domains/Exercise/ResultsComponents/FeedbackPeerFramework"
import {
  AggregatedResultsBlocksContainer,
  AppCodeURL,
  Confetti,
  CurrentUserContextContainer,
  CurrentUserIndividualResultsBlocksContainer,
  FacilitatorTip,
  GroupTimer,
  IconLabelGrid,
  IfAnyUserExerciseAnswerEqualsContainer,
  IfTeamLevelExerciseAnswerEqualsContainer,
  IfTeamLevelExerciseAnswerExistsOrNotContainer,
  MiniKitSessionSelectedUsersShareView,
  NumParticipantsWarning,
  NumPeopleCompleted,
  MarkStepCompleted,
  ParticipantBreakoutGroups,
  ParticipantCountGrid,
  ParticipantCountList,
  PreviewSessionStartButton,
  RaiseHandButton,
  RaisedHandPicker,
  ReflectionsForm,
  ResultsBlocksContainer,
  TeamExerciseNonSelectedParticipantOnly,
  TeamExerciseSelectedParticipantOnly,
  SessionExerciseForm,
  SessionTeamExerciseForm,
  SessionLink,
  SessionImage,
  SessionImagePrompt,
  ScheduleSession,
  SessionImageUploaded,
  SessionQRCode,
  SessionSelectedUsersShareView,
  SessionCodeCopyLink,
  SidebarContainer,
  StepTimer,
  StepTitle,
  TeamLevelExerciseResultsBlocksContainer,
  TeamMemberWelcomeStepInfo,
  NumMaxParticipants,
} from "domains/KitSession/components"
import { useHasTeamFeature } from "domains/Results/utils"
import { blockIcons } from "icons"
import { useKitParticipants, getAppCodeURL } from "resources/monthly_kit"
import { blockUIComponents, Callout, DefaultTimer, PromptTip, Button } from "ui"
import { SHARED_FLAGS } from "ui/hooks/useFeatures"
import { buildUrl } from "utils/string"

function getName(user, first = false, last = false) {
  const name = user?.name ?? null
  if (first) {
    return user?.first_name ?? name
  } else if (last) {
    return user?.last_name ?? name
  } else {
    return name
  }
}

const SessionCode = () => {
  const { kitInstance } = useKitSession()
  return kitInstance.session_code
}

const CodePageURL = () => getAppCodeURL()

const FullCodeURL = () => {
  const { kitInstance } = useKitSession()
  return buildUrl([getAppCodeURL(), kitInstance.session_code], { useTrailingSlash: false })
}

const UserName = ({ user, first = false, last = false }) => getName(user, first, last)

const TeamLeadName = ({ first = false, last = false }) => {
  const { kitInstance, team } = useKitSession()
  const { enabled: sessionRealtimeUpdatesV2 } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_REPLACE_POLLING)
  const { data: participants } = useKitParticipants({ kitInstance, sessionRealtimeUpdates: sessionRealtimeUpdatesV2 })

  if (!participants) {
    return null
  }
  const teamLead = getTeamLead(participants, team)
  return getName(teamLead, first, last)
}

const TeamLeadOnly = ({ isTeamLead, children }) => (!isTeamLead ? null : children)

const TeamMemberOnly = ({ isTeamLead, children }) => (isTeamLead ? null : children)

const HideIfPreview = ({ children }) => {
  const { preview } = useKitSession()
  return !!preview ? null : children
}

const ShowIfPreview = ({ children }) => {
  const { preview } = useKitSession()
  return !!preview ? children : null
}

const ShowIfSharedFlagActive = ({ sharedFlag, children }) => {
  const { team } = useKitSession()
  const { enabled } = useHasTeamFeature(team, sharedFlag)
  return enabled ? children : null
}

const ShowIfSharedFlagInactive = ({ sharedFlag, children }) => {
  const { team } = useKitSession()
  const { enabled } = useHasTeamFeature(team, sharedFlag)
  return enabled ? null : children
}

// Session Components
const SESSION_COMPONENTS = {
  AggregatedResultsBlocksContainer,
  AppCodeURL,
  Button,
  Callout,
  Confetti,
  CurrentUserContextContainer,
  CurrentUserIndividualResultsBlocksContainer,
  DefaultTimer,
  FacilitatorTip,
  FeedbackPeerFramework,
  GroupTimer,
  IconLabelGrid,
  IfAnyUserExerciseAnswerEqualsContainer,
  IfTeamLevelExerciseAnswerEqualsContainer,
  IfTeamLevelExerciseAnswerExistsOrNotContainer,
  HideIfPreview,
  MiniKitSessionSelectedUsersShareView,
  NumMaxParticipants,
  NumParticipantsWarning,
  NumPeopleCompleted,
  MarkStepCompleted,
  ParticipantBreakoutGroups,
  ParticipantCountGrid,
  ParticipantCountList,
  PreviewSessionStartButton,
  PromptTip,
  RaiseHandButton,
  RaisedHandPicker,
  ReflectionsForm,
  ResultsBlocksContainer,
  TeamExerciseNonSelectedParticipantOnly,
  TeamExerciseSelectedParticipantOnly,
  SessionCode,
  SessionExerciseForm,
  SessionTeamExerciseForm,
  SessionLink,
  SessionImage,
  SessionImagePrompt,
  ScheduleSession,
  SessionImageUploaded,
  SessionQRCode,
  SessionSelectedUsersShareView,
  SessionCodeCopyLink,
  CodePageURL,
  FullCodeURL,
  ShowIfSharedFlagActive,
  ShowIfSharedFlagInactive,
  ShowIfPreview,
  SidebarContainer,
  StepTimer,
  StepTitle,
  TeamLevelExerciseResultsBlocksContainer,
  TeamLeadName,
  TeamLeadOnly,
  TeamMemberOnly,
  TeamMemberWelcomeStepInfo,
  UserName,
}

const isSessionComponent = (Tag) => Object.values(SESSION_COMPONENTS).includes(Tag)

export default function renderKitBlocks(
  content,
  {
    kitInstance,
    sessionStep,
    user,
    isTeamLead,
    preview,
    navigateNextHandler,
    navigateBackHandler,
    nextButtonProps,
    backButtonProps,
    navRightContent,
  }
) {
  const components = { ...blockComponents, ...blockUIComponents, ...blockIcons, ...SESSION_COMPONENTS }
  return renderBlocks(content, components, ({ Tag, props }) => {
    if (isSessionComponent(Tag)) {
      return {
        ...props,
        kitInstance,
        sessionStep,
        user,
        isTeamLead,
        preview,
        navigateNextHandler,
        navigateBackHandler,
        nextButtonProps,
        backButtonProps,
        navRightContent,
      }
    } else {
      return props
    }
  })
}
