import { renderBlocks, blockComponents } from "components/Blocks"
import { blockIcons } from "icons"
import { blockUIComponents, DefaultTimer } from "ui"

const ExerciseBlocks = ({ blocks }) => {
  const components = { ...blockComponents, ...blockUIComponents, ...blockIcons, DefaultTimer }
  return <>{renderBlocks(blocks, components)}</>
}

export default ExerciseBlocks
